import styles from './TableBody.module.less'
import { TableCellType, TableRowType, UUID, } from '@/legacy/types'
import { UserRoles, } from '@/legacy/constants'
import TableRow from '@/legacy/components/EffectiveContract/TableContainer/components/TableBody/components/TableRow/TableRow'
import { EcTableName, } from '@/legacy/components/EffectiveContract/constants'
// import { EcRecordDataType, EcTableData, } from '@/gql/hooks/effectiveContractHooks/useGetTableData'


interface TableBodyProps {
  // userGuid?: UUID, // TODO: удалить это поле
  // currentUserId: UUID, // TODO: удалить это поле
  // role: UserRoles, // TODO: удалить это поле
  // tableName: EcTableName, // TODO: удалить это поле
  // isEditable: boolean, // TODO: удалить это поле
  headerFields?: TableCellType[]
  ecTableData: TableRowType[]
  dataType: EcTableName
  isAppealMode?: boolean
  // recordData: EcRecordDataType,
  // recordRowValues: TableRowType[] | undefined,
  // onUpdateRowHandler?: Function, // TODO: удалить это поле
  // onUpdateRowForTwoUsersHandler?: Function, // TODO: удалить это поле
  // onAlternativeUpdateHandler?: Function, // TODO: удалить это поле
  // popUpControllHandler?: Function, // TODO: удалить это поле
  // getRowData?: Function, // TODO: удалить это поле
  // extra?: { // TODO: удалить это поле
  //   isHeadOfDepartment?: boolean,
  //   returnDataForDisplayRejectReason?: Function,
  //   returnDataForAddManyParticipants?: Function,
  // },
}


const TableBody = ({
  headerFields,
  ecTableData,
  dataType,
  isAppealMode,
}: TableBodyProps) => (
  <tbody>
    { ecTableData?.map((row, rowNumber: number) => (
      <TableRow
        key={ row.rec_id }
        headerFields={ headerFields }
        row={ row }
        // recordData={ ecTableData.recordData }
        rowNumber={ rowNumber }
        dataType={ dataType }
        isAppealMode={ isAppealMode }
      />
    )) }
  </tbody >
)


export default TableBody
