import { useState, } from 'react'
import './WorkerInner.less'
import { IconChevronDown, } from '@tabler/icons'
import { Table, } from 'antd'
import info from '../../../../assets/images/info-icon.svg'
import TooltipTgu from '@/shared/ui/TooltipTgu/TooltipTgu'
import { IconTriangleInverted, } from '@tabler/icons'
import { IconEye, IconEyeOff, } from '@tabler/icons'
import ResultsTable from './ResultsTable/ResultsTable'
import { IconPencil, } from '@tabler/icons'
import EditPopup from './EditPopup/EditPopup'
import HomeWorkPopup from './HomeWorkPopup/HomeWorkPopup'
import Popper from '@mui/material/Popper'
import clsx from 'clsx'
import { TooltipPlacementVariant, } from '@/shared/ui/TooltipTgu/types'

function WorkerInner () {
  const [
    isResults,
    setIsResults,
  ] = useState(false)
  const [
    isEdit,
    setIsEdit,
  ] = useState(false)
  const [
    isHomework,
    setIsHomework,
  ] = useState()
  const [
    cellName,
    setCellName,
  ] = useState()
  const [
    cellMark,
    setCellMark,
  ] = useState()
  const [
    date,
    setDate,
  ] = useState()
  const [
    lectureTitle,
    setLectureTitle,
  ] = useState()

  function close () {
    setIsEdit(false)
    // @ts-expect-error TS(2345): Argument of type 'false' is not assignable to para... Remove this comment to see the full error message
    setIsHomework(false)
    setAnchorEl(null)
  }

  function handleHomework (title: any) {
    // @ts-expect-error TS(2345): Argument of type 'true' is not assignable to param... Remove this comment to see the full error message
    setIsHomework(!isHomework)
    setLectureTitle(title)
  }

  function handleResults () {
    setIsResults(!isResults)
  }

  const [
    anchorEl,
    setAnchorEl,
  ] = useState(null)

  function handleClick (event: any, name: any, mark: any, date: any) {
    setCellName(name)
    setCellMark(mark)
    setDate(date)
    setAnchorEl(anchorEl ? null : event.currentTarget)
    setIsEdit(!isEdit)
    event.currentTarget.closest('td').classList.toggle('currentCell')
  }

  const handleClose = () => {
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    document.querySelector('.currentCell').classList.remove('currentCell')
    setAnchorEl(null)
    setIsEdit(!isEdit)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popper' : undefined

  const columns: any = [
    {
      title: '№',
      width: 40,
      dataIndex: 'number',
      key: 'number',
      fixed: 'left',
    },
    {
      title: (
        <div className="headGrop">
          <span>1 подгруппа</span>
          <IconTriangleInverted color={ '#C5C5C5' } size={ 13 } />
        </div>
      ),
      width: 300,
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
    },

    {
      width: 214,
      title: (
        <TooltipTgu
          children={
            <div
              onClick={ () => handleHomework('Системный подход к анализу данных') }
              style={ { cursor: 'pointer', } }
              className="infoTitle"
            >
              <img src={ info } alt="info" />
              <p>Системный подход к анализу данных</p>
              <div>21.11.21</div>
            </div>
          }
          title="Системный подход к анализу данных"
          placement={ TooltipPlacementVariant.bottom } />
      ),
      dataIndex: '1',
      key: '1',
    },
    {
      width: 214,
      title: (
        <TooltipTgu
          children={
            <div
              onClick={ () => handleHomework('Системный подход к анализу данных') }
              style={ { cursor: 'pointer', } }
              className="infoTitle"
            >
              <img src={ info } alt="info" />
              <p>Системный подход к анализу данных</p>
              <div>21.11.21</div>
            </div>
          }
          title="Системный подход к анализу данных"
          placement={ TooltipPlacementVariant.bottom } />
      ),
      dataIndex: '2',
      key: '2',
    },
    {
      width: 214,
      title: (
        <TooltipTgu
          children={
            <div
              onClick={ () => handleHomework('Системный подход к анализу данных') }
              style={ { cursor: 'pointer', } }
              className="infoTitle"
            >
              <img src={ info } alt="info" />
              <p>Системный подход к анализу данных</p>
              <div>21.11.21</div>
            </div>
          }
          title="Системный подход к анализу данных"
          placement={ TooltipPlacementVariant.bottom } />
      ),
      dataIndex: '3',
      key: '3',
    },
    {
      width: 214,
      title: (
        <TooltipTgu
          children={
            <div
              onClick={ () => handleHomework('Системный подход к анализу данных') }
              style={ { cursor: 'pointer', } }
              className="infoTitle"
            >
              <img src={ info } alt="info" />
              <p>Системный подход к анализу данных</p>
              <div>21.11.21</div>
            </div>
          }
          title="Системный подход к анализу данных"
          placement={ TooltipPlacementVariant.bottom } />
      ),
      dataIndex: '4',
      key: '4',
    },
    {
      width: 214,
      title: (
        <TooltipTgu
          children={
            <div
              onClick={ () => handleHomework('Системный подход к анализу данных') }
              style={ { cursor: 'pointer', } }
              className="infoTitle"
            >
              <img src={ info } alt="info" />
              <p>Системный подход к анализу данных</p>
              <div>21.11.21</div>
            </div>
          }
          title="Системный подход к анализу данных"
          placement={ TooltipPlacementVariant.bottom } />
      ),
      dataIndex: '5',
      key: '5',
    },
    {
      width: 214,
      title: (
        <TooltipTgu
          children={
            <div
              onClick={ () => handleHomework('Системный подход к анализу данных') }
              style={ { cursor: 'pointer', } }
              className="infoTitle"
            >
              <img src={ info } alt="info" />
              <p>Системный подход к анализу данных</p>
              <div>21.11.21</div>
            </div>
          }
          title="Системный подход к анализу данных"
          placement={ TooltipPlacementVariant.bottom } />
      ),
      dataIndex: '6',
      key: '6',
    },
    {
      width: 214,
      title: (
        <TooltipTgu
          children={
            <div
              onClick={ () => handleHomework('Системный подход к анализу данных') }
              style={ { cursor: 'pointer', } }
              className="infoTitle"
            >
              <img src={ info } alt="info" />
              <p>Системный подход к анализу данных</p>
              <div>21.11.21</div>
            </div>
          }
          title="Системный подход к анализу данных"
          placement={ TooltipPlacementVariant.bottom } />
      ),
      dataIndex: '7',
      key: '7',
    },
  ]
  const data = [
    {
      key: '1',
      name: 'Васильева Анна Игоревна',
      1: (
        <div
          className={ clsx('fillCell') }
          onClick={ e => handleClick(e, 'Васильева Анна Игоревна', '5', '21.10.21')
          }
        >
          <IconPencil color={ '#C5C5C5' } size={ 15 } className="edit" />5
        </div>
      ),
      2: (
        <div
          className={ clsx('fillCell') }
          onClick={ e => handleClick(e, 'Васильева Анна Игоревна', '5', '21.10.21')
          }
        >
          <IconPencil color={ '#C5C5C5' } size={ 15 } className="edit" />5
        </div>
      ),
      3: (
        <div
          className={ clsx('fillCell') }
          onClick={ e => handleClick(e, 'Васильева Анна Игоревна', '5', '21.10.21')
          }
        >
          <IconPencil color={ '#C5C5C5' } size={ 15 } className="edit" />5
        </div>
      ),
      4: (
        <div
          className={ clsx('fillCell') }
          onClick={ e => handleClick(e, 'Васильева Анна Игоревна', '5', '21.10.21')
          }
        >
          <IconPencil color={ '#C5C5C5' } size={ 15 } className="edit" />5
        </div>
      ),
      5: (
        <div
          className={ clsx('fillCell') }
          onClick={ e => handleClick(e, 'Васильева Анна Игоревна', '5', '21.10.21')
          }
        >
          <IconPencil color={ '#C5C5C5' } size={ 15 } className="edit" />5
        </div>
      ),
      6: (
        <div
          className={ clsx('fillCell') }
          onClick={ e => handleClick(e, 'Васильева Анна Игоревна', '5', '21.10.21')
          }
        >
          <IconPencil color={ '#C5C5C5' } size={ 15 } className="edit" />5
        </div>
      ),
      7: (
        <div
          className={ clsx('fillCell') }
          onClick={ e => handleClick(e, 'Васильева Анна Игоревна', '5', '21.10.21')
          }
        >
          <IconPencil color={ '#C5C5C5' } size={ 15 } className="edit" />5
        </div>
      ),
      number: '1',
    },
    {
      key: '2',
      name: 'Васильева Анна Игоревна',
      1: (
        <div
          className={ clsx('fillCell') }
          onClick={ e => handleClick(e, 'Васильева Анна Игоревна', '5', '21.10.21')
          }
        >
          <IconPencil color={ '#C5C5C5' } size={ 15 } className="edit" />5
        </div>
      ),
      2: (
        <div
          className={ clsx('fillCell') }
          onClick={ e => handleClick(e, 'Васильева Анна Игоревна', '5', '21.10.21')
          }
        >
          <IconPencil color={ '#C5C5C5' } size={ 15 } className="edit" />5
        </div>
      ),
      3: (
        <div
          className={ clsx('fillCell') }
          onClick={ e => handleClick(e, 'Васильева Анна Игоревна', '5', '21.10.21')
          }
        >
          <IconPencil color={ '#C5C5C5' } size={ 15 } className="edit" />5
        </div>
      ),
      4: (
        <div
          className={ clsx('fillCell') }
          onClick={ e => handleClick(e, 'Васильева Анна Игоревна', '5', '21.10.21')
          }
        >
          <IconPencil color={ '#C5C5C5' } size={ 15 } className="edit" />5
        </div>
      ),
      5: (
        <div
          className={ clsx('fillCell') }
          onClick={ e => handleClick(e, 'Васильева Анна Игоревна', '5', '21.10.21')
          }
        >
          <IconPencil color={ '#C5C5C5' } size={ 15 } className="edit" />5
        </div>
      ),
      6: (
        <div
          className={ clsx('fillCell') }
          onClick={ e => handleClick(e, 'Васильева Анна Игоревна', '5', '21.10.21')
          }
        >
          <IconPencil color={ '#C5C5C5' } size={ 15 } className="edit" />5
        </div>
      ),
      7: (
        <div
          className={ clsx('fillCell') }
          onClick={ e => handleClick(e, 'Васильева Анна Игоревна', '5', '21.10.21')
          }
        >
          <IconPencil color={ '#C5C5C5' } size={ 15 } className="edit" />5
        </div>
      ),
      number: '1',
    },
    {
      key: '3',
      name: 'Васильева Анна Игоревна',
      1: (
        <div
          className={ clsx('fillCell') }
          onClick={ e => handleClick(e, 'Васильева Анна Игоревна', '5', '21.10.21')
          }
        >
          <IconPencil color={ '#C5C5C5' } size={ 15 } className="edit" />5
        </div>
      ),
      number: '1',
    },
    {
      key: '4',
      name: 'Васильева Анна Игоревна',
      1: (
        <div
          className={ clsx('fillCell') }
          onClick={ e => handleClick(e, 'Васильева Анна Игоревна', '5', '21.10.21')
          }
        >
          <IconPencil color={ '#C5C5C5' } size={ 15 } className="edit" />5
        </div>
      ),
      number: '1',
    },
    {
      key: '5',
      name: 'Васильева Анна Игоревна',
      1: (
        <div
          className={ clsx('fillCell') }
          onClick={ e => handleClick(e, 'Васильева Анна Игоревна', '5', '21.10.21')
          }
        >
          <IconPencil color={ '#C5C5C5' } size={ 15 } className="edit" />5
        </div>
      ),
      number: '1',
    },
    {
      key: '6',
      name: 'Васильева Анна Игоревна',
      1: (
        <div
          className={ clsx('fillCell') }
          onClick={ e => handleClick(e, 'Васильева Анна Игоревна', '5', '21.10.21')
          }
        >
          <IconPencil color={ '#C5C5C5' } size={ 15 } className="edit" />5
        </div>
      ),
      number: '1',
    },
    {
      key: '7',
      name: 'Васильева Анна Игоревна',
      1: (
        <div
          className={ clsx('fillCell') }
          onClick={ e => handleClick(e, 'Васильева Анна Игоревна', '5', '21.10.21')
          }
        >
          <IconPencil color={ '#C5C5C5' } size={ 15 } className="edit" />5
        </div>
      ),
      number: '1',
    },
  ]

  return (
    <div className="WorkerIn">
      <div className="tableSet">
        <div className="lectures">
          Лекции
          <IconChevronDown size={ 20 } />
        </div>
        <div className="showResults" onClick={ handleResults }>
          { isResults ? (
            <IconEyeOff color={ '#C5C5C5' } />
          ) : (
            <IconEye color={ '#7671DD' } />
          ) }
          <p>
            { isResults
              ? 'Скрыть итоговые результаты'
              : 'Показать итоговые результаты' }
          </p>
        </div>
      </div>
      <div className="K-ResultsWrap" style={ { position: 'relative', } }>
        <Table
          className={ 'd-scrollbar' }
          columns={ columns }
          dataSource={ data }
          scroll={ { x: 1300, } }
        />
        { isResults ? <ResultsTable /> : <></> }
      </div>
      <div
        onClick={ handleClose }
        className={ clsx('editBackground', isEdit && 'backVisible') }
      ></div>
      <HomeWorkPopup close={ close } isOpen={ isHomework } title={ lectureTitle } />
      <Popper
        style={ {
          overflow: 'visible',
          zIndex: '100',
        } }
        id={ id }
        open={ open }
        anchorEl={ anchorEl }
        disablePortal={ false }
      >
        <EditPopup
          isOpen={ isEdit }
          close={ close }
          name={ cellName }
          mark={ cellMark }
          date={ date }
        />
      </Popper>
    </div>
  )
}

export default WorkerInner
