import React, { useMemo, useEffect, } from 'react'
import { useSelector, useDispatch, } from 'react-redux'
import Preloader from '@/legacy/components/common/PreLoader/Preloader'
import NoContracts from '@/legacy/components/Finances/PaymentPage/NoContracts/NoContracts'
import { updateReference, } from '@/legacy/redux/Subscriptions/SubscriptionsSlice'
import EmployeeApplicationsTemplate from '@/legacy/components/Mfc/ReferenceTemplate/EmployeeApplicationsTemplate/EmployeeApplicationsTemplate'
import { useReferenceChanged } from '@/legacy/gql/hooks/mfc/useReferences'
import { useGetApplicationsMfc } from '@/legacy/gql/hooks/mfc/useApplications'

const EmployeeApplications = () => {
  const activeStatus = useSelector((state: any) => state.mfc.activeStatus)
  const refresh = useSelector((state: any) => state.subscriptions.references)

  const useReferenceEvent = () => {
    const dispatch = useDispatch()
    const { error, loading, data, } = useReferenceChanged()
    useEffect(() => {
      if (loading || error) {
        return
      }
      if (!data?.referenceChanged) {
        return
      }
      dispatch(updateReference())
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      loading,
      error,
      data,
    ])
  }

  useReferenceEvent()

  const { data, error, loading, refetch, } = useGetApplicationsMfc({
    type: 'EmployeeStatements',
    status: activeStatus.serverStat,
  })

  useEffect(() => {
    refetch()
  }, [
    refetch,
    refresh,
  ])

  const applications = useMemo(() => {
    if (loading) {
      return <Preloader />
    }
    if (error) {
      return <div>Произошла ошибка, попробуйте поздней</div>
    } else if (data) {
      if (data.mfcStatements.items.length < 1) {
        return <NoContracts title="У вас еще нет активных заявок" />
      }
      return data.mfcStatements.items.map((i: any) => (
        <EmployeeApplicationsTemplate key={ i.doc_id } content={ i } />
      ))
    }
  }, [
    data,
    error,
    loading,
  ])

  return <ul>{ applications }</ul>
}

export default EmployeeApplications
