import { months } from "./Table";
import format from "date-fns/format";
import ru from "date-fns/locale/ru";

export const SHORT_WEEKDAYS = [
  {
    ru: "пн",
    upd: "Пн",
    en: "Mo",
  },
  {
    ru: "вт",
    upd: "Вт",
    en: "Tu",
  },
  {
    ru: "ср",
    upd: "Ср",
    en: "We",
  },
  {
    ru: "чт",
    upd: "Чт",
    en: "Th",
  },
  {
    ru: "пт",
    upd: "Пт",
    en: "Fr",
  },
  {
    ru: "сб",
    upd: "Сб",
    en: "Sa",
  },
  {
    ru: "вс",
    upd: "Вс",
    en: "Su",
  },
];

export function changeWeekDays(date: any) {
  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  const result = SHORT_WEEKDAYS.find(
    (i) =>
      i.ru === format(new Date(date), "EEEEEE", { locale: ru }).toLowerCase()
  ).upd;
  return result;
}

export function changeFullDate(date: any) {
  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  const month = months.find(
    (i) => i.check === format(new Date(date), "MMMM", { locale: ru })
  ).ru;
  const year = format(new Date(date), "yyyy", { locale: ru });

  return `${month} ${year} г.`;
}

export function translateDate(date: any) {
  alert(format(new Date(date), "MMMM", { locale: ru }));
  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  const month = months.find(
    (i) => i.en === format(new Date(date), "MMMM", { locale: ru })
  ).ru;
  const year = format(new Date(date), "yyyy", { locale: ru });

  return `${month} ${year} г.`;
}

export function translateWeekDate(date: any) {
  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  const result = SHORT_WEEKDAYS.find(
    (i) =>
      i.en === format(new Date(date), "EEEEEE", { locale: ru }).toLowerCase()
  ).upd;
  return result;
}
