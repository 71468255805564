import { useState, useMemo, } from 'react'
import HistoryLinks from '../../common/HistoryLinks/HistoryLinks'
import './ScolarshipPage.less'
import { Table, } from 'antd'
import Payouts from './Payouts/Payouts'
import { usePlannedCharges, } from '../../../gql/hooks/useFinances'
import NoContracts from '../PaymentPage/NoContracts/NoContracts'
import ScolarInfo from './ScolarInfo/ScolarInfo'
import FilterButtons from '@/legacy/components/common/FilterButtons/FilterButtons'


function ScolarshipPage () {
  const filterBut = [
    'Назначенные стипендии и пособия',
    'Выплаты',
    'Информационная справка',
  ]
  const [
    filtStatus,
    setFiltStatus,
  ] = useState('Назначенные стипендии и пособия')

  function setStatus (i: any) {
    setFiltStatus(i)
  }

  function ScholarShips (): JSX.Element {
    const { data, error, loading, } = usePlannedCharges()

    const dormitoryTable = useMemo(() => {
      if (loading && !data) {
        return <div>....loading</div>
      } else if (error) {
        return <div>..........error</div>
      }
      const initial = data?.plannedCharges.block_accruals
      const columns = [
        {
          title: 'Вид',
          dataIndex: 'type',
          key: 'type',
          width: 500,
        },
        {
          title: 'Сумма',
          dataIndex: 'summ',
          key: 'summ',
          width: 300,
        },
        {
          title: 'Период назначения',
          dataIndex: 'period',
          key: 'period',
          width: 300,
        },
      ]

      // @ts-expect-error TS(2362): The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
      const sortedDates = initial.slice().sort((a: any, b: any) => new Date(a.valid_with) - new Date(b.valid_with))
      const content = sortedDates.reverse().map((i: any) => {
        return {
          key: sortedDates.indexOf(i),
          type: i.accrual,
          summ: `${ i.sum } руб.`,
          period: i.valid_with,
        }
      })


      return content?.length > 0 ? <div className="scol"><Table pagination={ false } columns={ columns } dataSource={ content/* ?.reverse() */ } /></div> : <NoContracts title="Информация отсутствует" />


      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      filtStatus,
      data,
      error,
      loading,
    ])

    return (
      data ? dormitoryTable : (
        <NoContracts
          title="Информация о назначенных стипендиях и пособиях отсутствует"
        />
      )
    )
  }


  return (
    <div className="payment">
      <HistoryLinks
        links={ [
          {
            value: 'Главная',
            path: '/',
          },
        ] }
        loc="Стипендии и выплаты"
      />

      <h2 className="paymentTitle">Стипендии и выплаты</h2>

      <FilterButtons
        onClick={ setStatus }
        items={ filterBut }
        status={ filtStatus }
      />

      { filtStatus === 'Назначенные стипендии и пособия' ? <ScholarShips /> : false }
      { filtStatus === 'Выплаты' ? <Payouts /> : false }
      { filtStatus === 'Информационная справка' ? <ScolarInfo /> : false }
    </div>
  )
}


export default ScolarshipPage
