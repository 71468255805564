/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import './UsersTable.less'
import TooltipTgu from '@/shared/ui/TooltipTgu/TooltipTgu'

import React, { useMemo, useEffect, useState, useCallback, } from 'react'
import { Table, } from 'antd'
import { useGetParticipantsFac, } from '../../../../../gql/hooks/useMyEducation'
import { useLocation, } from 'react-router-dom'
import Preloader from '../../../../common/PreLoader/Preloader'
import { useSelector, useDispatch, } from 'react-redux'
import { setSignedStudents, } from '../../../../../redux/MyEducationSlice'
import {
  IconTrash,
  IconCircleX,
  IconCirclePlus,
  IconUsers,
} from '@tabler/icons'
import { useSignOutFacultative, } from '../../../../../gql/hooks/useMyEducation'
import ButtonSimple from '../../../../common/ButtonSimple/ButtonSimple'
import { IconComponent, } from '../../../../common/ButtonSimple/ButtonSimple'
import { useAppContext, } from '@/App/providers/AppProvider'
import { URL as url, } from '../../../../../Utils/constants'
import { TooltipPlacementVariant, } from '@/shared/ui/TooltipTgu/types'

const columns = [
  {
    title: '№',
    dataIndex: 'number',
  },
  {
    title: 'ФИО',
    dataIndex: 'fullName',
  },
  {
    title: 'Уровень',
    dataIndex: 'level',
  },
  {
    title: 'Отписать студента',
    dataIndex: 'signout',
  },
]

const FacultativeStudentsTable = ({ setIsAddStudent, isAddStudent, }: any) => {
  const location = useLocation()
  const id = new URLSearchParams(location.search).get('id')
  const name = new URLSearchParams(location.search).get('name')
  const [
    isDeleteDisabled,
    setIsDeleteDisabled,
  ] = useState<boolean>(false)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const participants = useSelector(state => state.myEd.signedStudents)

  const [
    payload,
    setPayload,
  ] = useState({
    facultative_id: Number(id),
    participant_id: [],
  })

  const [
    esSignOutFacultative,
  ] = useSignOutFacultative(payload)

  async function setId (i: any) {
    setPayload({
      ...payload,
      participant_id: i,
    })
  }

  const handleSignOut = useCallback(
    (i: any) => {
      setIsDeleteDisabled(true)
      if (!isDeleteDisabled) {
        setId([
          i,
        ]).then(() => {
          esSignOutFacultative()
            .then((res: any) => {
              addNotification?.(
                'Мое обучение',
                res.data.esSignOutFacultative.map((i: any) => i[3]),
                {
                  message: `Вас отписали от факультатива "${ name }". Пожалуйста, выберите другой факультатив.`,
                },
                { path: '/learning-pathway', }
              )
            })
            .then(() => {
              setIsDeleteDisabled(false)
              openToast?.({ message: 'Вы успешно отписали студента', })
            })
            .catch(e => console.log(e))
        })
      }
      return
    },
    [
      esSignOutFacultative,
      setId,
    ]
  )

  const dispatch = useDispatch()

  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const refresh = useSelector(state => state.myEd.updateFacultatives)

  const { data, error, loading, refetch, } = useGetParticipantsFac({
    facultative_id: Number(id),
  })

  const { addNotification, openToast, } = useAppContext()

  const handleSignOutAll = () => {
    setIsDeleteDisabled(true)
    setId(participants.map((i: any) => i.id)).then(() => {
      esSignOutFacultative()
        .then((res: any) => {
          addNotification?.(
            'Мое обучение',
            res.data.esSignOutFacultative.map((i: any) => i[3]),
            {
              message: `Вас отписали от факультатива "${ name }". Пожалуйста, выберите другой факультатив.`,
            },
            { path: '/learning-pathway', }
          )
        })
        .then(() => {
          setIsDeleteDisabled(false)
          openToast?.({ message: 'Вы успешно отписали всех участников факультатива', })
        })
        .catch(e => console.log(e))
    })
  }

  const downloadFile = () => {
    const link = document.createElement('a')
    link.href = `https://${ url }/api/es/xls?type=facultative&id=${ id }`
    // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
    link.download = name
    link.click()
  }

  useEffect(() => {
    refetch()
  }, [
    refresh,
    refetch,
  ])

  const table = useMemo(() => {
    if (error) {
      return <div>error</div>
    }
    if (loading) {
      return <Preloader />
    } else if (data) {
      dispatch(setSignedStudents(data.esSignedStudents))

      const withoutDoubles: any = []

      data.esSignedStudents.reduce(
        (previos: any, current: any, index: any) => {
          if (previos.id !== current.id) {
            withoutDoubles.push({
              key: current.id,
              number: index + 1,
              fullName: current.full_name,
              level: current.level ? current.level : 'Факультатив без уровней',
              signout: (
                <TooltipTgu
                  children={
                    <IconTrash
                      color="rgb(255, 94, 94)"
                      onClick={ () => handleSignOut(current.id) }
                    />
                  }
                  title="Отписать студента"
                  placement={ TooltipPlacementVariant.bottom } />
              ),
            })
          }
          return current
        },
        { id: 'wfgreg', }
      )

      const locale = {
        emptyText: (
          <div style={ { padding: '30px 0', } }>Нет записавшихся студентов</div>
        ),
      }
      return (
        <>
          <Table
            /* components={components} */
            locale={ locale }
            rowClassName={ () => 'editable-row' }
            bordered
            dataSource={ withoutDoubles }
            columns={ columns }
            pagination={ false }
          />
          <div className="discipline-detail-card__students-distribution-buttons-wrapper">
            <ButtonSimple
              value={
                <IconComponent text="Добавить студента" Icon={ IconCirclePlus } />
              }
              active
              onClickHandler={ () => setIsAddStudent(!isAddStudent) }
            />

            <ButtonSimple
              value={
                <IconComponent
                  text="Отписать всех"
                  Icon={ IconCircleX }
                  iconStyles={ { color: '#FF5E5E', } }
                />
              }
              variant="groupe"
              onClickHandler={ handleSignOutAll }
            />
            <ButtonSimple
              onClickHandler={ downloadFile }
              styles={ { marginLeft: '0.6rem', } }
              value={
                <IconComponent
                  text="Выгрузить список записанных студентов"
                  Icon={ IconUsers }
                />
              }
              variant="groupe"
            />
          </div>
        </>
      )
    }
  }, [
    data,
    error,
    loading,
    dispatch,
    handleSignOut,
  ])

  return (
    <div className="discipline-detail-card__students-distribution-users-table">
      { table }
    </div>
  )
}

export default FacultativeStudentsTable
