import { legacy_effectiveContractModerGroupe, } from '@/legacy/components/EffectiveContract/constants/effectiveContractModersAndAdmins-constants'
import { format, } from 'date-fns'
import { useEffect, useState, } from 'react'
import { useSelector, } from 'react-redux'
import { useLocation, } from 'react-router-dom'
import { useCreateEcData, useUpdateEcData, } from '../../../../../gql/hooks/effectiveContractHooks/useEffectiveContract'
import { useAppContext, } from '@/App/providers/AppProvider'
import AttachedFiles from '../../../../common/AttachedFiles/AttachedFiles'
import ButtonSimple from '../../../../common/ButtonSimple/ButtonSimple'
import CalendarSimple from '../../../../common/CalendarSimple/CalendarSimple'
import { TitleH5, } from '../../../../common/GeneralElements/GeneralElements'
import InputFile from '../../../../common/InputFile/InputFile'
import InputSelectDeprecated from '../../../../common/InputSelectDeprecated/InputSelectDeprecated'
import InputTextDeprecated from '../../../../common/InputTextDeprecated/InputTextDeprecated'
import { defineUserGroupe, } from '../../../defineUserGroupe'
import SpecsSearch from '../../../Hooks/SpecsSearch/SpecsSearch'
import { table_cat_2_5, } from '../../../popUpInputsCatalogs'

const Inputs_2_5 = ({
  onClickHandler,
  hint,
  defaultValues,
}: any) => {
  const { addNotification, }: any = useAppContext()


  const { search, } = useLocation()
  const moderateId = new URLSearchParams(search).get('id')
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const currentRole = useSelector(state => state.auth.choosenRole)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const currentUserType = useSelector(state => state.auth.userType)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const currentUserId = useSelector(state => state.auth.me.data.guid)

  const [
    user_ids,
  ] = useState(
    (
      defineUserGroupe(currentRole, legacy_effectiveContractModerGroupe)
      || defineUserGroupe(currentUserType, legacy_effectiveContractModerGroupe)
    )
      ? moderateId
      : currentUserId)


  const [
    moderOrAdminCreatorId,
  ] = useState(
    (
      defineUserGroupe(currentRole, legacy_effectiveContractModerGroupe)
      || defineUserGroupe(currentUserType, legacy_effectiveContractModerGroupe)
    )
      ? currentUserId
      : null)


  const [
    onAddNewEcData,
  ] = useCreateEcData()
  const onAddNewEcDataHandler = (params: any) => {
    onAddNewEcData(params)
      .then(() => {
        addNotification(
          'Эффективный контракт',
          (
            (
              defineUserGroupe(currentRole, legacy_effectiveContractModerGroupe)
              || defineUserGroupe(currentUserType, legacy_effectiveContractModerGroupe)
            )
              ? moderateId
              : currentUserId
          ),
          { message: 'Ваше достижение добавлено!', },
          {
            path: '/effective-contract',
            from: 'Эффективный контракт',
          }
        )
      })
  }

  const [
    onUpdateRow,
  ] = useUpdateEcData()
  const onUpdateRowHandler = (id: any, newData: any) => onUpdateRow({
    variables: {
      rec_id: id,
      new_data: newData,
    },
  })


  const [
    otherVectorActivity,
    setOtherVectorActivity,
  ] = useState(false)

  const [
    vectorActivity,
    setVectorActivity,
  ] = useState('')
  const [
    level,
    setLevel,
  ] = useState('')
  const [
    competitionName,
    setCompetitionName,
  ] = useState('')
  const [
    date,
    setDate,
  ] = useState('')
  const [
    organizer,
    setOrganizer,
  ] = useState('')
  const [
    comandName,
    setComandName,
  ] = useState('')
  const [
    vector,
    setVector,
  ] = useState('')
  const [
    document,
    setDocument,
  ] = useState([])


  useEffect(() => {
    if (hint === 'edit') {
      setVectorActivity(defaultValues.vectorActivity)
      setLevel(defaultValues.level)
      setCompetitionName(defaultValues.competitionName)
      setDate(format(new Date(defaultValues.date), 'yyyy-MM-dd'))
      setOrganizer(defaultValues.organizer)
      setComandName(defaultValues.comandName)
      setVector(defaultValues.vector)
      // @ts-expect-error TS(2345): Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
      setDocument(defaultValues.document ? [
        defaultValues.document,
      ] : [])
    }
  }, [
    hint,
    defaultValues,
  ])


  const fieldsToDefault = () => {
    setVectorActivity('')
    setLevel('')
    setCompetitionName('')
    setDate('')
    setOrganizer('')
    setComandName('')
    setVector('')
    setDocument([])
  }


  return <>
    <InputSelectDeprecated
      defaultHint="Направление деятельности"
      items={ table_cat_2_5.vectorActivity }
      defaultValue={ otherVectorActivity ? 'иная' : vectorActivity }
      withResetValue={ true }
      callBack={ (answer: any) => {
        if (answer === 'иная') {
          setVectorActivity('')
          setOtherVectorActivity(true)
        } else {
          setOtherVectorActivity(false)
          setVectorActivity(answer)
        }
      } }
    />

    { otherVectorActivity ? (
      <InputTextDeprecated
        defaultHint="Направление деятельности (свой вариант)"
        value={ vectorActivity }
        onChangeValue={ setVectorActivity }
      />
    ) : false }


    <InputSelectDeprecated
      defaultHint="Уровень конкурса"
      items={ table_cat_2_5.level }
      defaultValue={ level }
      withResetValue={ true }
      callBack={ setLevel }
    />

    <InputTextDeprecated
      defaultHint="Название конкурса"
      value={ competitionName }
      onChangeValue={ setCompetitionName }
    />

    <TitleH5>
      Дата проведения
    </TitleH5>

    <CalendarSimple
      selectRange={ false }
      withBorder
      styles={ { height: '4.56rem', } }
      defaultSingleDate={
        defaultValues.date
          ? format(new Date(defaultValues.date), 'dd.MM.yyyy')
          : date
            ? format(new Date(date), 'dd.MM.yyyy')
            : false }
      returnSingle={ (date: any) => setDate(
        format(new Date(date), 'yyyy-MM-dd')) }
      calendarPositionStatic
      clickableAreaPositioAbsolute
    />

    <InputTextDeprecated
      defaultHint="Учреждение, организатор конкурса"
      value={ organizer }
      onChangeValue={ setOrganizer }
    />

    <InputTextDeprecated
      defaultHint="ФИО студента/ или название команды"
      value={ comandName }
      onChangeValue={ setComandName }
    />

    <SpecsSearch
      defaultHint="Шифр и наименование направления подготовки / специальности"
      value={ vector }
      onChangeValue={ setVector }
      handler={ (answer: any) => {
        setVector(answer)
      } }
      withListPositionAbsolute
      maxHeightList="12rem"
    />

    <br />

    <TitleH5>
      Подтверждающий документ
    </TitleH5>

    { document.length < 1 ? (
      <InputFile
        onChange={ event => {
          // TODO: сделать uploadFile
          // uploadFile(...event.target.files, setDocument)
        } }
      />
    ) : false }


    <AttachedFiles
      isDeleteFilesAllowed={ true }
      fileList={ document }
      getIndexFile={ (index: any) => {
        setDocument(document.filter((file, fIndex) => fIndex !== index))
      } }
    />

    <br />

    <ButtonSimple
      isDisabled={ (
        level.trim().length < 1
        || vectorActivity.trim().length < 1
        || competitionName.trim().length < 1
        || date.length < 1
        || organizer.trim().length < 1
        || comandName.trim().length < 1
        || vector.trim().length < 1
        || document.length < 1
      ) }
      value={ hint === 'edit' ? 'Сохранить изменения' : 'Добавить достижение' }
      variant="full-filled"
      onClickHandler={ () => {
        if (hint === 'edit') {
          onUpdateRowHandler(defaultValues.rec_id, {
            moderOrAdminCreatorId: defaultValues.moderOrAdminCreatorId,
            vectorActivity: vectorActivity,
            level: level,
            competitionName: competitionName,
            date: date,
            organizer: organizer,
            comandName: comandName,
            vector: vector,
            document: document[0],
            status: 'pending',
            reason: '',
            scores: null,
          })

          fieldsToDefault()
          onClickHandler()
        } else {
          onAddNewEcDataHandler({
            variables: {
              user_ids: [
                user_ids,
              ],
              dataType: 'table_2_5',
              data: {
                moderOrAdminCreatorId: moderOrAdminCreatorId,
                vectorActivity: vectorActivity,
                level: level,
                competitionName: competitionName,
                date: date,
                organizer: organizer,
                comandName: comandName,
                vector: vector,
                document: document[0],
                status: 'pending',
                reason: '',
                scores: null,
                isAddedByModer: defineUserGroupe(currentRole, legacy_effectiveContractModerGroupe),
              },
            },
          })

          fieldsToDefault()
          onClickHandler()
        };
      } }
    />
  </>
}


export default Inputs_2_5
