/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { createApi, fetchBaseQuery, } from '@reduxjs/toolkit/query/react'
import { URL, } from '@/legacy/Utils/constants'

export const authApi = createApi({
  reducerPath: 'auth/sso',
  baseQuery: fetchBaseQuery({
    baseUrl: `https://${ URL }/api`,
    credentials: 'include',
    prepareHeaders: (headers, { getState, }) => {
      // const { me } = getState().auth;
      // headers.set('account', me.email);
      if (localStorage.getItem('access_token')) {
        // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
        headers.set('x-access-token', localStorage.getItem('access_token'))
      }
      console.log('Setting headers', Array.from(headers))
      return headers
    },

    // @ts-expect-error TS(2322): Type '{ 'x-access-token': string | null; }' is not... Remove this comment to see the full error message
    headers: localStorage.getItem('access_token') ? {
      'x-access-token': localStorage.getItem('access_token'),
      // Cookie: `sessionId=${localStorage.getItem('sessionId')}`,
    } : {},

  }),
  endpoints: build => ({
    userInfo: build.query({
      // @ts-expect-error TS(2322): Type '() => { url: string; credentials: "include";... Remove this comment to see the full error message
      query: () => ({
        url: '/userinfo',
        credentials: 'include',
        headers: localStorage.getItem('access_token') ? {
          'x-access-token': localStorage.getItem('access_token'),
          // Cookie: `sessionId=${localStorage.getItem('sessionId')}`,
        } : {},
      }),
    }),
    /* logout: build.mutation({
            query:()=> ({url:'/logout'}),
            transformResponse:(response => {
                console.log("REPONSE",response);
                return response;
            })
        }), */
    logout: build.query({
      query: () => ({ url: '/endsession?redirect=http://localhost:3000', }),
      transformResponse: (response => {
        console.log('REPONSE', response)
        return response
      }),
    }),
  }),
})

export const { useLazyUserInfoQuery, useUserInfoQuery, useLogoutQuery, } = authApi
