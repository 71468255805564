import './Library.less'
import HistoryLinks from '../common/HistoryLinks/HistoryLinks'
import { useSelector, useDispatch, } from 'react-redux'
import { NewPageWrapper, } from '../common/GeneralElements/GeneralElements'
import ButtonSimple from '../common/ButtonSimple/ButtonSimple'
import FilteredSearch from './FilteredSearch/FilteredSearch'
import CardLibrary from './CardLibrary/CardLibrary'
import { LIBRARY_BOOK_DETAILS, } from '../../Utils/routes'
import PaginationSimple from '../common/PaginationSimple/PaginationSimple'
import useRedirect from '@/legacy/hooks/useRedirect'
import { setActiveTab } from '@/legacy/redux/LibrarySlice'


const Library = () => {
  const redirect = useRedirect()

  // const studInfo = useSelector((state) => state.prof.studentInfo);
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const activeTab = useSelector(state => state.lib.activeTab)

  const dispatch = useDispatch()

  /*  const [isFilters, setIsFilters] = useState(false);
   const [currentRecord, setCurrentRecord] = useState(studInfo[0]);*/

  const mainTabs = [
    'Рекомендованная литература',
    'Читательский билет',
    /* "Заказать список литературы", */ 'Избранное',
  ]

  return (
    <NewPageWrapper>
      <HistoryLinks
        links={
          [
            {
              value: 'Главная',
              path: '/',
            },
            {
              value: 'Учебный процесс',
              path: '/',
            },
          ]
        }
        loc="Библиотека"
      />
      <h1 className="page-main-title">Библиотека</h1>
      <ul>
        { mainTabs.map(i => <ButtonSimple key={ i } value={ i } active={ i === activeTab }
          onClickHandler={ () => dispatch(setActiveTab(i)) } styles={ { marginRight: '10px', } } />) }
      </ul>
      <FilteredSearch />
      <h2 className="section-title">{ activeTab }</h2>
      <ul>
        { Array.from(Array(6)).map((i, index) => <CardLibrary key={ index }
          onClickHandler={ () => redirect(LIBRARY_BOOK_DETAILS) } />) }
      </ul>
      <PaginationSimple count={ 20 } />
    </NewPageWrapper>
  )
}

export default Library
