/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, } from 'react'
import './News.less'

import { useDispatch, } from 'react-redux'
import PaginationSimple from '../../common/PaginationSimple/PaginationSimple'
import { useQuery, } from '@apollo/client'
import { GETNEWS_QUERY, } from '../../../gql/hooks/useNews'
import format from 'date-fns/format'
import ru from 'date-fns/locale/ru'
import Filter from '../Filters/Filter'
import { useLocation, } from 'react-router-dom'
import { /* MAIN_ROUTE, */ NEWS_ROUTE, } from '../../../Utils/routes'
import HistoryLinks from '../../common/HistoryLinks/HistoryLinks'
// @ts-expect-error TS(2307): Cannot find module '../../../assets/images/Default... Remove this comment to see the full error message
import DefaultNews from '../../../assets/images/DefaultNews.jpg'
import Preloader from '../../common/PreLoader/Preloader'
import useRedirect from '@/legacy/hooks/useRedirect'
import { setNews, setSelectedNews, setCurrentNews, } from '@/legacy/redux/NewsSlice/NewsSlices'

// --- Images --- //

function useQueryLocation() {
  const { search, } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [
    search,
  ])
}

function News() {
  const redirect = useRedirect()

  const query = useQueryLocation()
  const start = query.get('start') || 0
  const count = 6

  const [
    totalCount,
    setTotalCount,
  ] = useState(0)
  const [
    singleDate,
    setSingleDate,
  ] = useState(null)
  const [
    offs,
    setOffs,
  ] = useState(0)
  const [
    selectedCategories,
    setSelectedCategories,
  ] = useState<any>([])

  /* const [more, setMore] = useState(0);
  const [newItems, setNewItems] = useState({
    items: []
  }) */

  const fetchMoreData = () => {
    setOffs(offs + 20)
    console.log('mooooooooooorrrreeeee')
    /* fetchMore({
     variables: {
       offset: newItems?.items.length
     }
   })
   .then(() => console.log('newww', data) ) */
    /*  .then(() => setNewItems([newItems, ...data?.newsFeed?.items])) */
  }
  // const damnYar = useSelector((state) => state.news.news)
  // const selectedState = useSelector((state) => state.news.selectedNews)
  // const selectedCategories = useSelector((state) => state.news.selectedCategories)
  // const current = useSelector((state) => state.news.currentItems)

  const dispatch = useDispatch()

  const { data, loading, error, refetch, fetchMore, } = useQuery(GETNEWS_QUERY, {
    variables: {
      limit: 20,
      offset: offs,
      filter: {
        endDate: singleDate
          ? format(new Date(singleDate), 'yyyy-MM-dd', { locale: ru, })
          : null,
        startDate: singleDate
          ? format(new Date(singleDate), 'yyyy-MM-dd', { locale: ru, })
          : null,
        tags: selectedCategories,
        eventType: 'News',
      },
    },
  })

  /* useEffect (() => {
    fetchMore({
      offset: offs
    })
  }, [offs, fetchMore]) */

  useEffect(() => {
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedCategories,
    offs,
  ])

  /* useEffect(() => {
    setNewItems({items: newItems.items.concat(data?.newsFeed.items)})
  }, [data]) */
  useEffect(() => {
    data && dispatch(setNews([
      data?.newsFeed?.items,
    ]))
    data && setTotalCount(data?.news?.totalCount)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    error,
    loading,
  ])

  function click(obj: any) {
    dispatch(setSelectedNews(obj))
    redirect(`${NEWS_ROUTE}/${obj.id}`)
  }

  const pageCount = Math.ceil(totalCount / count)
  const pages = []

  for (let i = 0; i < pageCount; i++) {
    pages.push(i + 1)
  }

  // @ts-expect-error TS(2345): Argument of type 'string | number' is not assignab... Remove this comment to see the full error message
  const activePage = Math.floor(parseInt(start) / 6 + 1)

  function handlePaginate(e: any, value: any) {
    const page = document.querySelector('.main-scroll-bar')?.firstChild
    setOffs(value * 20)
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    page.scrollTo(0, 0)
  }

  const goToCurrent = (i: any) => {
    dispatch(setCurrentNews(i.id))
    // dispatch(setSelectedNews(i))
    redirect(`${NEWS_ROUTE}/${i.id}`)
  }

  /* const listener = useCallback(() => {
    setMore((page.scrollHeight - page.scrollTop) - window.innerHeight)
  }, [page])

   useEffect(() => {
    if (more < 1) {
      setOffs(offs + current?.length)
    }
  }, [more, page, listener, current, offs])


  useEffect(() => {
    if (page) {
      page.addEventListener('scroll', () => listener());
    } return () => {
      if (page) {
      page.removeEventListener('scroll', () => listener());
      }
    }
  }, [page, listener]) */

  const handleSelected = (i: any) => {
    setOffs(0)
    setSelectedCategories([
      ...selectedCategories,
      i,
    ])
  }

  return (
    <div className="News" style={{ paddingTop: '40px', }}>
      <div className={`loader ${!loading && 'off'}`}></div>

      <HistoryLinks
        links={[
          {
            value: 'Главная',
            path: '/',
          },
        ]}
        loc="Новости"
      />

      <div className="Blocks d-row d-space">
        <div style={{ width: '100%', }}>
          <h1 className="d-h1">Все новости</h1>
          {loading && <Preloader />}
          {data?.newsFeed?.items &&
            data?.newsFeed?.items.length < 1 &&
            selectedCategories.length > 0 && (
            <h2>Нет новостей по выбранным фильтрам</h2>
          )}
          {data?.newsFeed?.items &&
            data?.newsFeed?.items.length < 1 &&
            selectedCategories.length === 0 && (
            <h2>Нет новостей по выбранной дате</h2>
          )}

          <div className="n-blocks">
            {/* <InfiniteScroll
            dataLength={newItems?.items.length}
            next={fetchMoreData}
            hasMore={true}
            loader={<h4>Loading...</h4>}
            endMessage={
              <p style={{ textAlign: 'center' }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
            scrollableTarget="scrollableDiv"
          > */}
            {data?.newsFeed?.items &&
              !loading &&
              data?.newsFeed?.items.map((i: any) => (
                <div
                  key={i?.id}
                  className="n-block a-scale"
                  onClick={() => goToCurrent(i)}
                >
                  <div className="n-img">
                    <img
                      src={i?.urls?.imageUrl ? i?.urls?.imageUrl : DefaultNews}
                      alt={i?.urls?.imageUrl}
                    />
                  </div>

                  <div className="n-date text-date-style">{i?.pub_date}</div>

                  <h3 className="n-h3 text-heading-style">
                    {i?.title /* && i?.title.substring(0, 70) */}
                    {/*  {i?.title && i?.title.length > 70 && <>...</>} */}
                  </h3>
                </div>
              ))}
            {/* </InfiniteScroll> */}
          </div>
        </div>
        <Filter
          singleDate={singleDate}
          setSingleDate={setSingleDate}
          setSelectedCategories={setSelectedCategories}
          setOffs={setOffs}
          selectedCategories={selectedCategories}
        />
      </div>

      {/* <div className="Pagination d-row d-space p-10">
        {activePage > 1 && <button className="ActiveN p-10" onClick={() => history.push(NEWS_ROUTE + `?start=${parseInt(start) - 6}`)}>Предыдущая</button>}
        <div className="p-10">{activePage}</div>
        {activePage <= pageCount && <button className="ActiveN p-10" onClick={() => history.push(NEWS_ROUTE + `?start=${parseInt(start) + 6}`)}>Следующая</button>}
      </div> */}
      {/*  {newItems.items && <InfiniteScroll
        dataLength={newItems.items.length}
        next={fetchMoreData}
        hasMore={true}
        loader={<h4>Loading...</h4>}
        scrollableTarget="scrollableDiv"
      >
        {newItems.items && newItems.items.map((i, index) => (
          <div style={style} key={index}>
            div - #{index}
          </div>
        ))}
      </InfiniteScroll>} */}
      {data?.newsFeed?.metrics.count > 20 && (
        <PaginationSimple
          count={Math.round((data?.newsFeed?.metrics.count - 20) / 20)}
          onChange={handlePaginate}
          page={offs ? Math.round(offs / 20) : 1}
        />
      )}
    </div>
  )
}

export default News
