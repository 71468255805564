/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, } from 'react'
import {
  useNavigate,
} from 'react-router-dom'
import { useDispatch, useSelector, } from 'react-redux'
import Preloader from '../common/PreLoader/Preloader'
import { chooseRole, setRole, clearME, setToken, } from '../../redux/features/auth/auth'
import { clearStore, } from '../../redux/SideBarSlices/openSubMenu'
import { clearStudent, } from '../../redux/ProfileSlices/ProfileSlices'
import { clearPortfolio, initialState as portfolioInitialState, } from '../../redux/PortfolioSlices/PortfolioSlice'
import { setRealUserGuid, } from '../../redux/FakeChageUserSlice/FakeChageUserSlice'
import { EndPoints, } from '../../lib/endpoints'
import { setLogged, } from '../../redux/features/auth/auth'
import { client, } from '../../../shared/api/base'
import { persistor, } from '../../redux/store'


const Logout2 = () => {
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const isLogged = useSelector(state => state.auth.isLogged)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (isLogged) {
      new Promise(resolve => {
        /**
        * Это список действий которые будут выполнены при разлогине.
        *
        * Если нужна гарантия того что localStorage очистился перед тем как произошел редирект на главную,
        * то нужно дописать соответствующее условие в функцию checker.
        */
        console.log('Logout !', isLogged)
        dispatch(clearStore())
        dispatch(clearStudent())
        dispatch(setRole(null))
        dispatch(chooseRole(null))
        dispatch(clearME())
        dispatch(clearPortfolio())
        dispatch(setRealUserGuid(null))
        dispatch(setToken(null))
        dispatch(setLogged(false))
        // localStorage.removeItem("access_token")

        const checker = () => {
          if (
            /**
             * Список условий которые будут подтверждены перед редиректом.
             * По сути тут просто проверяем, что в localStorage значение успело измениться.
             *
             * Если Slice не включен в witeList, то не нужно сюда добавлять условий.
             */
            // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
            JSON.parse(JSON.parse(localStorage.getItem('persist:root'))?.sub)?.clickedSub === 'Главная'
            // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
            && JSON.parse(JSON.parse(localStorage.getItem('persist:root'))?.prof)?.studentInfo === false
            // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
            && JSON.parse(JSON.parse(localStorage.getItem('persist:root'))?.auth)?.userType === null
            // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
            && JSON.parse(JSON.parse(localStorage.getItem('persist:root'))?.auth)?.choosenRole === null
            // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
            && JSON.parse(JSON.parse(localStorage.getItem('persist:root'))?.auth)?.me === false
            // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
            && JSON.parse(localStorage.getItem('persist:root'))?.port === JSON.stringify(portfolioInitialState)
            // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
            && JSON.parse(JSON.parse(localStorage.getItem('persist:root'))?.fakeChangeUser)?.realUserGuid === null
            // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
            && JSON.parse(JSON.parse(localStorage.getItem('persist:root'))?.auth)?.access_token === null
          ) {
            // @ts-expect-error TS(2794): Expected 1 arguments, but got 0. Did you forget to... Remove this comment to see the full error message
            resolve()
          } else {
            setTimeout(() => checker(), 100)
          }
        }

        checker()
      })

        .then(() => {
          console.log('TIME FOR REDIDECT')
          window.location.replace(EndPoints.endSession)
          // navigate('/')
        })
    }
  }, [
    isLogged,
    dispatch,
    navigate,
  ])


  return <Preloader />
}


const Logout = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    client.resetStore()
      .then(() => {
        new Promise(resolve => {
          /**
          * Это список действий которые будут выполнены при разлогине.
          *
          * Если нужна гарантия того что localStorage очистился перед тем как произошел редирект на главную,
          * то нужно дописать соответствующее условие в функцию checker.
          */
          dispatch(clearStore())
          dispatch(clearStudent())
          dispatch(setRole(null))
          dispatch(chooseRole(null))
          dispatch(clearME())
          dispatch(clearPortfolio())
          dispatch(setRealUserGuid(null))
          dispatch(setToken(null))
          dispatch(setLogged(false))
          dispatch(setLogged(false))
          // localStorage.removeItem("access_token")

          const checker = () => {
            if (
              /**
               * Список условий которые будут подтверждены перед редиректом.
               * По сути тут просто проверяем, что в localStorage значение успело измениться.
               *
               * Если Slice не включен в witeList, то не нужно сюда добавлять условий.
               */
              // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
              JSON.parse(JSON.parse(localStorage.getItem('persist:root')).sub).clickedSub === 'Главная'
              // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
              && JSON.parse(JSON.parse(localStorage.getItem('persist:root')).prof).studentInfo === false
              // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
              && JSON.parse(JSON.parse(localStorage.getItem('persist:root')).auth).userType === null
              // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
              && JSON.parse(JSON.parse(localStorage.getItem('persist:root')).auth).choosenRole === null
              // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
              && JSON.parse(JSON.parse(localStorage.getItem('persist:root')).auth).me === false
              // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
              && JSON.parse(localStorage.getItem('persist:root')).port === JSON.stringify(portfolioInitialState)
              // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
              && JSON.parse(JSON.parse(localStorage.getItem('persist:root')).fakeChangeUser).realUserGuid === null
              // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
              && JSON.parse(JSON.parse(localStorage.getItem('persist:root')).auth).access_token === null
            ) {
              // @ts-expect-error TS(2794): Expected 1 arguments, but got 0. Did you forget to... Remove this comment to see the full error message
              resolve()
            } else {
              setTimeout(() => checker(), 100)
            }
          }

          checker()
        })
      })
      .then(() => {
        persistor.purge()
          .then(() => {
            window.location.replace(EndPoints.endSession)
          })
      })
  }, [
    dispatch,
  ])
  return <Preloader />
}

export default Logout
