import {
  EC_MODER_GROUPE,
  EcAchivementStatus,
  EcAppealStatus,
  EcTableName,
} from '@/legacy/components/EffectiveContract/constants'
import { UserRoles, } from '@/legacy/constants'
import { TableRowType, UUID, } from '@/legacy/types'
interface DefineAllowedChangeStatus {
  row: TableRowType
  userRole: UserRoles
  userGuid: UUID
  dataType: EcTableName
}
const defineAllowedChangeStatus = ({
  row,
  userRole,
  userGuid,
  dataType,
}: DefineAllowedChangeStatus): boolean => {
  const deniedStatuses = [
    EcAchivementStatus.REJECTED,
    EcAchivementStatus.APPROVED,
    EcAchivementStatus.MANUFACTURED,
    EcAchivementStatus.CERTIFICATE_ISSUED,
    EcAppealStatus.APPEAL_APPROVED,
    EcAppealStatus.APPEAL_REJECTED,
  ]
  if (deniedStatuses.some(deniedStatus => deniedStatus === row.status)) {
    return false
  }
  if (dataType.includes('appeal') && userRole === UserRoles.URVO_ADMIN) {
    return true
  }
  if (!EC_MODER_GROUPE[userRole]?.canChangeStatus.some(canChangeDataType => canChangeDataType === dataType)) {
    return false
  }
  if (row.isAddedByModer) {
    if (row.creator_id !== userGuid) {
      return false
    }
  }
  return true
}
export default defineAllowedChangeStatus
