/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, } from 'react'
import './FacultativeGrades.less'
import ButtonSimple from '../../../../../common/ButtonSimple/ButtonSimple'
import SuccessPopup from '../../../../../common/SuccessPopup/SuccessPopup'
import { useLocation, } from 'react-router-dom'
import {
  useSignOutFacultative,
  useSignUpFacultative,
} from '../../../../../../gql/hooks/useMyEducation'
import { useSelector, } from 'react-redux'
import { useUpdateStats, } from '../../../../../../gql/hooks/useMyEducation'
import { IconCircleCheck, } from '@tabler/icons'
import clsx from 'clsx'
import { useAppContext, } from '@/App/providers/AppProvider'

const FacultativeGrades = ({ info, id, isSigned, isActive, role, }: any) => {
  const [
    isSucces,
    setIsSuccess,
  ] = useState(false)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const me = useSelector(state => state.auth.me)
  const [
    disable,
    setDisable,
  ] = useState(false)

  const location = useLocation()

  const { openToast, } = useAppContext()

  const facultyName = new URLSearchParams(location.search).get('name')
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const currentUserId = useSelector(state => state.auth.me.data.guid)
  const namespec = new URLSearchParams(location.search).get('namespec')

  const studInfo = useSelector(state => (role === 'student' || role === 'student-rakus'
    ? // @ts-expect-error ts(18046): 'state' is of type 'unknown'.
    state.prof.studentInfo?.filter((i: any) => i.namespec === namespec)
    : false)
  )

  const { department_name, eform, nameprof, course, group, }: any = studInfo
    ? studInfo[0]
    : {}

  const [
    esSignUpFacultative,
  ] = useSignUpFacultative({
    facultative_id: +id,
    type: 'facultative',
    participants: [
      {
        id: currentUserId,
        department_name,
        eform,
        nameprof,
        namespec,
        course,
        group,
      },
    ],
    level: info,
  })

  const [
    esSignOutFacultative,
  ] = useSignOutFacultative({
    facultative_id: id,
    type: 'facultative',
    participant_id: [
      me.data.guid,
    ],
  })


  const { addNotification, } = useAppContext()

  const handleParticipate = () => {
    setDisable(true)
    esSignUpFacultative()
      .then(res => {
        addNotification?.(
          'Мое обучение',
          [
            currentUserId,
          ],
          { message: `Вы записались на факультатив "${ facultyName }"`, },
          { path: '/learning-pathway', }
        )
        setIsSuccess(!isSucces)
        setDisable(false)
      })
      .catch(e => console.log(e))
  }

  const handleSignOut = () => {
    setDisable(true)
    esSignOutFacultative()
      .then(() => {
        addNotification?.(
          'Мое обучение',
          [
            currentUserId,
          ],
          { message: `Вы отписались от факультатива "${ facultyName }"`, },
          { path: '/learning-pathway', }
        )
        setDisable(false)
        openToast?.({ message: 'Вы успешно отписались от факультатива', })
      })
      .catch(e => console.log(e))
  }

  return (
    <div
      className={ clsx(
        'FacultyGradeWrap',
        info === isSigned && 'FacultyGradeWrap_active'
      ) }
    >
      <h3>{ info }</h3>
      { !isSigned ? (
        isActive &&
        (role === 'student' || role === 'student-rakus') && (
          <ButtonSimple
            value="Записаться на факультатив"
            onClickHandler={ handleParticipate }
            variant="fill"
            isDisabled={ disable }
          />
        )
      ) : info === isSigned && isActive ? (
        <ButtonSimple
          value="Отписаться от факультатива"
          onClickHandler={ handleSignOut }
          variant="fill"
          isDisabled={ disable }
        />
      ) : null }
      { info === isSigned && !isActive && (
        <IconCircleCheck
          style={ {
            color: '#7671DD',
            fontSize: '1.5rem',
            marginLeft: '.5rem',
          } }
        />
      ) }
      <SuccessPopup
        text={ `Вы успешно записались на факультатив "${ facultyName }"` }
        isOpen={ isSucces }
        handlePopup={ () => setIsSuccess(!isSucces) }
      />
    </div>
  )
}

export default FacultativeGrades
