/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useMemo, } from 'react'
import './AddStudentPopup.less'
import Popup from '../../../../../common/Popup/Popup'
import { DebounceInput, } from 'react-debounce-input'
import { IconSearch, } from '@tabler/icons'
import ButtonSimple from '../../../../../common/ButtonSimple/ButtonSimple'
import StudentsList from './StudentsList'
import FilterDropDown from '../../../../../common/FilterDropDown/FilterDropDown'
// import useStudentInfoById from '../../../../../gql/hooks/useStudentInfo';
import { useSignUpFacultative, } from '../../../../../../gql/hooks/useMyEducation'
import { useAppContext, } from '@/App/providers/AppProvider'

function AddStudentFacultative ({
  isOpen,
  handlePopup,
  handleSubmit,
  facInfo,
  type,
}: any) {
  const [
    student,
    setStudent,
  ] = useState('')
  const [
    newStudents,
    setNewStudents,
  ] = useState([])
  const [
    level,
    setLevel,
  ] = useState()
  const [
    isDisabled,
    setIsDisabled,
  ] = useState<boolean>(false)


  const { addNotification, openToast, } = useAppContext()

  const [
    esSignUpFacultative,
  ] = useSignUpFacultative({
    facultative_id: facInfo.esFacultative.items[0].facultative_id,
    type: type === 'Электив' ? 'elective' : 'facultative',
    participants: newStudents.map((i: any) => {
      return {
        id: i.id,
        department_name: i.studentInfo.department_name,
        eform: i.studentInfo.eform,
        nameprof: i.studentInfo.nameprof,
        namespec: i.studentInfo.namespec,
        course: i.studentInfo.course,
        group: i.studentInfo.group,
      }
    }),
    level,
  })

  const handleAddParticipants = () => {
    setIsDisabled(true)
    esSignUpFacultative()
      .then(() => {
        addNotification?.(
          'Мое обучение',
          newStudents.map((i: any) => i.id),
          {
            message: `Вас записали на ${ type } "${ facInfo.esFacultative.items[0].name }"`,
          },
          { path: '/learning-pathway', }
        )
      })
      .then((res: any) => {
        handlePopup(!isOpen)
        setNewStudents([])
        // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        setLevel(null)
        setIsDisabled(false)
        openToast?.({
          message: type === 'Электив'
            ? 'Вы успешно подписали участников на электив'
            : 'Вы успешно подписали участников на факультатив',
        })
      })
      .catch(e => console.log(e))
  }

  const isLevel = useMemo(() => {
    return facInfo.esFacultative.items[0].level
  }, [
    facInfo,
  ])

  return (
    <>
      <Popup
        isOpen={ isOpen }
        handlePopup={ () => handlePopup(!isOpen) }
      >
        <div className="addStudentWrap">
          <h2 className="addStudentTitle">Добавить студентов</h2>
          { isLevel && (
            <FilterDropDown
              defaultHint="Выберите уровень факультатива"
              value={ level }
              items={ isLevel }
              withBorder={ true }
              onClickHandler={ setLevel }
            />
          ) }
          { (!isLevel || level) && (
            <>
              <div className="searchWrap">
                <IconSearch color="#C5C5C5" />
                <DebounceInput
                  debounceTimeout={ 500 }
                  type="text"
                  placeholder="Ф.И.О студента"
                  onChange={ e => setStudent(e.target.value) }
                  value={ student }
                />
              </div>
              <div className="addStudentsScroll">
                { student.length > 3 && (
                  <StudentsList
                    setNewStudents={ setNewStudents }
                    newStudents={ newStudents }
                    student={ student }
                  />
                ) }
              </div>
            </>
          ) }
          <ButtonSimple
            onClickHandler={ handleAddParticipants }
            variant="fill"
            value="Добавить студентов"
            styles={ {
              width: 'fit-content',
              alignSelf: 'end',
              marginTop: '10px',
            } }
            isDisabled={ isDisabled }
          />
        </div>
      </Popup>
    </>
  )
}

export default AddStudentFacultative
