import { EndPoints, } from '@/legacy/lib/endpoints'
import { useDispatch, } from 'react-redux'


export const getNameWithPostfix = (oldName: any) => {
  let newName = oldName.split('.')
  newName.splice('-1', 0, Date.now())
  newName = newName.join('.')

  return newName
}


const fetchFilesToServer = (formData: any) => {
  return fetch(EndPoints.upload, {
    method: 'POST',
    body: formData,
    credentials: 'include',
  })
    .then(res => res.json())
}


/**
 * for example:
 * {
 *   pathForSave: 'effective-contract/document',
 *   files: Array of file objects,
 * }
 */
const useUploadFiles = () => {
  const dispatch = useDispatch()

  const uploadFiles = (
    {
      pathForSave,
      files,
      addFilesToList,
    }: any
  ) => {
    const formData = new FormData()
    files.forEach((file: any) => formData.append(pathForSave, file, getNameWithPostfix(file.name)))


    return fetchFilesToServer(formData)
      .then(data => dispatch(addFilesToList(data)))
      // eslint-disable-next-line no-console
      .catch(error => console.log(error))
  }


  return uploadFiles
}


export default useUploadFiles
