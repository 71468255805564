/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React, { useState, useMemo } from "react";
import "./Filter.less";
import "./Calendar.less";

import { GETNEWSCATEGORIES_QUERY } from "../../../gql/hooks/useNews";
import { useQuery } from "@apollo/client";
import Str from "./str";
import Calendar from "react-calendar";
// import { changeFullDate, changeWeekDays, translateDate, translateWeekDate } from '../../Schedule/Fn'
import { months } from "../../Schedule/Table";
import Preloader from "../../common/PreLoader/Preloader";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { IconChevronUp, IconChevronDown } from "@tabler/icons";
import format from "date-fns/format";
import ru from "date-fns/locale/ru";

const Filter = ({
  singleDate,
  setSingleDate,
  setSelectedCategories,
  selectedCategories,
  setOffs,
}: any) => {
  const [offset, setOffset] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const limit = 500;

  const { data, error, loading } = useQuery(
    GETNEWSCATEGORIES_QUERY(limit, offset)
  );

  const handlSelected = (i: any) => {
    if (selectedCategories.includes(i)) {
      setSelectedCategories(
        selectedCategories.filter((item: any) => item !== i)
      );
      setOffs(0);
    } else {
      setSelectedCategories([...selectedCategories, i]);
      setOffs(0);
    }
  };

  const tags = useMemo(() => {
    if (error) {
      // @ts-expect-error TS(2322): Type 'ApolloError' is not assignable to type 'Reac... Remove this comment to see the full error message
      return <div>{error}</div>;
    } else if (loading) {
      return <Preloader />;
    } else if (data) {
      return data.newsTags.map((i: any) => (
        <Str
          i={i.tag}
          key={i.tag}
          setSelectedCategories={() => handlSelected(i.tag)}
          selectedCategories={selectedCategories}
          isActive={selectedCategories.includes(i.tag)}
        />
      ));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error, loading, selectedCategories]);

  /*  useEffect(() => {
     if (data) {
       if (cats) {
         let arr = []
         cats.map(i => arr.push(i))
         data?.newsCategories.map(y => arr.push(y))
         setCats(arr)
       }
       setCats(data?.newsCategories)
     }
     if (error) {
       console.log(error)
     }
     // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [data, error]) */

  /* if (loading) {
    return <div className="Filter">
      <h2>Загрузка</h2>
    </div>
  } */

  function changeDate(e: any) {
    format(new Date(e), "yyyy-MM-dd", { locale: ru }) ===
    format(new Date(singleDate), "yyyy-MM-dd", { locale: ru })
      ? setSingleDate(null)
      : setSingleDate(e);
    setOffs(0);
  }

  return (
    <div className="Filter" style={{ marginBottom: "50px" }}>
      <h1 className="d-h1">Фильтры</h1>
      <Accordion
        style={{
          borderRadius: "15px",
          boxShadow:
            "0px 12.521552085876465px 6.636422634124756px 0px rgba(0, 0, 0, 0.015)",
          marginBottom: ".7rem",
        }}
        className="infoCard"
      >
        <AccordionSummary
          id="panel1a-header"
          onClick={() => setIsOpen(!isOpen)}
        >
          <div
            className="accordionTitle"
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <h3 className="filterHead">
              {!isOpen ? "Развернуть фильтры" : "Свернуть фильтры"}
            </h3>
            {isOpen ? (
              <IconChevronUp
                size={35}
                color="#C5C5C5"
                style={{ cursor: "pointer" }}
              />
            ) : (
              <IconChevronDown
                size={35}
                color="#C5C5C5"
                style={{ cursor: "pointer" }}
              />
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="Inner">
            <ul>
              {/*  { offset > 1 && <button onClick={ () => setOffset(offset - limit) }>prev</button> } */}
              {tags}
              {/* { cats && cats.map(i =>
            <Str i={ i } />
          ) } */}
              {/*  <button onClick={ () => setOffset(offset + limit) }>next</button> */}
            </ul>
          </div>
        </AccordionDetails>
      </Accordion>
      <h1 className="Archive">Архив новостей</h1>

      <Calendar
        locale={"Ru"}
        className={"react-calendarC"}
        formatMonthYear={(locale: any, date: any) =>
          `${date && format(new Date(date), "MMMM", { locale: ru })}`
        }
        formatShortWeekday={(locale: any, date: any) =>
          format(new Date(date), "EEEEEE", { locale: ru }).toUpperCase()
        }
        value={singleDate}
        onChange={(e: any) => changeDate(e)}
        maxDate={new Date(Date.now())}
      />
    </div>
  );
};

export default Filter;
