import { TableCellType, } from '@/legacy/types'
import { table_cat_2_3, } from '../../popUpInputsCatalogs'

export const tableSource: TableCellType[] = [
  {
    editable: false,
    title: '№',
    index: 'number',
  },
  {
    title: 'ФИО автора (авторов)',
    index: 'authors',
    editable: true,
    editType: 'participants',
    style: { minWidth: '20rem', },
  },
  {
    title: 'Название работы',
    index: 'book_title',
    editable: true,
    editType: 'input',
    style: { minWidth: '15rem', },
  },
  {
    title: 'Вид работы',
    index: 'type_work',
    editable: true,
    editType: 'select',
    selectOptions: table_cat_2_3.projectType,
  },
  // {
  //   title: 'Издательство',
  //   index: 'publish',
  //   editable: true,
  //   editType: 'select',
  //   selectOptions: table_cat_2_3.publish,
  // },
  // {
  //   title: 'Издатель',
  //   index: 'publisher',
  //   editable: true,
  //   editType: 'input',
  //   style: { minWidth: '17rem', },
  // },
  {
    title: 'Год публикации',
    index: 'publication_year',
    editable: true,
    editType: 'inputNumbers',
    // indexDescription: 'dateFormat_2_3',
  },
  {
    title: 'Количество авторов',
    index: 'author_count',
    editable: true,
    editType: 'inputNumbers',
    // editType: 'select',
    // selectOptions: table_cat_2_3.countAuthors,
  },
  // {
  //   title: 'Шифр и наименование направления подготовки / специальности',
  //   index: 'major',
  //   editable: true,
  //   editType: 'inputWithSpecsSearch',
  //   style: { minWidth: '17rem', },
  // },
  {
    title: 'Название дисциплины',
    index: 'disciplineName',
    editable: true,
    editType: 'input',
    style: { minWidth: '12rem', },
  },
  {
    title: 'Объем, п.л',
    index: 'volume',
    editable: true,
    editType: 'inputNumbers',
  },
  {
    title: 'Формат издания',
    index: 'format',
    editable: true,
    editType: 'select',
    selectOptions: table_cat_2_3.format,
  },
  // {
  //   title: 'Источник финансирования',
  //   index: 'financeSource',
  //   editable: true,
  //   editType: 'select',
  //   selectOptions: table_cat_2_3.financeSource,
  // },
  // {
  //   title: 'Тираж',
  //   index: 'print_run',
  //   editable: true,
  //   editType: 'inputNumbers',
  // },
  {
    title: 'Документ',
    index: 'document',
    editable: true,
    editType: 'upload',
    style: { minWidth: '10rem', },
  },
  {
    title: 'Баллы',
    index: 'scores',
    editable: false,
  },
  {
    editable: false,
    title: 'Статус',
    index: 'status',
    style: { minWidth: '11rem', },
  },
]


export const appealTableSource: TableCellType[] = [
  {
    title: '№',
    index: 'number',
    editable: false,
  },
  {
    title: 'ФИО автора (авторов)',
    index: 'authors',
    editable: true,
    editType: 'participants',
    style: { minWidth: '10rem', },
  },
  {
    title: 'Название работы',
    index: 'book_title',
    editable: true,
    editType: 'input',
    style: { minWidth: '15rem', },
  },
  {
    title: 'Вид работы',
    index: 'type_work',
    editable: true,
    editType: 'select',
    selectOptions: table_cat_2_3.projectType,
  },
  // {
  //   title: 'Издательство',
  //   index: 'publish',
  //   editable: true,
  //   editType: 'select',
  //   selectOptions: table_cat_2_3.publish,
  // },
  // {
  //   title: 'Издатель',
  //   index: 'publisher',
  //   editable: true,
  //   editType: 'input',
  //   style: { minWidth: '17rem', },
  // },
  {
    title: 'Год публикации',
    index: 'publication_year',
    editable: true,
    editType: 'inputNumbers',
  },
  {
    title: 'Количество авторов',
    editable: false,
    index: 'countAuthors',
    // editable: true,
    editType: 'select',
    selectOptions: table_cat_2_3.countAuthors,
  },
  // {
  //   title: 'Шифр и наименование направления подготовки / специальности',
  //   index: 'major',
  //   editable: true,
  //   editType: 'inputWithSpecsSearch',
  //   style: { minWidth: '17rem', },
  // },
  {
    title: 'Название дисциплины',
    index: 'disciplineName',
    editable: true,
    editType: 'input',
    style: { minWidth: '12rem', },
  },
  {
    title: 'Объем, п.л',
    index: 'volume',
    editable: true,
    editType: 'inputNumbers',
  },
  {
    title: 'Формат издания',
    index: 'format',
    editable: true,
    editType: 'select',
    selectOptions: table_cat_2_3.format,
  },
  // {
  //   title: 'Источник финансирования',
  //   index: 'financeSource',
  //   editable: true,
  //   editType: 'select',
  //   selectOptions: table_cat_2_3.financeSource,
  // },
  // {
  //   title: 'Тираж',
  //   index: 'print_run',
  //   editable: true,
  //   editType: 'inputNumbers',
  // },
  {
    title: 'Документ',
    index: 'document',
    editable: true,
    editType: 'upload',
    style: { minWidth: '10rem', },
  },
  {
    title: 'Баллы',
    index: 'scores',
    editable: true,
    editType: 'inputNumbers',
  },
  {
    editable: false,
    title: 'Причина отказа',
    index: 'reason',
    editType: 'input',
    style: { minWidth: '20rem', },
  },
  {
    title: 'Комментарий',
    editable: false,
    index: 'textAppeal',
    style: { minWidth: '25rem', },
    editType: 'input',
  },
  {
    editable: false,
    title: 'Статус',
    index: 'status',
    style: { minWidth: '11rem', },
  },
]
