/* eslint-disable no-console */
import React from 'react'
import './AnnounceInner.less'
import HistoryLinks from '../../common/HistoryLinks/HistoryLinks'
import { useSelector, } from 'react-redux'
import format from 'date-fns/format'
// import ru from 'date-fns/locale/ru';
import { IconMapPin, IconCalendarMinus, IconClock, } from '@tabler/icons'
import vk from '../../../assets/images/VK.svg'
import ru from 'date-fns/locale/ru'


function AnnounceInner () {
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const current = useSelector(state => state.news.currentAnnounce)

  console.log(current.eventDate)
  const eventPlace = [
    {
      title: 'Место проведения',
      value: current.eventPlace,
      icon: <IconMapPin color={ '#7671DD' } />,
      show: current.eventPlace.length > 0,
    },
    {
      title: 'Дата проведения',
      value: current.eventDate.slice(0, 3) + current.eventDate[3].toUpperCase() + current.eventDate.slice(4),
      icon: <IconCalendarMinus color={ '#7671DD' } />,
      show: current.eventDate.length > 0,
    },
    {
      title: 'Время:',
      value: current.eventTime,
      icon: <IconClock color={ '#7671DD' } />,
      show: current.eventTime.length > 0,
    },
  ]


  return <div className="InnerPage AnnounceInner">
    <HistoryLinks links={ [
      {
        value: 'Главная',
        path: '/',
      },
      {
        value: 'Объявления',
        path: '/anounces',
      },
    ] } loc={ current.title } />
    <div className="studentInner">
      <div className="contentWrap">
        <h2 className="innerTitle">{ current.title }</h2>
        <p className="eventDate">{ format(new Date(current.date), 'dd MMMM yyyy', { locale: ru, }).slice(0, 3) + format(new Date(current.date), 'dd MMMM yyyy', { locale: ru, })[3].toUpperCase() + format(new Date(current.date), 'dd MMMM yyyy', { locale: ru, }).slice(4) }</p>
        <p className="eventDescription" dangerouslySetInnerHTML={ { __html: current.description, } } />
        <ul style={ { display: 'flex', } }>
          { eventPlace.map(i => {
            return i.show && <div key={ i.title } className="placeWrap">
              { i.icon }
              <div>
                <h3 className="placeTitle">{ i.title }</h3>
                <p className="placeValue">{ i.value }</p>
              </div>
            </div>
          }) }
        </ul>
        <p className="Share">Поделиться: <li><a href="https://vk.com/tsutmb"><img src={ vk } alt="logo" /></a></li>Vkontakte</p>
      </div>
    </div>
  </div>
}

export default AnnounceInner
