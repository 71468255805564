import { table_cat_2_1, } from '@/legacy/components/EffectiveContract/popUpInputsCatalogs'
import { TableCellType, } from '@/legacy/types'

export const tableSource: TableCellType[] = [
  {
    editable: false,
    title: '№',
    index: 'number',
  },
  {
    title: 'Название премии/награды',
    index: 'presentName',
    editable: true,
    editType: 'select',
    selectOptions: table_cat_2_1.presentName,
  },
  {
    title: 'Подтверждающий документ',
    index: 'documentName',
    editable: true,
    editType: 'upload',
  },
  {
    title: 'Баллы',
    index: 'scores',
    editable: false,
  },
  {
    editable: false,
    title: 'Статус',
    index: 'status',
    style: { minWidth: '8rem', },
  },
]


export const appealTableSource: TableCellType[] = [
  {
    title: '№',
    index: 'number',
    editable: false,
  },
  {
    title: 'Название премии/награды',
    index: 'presentName',
    editable: true,
    editType: 'select',
    selectOptions: table_cat_2_1.presentName,
  },
  {
    title: 'Подтверждающий документ',
    index: 'documentName',
    editable: true,
    editType: 'input',
  },
  {
    title: 'Баллы',
    index: 'scores',
    editable: true,
    editType: 'inputNumbers',
  },
  {
    title: 'Причина отказа',
    index: 'reason',
    style: { minWidth: '20rem', },
    editable: false,
    editType: 'input',
  },
  {
    title: 'Комментарий',
    index: 'textAppeal',
    editable: false,
    editType: 'input',
  },
  {
    title: 'Статус',
    index: 'status',
    style: { minWidth: '10rem', },
    editable: false,
  },
]
