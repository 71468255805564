/* eslint-disable no-console */
import { useQuery, } from '@apollo/client'
import { useEffect, useMemo, useState, } from 'react'
import { Carousel, } from 'antd'
import { GETNEWSFROMID_QUERY, } from '../../../../gql/hooks/useNews'
import HistoryLinks from '../../../common/HistoryLinks/HistoryLinks'
import { useSelector, } from 'react-redux'

import './CurrentNews.less'
import Social from './Social'
import Preloader from '@/legacy/components/common/PreLoader/Preloader'

const CurrentNews = () => {
  const [
    /* load */, setIsLoad,
  ] = useState(true)
  const [
    news,
    setNews,
  ] = useState<any>({})

  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const current = useSelector(state => state.news.currentNews)

  const { data, loading, error, } = useQuery(GETNEWSFROMID_QUERY(current))

  const settings = {
    slidesToShow: 1,
    dots: true,
  }

  useEffect(() => {
    if (data) {
      setNews(data?.getNewsFromId[0])
    }
    if (error) {
      console.log(error)
    }
    setIsLoad(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    loading,
    error,
  ])


  const sliderView = useMemo(() => {
    if (loading) {
      return <Preloader />
    } return (
      <Carousel { ...settings }>
        { data?.getNewsFromId[0]?.urls?.mediaUrl.map(
          (item: any, index: any) => (
            <img
              key={ index }
              src={ item }
              width={ 200 }
              height={ 200 }
              alt=""
            />
          )) }
      </Carousel>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    loading,
    data,
  ])


  if (loading) {
    return <Preloader />
  }

  return (
    <div className='CurrentNews'>
      <HistoryLinks
        links={ [
          {
            value: 'Главная',
            path: '/',
          },
          {
            value: 'Новости',
            path: '/news',
          },
        ] }
        loc={ news?.title?.replace('%', ' ') }
      />

      <div className='CN_Block'>
        { sliderView }

        <h1 className='CN_Title'>{ news?.title }</h1>

        <span className='CN_Publised'>{ news?.pub_date }</span>

        <div className="ChangeHtml" dangerouslySetInnerHTML={ { __html: news?.details, } } />

        <Social />
      </div>
    </div>
  )
}

export default CurrentNews
