import React, { useMemo, useEffect, } from 'react'
import { useSelector, } from 'react-redux'
import { useGetFacultatives, useUpdateOneActiveStatus, } from '../../../../../../gql/hooks/useMyEducation'
import Preloader from '../../../../../common/PreLoader/Preloader'
import CardDisciplinePathway from '../../CardDisciplinePathway/CardDisciplinePathway'
import _ from 'lodash'


const FacultativeList = ({
  nameSpec,
  currentUserId,
  role,
}: any) => {
  const payload = {
    filter: null,
    pagination: {
      offset: 0,
      limit: 1000,
    },
    sort: [
      {
        column: 'is_visible',
        order: 'desc',
      },
      {
        column: 'created',
        order: 'desc',
      },
    ],
  }

  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const refresh = useSelector(state => state.myEd.updateFacultatives)

  const [
    esDisciplineActivate,
  ] = useUpdateOneActiveStatus()

  const params = (role === 'student' || role === 'student-rakus') ? {
    ...payload,
    filter: {
      namespec: nameSpec.namespec,
      department: nameSpec.department_name,
      nameprof: nameSpec.nameprof,
      group: nameSpec.group,
      course: nameSpec.course,
      student_id: currentUserId,
      eform: nameSpec.eform,
      isModerator: false,
      elevel: nameSpec.elevel,
      type: 'facultative',
    },
    pagination: {
      'offset': 0,
      'limit': 1000,
    },
  } : {
    ...payload,
    filter: {
      isModerator: true,
      type: 'facultative',
    },
    pagination: {
      'offset': 0,
      'limit': 1000,
    },
  }

  const { data, error, loading, refetch, } = useGetFacultatives(params)

  useEffect(() => {
    refetch()
  }, [
    refresh,
    refetch,
  ])


  const facultativeCards = useMemo(() => {
    if (error) {
      return <div>error</div>
    } else if (loading) {
      return <Preloader />
    } else if (data?.esFacultative?.items) {
      const handleActivateOne = (e: any, facultative_id: any, is_active: any) => {
        e.stopPropagation()
        esDisciplineActivate({
          variables: {
            is_active,
            facultative_id,
          },
        })
      }

      return data.esFacultative?.items.map((i: any) => <CardDisciplinePathway key={ i.facultative_id } i={ _.omit(i, 'sign_count', 'is_signed') }
        type="Факультатив" role={ role } nameSpec={ nameSpec } handleActivateOne={ handleActivateOne } is_signed={ i.is_signed } />)
    }
  }, [
    data,
    error,
    loading,
    role,
    nameSpec,
    esDisciplineActivate,
  ])

  return facultativeCards
}

export default FacultativeList
