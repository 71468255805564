import React, { useContext, } from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import {
  IconChevronDown,
} from '@tabler/icons'
import Typography from '@mui/material/Typography'
import { EcTableName, } from '@/legacy/components/EffectiveContract/constants'
import useGetTableData from '@/legacy/gql/hooks/effectiveContractHooks/useGetTableData'
import { UUID, } from '@/legacy/types'
import { EcSubscriptionContext, } from '@/features'
import CardsContainer from '@/legacy/components/EffectiveContract/Tabs/refactor/CardsContainer/CardsContainer'
import cls from './AccordionInner.module.less'
interface IconProps {
  src: string;
}

interface AccordionInnerProps {
  Icon?: React.ElementType;
  accordionStyle?: React.CSSProperties;
  accordionDirection?: string;
  headerText: string;
  subHeaderText?: string;
  isOpened?: boolean;
  achievementData?: any;
  children?: React.ReactNode;
  iconProps?: IconProps;
  moderateId: UUID;
  dataType: EcTableName;
  stage:any;
  handlers:any;
}
const dates = [
  { year: '2024', },
  // { year: '2023', },
  // { year: '2022', },
]
const tablesDictionary: any = {
  [EcTableName.TABLE_1_1]: null,
  [EcTableName.TABLE_1_11]: null,
  [EcTableName.TABLE_1_12]: null,
  [EcTableName.TABLE_1_2]: null,
  [EcTableName.TABLE_1_3]: null,
  [EcTableName.TABLE_1_4]: null,
  [EcTableName.TABLE_1_41]: null,
  [EcTableName.TABLE_1_5]: null,
  [EcTableName.TABLE_2_1]: dates,
  [EcTableName.TABLE_2_2]: dates,
  [EcTableName.TABLE_2_3]: dates,
  [EcTableName.TABLE_2_4]: dates,
  [EcTableName.TABLE_2_41]: dates,
  [EcTableName.TABLE_2_5]: dates,
  [EcTableName.TABLE_2_6]: dates,
  [EcTableName.TABLE_2_7]: dates,
  [EcTableName.TABLE_2_8]: dates,
  [EcTableName.TABLE_2_9]: dates,
  [EcTableName.TABLE_2_10]: dates,
  [EcTableName.TABLE_3_1]: dates,
  [EcTableName.TABLE_3_2]: dates,
  [EcTableName.TABLE_3_3]: dates,
  [EcTableName.TABLE_3_31]: dates,
  [EcTableName.TABLE_3_4]: dates,
  [EcTableName.TABLE_3_5]: dates,
  [EcTableName.TABLE_4_1]: null,
}


const AccordionInner: React.FC<AccordionInnerProps> = ({
  Icon,
  accordionStyle,
  headerText,
  subHeaderText,
  isOpened = false,
  children,
  dataType,
  moderateId,
  stage,
  handlers,
}) => {
  const hasChildren = React.Children.count(children) > 0
  const dataTypeUpdated = useContext(EcSubscriptionContext)
  // @ts-ignore
  // console.log('dataTypeUpdated', dataTypeUpdated)
  const getTableData = (dataType: EcTableName, date: { year: string;}) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useGetTableData({
      dataType,
      user_id: moderateId,
      status: null,
      date: date,
      dataTypeUpdated,
    })
  }
  const data = useGetTableData({ dataType,
    user_id: moderateId,
    status: null,
    date: null,
    dataTypeUpdated, })
  return (
    <Accordion
      defaultExpanded={isOpened}
      style={{
        borderRadius: '1rem',
        boxShadow:
          '0px 12.521552085876465px 6.636422634124756px 0px rgba(0, 0, 0, 0.015)',
        marginBottom: '.7rem',
        ...accordionStyle,
      }}
    >
      <AccordionSummary
        className="collapsed-container__accordion-summary"
        style={{
          padding: Icon ? '0 2.6rem 0 .5rem' : '1.2rem 2.6rem .8rem 1.9rem',
        }}

        expandIcon={<IconChevronDown/>}
      >
        {Icon ? (
          <div className="collapsed-container__icon-wrapper">
            <Icon
              style={{
                height: '3.2rem',
                width: '3.2rem',
                color: '#bfc6e0',
              }}
            />
          </div>
        ) : (
          false
        )}

        <Typography
          style={{
            alignSelf: 'center',
            fontSize: '1.2rem',
            fontWeight: 'bold',
            letterSpacing: 0.35,
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <span className="collapsed-container__header-text">{headerText}</span>

          {subHeaderText && (
            <span className="collapsed-container__header-text collapsed-container__header-text_subheader">
              {subHeaderText}
            </span>
          )}
        </Typography>
      </AccordionSummary>

      {hasChildren ? (
        <AccordionDetails
          className="collapsed-container__accordion-details"
          style={{
            padding: Icon ? '0 .6rem 2rem 7rem' : '0 1.9rem 2rem 1.9rem',
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          {children}
        </AccordionDetails>
      ) : (
        <AccordionDetails
          className="collapsed-container__accordion-details"
          style={{
            padding: Icon ? '0 .6rem 2rem 7rem' : '0 1.9rem 2rem 1.9rem',
            display: 'flex',
            flexWrap: 'wrap',
            width: '1300px',
          }}
        >
          {tablesDictionary[dataType] ? (
            tablesDictionary[dataType].map((date: any, index: any) => (
              <React.Fragment key={index}>
                <Typography
                  style={{
                    width: '100%',
                    fontSize: '1.2rem',
                    fontWeight: 'bold',
                    letterSpacing: '0.35px',
                  }}
                  variant="h6"
                >
                  {getTableData(dataType, date).length > 0 ? date.year : null}
                </Typography>
                <CardsContainer
                  tableData={getTableData(dataType, date)}
                  dataType={dataType}
                  stage={stage}
                  handlers={handlers}
                  year={date.year}
                />
              </React.Fragment>
            ))
          ) : (
            <CardsContainer
              // eslint-disable-next-line react-hooks/rules-of-hooks
              tableData={data}
              dataType={dataType}
              stage={stage}
              handlers={handlers}
              year={null}
            />
          )}
        </AccordionDetails>
      )}
    </Accordion>
  )
}

export default AccordionInner
