/* eslint-disable import/extensions */
import React, { useEffect, useMemo, useState, } from 'react'
import Popup from '../../common/Popup/Popup'
import ButtonSimple from '../../common/ButtonSimple/ButtonSimple'
import useNotificationSplash from '../../../gql/hooks/common/useNotificationSplash'
import { useUpdateNotification, } from '@/shared/api/notifications'
import { useLocation, } from 'react-router-dom'
import styles from './ImportantPopupMessage.module.less'
import AttachedFiles from '../../common/AttachedFiles/AttachedFiles'
import clsx from 'clsx'
import { NotificationStatus, } from '@/__generated__/graphql'


const ImportantPopupMessage = () => {
  const splashArray = useNotificationSplash()

  const [
    isOpen,
    setIsOpen,
  ] = useState(false)
  const [
    currentMessage,
    setCurrentMessage,
  ] = useState(0)

  const location = useLocation()

  const [
    updateNotification,
  ] = useUpdateNotification()

  useEffect(() => {
    if (splashArray.length > 0) {
      setIsOpen(true)
    }
  }, [
    splashArray,
    location.pathname,
  ])

  return (
    <>
      {isOpen && (
        <Popup isOpen={isOpen} handlePopup={setIsOpen} isCloseButton={false}>
          <>
            <div className={styles.messagesCountWrapper}>
              <strong>Новых уведомлений: {splashArray.length} шт.</strong>
            </div>
            <br />
            <div className={styles.messageBody}>
              <span className={styles.messageNumber}>
                {currentMessage + 1} / {splashArray?.length}
              </span>
              <h2>
                {splashArray[currentMessage]?.body?.title
                  ? splashArray[currentMessage]?.body?.title
                  : 'Уважаемые студенты и преподаватели!'}
              </h2>
              <p
                dangerouslySetInnerHTML={{ __html: splashArray[currentMessage]?.body?.message, }}
                style={{ maxHeight: '500px',
                  overflow: 'auto', }}
              />
              {splashArray[currentMessage]?.files?.length > 0 && (
                <AttachedFiles
                  fileList={splashArray[currentMessage]?.files}
                  isDeleteFilesAllowed={false}
                />
              )}
            </div>
            <br />
            <div className={clsx(styles.buttonsWrapper, styles.buttonsWrapper_navigation)}>
              <ButtonSimple
                value={'Предыдущее'}
                variant="small-button"
                active
                onClickHandler={() => {
                  setCurrentMessage(prev => prev - 1)
                }}
                isDisabled={currentMessage < 1}
              />
              <ButtonSimple
                value={'Следующее'}
                variant="small-button"
                active
                onClickHandler={() => {
                  setCurrentMessage(prev => prev + 1)
                }}
                isDisabled={currentMessage >= splashArray.length - 1}
              />
            </div>
            <br />
            <div className={styles.buttonsWrapper}>
              <ButtonSimple
                value={'Понятно'}
                variant="full-filled"
                active
                onClickHandler={() => {
                  updateNotification({
                    variables: {
                      message_ids: splashArray.map((message: { msg_id: any }) => message.msg_id),
                      status: NotificationStatus.Seen,
                    },
                  })
                  setIsOpen(false)
                }}
                isDisabled={currentMessage < splashArray.length - 1}
              />
            </div>
          </>
        </Popup>
      )}
    </>
  )
}

export default ImportantPopupMessage
