import arrow from '../../../../../assets/images/arrow.svg'


const Left = (props: any) => <div
  className="Arrow Arrow-left To-Left a-scale"
  onClick={ props.onClick }
>
  <img src={ arrow } alt="" />
</div>


export default Left
