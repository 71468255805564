import arrow from '../../../../../assets/images/arrow.svg'


const Right = (props: any) => (
  <div
    className="Arrow Arrow-right a-scale"
    onClick={ props.onClick }
  >
    <img src={ arrow } alt="" />
  </div >
)


export default Right
