import { useMemo, useEffect, useCallback, } from 'react'
import HistoryLinks from '../../common/HistoryLinks/HistoryLinks'
import './ProjectPage.less'
import clsx from 'clsx'
import InfoProject from './InfoProject/InfoProject'
import ProjectSchedule from './ProjectSchedule/ProjectSchedule'
import TasksProject from './TasksProject/TasksProject'
import { useSelector, useDispatch, } from 'react-redux'
import { useGetProject, } from '../../../gql/hooks/useProjects'
import { setFilter, } from '../../../redux/ProjectsSlice/ProjectSlice'
// import { useProjectInitiated } from '../../../gql/hooks/useProjects';
// import { useCreateProject } from '../../../gql/hooks/useProjects';

const filterButtons = [
  'Информация о проекте',
  'План-график проекта',
  'Мои задачи',
]

function ProjectPage () {
  const dispatch = useDispatch()

  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const filter = useSelector(state => state.proj.filter)

  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const proj = useSelector(state => state.proj.currentProject)


  const { refetch, data, error, loading, } = useGetProject(proj.req_id)

  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const updateProjects = useSelector(state => state.subscriptions.applyStatus)

  useEffect(() => {
    refetch()
  }, [
    updateProjects,
    refetch,
  ])

  const isActive = useCallback((i: any) => {
    return i === filter
  }, [
    filter,
  ])


  const content = useMemo(() => {
    if (error) {
      return <div>....eeerrror</div>
    } else if (loading) {
      return <div>....loading....</div>
    } else if (data) {
      const project = data.paGetProject[0]
      return <div className="studentInner">
        <h2 className="profileTitle">{ `Работа с проектом №${ project.req_id }` }</h2>
        <div className="filterWrap">
          {
            filterButtons.map(i => {
              return <button className={ clsx({
                'statusButton': true,
                'buttonActive': isActive(i),
              }) } onClick={ () => dispatch(setFilter(i)) } key={ filterButtons.indexOf(i) }>{ i }</button>
            })
          }
        </div>
        { filter === 'Информация о проекте' && <InfoProject project={ project } /> }
        { filter === 'План-график проекта' && <ProjectSchedule project={ project } /> }
        { filter === 'Мои задачи' && <TasksProject project={ project } /> }
      </div>
    }
  }, [
    data,
    error,
    loading,
    filter,
    isActive,
    dispatch,
  ])


  return (
    <div className="InnerPage Project d-scrolloff">
      <HistoryLinks links={ [
        {
          value: 'Главная',
          path: '/',
        },
        {
          value: 'Учебный процесс',
          path: '/projects-all',
        },
        {
          value: 'Проектная деятельность',
          path: '/projects-all',
        },
      ] } loc="Проект" />
      { content }
      {/* <ToastContainer /> */ }
    </div>
  )
}

export default ProjectPage
