import './CalendarSimple.less'
import { ReactComponent as IconCalendar, } from '../../../assets/images/Group.svg'
import clsx from 'clsx'

import Calendar from 'react-calendar'
import { format, } from 'date-fns'
import { ru, } from 'date-fns/locale'

import { useEffect, useState, } from 'react'


const CalendarSimple = ({
  styles,
  withBorder = false,
  display,
  selectRange = true,
  tableView = false,
  returnRange,
  returnSingle,
  defaultSingleDate,
  defaultRangeDate,
  calendarPositionStatic,
  clickableAreaPositioAbsolute,
  placeholder,
  placeholderColor,
  isWithoutDay,
  disabled,
}: any) => {
  const [
    isOpen,
    setIsOpen,
  ] = useState(false)
  const [
    rangeDate,
    setRangeDate,
  ] = useState(null)
  const [
    singleDate,
    setSingleDate,
  ] = useState(null)

  useEffect(() => {
    // @ts-expect-error TS(2345): Argument of type '""' is not assignable to paramet... Remove this comment to see the full error message
    setSingleDate('')
  }, [
    defaultSingleDate,
  ])

  useEffect(() => {
    setRangeDate(null)
  }, [
    defaultRangeDate,
  ])

  return (
    <div
      className={ clsx({
        'calendar-simple': true,
        'calendar-simple_with-border': withBorder,
      }) }
      style={ styles }
    >
      <button
        className={ clsx({
          'calendar-simple__clickable-area': true,
          'position-absolute': clickableAreaPositioAbsolute,
        }) }
        onClick={ () => !disabled && setIsOpen(!isOpen) }
        type="button"
      >
        <span
          className={ clsx({
            'calendar-simple__dates-wrapper': true,
            'no-paddings': tableView,
            'no-marging': tableView,
          }) }
        >
          { rangeDate
            ? (
              <>
                <span>
                  { `${ formatRuMonth(
                    format(rangeDate[0], 'dd MMM yyyy', { locale: ru, })) } ` }
                </span>
                <span className="calendar-simple__dates-dash">—</span>
                <span>
                  { ` ${ formatRuMonth(
                    format(rangeDate[1], 'dd MMM yyyy', { locale: ru, })) } ` }
                </span>
              </>
            ) : false
          }

          { singleDate
            ? (
              <span
                className={ clsx({
                  'no-wrap': tableView,
                  'no-paddings': tableView,
                }) }
              >
                {
                  isWithoutDay
                    ? (format(new Date(singleDate), 'LLLL yyyy', { locale: ru, }))
                    : formatRuMonth(format(singleDate, 'dd MMM yyyy', { locale: ru, }))
                }
              </span>
            ) : false }

          { selectRange && !rangeDate ? (
            <span style={ { color: placeholderColor, } }>{ placeholder || 'Дата, с—по' }</span>
          ) : false }

          { !selectRange && !singleDate ? (
            <span style={ { color: !defaultSingleDate && placeholderColor, } }>
              { defaultSingleDate ? defaultSingleDate : (placeholder || 'Дата') }
            </span>
          ) : false }
        </span>

        <IconCalendar className={ clsx({
          'light-stroke': tableView,
        }) } />
      </button>


      { isOpen && (
        <>
          <div
            className={ display === 'center'
              ? 'dark-layer'
              : 'hidden-layer' }
            onClick={ () => setIsOpen(false) }
          ></div>

          <Calendar
            defaultView={ isWithoutDay && 'year' }
            onClickMonth={ (date: any) => {
              if (isWithoutDay) {
                setSingleDate(date)
                returnSingle(date)
                setIsOpen(false)
              }
            } }
            locale={ 'Ru' }
            className={ clsx({
              'ScheduleCalendar': true,
              'off': !isOpen,
              'display-center': display === 'center',
              'position-static': calendarPositionStatic,
            })
            }
            onChange={ (date: any) => {
              selectRange ? setRangeDate(date) : setSingleDate(date)
              selectRange ? returnRange(date) : returnSingle(date)
              setIsOpen(false)
            } }
            selectRange={ selectRange }
          />
        </>
      ) }
    </div>
  )
}


const formatRuMonth = (date: any) => {
  const tempArr = date.split(' ')
  tempArr[1] = `${ tempArr[1][0].toUpperCase() + tempArr[1].substring(1, 3) },`

  return tempArr.join(' ')
}


export default CalendarSimple
