import React, { useMemo, useEffect, useState, } from 'react'
import { useGetProjectApplys, } from '../../legacy/gql/hooks/useProjects'
import { useSelector, } from 'react-redux'
import ModerCard from '@/entities/PAapplications/ui/ModerCard/ModerCard'
import PaginationSimple from '../../legacy/components/common/PaginationSimple/PaginationSimple'
import { IconSortDescending, IconSortAscending, } from '@tabler/icons'
import { InputNumber, } from 'antd'
import ButtonSimple from '../../legacy/components/common/ButtonSimple/ButtonSimple'
import NoContracts from '../../legacy/components/Finances/PaymentPage/NoContracts/NoContracts'
import { useLocation, } from 'react-router-dom'
import Preloader from '../../legacy/components/common/PreLoader/Preloader'


function Applications ({
  filter,
  params,
  payl,
}: any) {
  const [
    sort,
    setSort,
  ] = useState('desc')
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const updateProjects = useSelector(state => state.subscriptions.applyStatus)
  const [
    offs,
    setOffs,
  ] = useState(0)

  const [
    page,
    setPage,
  ] = useState<any>(0)

  // const navigate = useNavigate();
  const location = useLocation()

  const pagination = {
    offset: offs,
    limit: 10,
  }

  const payloadFilter = location?.state?.from ? {
    ...params,
    sort: [
      {
        column: 'updated',
        order: sort,
      },
    ],
    filter: payl,
  } : {
    ...params,
    sort: [
      {
        column: 'updated',
        order: sort,
      },
    ],
  }

  const { refetch, data, error, loading, } = useGetProjectApplys(payloadFilter, pagination)
  useEffect(() => {
    refetch()
  }, [
    updateProjects,
    refetch,
  ])


  const choosePage = (i: any) => {
    if (i > 0) {
      goTop()
      if (i === 1) {
        setOffs(0)
      } else {
        setOffs(((i - 1) * 10))
      }
    }
  }

  const goTop = () => {
    const page = document.querySelector('.main-scroll-bar')?.firstChild
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    page.scrollTo(0, 0)
  }


  const applications = useMemo(() => {
    if (error) {
      return <div>.....errrr</div>
    } else if (loading) {
      return <Preloader />
    } else if (data) {
      return (
        <ul className="moderCards">
          { data.paListProjects.items.map((i: any, index: any) => <li key={ index }><ModerCard type={ filter } i={ i } data={ i.data } status="project" /></li>) }
          { data?.paListProjects?.pagination.total > 10 && <div style={ {
            display: 'flex',
            alignItems: 'center',
          } } className="choosePageWrap">
            <InputNumber
              min={ 1 }
              max={ 300 }
              placeholder="Введите номер страницы"
              autoFocus={ false }
              onChange={ setPage }
            />

            <ButtonSimple onClickHandler={ () => choosePage(page) } value="перейти" variant="fill" active={ true } styles={ { marginLeft: '10px', } } />
          </div> }
          { data.paListProjects.pagination.total > 10 && <PaginationSimple count={ Math.ceil(data.paListProjects.pagination.total / 10) } onChange={ handlePaginate } page={ offs ? Math.round((offs + 10) / 10) : 1 } /> }
          { data.paListProjects.items.length < 1 && <NoContracts title="Нет подходящих проектов по выбранным критериям, попробуйте изменить фильтры поиска" /> }
        </ul>
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    error,
    loading,
    page,
    offs,
  ])


  function handlePaginate (e: any, value: any) {
    const page = document.querySelector('.main-scroll-bar')?.firstChild
    setOffs((value * 10) - 10)
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    page.scrollTo(0, 0)
  }

  return (
    <>
      <div className="k-dateSort" onClick={ () => (sort === 'asc' ? setSort('desc') : setSort('asc')) }>{ sort === 'desc' ? <IconSortAscending color="#7671DD" size={ 30 } /> : <IconSortDescending color="#7671DD" size={ 30 } /> }Сортировать по дате заявки</div>
      { applications }
      {/* {   data?.newsFeed?.metrics.count > 20 &&  <PaginationSimple count={Math.round(100data?.newsFeed?.metrics.count / 20 )} onChange={handlePaginate} page={offs ? Math.round(offs / 20) : 1} />} */ }
    </>
  )
}

export default Applications
