import HistoryLinks from '../../common/HistoryLinks/HistoryLinks'
import './PaymentPage.less'
import { setFilter, } from '../../../redux/FinancesSlices/PaymentSlice'
import { useSelector, useDispatch, } from 'react-redux'
import PaymentFrame from './PaymentFrame/PaymentFrame'
import FilterButtons from '@/legacy/components/common/FilterButtons/FilterButtons'


const filterBut = [
  'Образование',
  'Дополнительное образование',
  'Общежитие',
]

function PaymentPage () {
  const dispatch = useDispatch()
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const filtStatus = useSelector(state => state.pay.filtStat)

  function setStatus (i: any) {
    dispatch(setFilter(i))
  }


  return (
    <div className="payment">
      <HistoryLinks links={ [
        {
          value: 'Главная',
          path: '/',
        },
      ] } loc="Оплата и задолженность" />
      <h2 className="paymentTitle">Оплата и задолженность</h2>
      <FilterButtons onClick={ setStatus } items={ filterBut } status={ filtStatus } />
      <PaymentFrame status={ filtStatus } />
    </div>
  )
}

export default PaymentPage
