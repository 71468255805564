import vk from '../../../../assets/social/vk.svg'
/* import fb from '../../../../assets/social/fb.svg'
import twitter from '../../../../assets/social/twitter.svg' */


function Social () {
  return (
    <div className='CN_Social'>
      <span className='CN_Link CN_LinkFirst'>Поделиться:</span>
      <a
        href="https://vk.com"
        target="_blank"
        rel="noreferrer"
        className='d-row d-center'
      >
        <div className='CN_Ico'>
          <img src={ vk } alt={ 'vk' } />
        </div>
        <span className='CN_Link'>Vkontakte</span>
      </a>
      {/* <a
                href="https://vk.com"
                target="_blank"
                rel="noreferrer"
                className='d-row d-center'
            >
                <div className='CN_Ico'>
                    <img src={fb} alt={fb} />
                </div>
                <span className='CN_Link'>Facebook</span>
            </a>
            <a
                href="https://vk.com"
                target="_blank"
                rel="noreferrer"
                className='d-row d-center'
            >
                <div className='CN_Ico'>
                    <img src={twitter} alt={twitter} />
                </div>
                <span className='CN_Link'>Twitter</span>
            </a> */}

    </div>
  )
}


export default Social
