import { useEffect, useState, } from 'react'

import HistoryLinks from '../../../common/HistoryLinks/HistoryLinks'
import VisualizeDataBox from '../../VisualizeDataBox/VisualizeDataBox'
import RadioButtonFacade from '../../../common/RadioButtonFacade/RadioButtonFacade'
import ButtonSimple from '../../../common/ButtonSimple/ButtonSimple'

import Popup from '../../../common/Popup/Popup'
import PopupContentReject from '../../popupContent/PopupContentReject/PopupContentReject'
import PopupContentCreateOrEdit from '../../popupContent/PopupContentCreateOrEdit/PopupContentCreateOrEdit'
import { useGetAllDiagrammScores, useUpdateEcDataType, } from '../../../../gql/hooks/effectiveContractHooks/useEffectiveContract'
import _ from 'lodash'
import PopupContentSupplyAppeal from '../../popupContent/PopupContentSupplyAppeal/PopupContentSupplyAppeal'
import PopupContentGetAppealStatus from '../../popupContent/PopupContentGetAppealStatus/PopupContentGetAppealStatus'
import { useSelector, } from 'react-redux'
import DocumentsBlock from '../../DocumentsBlock/DocumentsBlock'
import appealCheck from '../../popupContent/PopupContentSupplyAppeal/appealCheck.svg'
import useGetEcDocuments from '../../../../gql/hooks/effectiveContractHooks/useGetEcDocuments'
import useGetEcStages from '../../../../gql/hooks/effectiveContractHooks/useGetEcStages'
import BasicIndicatorsTab from '@/legacy/components/EffectiveContract/Tabs/refactor/BasicIndicatorsTab_2/BasicIndicatorsTab'
import HighPerformanceTab from '@/legacy/components/EffectiveContract/Tabs/refactor/HighPerformanceTab_2/HighPerformanceTab'
import BonusPointsTab from '@/legacy/components/EffectiveContract/Tabs/refactor/BonusPointsTab_2/BonusPointsTab'
import DowngradePaymentTab from '@/legacy/components/EffectiveContract/Tabs/refactor/DowngradePaymentTab_2/DowngradePaymentTab'
import useUpdateEcData from '@/legacy/gql/hooks/effectiveContractHooks/useUpdateEcData'

type PopupContentType = 'rebaseAchievement' | 'reject' | 'getAppealStatus' | 'create' | 'edit' | 'supplyApeal' | null;

const tabButtons = [
  { text: 'Базовые показатели', },
  { text: 'Показатели высокой эффективности труда', },
  { text: 'Бонусные баллы', },
  { text: 'Основания для снижения выплат стимулирующего характера', },
]


const TeacherPage = () => {
  const { active_stage, } = useGetEcStages()
  const stage = `stage-${ active_stage?.stage_number }`

  const { documents, } = useGetEcDocuments()

  const [
    contentIndex,
    setContentIndex,
  ] = useState(tabButtons[0].text)
  const [
    isPopupOpen,
    setIsPopupOpen,
  ] = useState(false)
  const [
    reasonFailure,
    setReasonFailure,
  ] = useState({})
  const [
    popupContentType,
    setPopupContentType,
  ] = useState<PopupContentType>(null)

  const [
    defaultPopupValues,
    setDefaultPopupValues,
  ] = useState({})


  const { onUpdateEcDataHandler, } = useUpdateEcData()

  const [
    onUpdateEcDataType,
  ] = useUpdateEcDataType()
  const onUpdateEcDataTypeHandler = (rec_id: any, newDataType: any) => onUpdateEcDataType({
    variables: {
      rec_id: rec_id,
      dataType: newDataType,
    },
  })


  const onEditAchievement = (defaultValues = {}) => {
    setPopupContentType('edit')
    setIsPopupOpen(true)
    setDefaultPopupValues({ ...defaultValues, })
  }

  const onSupplyApeal = (achievementFieldsOnly: any, allValues: any) => {
    setPopupContentType('supplyApeal')
    setIsPopupOpen(true)
    setDefaultPopupValues({
      achievementFieldsOnly: achievementFieldsOnly,
      allValues: allValues,
    })
  }

  const onRebaseAchievement = (params: any = {}) => {
    const defineNewDataType = (oldDataType: any) => {
      if (oldDataType === 'table_1_1') {
        return 'table_2_3'
      } else if (oldDataType === 'table_2_3') {
        return 'table_1_1'
      } else if (oldDataType === 'table_1_4') {
        return 'table_2_7'
      } else if (oldDataType === 'table_2_7') {
        return 'table_1_4'
      }
    }
    onUpdateEcDataHandler({
      recordId: params?.achievementData?.rec_id,
      newData: {
        ...params.achievementData.current_data,
        isRebased: true,
        dataType: params.dataType,
      },
      userIds: params.achievementData.user_ids || params.achievementData.userId,
    }).then(() => onUpdateEcDataTypeHandler(params.rec_id, defineNewDataType(params.dataType))
      .then(() => {
        setPopupContentType('rebaseAchievement')
        setIsPopupOpen(true)
      })
    )
  }

  const getAndShowReasonFailure = (data: any) => {
    setReasonFailure({ ...data, })
    setPopupContentType('reject')
    setIsPopupOpen(true)
  }

  const getAppealStatus = (answer: any) => {
    setDefaultPopupValues({ rec_id: answer.appealLinkId,
      dataType: answer.dataType, })
    setPopupContentType('getAppealStatus')
    setIsPopupOpen(true)
  }
  const popupContent = {
    rebaseAchievement: (
      <>
        <div style={ {
          display: 'flex',
          justifyContent: 'center',
        } }>
          <div style={ {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          } }>
            <img src={ appealCheck } style={ {
              width: '7.5rem',
              alignSelf: 'center',
            } } alt="" />
            <br />
            <h2 className="popup-content-reject__title">Достижение перенесено</h2>
          </div>
        </div>
        <br />
        <ButtonSimple
          value="Хорошо"
          variant="full-filled"
          onClickHandler={ () => {
            setIsPopupOpen(false)
          }}
        />
      </>
    ),

    reject: (
      <PopupContentReject
        // @ts-expect-error TS(2339): Property 'title' does not exist on type '{}'.
        title={ reasonFailure.title }
        // @ts-expect-error TS(2339): Property 'reason' does not exist on type '{}'.
        reason={ reasonFailure.reason }
        onClickHandler={ () => setIsPopupOpen(false) }
      />
    ),

    getAppealStatus: (
      <PopupContentGetAppealStatus
        defaultValues={ defaultPopupValues }
        onClosePopUp={ () => setIsPopupOpen(false) }
      />
    ),

    create: <PopupContentCreateOrEdit
      onClickHandler={ () => setIsPopupOpen(false) }
      defaultValues={ defaultPopupValues }
    />,

    edit: <PopupContentCreateOrEdit
      hint='edit'
      onClickHandler={ () => setIsPopupOpen(false) }
      defaultValues={ defaultPopupValues }
    />,

    supplyApeal: <PopupContentSupplyAppeal
      onClosePopUp={ () => setIsPopupOpen(false) }
      defaultValues={ defaultPopupValues }
    />,
  }

  const onClosePopupHandler = () => {
    setIsPopupOpen(false)
    // setDefaultPopupValues({});
  }


  const [
    buttons,
    setButtons,
  ] = useState(tabButtons)


  const isTeacher = useSelector(
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    state => state.auth.me.positions.some((position: any) => position.isTeacher))
  useEffect(() => {
    setButtons(tabButtons)
    setContentIndex(tabButtons[0].text)
  }, [
    isTeacher,
  ])

  const isForeignTeacher = useSelector(
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    state => state.auth.me.positions.some((position: any) => position.position_type === 'ВнешнееСовместительство'))
  useEffect(() => {
    setButtons(tabButtons)
    setContentIndex(tabButtons[0].text)
  }, [
    isForeignTeacher,
  ])


  const [
    scores1percent,
    setScores1percent,
  ] = useState(0)
  const [
    scores2,
    setScores2,
  ] = useState(0)
  const [
    scores2percent,
    setScores2percent,
  ] = useState(0)
  const [
    scores3,
    setScores3,
  ] = useState(0)
  const [
    scores3percent,
    setScores3percent,
  ] = useState(0)
  const [
    scores4,
    setScores4,
  ] = useState(0)
  const [
    scores4percent,
    setScores4percent,
  ] = useState(0)
  const [
    scoresTotal,
    setScoresTotal,
  ] = useState(0)


  const getAllDiagrammScores = useGetAllDiagrammScores()

  useEffect(() => {
    if (getAllDiagrammScores.data) {
      const totalData = getAllDiagrammScores.data.ecGraphDataTotals

      setScores1percent(totalData.info_1.percents)
      setScores2(totalData.info_2.total)
      setScores2percent(totalData.info_2.percents)
      setScores3(totalData.info_3.total)
      setScores3percent(totalData.info_3.percents)
      setScores4(totalData.info_4.total)
      setScores4percent(totalData.info_4.percents)
      setScoresTotal(totalData.summary)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getAllDiagrammScores.loading,
  ])


  const dataOfPoints = {
    basicIndicators: {
      'data': scores1percent,
    },
    hightPerformance: {
      'data': _.round(scores2percent, 2),
      'commonScores': scores2,
    },
    bonusPoints: {
      'data': _.round(scores3percent, 2),
      'commonScores': scores3,
    },
    downgradePayment: {
      'data': _.round(scores4percent, 2),
      'commonScores': scores4,
    },
    totalPoints: scoresTotal,
  }


  return (
    <div className="effective-contract">
      <HistoryLinks
        links={ [
          {
            value: 'Главная',
            path: '/',
          },
        ] }
        loc="Эффективный контракт"
      />

      <h2 className="effective-contract__title">Эффективный контракт</h2>

      <VisualizeDataBox
        stage={ stage }
        dataOfPoints={ dataOfPoints }
      />

      <div className="effective-contract__buttons-wrapper">
        <RadioButtonFacade
          content={ buttons }
          BtnComponent={ ButtonSimple }
          activeIndex={ buttons.findIndex(button => button.text === contentIndex) }
          setActiveIndex={ (answer: any) => setContentIndex(buttons[answer].text) }
        />
      </div>

      { contentIndex === tabButtons[0].text && (
        <BasicIndicatorsTab
          stage={ stage }
          categoryName="Базовые показатели"
          handlers={ {
            onEditAchievement: onEditAchievement,
            onSupplyApeal: onSupplyApeal,
            onRebaseAchievement: onRebaseAchievement,
            getReasonFailure: getAndShowReasonFailure,
            getAppealStatus: getAppealStatus,
          } }
        />
      ) }


      { contentIndex === tabButtons[1].text && (
        <HighPerformanceTab
          stage={ stage }
          categoryName="Показатели высокой эффективности труда"
          handlers={ {
            onEditAchievement: onEditAchievement,
            onSupplyApeal: onSupplyApeal,
            onRebaseAchievement: onRebaseAchievement,
            getReasonFailure: getAndShowReasonFailure,
            getAppealStatus: getAppealStatus,
          } }
        />
      ) }


      { contentIndex === tabButtons[2].text && (
        <BonusPointsTab
          stage={ stage }
          categoryName="Бонусные баллы"
          handlers={ {
            onSupplyApeal: onSupplyApeal,
            onEditAchievement: onEditAchievement,
            getReasonFailure: getAndShowReasonFailure,
            getAppealStatus: getAppealStatus,
          } }
        />
      ) }


      { contentIndex === tabButtons[3].text && (
        <DowngradePaymentTab
          stage={ stage }
          categoryName="Неисполнение индивидуального плана развития"
          handlers={ {
            onSupplyApeal: onSupplyApeal,
            onEditAchievement: onEditAchievement,
            getReasonFailure: getAndShowReasonFailure,
            getAppealStatus: getAppealStatus,
          } }
        />
      ) }

      <br />
      <DocumentsBlock items={ documents } />

      <Popup
        isOpen={ isPopupOpen }
        handlePopup={ onClosePopupHandler }
        withScroll
      >
        { popupContentType ? popupContent[popupContentType] : <></> }
      </Popup>
    </div>
  )
}


export default TeacherPage
