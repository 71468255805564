/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import check from '../../../assets/images/check.svg'
// import { setSelectedCategories } from '../../../redux/NewsSlice/NewsSlices'


export default function Str ({
  i,
  selectedCategories,
  setSelectedCategories,
  isActive,
}: any) {
  // const [isActive, setIsActive] = useState(false)
  // const selectedCategories = useSelector((state) => state.news.selectedCategories)

  // const dispatch = useDispatch()

  /* useEffect(() => {
    selectedCategories && selectedCategories.find(y => i === y && setIsActive(true))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategories]) */


  /* const click = (i) => {
   if (!isActive) {
     let arr = []

     if (selectedCategories && selectedCategories.length > 0) {
       selectedCategories.forEach(y => {
         y !== i && arr.push(y)
       })
     }

     arr.push(i)
     setSelectedCategories(arr)
   } else {
     let arr = []
     selectedCategories.forEach(y => {
       if (y !== i) {
         arr.push(y)
       }
     })
     setSelectedCategories(arr)
     setIsActive(false)
   }
 }
  */


  /* const click = (i) => {
    setSelectedCategories(i)
    console.log('bottom', selectedCategories)
  } */

  return (
    <li
      key={ i }
      onClick={ setSelectedCategories }
      className="list-item-text-style"
    >
      <button className={ isActive ? 'Click Active' : 'Click' }>
        <img src={ check } alt={ '' } />
      </button>
      { i }
    </li>
  )
}
