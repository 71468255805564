import { NewPageWrapper, } from '../../common/GeneralElements/GeneralElements'
import HistoryLinks from '../../common/HistoryLinks/HistoryLinks'
import BackToButton from '../../common/BackToButton/BackToButton'
import CardLibrary from '../CardLibrary/CardLibrary'
import AddToFavorites from '../ui/AddToFavorites/AddToFavorites'
import LocationTable from './LocationTable/LocationTable'
import useRedirect from '@/legacy/hooks/useRedirect'

const BookDetailsPage = () => {
  const redirect = useRedirect()


  return <NewPageWrapper>
    <HistoryLinks
      links={
        [
          {
            value: 'Главная',
            path: '/',
          },
          {
            value: 'Учебный процесс',
            path: '/',
          },
        ]
      }
      loc="Библиотека"
    />
    <BackToButton title="Вернуться к списку" onClick={ () => redirect('/library') } />
    <CardLibrary footer={ <AddToFavorites /> } isDetail={ true } styles={ { marginTop: '20px', } } />
    <LocationTable />
  </NewPageWrapper>
}

export default BookDetailsPage
