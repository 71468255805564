import './EventCard.less'
import { useDispatch, } from 'react-redux'
import useRedirect from '@/legacy/hooks/useRedirect'
import { setCurrentAnnounce, } from '@/legacy/redux/NewsSlice/NewsSlices'


function EventCard ({
  i,
}: any) {
  const redirect = useRedirect()
  const dispatch = useDispatch()

  const goToInner = () => {
    dispatch(setCurrentAnnounce(i))
    if (i.description.startsWith('<')) {
      document.location.href = i.urls.linkUrl
    } else {
      redirect('/announce-inner')
    }
  }


  return <div className="AdCard Event" onClick={ goToInner }>
    <div className="eventInfo">
      <div className="anounceHashtag">Мероприятие</div>
      <p className="AnouncesContent">{ i.title }</p>
    </div>
    { i.eventDate.split(' ').length < 4 ? <div className="eventDate">
      <p className='month'>{ i.eventDate.split(' ')[0] }<br />{ i.eventDate.split(' ')[1] }</p>
      <p className="eventYear">{ i.eventDate.split(' ')[2] }</p>
    </div> :
      <div className="eventDate">
        <p className='month'>{ `${ i.eventDate.split(' ')[0] } -  ${ i.eventDate.split(' ')[3] }` }<br />{ `${ i.eventDate.split(' ')[1] } -  ${ i.eventDate.split(' ')[4] }` }</p>
        <p className="eventYear">{ i.eventDate.split(' ')[5] }</p>
      </div> }
  </div>
}

export default EventCard
