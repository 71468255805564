import { useState, } from 'react'
import './LearningPathway.less'

import { useSelector, } from 'react-redux'
import { useDispatch, } from 'react-redux'
import { setSelectedTabIndex, } from '../../../../redux/learningPathwaySlices/learningPathwaySlices'

import HistoryLinks from '../../../common/HistoryLinks/HistoryLinks'
import RadioButtonFacade from '../../../common/RadioButtonFacade/RadioButtonFacade'
import ButtonSimple, { IconComponent, } from '../../../common/ButtonSimple/ButtonSimple'
import { IconCirclePlus, IconCloudUpload, IconUsers, IconDownload, } from '@tabler/icons'

import { attachedFiles, } from './mockData/attachedFiles'
import Tab from './Tab/Tab'
import { NewPageWrapper, } from '../../../common/GeneralElements/GeneralElements'
import AddFacultativePopup from './AddDisciplinePopup/AddFacultativePopup'
import FilterDropDown from '../../../common/FilterDropDown/FilterDropDown'
import { useSendData, } from '../../../../gql/hooks/useMyEducation'
import FacultativeSignAllow from './SignUpPermission/FacultativeSignAllow'
import TrackSignAllow from './SignUpPermission/TrackSignAllow'
import AddTrackPopup from './AddDisciplinePopup/AddTrackPopup'
import AddElectivePopup from './AddDisciplinePopup/AddElectivePopup'
import ElectiveSignAllow from './SignUpPermission/ElectiveSignAllow'

import { URL as url, } from '../../../../Utils/constants'
import { AnyKindOfDictionary, } from 'lodash'
import useGetRole from '@/legacy/redux/hooks/common/useGetRole'
import { useAppContext, } from '@/App/providers/AppProvider'


const LearningPathway = () => {
  const dispatch = useDispatch()
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const studentInfo = useSelector(state => state.prof.studentInfo)

  const { openToast, } = useAppContext()

  const [
    isShipDisabled,
    setIsShipDisabled,
  ] = useState(false)


  /* const namesSpec = studentInfo.map(info => info); */

  const [
    filters,
    setFilters,
  ] = useState({
    /* semester: [1, 2, 3, 4, 5, 6, 7], */ nameSpec: studentInfo[0],
    /*  disciplineType: { plan: `б1.(б|в|о)` } */
  })


  const role = useGetRole()


  const [
    isAddTrack,
    setIsAddTrack,
  ] = useState(false)


  const selectedTabIndex = useSelector(
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    state => state.pathway.selectedTabIndex)

  const setSelectedTabHandler = (tabIndex: any) => dispatch(
    setSelectedTabIndex(tabIndex))

  const mainConent = [
    {
      moderAddButton: {
        text: 'Добавить трек',
      },
      tabType: 'important',
      downloadType: 'miner',
    },
    {
      moderAddButton: {
        text: 'Добавить дисциплину(электив)',
      },
      tabType: 'lesser',
      downloadType: 'elective',
    },
    {
      moderAddButton: {
        text: 'Добавить факультатив',
      },
      tabType: 'elective',
      downloadType: 'facultative',
    },
  ]

  const buttons = [
    { text: 'Дисциплины по выбору(майноры)', },
    { text: 'Дисциплины по выбору(элективы)', },
    { text: 'Факультативы', },
  ]

  const signUpButtons: AnyKindOfDictionary = {
    elective: <FacultativeSignAllow />,
    important: <TrackSignAllow />,
    lesser: <ElectiveSignAllow />,
  }

  const addDisciplinePopups: any = {
    elective: <AddFacultativePopup isOpen={ isAddTrack } handlePopup={ () => setIsAddTrack(!isAddTrack) } title={ mainConent[selectedTabIndex].moderAddButton.text }
      type={ mainConent[selectedTabIndex].tabType } />,
    important: <AddTrackPopup isOpen={ isAddTrack } handlePopup={ () => setIsAddTrack(!isAddTrack) } title={ mainConent[selectedTabIndex].moderAddButton.text }
      type={ mainConent[selectedTabIndex].tabType } />,
    lesser: <AddElectivePopup isOpen={ isAddTrack } handlePopup={ () => setIsAddTrack(!isAddTrack) } title={ mainConent[selectedTabIndex].moderAddButton.text }
      type={ mainConent[selectedTabIndex].tabType } />,
  }


  const downloadFile = (href: any) => {
    const link = document.createElement('a')
    link.href = href
    /* link.download = `Выгрузка аписанных студентов тип: ${type}`; */
    link.click()
  }

  const [
    esSendDisciplines,
  ] = useSendData([
    mainConent[selectedTabIndex].downloadType,
  ])

  const shipData = () => {
    setIsShipDisabled(true)
    esSendDisciplines()
      .then(() => {
        openToast?.({ message: 'Данные успешно отправленны в шину', })
        setIsShipDisabled(false)
      })
      .catch(e => {
        openToast?.({ message: e.message, })
        setIsShipDisabled(false)
      })
  }


  return (
    <NewPageWrapper className='learning-pathway'>
      <HistoryLinks
        links={
          [
            {
              value: 'Главная',
              path: '/',
            },
            {
              value: 'Высшее образование',
              path: '/high-education',
            },
          ]
        }
        loc="Выбор дисциплин и факультативов"
      />

      <h2 className="learning-pathway__title">Выбор дисциплин и факультативов</h2>

      <div className='learning-pathway__navigation-wrapper'>
        { role === 'osrv-moder' ? (
          <div>
            <ButtonSimple
              value={
                <IconComponent
                  text={ mainConent[selectedTabIndex].moderAddButton.text }
                  Icon={ IconCirclePlus }
                />
              }
              onClickHandler={ () => setIsAddTrack(!isAddTrack) }
              variant="fill"
            />
          </div>
        ) : ((role === 'student' || role === 'student-rakus') && <FilterDropDown
          items={ studentInfo.map((info: any) => info) }
          field="namespec"
          defaultHint={ studentInfo[0].namesSpec }
          onClickHandler={ (i: any) => setFilters({
            ...filters,
            nameSpec: i,
          }) }
          firstIsSelected
          customWidth="25%"
        />
        ) }
        <div className="learning-pathway__buttons-wrapper">
          <RadioButtonFacade
            content={ buttons }
            BtnComponent={ ButtonSimple }
            activeIndex={ selectedTabIndex }
            setActiveIndex={ setSelectedTabHandler }
          />
        </div>
      </div>
      { role === 'osrv-moder' && (
        <div className="exportWrap">
          <>
            { signUpButtons[mainConent[selectedTabIndex].tabType] }

            <ButtonSimple
              onClickHandler={ shipData }
              isDisabled={ isShipDisabled }
              styles={ {
                width: 'fit-content',
                marginBottom: '16px',
                marginLeft: '10px',
              } }
              value={
                <IconComponent
                  text="Выгрузить в шину"
                  Icon={ IconCloudUpload }
                />
              }
              variant="groupe"
            />
            <ButtonSimple
              onClickHandler={ () => downloadFile(`https://${ url }/api/es/xls?type=${ mainConent[selectedTabIndex].downloadType }`) }
              styles={ { marginLeft: '0.6rem', } }
              value={
                <IconComponent
                  text="Список записавшихся студентов"
                  Icon={ IconUsers }
                />
              }
              variant="groupe"
            />
            { mainConent[selectedTabIndex].downloadType === 'miner' && <ButtonSimple
              onClickHandler={ () => downloadFile(`https://${ url }/api/es/xls?type=${ mainConent[selectedTabIndex].downloadType }&unsigned=1 `) }
              styles={ { marginLeft: '0.6rem', } }
              value={
                <IconComponent
                  text="Список не записавшихся студентов"
                  Icon={ IconUsers }
                />
              }
              variant="groupe"
            /> }
            <ButtonSimple
              onClickHandler={ () => downloadFile(`https://${ url }/api/es/stats?type=${ mainConent[selectedTabIndex].downloadType }`) }
              styles={ { marginLeft: '0.6rem', } }
              value={
                <IconComponent
                  text="Статистика"
                  Icon={ IconDownload }
                />
              }
              variant="groupe"
            />
          </>
        </div>
      ) }

      <div className='learning-pathway__main-content'>
        <Tab
          attachedFiles={ attachedFiles }
          role={ role }
          type={ mainConent[selectedTabIndex].tabType }
          nameSpec={ filters.nameSpec }
        />
      </div>
      { addDisciplinePopups[mainConent[selectedTabIndex].tabType] }
    </NewPageWrapper>
  )
}


export default LearningPathway
