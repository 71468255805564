import { createContext, useState, } from 'react'
import { useNavigate, } from 'react-router-dom'
import { useGetAchievementType, } from '../../../gql/hooks/portfolio/useAchievementTypes'
import useChangeTypeDirection from '../../../gql/hooks/portfolio/useChangeTypeDirection'
import useUpdateAchievementType from '../../../gql/hooks/portfolio/useUpdateAchievementType'
import { PORTFOLIO_EDIT_INDICATOR, PORTFOLIO_INDICATORS, PORTFOLIO_TEMPLATES, } from '../../../Utils/routes'
import BackToButton from '../../common/BackToButton/BackToButton'
import ButtonSimple from '../../common/ButtonSimple/ButtonSimple'
import { NewPageWrapper, TitleH2, TitleH3, } from '../../common/GeneralElements/GeneralElements'
import Popup from '../../common/Popup/Popup'
import useUrlSearch from '../../../hooks/useUrlSearch'
import HistoryLinks from '../../common/HistoryLinks/HistoryLinks'
import AddCriteriaBlock from './AddCriteriaBlock/AddCriteriaBlock'
import Criteries from './Criteries/Criteries'
import MainInfo from './MainInfo/MainInfo'
import styles from './PortfolioEditIndicator.module.less'
import useCreateJsondata from './useCreateJsondata'
import useRedirect from '@/legacy/hooks/useRedirect'


export const CreateJsonContext = createContext([])

const PortfolioEditIndicator = () => {
  const [
    isNotifyPopupOpen,
    setIsNotifyPopupOpen,
  ] = useState(false)
  const navigate = useNavigate()
  const searchCatid = useUrlSearch('catid')
  const searchKey = useUrlSearch('key')
  const {
    achievementType,
    refetch,
  } = useGetAchievementType({
    key: Number(searchKey),
    cat_id: Number(searchCatid),
    is_admin: true,
  })
  const jsondata = useCreateJsondata(achievementType?.jsondata)
  const [
    mainInfo,
    setMainInfo,
  ] = useState({})

  const onChangeAchievementType = useChangeTypeDirection()
  const onUpdateAchievementHandler = useUpdateAchievementType()

  const redirect = useRedirect()


  return (
    <>
      <CreateJsonContext.Provider
        // @ts-expect-error TS(2740): Type '{ jsondata: any; removeCriteria: (field: any... Remove this comment to see the full error message
        value={ { ...jsondata, } }
      >
        <NewPageWrapper>
          <HistoryLinks links={ [
            {
              value: 'Главная',
              path: '/',
            },
            {
              value: 'Шаблоны показателей',
              path: PORTFOLIO_TEMPLATES,
            },
            {
              value: 'Оцениваемые показатели',
              path: PORTFOLIO_INDICATORS,
            },
          ] } loc={ achievementType?.name } />
          <br />
          <BackToButton
            className={ styles.backButton }
            title={ 'Вернуться к оцениваемым показателям' }
            onClick={ () => redirect(`${ PORTFOLIO_INDICATORS }?catid=${ searchCatid }`) }
          />
          <br />
          <TitleH2>
            { achievementType?.name }
          </TitleH2>

          <MainInfo achievementType={ achievementType } setMainInfo={ setMainInfo } />
          <br />
          <Criteries />
          <br />
          <AddCriteriaBlock />
          <br />
          <ButtonSimple
            styles={ { width: 'fit-content', } }
            active
            variant={ 'alone' }
            value={ 'Сохранить' }
            onClickHandler={ () => {
              const achievementsParamsForUpdate = {
                key: Number(searchKey),
                direction_key: Number(searchCatid),
                // @ts-expect-error TS(2339): Property 'name' does not exist on type '{}'.
                name: mainInfo?.name,
                status: achievementType?.status,
                jsondata: jsondata.jsondata,
                // @ts-expect-error TS(2339): Property 'need_confirm' does not exist on type '{}... Remove this comment to see the full error message
                need_confirm: mainInfo?.need_confirm,
                // @ts-expect-error TS(2339): Property 'detached' does not exist on type '{}'.
                detached: mainInfo?.detached,
              }


              // @ts-expect-error TS(2339): Property 'direction_key' does not exist on type '{... Remove this comment to see the full error message
              if (Number(searchCatid) !== Number(mainInfo?.direction_key)) {
                onUpdateAchievementHandler(achievementsParamsForUpdate)
                  .then(() => {
                    onChangeAchievementType({
                      direction_key: Number(searchCatid),
                      key: Number(searchKey),
                      // @ts-expect-error TS(2339): Property 'direction_key' does not exist on type '{... Remove this comment to see the full error message
                      new_direction_key: Number(mainInfo?.direction_key),
                    })
                  })
                  .then(() => {
                    // @ts-expect-error TS(2339): Property 'direction_key' does not exist on type '{... Remove this comment to see the full error message
                    navigate(`${ PORTFOLIO_EDIT_INDICATOR }?catid=${ Number(mainInfo?.direction_key) }&key=${ Number(searchKey) }`)
                  })
                  .then(() => setIsNotifyPopupOpen(true))
              } else {
                onUpdateAchievementHandler(achievementsParamsForUpdate)
                  .then(() => refetch())
                  .then(() => setIsNotifyPopupOpen(true))
              }
            } }
          />
        </NewPageWrapper>
      </CreateJsonContext.Provider>

      <Popup
        isOpen={ isNotifyPopupOpen }
        handlePopup={ () => setIsNotifyPopupOpen(false) }
      >
        <>
          <TitleH3>
            Изменения сохранены
          </TitleH3>

          <ButtonSimple
            active
            onClickHandler={ () => setIsNotifyPopupOpen(false) }
            value='Ок'
            variant={ 'full-filled' }
          />
        </>
      </Popup>
    </>
  )
}


export default PortfolioEditIndicator
