import React, { Suspense, useEffect, } from 'react'
import './App.less'
// eslint-disable-next-line import/extensions
import '@/legacy/components/SideBar/SideBar.less'
import Header from '@/legacy/components/Header/Header'
import SideBarTgu from '@/legacy/components/SideBar/SideBarTgu/SideBarTgu'
import Footer from '@/legacy/components/Footer/Footer'
import AppRouter from './AppRouter/AppRouter'
import { Helmet, } from 'react-helmet-async'
// @ts-expect-error TS(2307): Cannot find module '../../assets/images/favicon.ic... Remove this comment to see the full error message
// eslint-disable-next-line import/extensions
import logo from '@/legacy/assets/images/favicon.ico'
import { ApolloClient, } from '@/App/providers/ApolloClientProvider'
import Login from '@/legacy/components/Login/Login'
import Logout from '@/legacy/components/Logout/Logout'
import { PersistGate, } from 'redux-persist/lib/integration/react'
import { Provider, } from 'react-redux'
import store, { persistor, } from '@/legacy/redux/store'
import AppProvider from './providers/AppProvider'
import Preloader from '@/legacy/components/common/PreLoader/Preloader'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom'
import Scrollbars from 'react-custom-scrollbars-2'
import SmallDevicesPage from '@/legacy/components/common/SmallDevicesPage/SmallDevicesPage'
import LocationListener from './LocationListener/LocationListener'
import { isAndroid, isIOS, } from 'react-device-detect'
import useGetRole from '@/legacy/redux/hooks/common/useGetRole'
import { EcSubscriptionsProvider, } from '@/features'

// import Snowfall from "react-snowfall";
// import { useSelector, } from 'react-redux'

const MainScreen = () => {
  const role = useGetRole()
  const location = useLocation()
  // const isSnow = useSelector((state: any) => state.util.snowAnymation);

  useEffect(() => {
    const page = document.querySelector('.main-scroll-bar')?.firstChild
    if (page) {
      // @ts-expect-error TS(2339): Property 'scrollTo' does not exist on type 'ChildN... Remove this comment to see the full error message
      page.scrollTo(0, 0)
    }
  }, [
    location,
  ])

  return (
    <>
      <Helmet>
        <title>ЛК ТГУ</title>
        <link rel="icon" href={ logo } />
      </Helmet>

      { role !== null ? (
        <div className="page">
          <SideBarTgu />
          {/* {isSnow && <Snowfall color="#AFEEEE" style={ {zIndex: "1000000000000"}}  snowflakeCount={400}/>} */ }
          <main className="main" id="scrollableDiv">
            <Scrollbars className="main-scroll-bar">
              <Header />
              <AppRouter /* isLogged={isLogged} */ />
              <Footer />
              {/*   { !isLogged ? <div>.....крутится прелоадер....</div> : <></> } */ }
            </Scrollbars>
          </main>
        </div>
      ) : (
        <Preloader />
      ) }
    </>
  )
}

const Root = () => {
  return (
    <ApolloClient>
      <AppProvider>
        <EcSubscriptionsProvider>
          <MainScreen />
          <LocationListener />
        </EcSubscriptionsProvider>
      </AppProvider>
    </ApolloClient>
  )
}

const AppRoutes = () => {
  return (
    <Suspense fallback="">
      <Routes>
        <Route path="/*" element={ <Root /> } />
        <Route path="/login" element={ <Login /> } />
        <Route path="/logout" element={ <Logout /> } />
      </Routes>
    </Suspense>
  )
}

function App () {
  // const stud = useSelector((state) => state.prof.studentInfo)
  useEffect(() => {
    if (isAndroid) {
      window.location.replace('mytgumobilev1://app')
    } else if (isIOS) {
      window.location.replace('mytgumobilev1://')
    }
  }, [])

  return !isIOS && !isAndroid ? (
    <Provider store={ store }>
      <PersistGate loading={ null } persistor={ persistor }>
        <Router>
          <AppRoutes />
        </Router>
      </PersistGate>
    </Provider>
  ) : (
    <SmallDevicesPage />
  )
}

export default App
