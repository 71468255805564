import React, { useState, useEffect, } from 'react'
import useGetWorkspaceIconsList from '@/legacy/hooks/useGetWorkspaceIconsList/useGetWorkspaceIconsList'
import './WorkspaceIconsList.less'
import useInternetConnectionStatus from '@/legacy/hooks/useInternetConnectionStatus/useInternetConnectionStatus'
import useActivateInternetSessionByCurrentUser
  from '@/legacy/hooks/useActivateInternetSessionByCurrentUser/useActivateInternetSessionByCurrentUser'
import useDeactivateInternetSessionMutation
  from '@/legacy/hooks/useDeactivateInternetSessionMutation/useDeactivateInternetSessionMutation'
import InternetBarToggle
  from '@/features/InternetBar/ui/InternetBarToggle/InternetBarToggle'
import wifi from '../../legacy/assets/NavIcons/wifi.svg'

const WorkspaceIconsList: React.FC = () => {
  const { error, workspaceIcons, } = useGetWorkspaceIconsList()
  const [
    iconImages,
    setIconImages,
  ] = useState<{ [key: string]: string }>({})

  const { loading, internetConnectionStatus, refetch, } = useInternetConnectionStatus()

  const { activateSession: activateInternetSession, } = useActivateInternetSessionByCurrentUser()
  const { deactivateSession: deactivateInternetSession, } = useDeactivateInternetSessionMutation()

  useEffect(() => {
    // Динамический импорт всех иконок и сохранение их в стейте
    Promise.all(
      workspaceIcons?.map(icon => import(`../../legacy/assets/workspaceIcons/${icon.iconImage}`)
        .then(image => ({ [icon.iconImage]: image.default, }))
      ) || []
    ).then(images => {
      setIconImages(Object.assign({}, ...images))
    })
  }, [
    workspaceIcons,
  ])

  if (error) {
    return <div>Error...</div>
  }
  const handleIconClick = (iconLink: string | undefined) => {
    if (iconLink) {
      window.open(iconLink, '_blank')
    }
  }

  return (
    <div className="workspaceIconsList">
      <h1 className="d-h1">Рабочий стол</h1>
      {
        !loading && internetConnectionStatus?.intranetStatus !== false && (
          <div style={ { backgroundColor: 'white',
            display: 'flex',
            gap: 50,
            width: 460,
            alignItems: 'center',
            borderRadius: 15,
            marginBottom: 40, } }>
            <img className="p-20" src={ wifi } alt={ '' } />
            {
              internetConnectionStatus?.code === 200
                ? <p>Подключен к интернету</p>
                : <p>Подключиться к интернету</p>
            }
            <InternetBarToggle
              code={internetConnectionStatus?.code}
              intranetStatus={internetConnectionStatus?.intranetStatus}
              useActivateInternetSessionByCurrentUser={activateInternetSession}
              useDeactivateInternetSessionMutation={deactivateInternetSession}
              refetchData={refetch}
            />
          </div>
        )
      }
      <ul className="cards">
        {workspaceIcons?.map(icon => (
          <li className= "cards__item" key={ icon.iconID }>
            <article className="card">
              <img className="card__image" src={ iconImages[icon.iconImage] } alt={ icon.iconName } />
              <h4 className="card__title">{ icon.iconName }</h4>
              <button className="btn" onClick={ () => handleIconClick(icon.iconLink) }>Перейти</button>
            </article>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default WorkspaceIconsList
