/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMemo, } from 'react'
import ButtonSimple from '../../../../common/ButtonSimple/ButtonSimple'
import { useGetTrackActiveStats, useUpdateTracksActive, } from '../../../../../gql/hooks/useMyEducation'
import { useAppContext, } from '@/App/providers/AppProvider'


const TrackSignAllow = () => {
  const { data, refetch, } = useGetTrackActiveStats()

  const isAllActive = useMemo(() => {
    if (data) {
      const { total, active, } = data.esTrackState

      return total === active
    } return true
  }, [
    data,
  ])

  const [
    esTrackActivate,
  ] = useUpdateTracksActive(!isAllActive)

  const { openToast, } = useAppContext()

  const handleActiveStatus = () => {
    esTrackActivate()
      .then(() => {
        refetch()
        openToast?.({ message: isAllActive ? 'Запись закрыта' : 'Запись открыта', })
      })
      .catch(e => console.log(e))
  }


  return <ButtonSimple value={ isAllActive ? 'Закрыть запись' : 'Открыть запись' }
    variant="fill" onClickHandler={ handleActiveStatus } />
}

export default TrackSignAllow
