import React, { useEffect, } from 'react'
import Preloader from '../common/PreLoader/Preloader'
import { EndPoints, } from '../../lib/endpoints'

const Login = () => {
  useEffect(() => {
    window.location.replace(EndPoints.auth)
  }, [])
  return <Preloader />
}

export default Login
