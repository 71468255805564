/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import styles from './Table.module.less'
import { useEffect, useState, } from 'react'
import format from 'date-fns/format'
import './ShortSchedule.less'
import ru from 'date-fns/locale/ru'
import { months, weekdays, } from '../../../Schedule/Table'
import clsx from 'clsx'


const Table = ({
  props,
  date,
}: any) => {
  const [
    month,
    setMonth,
  ] = useState('')
  const [
    weekday,
    setWeekday,
  ] = useState('')
  const chislitel = props?.[0]?.chislitel


  useEffect(() => {
    months.find(i => i.en === format(date, 'MMMM') && setMonth(i.ru))
    weekdays.find(i => i.en === format(date, 'EEEE') && setWeekday(i.ru))
  }, [
    date,
  ])

  return (
    <div className="Table d-scrolloff">
      <h1 className={ clsx('Date d-h1 p-20', styles.dateDescription) }>
        <span>
          { date && weekday }, { date && format(date, 'd') } { date && format(date, 'MMMM', { locale: ru, }) }
        </span>

        <span>
          { (chislitel !== null && chislitel !== undefined) && (chislitel ? 'Неделя: Числитель' : 'Неделя: Знаменатель') }
        </span>
      </h1>

      <table>
        <tbody>
          <tr className="info Bold">
            <td className="date Bold" style={ {
              fontWeight: '700',
              fontSize: '16px',
            } }>Пара</td>
            <td className="time Bold" style={ {
              fontWeight: '700',
              fontSize: '16px',
            } }>Время</td>
            <td className="lesson Bold" style={ {
              fontWeight: '700',
              fontSize: '16px',
            } }>Предмет</td>
            <td className="coach Bold" style={ {
              fontWeight: '700',
              fontSize: '16px',
            } }>Преподаватель</td>
            <td className="type Bold" style={ {
              fontWeight: '700',
              fontSize: '16px',
            } }>Тип</td>
            <td className="audince Bold" style={ {
              fontWeight: '700',
              fontSize: '16px',
            } }>Аудитория</td>
          </tr>

          { props?.length > 0 ? props.map((i: any, index: any) => <tr key={ index } className="row">
            <td className="date">{ i.couple ? i.couple : 'Не указано' }</td>
            <td className="time">{ i.start ? i.start : 'Не указано' }-{ i.end ? i.end : 'Не указано' }</td>
            <td className="lesson">{ i.discipline ? i.discipline : 'Не указано' }</td>
            <td className="coach">{ i.teacher ? i.teacher : 'Не указано' }</td>
            <td className="type">{ i.type ? i.type : 'Не указано' }</td>
            <td className="audince">{ i.room ? i.room : 'Не указано' }</td>
          </tr>
          ) : (
            <tr className="None">
              <td>
                <h2 className="p-20">На сегодня пары не указаны</h2>
              </td>
            </tr>
          ) }
        </tbody>
      </table>
    </div>
  )
}


export default Table
