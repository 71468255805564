import { useMemo, } from 'react'
import HistoryLinks from '../common/HistoryLinks/HistoryLinks'
import './RecordBook.less'
import RecordCard from './RecordCard/RecordCard'
import { useRecordbook, } from '../../gql/hooks/useRecordbook'
import RecordBookInfo from './RecordBookInfo/RecordBookInfo'
import { useSelector, useDispatch, } from 'react-redux'
import ProgrammResults from './RecordBookInfo/PrigrammResults/PrigrammResults'
import { setCurrentBook, } from '../../redux/RecordBookSlices/RecordBookSlice'
import Preloader from '../common/PreLoader/Preloader'
import NoContracts from '../Finances/PaymentPage/NoContracts/NoContracts'

function RecordBook () {
  const dispatch = useDispatch()
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const currentCard = useSelector(state => state.rec.currentBook)
  const { data, error, loading, } = useRecordbook()


  const recordCards = useMemo(() => {
    if (data) {
      dispatch(setCurrentBook(data.recordbooks[0]))
      return data.recordbooks.map((i: any) => {
        return <RecordCard key={ i.recordbook_number } data={ i } />
      })
    } else if (error) {
      return <div>Error ocured</div>
    }
    return <Preloader />


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    error,
    loading,
  ])

  const info = useMemo(() => {
    if (currentCard !== null) {
      return <RecordBookInfo recordCard={ currentCard } />
    } else if (data) {
      return <RecordBookInfo recordCard={ data.recordbooks[0] } />
    }
  }, [
    data,
    currentCard,
  ])

  const programmResults = useMemo(() => {
    if (currentCard !== null) {
      return <ProgrammResults data={ currentCard } />
    } else if (data) {
      return <ProgrammResults data={ data.recordbooks[0] } />
    }
  }, [
    data,
    currentCard,
  ])


  return (
    <div className="recordBook">
      <HistoryLinks links={ [
        {
          value: 'Главная',
          path: '/',
        },
      ] } loc="Зачетная книжка" />
      <h2 className="profileTitle">Зачетная книжка</h2>
      { (data?.recordbooks.length) > 0 ? <>
        <div className="recCardsWrap">
          { recordCards }
        </div>
        { info }
        { programmResults }
      </> : (loading ? <Preloader /> : <NoContracts title="У вас нет активных зачетных книжек" />) }
    </div>
  )
}

export default RecordBook
