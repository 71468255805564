import dayjs from 'dayjs'
import { months, } from './Table'

export const SHORT_WEEKDAYS = [
  {
    ru: 'пн',
    upd: 'Пн',
    en: 'Mo',
  },
  {
    ru: 'вт',
    upd: 'Вт',
    en: 'Tu',
  },
  {
    ru: 'ср',
    upd: 'Ср',
    en: 'We',
  },
  {
    ru: 'чт',
    upd: 'Чт',
    en: 'Th',
  },
  {
    ru: 'пт',
    upd: 'Пт',
    en: 'Fr',
  },
  {
    ru: 'сб',
    upd: 'Сб',
    en: 'Sa',
  },
  {
    ru: 'вс',
    upd: 'Вс',
    en: 'Su',
  },
]

export function changeWeekDays (date: any) {
  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  const result = SHORT_WEEKDAYS.find(i => i.ru === dayjs(date).format('dd')).upd
  return result
}

export function changeFullDate (date: any) {
  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  const month = months.find(i => i.check === dayjs(date).format('MMMM')).ru
  const year = dayjs(date).format('YYYY')

  return `${ month } ${ year } г.`
}

export function translateDate (date: any) {
  alert(dayjs(date).format('MMMM'))
  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  const month = months.find(i => i.en === dayjs(date).format('MMMM')).ru
  const year = dayjs(date).format('YYYY')

  return `${ month } ${ year } г.`
}

export function translateWeekDate (date: any) {
  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  const result = SHORT_WEEKDAYS.find(i => i.en === dayjs(date).format('dd')).upd
  return result
}
