import lecture from '../../../../assets/images/lecture.svg'
import clsx from 'clsx'

function LecturePopup ({
  isOpen,
  close,
}: any) {
  return (
    <div className={ clsx('K-LecturePop-Close', isOpen && 'K-LecturePop') }>
      <div className="LectureContainer">
        <button className="close" onClick={ close }>x</button>
        <h2>Системный подход к анализу финансового состояния предприятия</h2>
        <p><span>Описание: </span>Изучаем принципы данного подхода</p>
        <div className="download">
          <img src={ lecture } alt="download" />
          <div className="dowWrap">
            <p className="dz">дз#2.docx</p>
            <p className="weight">444 КБ</p>
          </div>
        </div>
        <p><span>Домашнее задание:</span> Ответить на поставленные вопросы, решить тест и т.д.</p>
        <div className="download">
          <img src={ lecture } alt="download" />
          <div className="dowWrap">
            <p className="dz">дз#2.docx</p>
            <p className="weight">444 КБ</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LecturePopup
