/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./NewProject.less";
import { format } from "date-fns";
import {
  useApplyProject,
  useProjectActivityCreate,
  useCreateProject,
  useDeleteApply,
} from "../../../gql/hooks/useProjects";
import { useAppContext } from "@/App/providers/AppProvider";
import clsx from "clsx";
import HistoryLinks from "../../common/HistoryLinks/HistoryLinks";
import { IconArrowLeft } from "@tabler/icons";
import { handleRole } from "../../../Utils/functions";
import useRedirect from "@/legacy/hooks/useRedirect";
import NewProjectForm from "@/legacy/components/Projects/NewProject/NewProjectForm/NewProjectForm";
import useGetRole from "@/legacy/redux/hooks/common/useGetRole";
import useUploadS3Test from "@/legacy/hooks/useUploadS3Test/useUploadS3Test";
import useFileUploadConfirmation from "@/legacy/hooks/useUploadS3Test/useFileConfirmation";
import {
  RootState,
  FormData,
} from "@/legacy/components/Projects/NewProject/types/types";
import { useFormFields } from "@/legacy/components/Projects/NewProject/hooks/useFormFields";
import {
  setFixProject,
  setProjectId,
} from "@/legacy/redux/ProjectsSlice/ProjectSlice";
import { setModerPage, setTeacherTab } from "@/legacy/redux/utilsSlice";

function Projects() {
  const redirect = useRedirect();
  const editProject = useSelector((state: RootState) => state.proj.fixProject);
  const status = useSelector((state: RootState) => state.proj.editStatus);
  const id = useSelector((state: RootState) => state.proj.projectId);
  const dispatch = useDispatch();
  const role = useGetRole();
  const { openToast } = useAppContext();
  const { handleFileUpload } = useUploadS3Test();
  const { confirmFileUpload } = useFileUploadConfirmation();

  const [paInitiate] = useProjectActivityCreate();
  const [paDeleteInitiative] = useDeleteApply();
  const [applyProject] = useApplyProject();
  const [paControl] = useCreateProject();

  const { addNotification } = useAppContext();

  const initialFormState: FormData = {
    projectName: editProject ? editProject.name : "",
    calendar: false,
    rangeDate: editProject?.dateStart
      ? [editProject?.dateStart, editProject?.dateEnd]
      : null,
    projectType: editProject ? editProject.projectType : "",
    customerType: editProject ? editProject.customerType : "",
    customer: editProject ? editProject.customer : "",
    foreignCustomer: editProject ? editProject.foreignCustomer : "",
    customerName: editProject ? editProject.customerName : "",
    supervisor: editProject ? editProject.supervisor : "",
    consultant:
      editProject && (editProject?.name ? editProject.consultant : []),
    thesis: editProject ? editProject.description : "",
    participants: editProject ? editProject.participants : "",
    requirments: editProject ? editProject.requirments : "",
    criteria: editProject ? editProject.criteria : "",
    reportForm: editProject ? editProject.reportForm : "",
    additional: editProject ? editProject.additional : "",
    nameSpec:
      editProject && (editProject?.namespec ? editProject?.namespec : []),
    nameProf:
      editProject && (editProject?.nameProf ? editProject?.nameProf : []),
    courses:
      editProject && (editProject?.courses ? editProject?.courses : null),
    semester: editProject ? editProject.semester : "",
    mobile: editProject && (editProject?.mobile ? editProject?.mobile : ""),
    customerPhone:
      editProject &&
      (editProject?.customerPhone ? editProject?.customerPhone : ""),
    email: editProject && (editProject?.email ? editProject?.email : ""),
    workType: editProject?.workType ? editProject?.workType : null,
    specValue: "",
    profValue: "",
    personsValue: "",
    consultValue: "",
    file: editProject?.file ? editProject?.file : null,
    fileId: editProject?.fileId ? editProject?.fileId : null,
    checkboxValue: editProject?.checkboxValue
      ? editProject?.checkboxValue
      : null,
    uploadCompleted: false,
  };

  const { formFields, setField, setFields } = useFormFields(initialFormState);

  const [calendar, setCalendar] = useState(false);
  const [rangeDate, setRangeDate] = useState(
    editProject?.dateStart
      ? [editProject?.dateStart, editProject?.dateEnd]
      : null
  );
  const [currentField, setCurrentField] = useState<string | null>(null);

  const [file, setFile] = useState<File | null>(
    editProject?.file ? editProject?.file : null
  );

  const [fileId, setFileId] = useState(
    editProject?.fileId ? editProject?.fileId : null
  );

  const [checkboxValue, setCheckboxValue] = useState(
    editProject?.checkboxValue ? editProject?.checkboxValue : null
  );

  const [uploadCompleted, setUploadCompleted] = useState(false);

  const [disabled, setDisabled] = useState(true);
  console.log("nameSpec", formFields.nameSpec);
  const values = useMemo(
    () => [
      formFields.projectName,
      formFields.projectType,
      formFields.customerType,
      formFields.customer || formFields.foreignCustomer,
      formFields.thesis,
      formFields.participants,
      formFields.requirments,
      formFields.reportForm,
      formFields.additional,
      formFields.supervisor,
      formFields.nameSpec,
      formFields.nameProf,
      rangeDate,
      formFields.semester,
      formFields.courses,
      checkboxValue,
    ],
    [
      formFields.projectName,
      formFields.projectType,
      formFields.customerType,
      formFields.customer,
      formFields.foreignCustomer,
      formFields.thesis,
      formFields.participants,
      formFields.requirments,
      formFields.reportForm,
      formFields.nameSpec,
      formFields.nameProf,
      formFields.additional,
      formFields.supervisor,
      rangeDate,
      formFields.semester,
      formFields.courses,
      checkboxValue,
    ]
  );

  useEffect(() => {
    const isCustomerExternal =
      formFields.customerType === "Внешний заказчик" &&
      formFields.projectType === "Прикладные проекты";

    const areAllValuesDefined = values.every((i) => {
      if (Array.isArray(i)) {
        return i.length > 0 && i.every((j) => j !== undefined && j !== null);
      }
      return i !== undefined && i !== null;
    });

    setDisabled(
      isCustomerExternal
        ? !areAllValuesDefined || fileId === undefined || fileId === null
        : !areAllValuesDefined
    );
  }, [values, file, formFields.customerType, formFields.projectType, fileId]);

  useEffect(() => {
    if (file !== null && !uploadCompleted) {
      const inputFile = {
        fileCategory: "projectactivity",
        fileName: file?.name,
        fileType: file?.type,
      };

      handleFileUpload(file, inputFile).then((newFileId) => {
        if (newFileId) {
          confirmFileUpload(newFileId);
          setFileId(newFileId);
          setUploadCompleted(true);
        } else {
          console.log("FileId was not provided");
        }
      });
    }
  }, [confirmFileUpload, file, handleFileUpload, uploadCompleted]);

  function onModerSubmit(e: any) {
    e.preventDefault();
    const params = {
      event: "newInitiate",
      payload: {
        userType: handleRole(role),
        name: "ebrtbrtb",
        description: "ebrtbrtb",
        activity: "ebrtbrtb",
      },
    };
    paInitiate({ variables: { params } })
      .then((res) => {
        return res;
      })
      .then((res) => {
        const params = {
          event: "paApplied",
          payload: {
            req_id: res.data.paInitiate.req_id,
            name: formFields.projectName,
            projectType: formFields.projectType,
            customerType: formFields.customerType,
            customer: formFields.customer,
            foreignCustomer: formFields.foreignCustomer,
            customerName: formFields.customerName,
            supervisor: formFields.supervisor,
            consultant: formFields.consultant,
            description: formFields.thesis,
            dateStart: rangeDate
              ? format(new Date(rangeDate[0]), "yyyy-MM-dd")
              : "",
            dateEnd: rangeDate
              ? format(new Date(rangeDate[1]), "yyyy-MM-dd")
              : "",
            participants: formFields.participants,
            requirments: formFields.requirments,
            namespec: formFields.nameSpec,
            nameProf: formFields.nameProf,
            criteria: formFields.criteria,
            reportForm: formFields.reportForm,
            additional: formFields.additional,
            results: [],
            responses: [],
            workType: formFields.workType,
            courses: formFields.courses,
            mobile: formFields.mobile,
            customerPhone: formFields.customerPhone,
            email: formFields.email,
            semester: formFields.semester,
            fileId,
            checkboxValue,
          },
        };
        applyProject({ variables: { params } })
          .then((res) => {
            return res;
          })
          .then((data) =>
            paDeleteInitiative({
              variables: {
                req_id: [data.data.paApply.req_id],
              },
            }).catch((e) => console.log(e))
          )
          .then((res) => openToast?.({ message: "Заявка отправленна" }))
          .catch((e) => {
            console.log("onCreate error", e);
          });
      })
      .then(() => redirect("/moderate-applications"))
      .catch((e) => {
        console.log("onCreate error", e);
      });
  }

  function onProjectEdit(e: any) {
    e.preventDefault();
    const params = {
      event: "change",
      payload: {
        req_id: id,
        args: {
          status: status === "ready" ? "ready" : "wait",
          data: {
            name: formFields.projectName,
            projectType: formFields.projectType,
            customerType: formFields.customerType,
            customer: formFields.customer,
            foreignCustomer: formFields.foreignCustomer,
            customerName: formFields.customerName,
            supervisor: formFields.supervisor,
            consultant: formFields.consultant,
            description: formFields.thesis,
            dateStart: rangeDate
              ? format(new Date(rangeDate[0]), "yyyy-MM-dd")
              : editProject?.dateStart,
            dateEnd: rangeDate
              ? format(new Date(rangeDate[1]), "yyyy-MM-dd")
              : editProject?.dateEnd,
            participants: formFields.participants,
            requirments: formFields.requirments,
            namespec: formFields.nameSpec,
            nameProf: formFields.nameProf,
            criteria: formFields.criteria,
            reportForm: formFields.reportForm,
            additional: formFields.additional,
            results: editProject?.results,
            responses: editProject.responses,
            workType: formFields.workType,
            courses: formFields.courses,
            mobile: formFields.mobile,
            customerPhone: formFields.customerPhone,
            email: formFields.email,
            semester: formFields.semester,
            fileId,
            checkboxValue,
          },
        },
        action: "update",
      },
    };
    paControl({ variables: { params } })
      .then(() =>
        addNotification?.(
          "Проектная деятельность",
          [
            "303d607e-e08e-11eb-bc1f-005056aa8995",
            "a92f5442-313c-11e6-80bc-7824af85d229",
          ],
          { message: `Изменения в заявке, ID: ${id}` },
          {
            path: "/moderate-applications",
            from: "Проектная деятельность",
          }
        )
      )
      .then((res) => {
        dispatch(setFixProject({}));
        handleBack();
      })
      .then((res) => {
        dispatch(setProjectId(null));
        console.log("Проект отредактирован", res);
      })
      .catch((e) => {
        console.log("onCreate error", e);
      });
  }

  function handleBack() {
    if (role === "pa_moder") {
      redirect("/moderate-applications");
      dispatch(setModerPage("Проектные заявки"));
    } else {
      redirect("/my-applications");
      dispatch(setTeacherTab("Проектные заявки"));
    }
    dispatch(setFixProject({}));
  }

  const formProps = {
    editProject,
    setFile,
    setCurrentField,
    currentField,
    rangeDate,
    setCalendar,
    calendar,
    role,
    onProjectEdit,
    onModerSubmit,
    checkboxValue,
    setCheckboxValue,
    setRangeDate,
    formFields,
    setField,
    setFields,
    disabled,
  };

  return (
    <div className="InnerPage applyProject">
      <HistoryLinks
        links={[
          {
            value: "Главная",
            path: "/",
          },
          {
            value: "Учебный процесс",
            path: "/projects-all",
          },
          {
            value: "Проектная деятельность",
            path: "/projects-all",
          },
          {
            value: "Мои заявки",
            path: "/my-applications",
          },
        ]}
        loc="Новая заявка на проект"
      />
      <div className="studentInner">
        <button onClick={handleBack} className={"getRefBut"}>
          <IconArrowLeft
            style={{ margin: "0 16px 0 0" }}
            size={19}
            color={"#7671DD"}
          />
          Вернуться к заявкам
        </button>
        <h2 className="profileTitle">Проектная заявка</h2>
        <NewProjectForm {...formProps} />
      </div>
      <div
        className={clsx("hiddenLayer", calendar && "hiddenLayer_vis")}
        onClick={() => setCalendar(!calendar)}
      />
    </div>
  );
}

export default Projects;
