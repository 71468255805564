import { legacy_effectiveContractModerGroupe, } from '@/legacy/components/EffectiveContract/constants/effectiveContractModersAndAdmins-constants'
import { format, } from 'date-fns'
import { useEffect, useState, } from 'react'
import { useSelector, } from 'react-redux'
import { useLocation, } from 'react-router-dom'
import { useCreateEcData, useUpdateEcData, } from '../../../../../gql/hooks/effectiveContractHooks/useEffectiveContract'
import { useAppContext, } from '@/App/providers/AppProvider'
import AttachedFiles from '../../../../common/AttachedFiles/AttachedFiles'
import ButtonSimple from '../../../../common/ButtonSimple/ButtonSimple'
import CalendarSimple from '../../../../common/CalendarSimple/CalendarSimple'
import { TitleH5, } from '../../../../common/GeneralElements/GeneralElements'
import InputFile from '../../../../common/InputFile/InputFile'
import InputSelectDeprecated from '../../../../common/InputSelectDeprecated/InputSelectDeprecated'
import InputTextDeprecated from '../../../../common/InputTextDeprecated/InputTextDeprecated'
import { defineUserGroupe, } from '../../../defineUserGroupe'
import { table_cat_1_2, } from '../../../popUpInputsCatalogs'


const Inputs_1_2 = ({
  onClickHandler,
  hint,
  defaultValues,
}: any) => {
  const { addNotification, }: any = useAppContext()


  const { search, } = useLocation()
  const moderateId = new URLSearchParams(search).get('id')
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const currentRole = useSelector(state => state.auth.choosenRole)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const currentUserType = useSelector(state => state.auth.userType)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const currentUserId = useSelector(state => state.auth.me.data.guid)

  const [
    user_ids,
  ] = useState(
    (
      defineUserGroupe(currentRole, legacy_effectiveContractModerGroupe)
      || defineUserGroupe(currentUserType, legacy_effectiveContractModerGroupe)
    )
      ? moderateId
      : currentUserId)


  const [
    onAddNewEcData,
  ] = useCreateEcData()
  const onAddNewEcDataHandler = (params: any) => {
    onAddNewEcData(params)
      .then(() => {
        addNotification(
          'Эффективный контракт',
          [
            moderateId,
          ],
          { message: 'Ваше достижение добавлено!', },
          {
            path: '/effective-contract',
            from: 'Эффективный контракт',
          }
        )
      })
  }

  const [
    onUpdateRow,
  ] = useUpdateEcData()
  const onUpdateRowHandler = (id: any, newData: any) => onUpdateRow({
    variables: {
      rec_id: id,
      new_data: newData,
    },
  })


  const [
    kind_of_education,
    setKind_of_education,
  ] = useState('')
  const [
    start,
    setStart,
  ] = useState('')
  const [
    end,
    setEnd,
  ] = useState('')
  const [
    type_of_document,
    setType_of_document,
  ] = useState('')
  const [
    doc_series,
    setDoc_series,
  ] = useState('')
  const [
    doc_number,
    setDoc_number,
  ] = useState('')
  const [
    course,
    setCourse,
  ] = useState('')
  const [
    course_of_category,
    setCourse_of_category,
  ] = useState('')
  const [
    universityName,
    setUuniversityName,
  ] = useState('Тамбовский государственный университет имени Г.Р. Державина')
  const [
    date_of_issue,
    setDate_of_issue,
  ] = useState('')
  const [
    attachedFile,
    setAttachedFile,
  ] = useState([])

  useEffect(() => {
    if (hint === 'edit') {
      setKind_of_education(defaultValues.kind_of_education)
      setStart(defaultValues.start)
      setEnd(defaultValues.end)
      setType_of_document(defaultValues.type_of_document)
      setDoc_series(defaultValues.doc_series)
      setDoc_number(defaultValues.doc_number)
      setCourse(defaultValues.course)
      setCourse_of_category(defaultValues.course_of_category)
      setUuniversityName(defaultValues.universityName)
      setDate_of_issue(format(new Date(defaultValues.date_of_issue), 'yyyy-MM-dd'))
      // @ts-expect-error TS(2345): Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
      setAttachedFile(defaultValues.attachedFile ? [
        defaultValues.attachedFile,
      ] : [])
    }
  }, [
    hint,
    defaultValues,
  ])


  const fieldsToDefault = () => {
    setKind_of_education('')
    setStart('')
    setEnd('')
    setType_of_document('')
    setDoc_series('')
    setDoc_number('')
    setCourse('')
    setCourse_of_category('')
    setUuniversityName('')
    setDate_of_issue('')
    setAttachedFile([])
  }


  return <>
    {/* <InputText
      defaultHint="Вид курса"
      value={ kind_of_education }
      onChangeValue={ setKind_of_education }
    />

    <TitleH5>
      Дата начала курса
    </TitleH5>

    <CalendarSimple
      selectRange={ false }
      withBorder
      styles={ { height: '4.56rem' } }
      defaultSingleDate={
        defaultValues.start
          ? format(new Date(defaultValues.start), 'dd.MM.yyyy')
          : start
            ? format(new Date(start), 'dd.MM.yyyy')
            : false }
      returnSingle={ date => setStart(
        format(new Date(date), 'yyyy-MM-dd')) }
    />

    <TitleH5>
      Дата окончаня курса
    </TitleH5>

    <CalendarSimple
      selectRange={ false }
      withBorder
      styles={ { height: '4.56rem' } }
      defaultSingleDate={
        defaultValues.end
          ? format(new Date(defaultValues.end), 'dd.MM.yyyy')
          : end
            ? format(new Date(end), 'dd.MM.yyyy')
            : false }
      returnSingle={ date => setEnd(
        format(new Date(date), 'yyyy-MM-dd')) }
    />

    <InputText
      defaultHint="Тип документа"
      value={ type_of_document }
      onChangeValue={ setType_of_document }
    />

    <InputText
      defaultHint="Серия документа"
      value={ doc_series }
      onChangeValue={ setDoc_series }
    />

    <InputText
      defaultHint="Номер документа"
      value={ doc_number }
      onChangeValue={ setDoc_number }
    /> */}

    <InputTextDeprecated
      defaultHint="Название курса"
      value={ course }
      onChangeValue={ setCourse }
    />

    <InputSelectDeprecated
      defaultHint="Категория курсов"
      items={ table_cat_1_2.course_of_category }
      defaultValue={ course_of_category }
      withResetValue={ true }
      callBack={ setCourse_of_category }
    />

    {/* <InputTextDeprecated
      defaultHint="Название учебного заведения"
      value={ universityName }
      onChangeValue={ setUuniversityName }
    /> */}

    <TitleH5>
      Дата прохождения обучения
    </TitleH5>
    <CalendarSimple
      selectRange={ false }
      withBorder
      styles={ { height: '4.56rem', } }
      defaultSingleDate={
        defaultValues.date_of_issue
          ? format(new Date(defaultValues.date_of_issue), 'dd.MM.yyyy')
          : date_of_issue
            ? format(new Date(date_of_issue), 'dd.MM.yyyy')
            : false }
      returnSingle={ (date: any) => setDate_of_issue(
        format(new Date(date), 'yyyy-MM-dd')) }
      calendarPositionStatic
      clickableAreaPositioAbsolute
    />

    {/* <TitleH5>
      Ссылка на прикрепленный документ
    </TitleH5>

    { attachedFile.length < 1 ? (
      <InputFile
        onChange={ event => {
          // TODO: добавить uploadFile
          // uploadFile(...event.target.files, setAttachedFile)
        } }
      />
    ) : false }

    <AttachedFiles
      isDeleteFilesAllowed={ true }
      fileList={ attachedFile }
      getIndexFile={ (index: any) => {
        setAttachedFile(attachedFile.filter((file, fIndex) => fIndex !== index))
      } }
    /> */}

    <br />

    <ButtonSimple
      isDisabled={ (
        // kind_of_education.trim().length < 1
        // || start.trim().length < 1
        // || end.trim().length < 1
        // || type_of_document.trim().length < 1
        // || doc_series.trim().length < 1
        // || doc_number.trim().length < 1
        course.trim().length < 1
        || course_of_category.trim().length < 1
        || universityName.trim().length < 1
        || date_of_issue.length < 1
        || attachedFile.length < 1
      ) }
      value={ hint === 'edit' ? 'Сохранить изменения' : 'Добавить достижение' }
      variant="full-filled"
      onClickHandler={ () => {
        if (hint === 'edit') {
          onUpdateRowHandler(defaultValues.rec_id, {
            kind_of_education: kind_of_education,
            start: start ? format(new Date(start), 'yyyy-MM-dd') : null,
            end: end ? format(new Date(end), 'yyyy-MM-dd') : null,
            type_of_document: type_of_document,
            doc_series: doc_series,
            doc_number: doc_number,
            course: course,
            course_of_category: course_of_category,
            // universityName: universityName,
            date_of_issue: date_of_issue ? format(new Date(date_of_issue), 'yyyy-MM-dd') : null,
            // attachedFile: attachedFile[0],
            status: 'pending',
            reason: '',
            scores: null,
          })

          fieldsToDefault()
          onClickHandler()
        } else {
          onAddNewEcDataHandler({
            variables: {
              user_ids: [
                user_ids,
              ],
              dataType: 'table_1_2',
              data: {
                kind_of_education: kind_of_education,
                start: start ? format(new Date(start), 'yyyy-MM-dd') : null,
                end: end ? format(new Date(end), 'yyyy-MM-dd') : null,
                type_of_document: type_of_document,
                doc_series: doc_series,
                doc_number: doc_number,
                course: course,
                course_of_category: course_of_category,
                // universityName: universityName,
                date_of_issue: date_of_issue ? format(new Date(date_of_issue), 'yyyy-MM-dd') : null,
                // attachedFile: attachedFile[0],
                status: 'pending',
                reason: '',
                scores: null,
                isAddedByModer: defineUserGroupe(currentRole, legacy_effectiveContractModerGroupe),
              },
            },
          })

          fieldsToDefault()
          onClickHandler()
        };
      } }
    />
  </>
}


export default Inputs_1_2
