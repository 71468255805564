import React from 'react'
import './ActivityHistory.less'
import { NewPageWrapper, } from '@/legacy/components/common/GeneralElements/GeneralElements'
import HistoryLinks from '@/legacy/components/common/HistoryLinks/HistoryLinks'


const ActivityHistory = () => {
  return <NewPageWrapper className="projects_introduction">
    <HistoryLinks
      links={ [
        {
          value: 'Главная',
          path: '/',
        },
      ] }
      loc="История активности"
    />
    <h1 className="page-main-title">История активности пользователей</h1>
  </NewPageWrapper>
}

export default ActivityHistory
