/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import { useState, } from 'react'
import { IconCircleCheck, IconEye, IconEyeOff, } from '@tabler/icons'
import clsx from 'clsx'
import DeletePopup from '../../../../../../common/DeletePopup/DeletePopup'
import TooltipTgu from '@/shared/ui/TooltipTgu/TooltipTgu'
import AnimatedTrash from '../../../../../../common/AnimatedTrash/AnimatedTrash'
import {
  useDeleteTrack,
  useUpdateTrackActiveOne,
  useUpdateTrackVisibility,
} from '../../../../../../../gql/hooks/useMyEducation'
import { useUpdateStats, } from '../../../../../../../gql/hooks/useMyEducation'
import { IconLockOpen, IconLock, } from '@tabler/icons'
import { TooltipPlacementVariant, } from '@/shared/ui/TooltipTgu/types'
import { useAppContext, } from '@/App/providers/AppProvider'
import './TreckCard.less'

const TreckCard = ({
  type,
  id,
  is_signed,
  is_visible,
  handleNavigate,
  name,
  styles,
  active = false,
  role = false,
  nameSpec,
  is_active,
}: any) => {
  const [
    isDeletePopup,
    setIsDeletePopup,
  ] = useState(false)

  const [
    esUpdateDisciplineStats,
  ] = useUpdateStats({
    ref_id: +id,
    namespec: nameSpec?.namespec,
    type: 'miner',
    counter: 'view',
  })

  const [
    esTrackDelete,
  ] = useDeleteTrack([
    id,
  ])

  const { openToast, } = useAppContext()

  const handleDeleteTrack = (e: any) => {
    e.stopPropagation()
    esTrackDelete()
      .then(res => openToast?.({ message: 'Трек удален', }))
      .catch(e => console.log(e))
  }

  const handleRedirect = (e: any) => {
    if (role === 'student' || role === 'student-rakus') {
      handleNavigate(e)
      esUpdateDisciplineStats()
        .then(res => console.log('success'))
        .catch(e => console.log(e))
    } else {
      handleNavigate(e)
    }
  }

  const [
    esTrackActivate,
  ] = useUpdateTrackActiveOne()

  const handleActivateOne = (e: any, track_id: any, is_active: any) => {
    e.stopPropagation()
    esTrackActivate({
      variables: {
        is_active,
        track_id,
      },
    })
  }

  const [
    esUpdateVisibility,
  ] = useUpdateTrackVisibility()

  const allowParticipate = (e: any, track_id: number, is_visible:any) => {
    e.stopPropagation()
    esUpdateVisibility({
      variables: {
        is_visible,
        track_id,
      },
    })
      .then((res:any) => {
        openToast?.({
          message: `Факультатив ${ is_visible ? 'отображен' : 'скрыт'
          } для студентов`,
        })
      })
      .catch((error:any) => {
        console.error('Error updating visibility:', error)
      })
    esTrackActivate({
      variables: {
        is_active: false,
        track_id,
      },
    })
  }
  if ((role === 'student' || role === 'student-rakus') && (is_visible === false && !is_signed)) {
    return null
  }

  return (
    <>
      <div
        className={ clsx({
          'card-discipline-pathway': true,
          'card-discipline-pathway_active': is_signed,
          'unvisible-card': !is_visible,
        }) }
        style={ styles }
        onClick={ e => handleRedirect(e) }
      >
        <div className="card-discipline-pathway__title-wrapper">
          <div
            className="card-discipline-pathway__name"
            style={ { width: '100%', } }
          >
            <div
              style={ {
                display: 'flex',
                justifyContent: 'space-between',
              } }
            >
              { name }
              <div className="moderStatusesWrapElective">
                { is_signed ? (
                  <TooltipTgu children={
                    <IconCircleCheck
                      style={ {
                        color: '#7671DD',
                        fontSize: '1.5rem',
                        marginLeft: '.5rem',
                      } }
                    />
                  }
                  placement={ TooltipPlacementVariant.bottom }
                  title="Вы записаны" />

                ) : (
                  false
                ) }
                { role === 'osrv-moder' ? (
                  is_visible ? (
                    <TooltipTgu children={
                      <IconEyeOff
                        onClick={ e => allowParticipate(e, +id, !is_visible) }
                        color="#7F8190"
                      />
                    }
                    placement={ TooltipPlacementVariant.bottom }
                    title="Cкрыть дисциплину от студентов" />
                  ) : (
                    <TooltipTgu children={
                      <IconEye
                        color="#7671dd"
                        onClick={ e => allowParticipate(e, +id, !is_visible) }
                      />
                    }
                    placement={ TooltipPlacementVariant.bottom }
                    title='Отображать дисциплину студентам' />
                  )
                ) : (
                  false
                ) }
                { role === 'osrv-moder' ? (
                  is_active ? (
                    <TooltipTgu
                      children={
                        <IconLockOpen
                          size={ 27 }
                          color="#7671dd"
                          onClick={ e => handleActivateOne(e, +id, !is_active) }
                        />
                      }
                      title="Закрыть запись"
                      placement={ TooltipPlacementVariant.bottom } />
                  ) : (
                    <TooltipTgu
                      children={
                        <IconLock
                          color="#7F8190"
                          onClick={ e => handleActivateOne(e, +id, !is_active) }
                          size={ 27 }
                        />
                      }
                      title="Открыть запись"
                      placement={ TooltipPlacementVariant.bottom } />
                  )
                ) : (
                  false
                ) }
              </div>
            </div>
          </div>
        </div>

        <div className="card-discipline-pathway__type">
          { type }
          { role === 'osrv-moder' && (
            <AnimatedTrash
              clickHandler={ (e: any) => handleDeleteTrack(e) }
              styles={ {
                width: '15px',
                height: '20px',
              } }
            />
          ) }
        </div>

        {/* <div className='card-discipline-pathway__student-count-wrapper'>
    { studentCount ? (
      <div className='card-discipline-pathway__student-count'>
        Студентов { studentCount[0] } / { studentCount[1] }
      </div>
    ) : false }

    { studentCount ? (
      <CustomizedProgressBars
        value={ studentCount[0] / studentCount[1] * 100 }
      />
    ) : false }
  </div> */}
      </div>
      <DeletePopup
        title={ `Удалить ${ type } ${ name }?` }
        /* onClickHandler={(e) => handleDeleteDiscipline(e)} */
        isDeletePopup={ isDeletePopup }
        setIsDeletePopup={ setIsDeletePopup }
      />
    </>
  )
}

export default TreckCard
