import './AdCard.less'
import { useDispatch, } from 'react-redux'
import useRedirect from '@/legacy/hooks/useRedirect'
import { setCurrentAnnounce, } from '@/legacy/redux/NewsSlice/NewsSlices'


function AdCard ({
  i,
}: any) {
  const dispatch = useDispatch()
  const redirect = useRedirect()


  const goToInner = () => {
    dispatch(setCurrentAnnounce(i))
    if (i.description.startsWith('<')) {
      document.location.href = i.urls.linkUrl
    } else {
      redirect('/announce-inner')
    }
  }
  return <div className="AdCard" onClick={ goToInner }>
    <div className="anounceHashtag">Объявления</div>
    <p className="AnouncesContent">{ i.title }</p>
  </div>
}

export default AdCard
