import './CardLibrary.less'
import book from '../../../assets/images/book.png'
import clsx from 'clsx'


const CardLibrary = ({
  onClickHandler,
  footer,
  isDetail,
  styles,
}: any) => {
  return <div className={ clsx(!isDetail && 'library-card_hover', 'library-card') }
    onClick={ onClickHandler } style={ styles }>
    <img className="book-cover" src={ book } alt="book cover" />
    <div className="library-card__book-content">
      <h3 className="library-card__title">Новая поведенческая экономика</h3>
      <p className="library-card__author">М. В. Холодкова, О. А. Дронова, Т. А. Дьякова, Ж. И. Жеребцова</p>
      <p className="library-card__description">Новая поведенческая экономика — научно-популярная книга американского экономиста Ричарда
        Талера, профессора Чикагской школы бизнеса, лауреата Нобелевской премии в области экономики 2017 года.</p>
      { footer }
    </div>
  </div>
}

export default CardLibrary
