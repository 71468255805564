import { useState, useMemo, useRef, } from 'react'
import './InfoProject.less'
import TooltipTgu from '@/shared/ui/TooltipTgu/TooltipTgu'
import ResultsProject from './components/ResultsProject/ResultsProject'
import { useStagesList, } from '../../../../gql/hooks/useProjects'
import ResultsBlockForSupevisor from '@/legacy/components/Projects/ProjectPage/InfoProject/components/ResultsBlockForSupevisor/ResultsBlockForSupevisor'
import useGetUserInfo from '@/legacy/hooks/useGetUserInfo'
import ResultsBlockForStudent from '@/legacy/components/Projects/ProjectPage/InfoProject/components/ResultsBlockForStudent/ResultsBlockForStudent'
import { onAddStudentResultCreatePayload, } from '@/legacy/gql/hooks/projects/useUpdateProject'
import useGetRole from '@/legacy/redux/hooks/common/useGetRole'
import { UserRoles, } from '@/legacy/constants'
import { CreatePayloadParam, } from '@/legacy/gql/hooks/projects/useUpdateProjectTypes'
import { TooltipPlacementVariant, } from '@/shared/ui/TooltipTgu/types'


type Responses = {
  id: string,
  approved: string,
}

const isSupervisorRole = (userId: string, projectSupervisorId: string) => userId === projectSupervisorId
const isResponderRole = (userId: string, projectRespondedIdList: string[]) => {
  return !!projectRespondedIdList.find(id => id === userId)
}


function InfoProject ({
  project,
}: { project: CreatePayloadParam }) {
  const role = useGetRole()
  const { userGuid, } = useGetUserInfo()

  const { name, description, } = project.data
  const [
    isDown,
    setIsDown,
  ] = useState(false)
  const [
    startX,
    setStartX,
  ] = useState(null)
  const [
    scrollLeft,
    setScrollLeft,
  ] = useState(0)

  const steps = useRef(null)


  const handleMouseDown = (e: any) => {
    setIsDown(true)
    // @ts-expect-error TS(2345): Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
    setStartX(e.pageX - steps.current.offsetLeft)
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    setScrollLeft(steps.current.scrollLeft)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleMouseMove = (e: any) => {
    if (!isDown) {
      return
    }

    e.preventDefault()
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    const x = e.pageX - steps.current.offsetLeft
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    const walk = (x - startX)
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    steps.current.scrollLeft = scrollLeft - walk
  }

  const params = {
    req_id: project.req_id,
  }
  const { data, error, loading, } = useStagesList(params)


  const stages = useMemo(() => {
    if (error) {
      return <div>.....error....</div>
    } else if (loading) {
      return <div>....loading...</div>
    }
    return <ul className="steps d-scrolloff" ref={ steps } onMouseDown={ e => handleMouseDown(e) }
      onMouseLeave={ () => setIsDown(false) } onMouseUp={ () => setIsDown(false) }
      onMouseMove={ e => handleMouseMove(e) }>{
        data.paStages.map((i: any) => <TooltipTgu
          children={
            <li key={ i.stage_num }>
              <div>{ `Этап ${ i.stage_num }` }</div>
            </li>
          }
          title={i.data.title}
          placement={TooltipPlacementVariant.bottom}/>
        )
      }
    </ul>
  }, [
    data,
    error,
    loading,
    steps,
    handleMouseMove,
  ])

  const studentResultInit = {
    'files': [],
    'status': 'pending',
  }

  return (
    <div className="infoProject">
      <>
        <h2>График жизни проекта</h2>
        { stages }
        <div className="projectCard">
          <h3>Проект</h3>
          <h2>{ name }</h2>
          <p>{ description }</p>
        </div>

        { (isSupervisorRole(userGuid, project.data.supervisor.id) || role === UserRoles.PA_MODER) && (
          <>
            <ResultsBlockForSupevisor
              responsesList={ project.data.responses.filter((student: Responses) => student.approved !== 'rejected') }
              projectId={ project.req_id }
              projectPayloadData={ onAddStudentResultCreatePayload(project) }
            />
            <br />
          </>
        ) }

        { project.data.responses.find(responder => responder.approved === 'approved' && responder.id === userGuid) && (
          <>
            <ResultsBlockForStudent
              projectId={ project.req_id }
              userGuid={ userGuid }
              studentResults={ (project.data.responses.find(student => student.id === userGuid && student.studentResults === undefined && student.approved === 'approved'))
                ? studentResultInit
                : project.data.responses.find((student: Responses) => student.id === userGuid && student.approved !== 'rejected')?.studentResults }
              projectPayloadData={ onAddStudentResultCreatePayload(project) }
            />
            <br />
          </>
        ) }


        <ResultsProject
          project={ project }
          isSupervisorMode={ isSupervisorRole(userGuid, project.data.supervisor.id) }
        />
      </>
    </div>
  )
}

export default InfoProject
