import { legacy_effectiveContractModerGroupe, } from '@/legacy/components/EffectiveContract/constants/effectiveContractModersAndAdmins-constants'
import { useEffect, useState, } from 'react'
import { useSelector, } from 'react-redux'
import { useLocation, } from 'react-router-dom'
import { useCreateEcData, useUpdateEcData, } from '../../../../../gql/hooks/effectiveContractHooks/useEffectiveContract'
import { useAppContext, } from '@/App/providers/AppProvider'
import AttachedFiles from '../../../../common/AttachedFiles/AttachedFiles'
import ButtonSimple from '../../../../common/ButtonSimple/ButtonSimple'
import { TitleH5, } from '../../../../common/GeneralElements/GeneralElements'
import InputFile from '../../../../common/InputFile/InputFile'
import InputSelectDeprecated from '../../../../common/InputSelectDeprecated/InputSelectDeprecated'
import InputTextDeprecated from '../../../../common/InputTextDeprecated/InputTextDeprecated'
import { defineUserGroupe, } from '../../../defineUserGroupe'
import { table_cat_2_2, } from '../../../popUpInputsCatalogs'


const Inputs_2_2 = ({
  onClickHandler,
  hint,
  defaultValues,
}: any) => {
  const { addNotification, }: any = useAppContext()


  const { search, } = useLocation()
  const moderateId = new URLSearchParams(search).get('id')
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const currentRole = useSelector(state => state.auth.choosenRole)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const currentUserType = useSelector(state => state.auth.userType)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const currentUserId = useSelector(state => state.auth.me.data.guid)

  const [
    user_ids,
  ] = useState(
    (
      defineUserGroupe(currentRole, legacy_effectiveContractModerGroupe)
      || defineUserGroupe(currentUserType, legacy_effectiveContractModerGroupe)
    )
      ? moderateId
      : currentUserId)


  const [
    onAddNewEcData,
  ] = useCreateEcData()
  const onAddNewEcDataHandler = (params: any) => {
    onAddNewEcData(params)
      .then(() => {
        addNotification(
          'Эффективный контракт',
          (
            (
              defineUserGroupe(currentRole, legacy_effectiveContractModerGroupe)
              || defineUserGroupe(currentUserType, legacy_effectiveContractModerGroupe)
            )
              ? moderateId
              : currentUserId),
          { message: 'Ваше достижение добавлено!', },
          {
            path: '/effective-contract',
            from: 'Эффективный контракт',
          }
        )
      })
  }

  const [
    onUpdateRow,
  ] = useUpdateEcData()
  const onUpdateRowHandler = (id: any, newData: any) => onUpdateRow({
    variables: {
      rec_id: id,
      new_data: newData,
    },
  })


  const [
    otherLanguage,
    setOtherLanguage,
  ] = useState(false)

  const [
    language,
    setLanguage,
  ] = useState('')
  const [
    level,
    setLevel,
  ] = useState('')
  const [
    sertificate,
    setSertificate,
  ] = useState('')
  const [
    documentLink,
    setDocumentLink,
  ] = useState([])


  useEffect(() => {
    if (hint === 'edit') {
      setLanguage(defaultValues.language)
      setLevel(defaultValues.level)
      setSertificate(defaultValues.sertificate)
      // @ts-expect-error TS(2345): Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
      setDocumentLink(defaultValues.documentLink ? [
        defaultValues.documentLink,
      ] : [])
    }
  }, [
    hint,
    defaultValues,
  ])


  const fieldsToDefault = () => {
    setLanguage('')
    setLevel('')
    setSertificate('')
    setDocumentLink([])
  }


  return (
    <>
      <InputSelectDeprecated
        defaultHint="Иностранный язык"
        items={ table_cat_2_2.language }
        defaultValue={ otherLanguage ? 'иной' : language }
        withResetValue={ true }
        callBack={ (answer: any) => {
          if (answer === 'иной') {
            setLanguage('')
            setOtherLanguage(true)
          } else {
            setOtherLanguage(false)
            setLanguage(answer)
          }
        } }
      />

      { otherLanguage ? (
        <InputTextDeprecated
          defaultHint="Иностранный язык (свой вариант)"
          value={ language }
          onChangeValue={ setLanguage }
        />
      ) : false }

      <InputSelectDeprecated
        defaultHint="Уровень владения"
        items={ table_cat_2_2.level }
        defaultValue={ level }
        withResetValue={ true }
        callBack={ setLevel }
      />

      <InputTextDeprecated
        defaultHint="Указание международного сертификата"
        value={ sertificate }
        onChangeValue={ setSertificate }
      />

      <TitleH5>
        Ссылка на подгруженный документ
      </TitleH5>

      { documentLink.length < 1 ? (
        <InputFile
          onChange={ event => {
            // TODO: сделать uploadFile
            // uploadFile(...event.target.files, setDocumentLink)
          } }
        />
      ) : false }

      <AttachedFiles
        isDeleteFilesAllowed={ true }
        fileList={ documentLink }
        getIndexFile={ (index: any) => {
          setDocumentLink(documentLink.filter((file, fIndex) => fIndex !== index))
        } }
      />

      <br />

      <ButtonSimple
        isDisabled={ (
          language.trim().length < 1
          || level.trim().length < 1
          || sertificate.trim().length < 1
          || documentLink.length < 1
        ) }
        value={ hint === 'edit' ? 'Сохранить изменения' : 'Добавить достижение' }
        variant="full-filled"
        onClickHandler={ () => {
          if (hint === 'edit') {
            onUpdateRowHandler(defaultValues.rec_id, {
              language: language,
              level: level,
              sertificate: sertificate,
              documentLink: documentLink[0],
              status: 'pending',
              reason: '',
              scores: null,
            })

            fieldsToDefault()
            onClickHandler()
          } else {
            onAddNewEcDataHandler({
              variables: {
                user_ids: [
                  user_ids,
                ],
                dataType: 'table_2_2',
                data: {
                  language: language,
                  level: level,
                  sertificate: sertificate,
                  documentLink: documentLink[0],
                  status: 'pending',
                  reason: '',
                  scores: null,
                  isAddedByModer: defineUserGroupe(currentRole, legacy_effectiveContractModerGroupe),
                },
              },
            })

            fieldsToDefault()
            onClickHandler()
          };
        } }
      />
    </>
  )
}


export default Inputs_2_2
