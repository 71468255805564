import { EndPoints, } from '../../../lib/endpoints'

function Image ({
  id,
  style,
  className,
}: any) {
  return <img
    className={ className }
    style={ style }
    src={ `${ EndPoints.userpic }/${ id }` }
    alt="avatar"
  />
}

export default Image
