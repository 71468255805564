/* eslint-disable indent */
import { useEffect, useState, useMemo, } from 'react'
import { useQuery, } from '@apollo/client'
// import Select from './Select';
import './ShortSchedule.less'
import Table from './Table'
import {
  GET_TIMETABLE /* , GETUSERGROUPS_QUERY */,
} from '../../../../gql/hooks/useTimetable'
import { IconChevronUp, IconChevronDown, } from '@tabler/icons'
import Calendar from 'react-calendar'
import {
  IconCalendarMinus,
  IconChevronLeft,
  IconChevronRight,
} from '@tabler/icons'
import { format, } from 'date-fns'
import Preloader from '../../../common/PreLoader/Preloader'
import addDays from 'date-fns/addDays'

import ru from 'date-fns/locale/ru'

const ShortSchedule = ({ studInfo, }: any) => {
  const [
    date,
    setDate,
  ] = useState(new Date(Date.now()))
  const [
    isCalendar,
    setIsCalendar,
  ] = useState(false)
  // const [groups, setGroups] = useState(null);
  // const [group, setGroup] = useState(null);
  const [
    isOpen,
    setIsOpen,
  ] = useState(false)

  const [
    group,
    setGroup,
  ] = useState(studInfo[0]?.group)
  const [
    spec,
    setSpec,
  ] = useState(studInfo[0]?.namespec)

  const { data, error, loading, } = useQuery(GET_TIMETABLE, {
    variables: {
      dateStart: format(new Date(date), 'yyyy-MM-dd'),
      dateEnd: format(new Date(date), 'yyyy-MM-dd'),
      groups: group,
    },
  })

  const table = useMemo(() => {
    if (error) {
      return <div>error</div>
    } else if (loading) {
      return <Preloader />
    } else if (data) {
      return <Table props={ data.studentTimetable } date={ date } />
    }
  }, [
    data,
    error,
    loading,
    date,
  ])

  function updateGroup (i: any) {
    setGroup(i.group)
    setSpec(i.namespec)
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    setGroup(studInfo[0]?.group)
    setSpec(studInfo[0]?.namespec)
  }, [
    studInfo,
  ])

  const handleNextDay = () => {
    setDate(addDays(date, 1))
  }

  const handlePreviosDay = () => {
    setDate(addDays(date, -1))
  }

  return (
    <div className="ShortSchedule d-scrolloff">
      <div className="Select d-row">
        <h1 className="Select__title d-h1">Расписание на сегодня</h1>

        <div className="Select__controls-wrapper">
          <div className="mainCalendarWrap">
            <div className="scheduleNextPrevios" onClick={ handlePreviosDay }>
              <IconChevronLeft /> Предыдущий день
            </div>

            <div className="dateWrap">
              <div
                className="chooseField inp"
                onClick={ () => setIsCalendar(!isCalendar) }
              >
                <input
                  className="zachem"
                  type="text"
                  placeholder="выберите дату"
                  onClick={ () => setIsCalendar(!isCalendar) }
                  disabled
                  value={
                    format(date, 'dd MMMM yyyy', { locale: ru, }).slice(0, 3) +
                    format(date, 'dd MMMM yyyy', {
                      locale: ru,
                    })[3].toUpperCase() +
                    format(date, 'dd MMMM yyyy', { locale: ru, }).slice(4)
                  }
                />
              </div>

              <IconCalendarMinus
                color="#7F8191"
                onClick={ () => setIsCalendar(!isCalendar) }
              />

              <Calendar
                formatMonthYear={ (locale: any, date: any) => format(date, 'dd MMMM yyyy', { locale: ru, }).slice(0, 3) +
                  format(date, 'dd MMMM yyyy', {
                    locale: ru,
                  })[3].toUpperCase() +
                  format(date, 'dd MMMM yyyy', { locale: ru, }).slice(4)
                }
                locale={ 'Ru' }
                className={
                  isCalendar ? 'ScheduleCalendar ' : 'ScheduleCalendar off'
                }
                //@ts-ignore
                onChange={ setDate }
              />
            </div>

            <div className="scheduleNextPrevios" onClick={ handleNextDay }>
              Следующий день <IconChevronRight />
            </div>
          </div>

          <div
            className="chooseFieldY d-cursor"
            onClick={ () => setIsOpen(!isOpen) }
          >
            { isOpen && (
              <div className="ScheChoose">
                { studInfo.map((i: any) => (
                  <span
                    className="OptionChoose"
                    key={ i }
                    onClick={ () => updateGroup(i) }
                  >
                    { i.namespec }
                  </span>
                )) }
              </div>
            ) }
            <span className="zachem">{ spec ? spec : 'Выберите группу' }</span>
            { !isOpen ? (
              <IconChevronDown size={ 25 } />
            ) : (
              <IconChevronUp size={ 25 } />
            ) }
          </div>
        </div>
      </div>

      <br />

      { table }
    </div>
  )
}

export default ShortSchedule
