import { NewPageWrapper, TitleH2, } from '../common/GeneralElements/GeneralElements'
import HistoryLinks from '../common/HistoryLinks/HistoryLinks'
import TabsBox from './TabsBox/TabsBox'


const historyLinks = [
  {
    value: 'Главная',
    path: '/',
  },
]


const ModerateNotifications = () => {
  return (
    <NewPageWrapper>
      <HistoryLinks links={ historyLinks } loc="Добавить уведомление" />
      <br />
      <TitleH2>
        Добавление уведомлений
      </TitleH2>

      <TabsBox />
    </NewPageWrapper>
  )
}


export default ModerateNotifications
