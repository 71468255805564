import { legacy_effectiveContractModerGroupe, } from '@/legacy/components/EffectiveContract/constants/effectiveContractModersAndAdmins-constants'
import { useEffect, useState, } from 'react'
import { useSelector, } from 'react-redux'
import { useLocation, } from 'react-router-dom'
import { useGet_2_8_categories, useGet_2_8_roles, } from '../../../../../gql/hooks/effectiveContractHooks/useEcCatalogs'
import { useCreateEcData, useUpdateEcData, } from '../../../../../gql/hooks/effectiveContractHooks/useEffectiveContract'
import { useAppContext, } from '@/App/providers/AppProvider'
import AttachedFiles from '../../../../common/AttachedFiles/AttachedFiles'
import ButtonSimple from '../../../../common/ButtonSimple/ButtonSimple'
import { TitleH5, } from '../../../../common/GeneralElements/GeneralElements'
import InputFile from '../../../../common/InputFile/InputFile'
import InputSelectDeprecated from '../../../../common/InputSelectDeprecated/InputSelectDeprecated'
import InputTextDeprecated from '../../../../common/InputTextDeprecated/InputTextDeprecated'
import { defineUserGroupe, } from '../../../defineUserGroupe'

const Inputs_2_8 = ({
  onClickHandler,
  hint,
  defaultValues,
}: any) => {
  const { addNotification, }: any = useAppContext()


  const { search, } = useLocation()
  const moderateId = new URLSearchParams(search).get('id')
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const currentRole = useSelector(state => state.auth.choosenRole)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const currentUserType = useSelector(state => state.auth.userType)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const currentUserId = useSelector(state => state.auth.me.data.guid)

  const [
    user_ids,
  ] = useState(
    (
      defineUserGroupe(currentRole, legacy_effectiveContractModerGroupe)
      || defineUserGroupe(currentUserType, legacy_effectiveContractModerGroupe)
    )
      ? moderateId
      : currentUserId
  )


  const [
    onAddNewEcData,
  ] = useCreateEcData()
  const onAddNewEcDataHandler = (params: any) => {
    onAddNewEcData(params)
      .then(() => {
        addNotification(
          'Эффективный контракт',
          (
            (
              defineUserGroupe(currentRole, legacy_effectiveContractModerGroupe)
              || defineUserGroupe(currentUserType, legacy_effectiveContractModerGroupe)
            )
              ? moderateId
              : currentUserId
          ),
          { message: 'Ваше достижение добавлено!', },
          {
            path: '/effective-contract',
            from: 'Эффективный контракт',
          }
        )
      })
  }

  const [
    onUpdateRow,
  ] = useUpdateEcData()
  const onUpdateRowHandler = (id: any, newData: any) => onUpdateRow({
    variables: {
      rec_id: id,
      new_data: newData,
    },
  })


  const [
    theme,
    setTheme,
  ] = useState('')
  const [
    category,
    setCategory,
  ] = useState('')
  const [
    is_leader,
    setIs_leader,
  ] = useState(false)
  const [
    is_executor,
    setIs_executor,
  ] = useState(false)
  const [
    document,
    setDocument,
  ] = useState([])


  useEffect(() => {
    if (hint === 'edit') {
      setTheme(defaultValues.theme)
      setCategory(defaultValues.category)
      setIs_leader(defaultValues.is_leader)
      setIs_executor(defaultValues.is_executor)
      // @ts-expect-error TS(2345): Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
      setDocument(defaultValues.document ? [
        defaultValues.document,
      ] : [])
    }
  }, [
    hint,
    defaultValues,
  ])


  const fieldsToDefault = () => {
    setTheme('')
    setCategory('')
    setIs_leader(false)
    setIs_executor(false)
    setDocument([])
  }


  const [
    categories_2_8,
    setCategories_2_8,
  ] = useState([])
  const [
    roles_2_8,
    setRoles_2_8,
  ] = useState([])
  const get_2_8_categoriesQuery = useGet_2_8_categories()
  const get_2_8_rolesQuery = useGet_2_8_roles()

  useEffect(() => {
    if (get_2_8_categoriesQuery?.data) {
      setCategories_2_8(get_2_8_categoriesQuery.data.catalogs.ecTable_2_8_Categories)
    }

    if (get_2_8_rolesQuery?.data) {
      setRoles_2_8(get_2_8_rolesQuery.data.catalogs.ecTable2_8_Roles)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    get_2_8_categoriesQuery.loading,
    get_2_8_rolesQuery.loading,
  ])


  return <>
    <InputTextDeprecated
      defaultHint="Тема научного гранта или проекта"
      value={ theme }
      onChangeValue={ setTheme }
    />

    <InputSelectDeprecated
      defaultHint="Сумма финансовой поддержки"
      items={ categories_2_8 }
      defaultValue={ category }
      withResetValue={ true }
      callBack={ setCategory }
    />

    <InputSelectDeprecated
      defaultHint="Роль"
      // @ts-expect-error TS(2339): Property 'value' does not exist on type 'never'.
      items={ roles_2_8.map(role => role.value) }
      defaultValue={
        (is_leader)
          ? 'Руководитель'
          : (is_executor)
            ? 'Участник'
            : ''
      }
      withResetValue={ true }
      callBack={ (answer: any) => {
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        const role = roles_2_8.find(item => item.value === answer).role

        if (role === 'is_leader') {
          setIs_leader(true)
          setIs_executor(false)
        }

        if (role === 'is_executor') {
          setIs_leader(false)
          setIs_executor(true)
        }
      } }
    />

    <br />

    <TitleH5>
      Документ подтверждающий участие в гранте
    </TitleH5>

    { document.length < 1 ? (
      <InputFile
        onChange={ event => {
          // TODO: сделать uploadFile
          // uploadFile(...event.target.files, setDocument)
        } }
      />
    ) : false }

    <AttachedFiles
      isDeleteFilesAllowed={ true }
      fileList={ document }
      getIndexFile={ (index: any) => {
        setDocument(document.filter((file, fIndex) => fIndex !== index))
      } }
    />

    <br />

    <ButtonSimple
      isDisabled={ (
        theme.trim().length < 1
        || category.trim().length < 1
        || (is_leader === false && is_executor === false)
        || document.length < 1
      ) }
      value={ hint === 'edit' ? 'Сохранить изменения' : 'Добавить достижение' }
      variant="full-filled"
      onClickHandler={ () => {
        if (hint === 'edit') {
          onUpdateRowHandler(defaultValues.rec_id, {
            theme: theme,
            category: category,
            is_leader: is_leader,
            is_executor: is_executor,
            document: document[0],
            status: 'pending',
            reason: '',
            scores: null,
          })

          fieldsToDefault()
          onClickHandler()
        } else {
          onAddNewEcDataHandler({
            variables: {
              user_ids: [
                user_ids,
              ],
              dataType: 'table_2_8',
              data: {
                theme: theme,
                category: category,
                is_leader: is_leader,
                is_executor: is_executor,
                document: document[0],
                status: 'pending',
                reason: '',
                scores: null,
                isAddedByModer: defineUserGroupe(currentRole, legacy_effectiveContractModerGroupe),
              },
            },
          })
          fieldsToDefault()
          onClickHandler()
        };
      } }
    />
  </>
}


export default Inputs_2_8
