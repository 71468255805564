import { Fragment, } from 'react'
import useAchievementTypeChanged from '../../../gql/hooks/portfolio/useAchievementTypeChanged'
import { useGetAchievementTypesList, } from '../../../gql/hooks/portfolio/useAchievementTypes'
import useCreateAchievementType from '../../../gql/hooks/portfolio/useCreateAchievementType'
import useDeleteAchievementType from '../../../gql/hooks/portfolio/useDeleteAchievementType'
import useRenameAchievementType from '../../../gql/hooks/portfolio/useRenameAchievementType'
import useUpdatePublishAchievementType from '../../../gql/hooks/portfolio/useUpdatePublishAchievementType'
import { PORTFOLIO_EDIT_INDICATOR, PORTFOLIO_TEMPLATES, } from '../../../Utils/routes'
import BackToButton from '../../common/BackToButton/BackToButton'
import { NewPageWrapper, TitleH2, } from '../../common/GeneralElements/GeneralElements'
import useUrlSearch from '../../../hooks/useUrlSearch'
import HistoryLinks from '../../common/HistoryLinks/HistoryLinks'
import CreateNewItemBlock from '../PortfolioTemplates/TemplateItem/CreateNewItemBlock/CreateNewItemBlock'
import TemplateItem from '../PortfolioTemplates/TemplateItem/TemplateItem'
import styles from './PortfolioIndicators.module.less'
import useRedirect from '@/legacy/hooks/useRedirect'


const PortfolioIndicators = () => {
  const searchCatid = useUrlSearch('catid')
  const achievementsTypes = useGetAchievementTypesList({
    cat_id: Number(searchCatid),
    is_admin: true,
  })
  const onCreateNewItem = useCreateAchievementType()
  const onDeleteItem = useDeleteAchievementType()
  const onRenameItem = useRenameAchievementType()
  const onChangeStatus = useUpdatePublishAchievementType()
  useAchievementTypeChanged(achievementsTypes.refetch)
  const redirect = useRedirect()

  return (
    <NewPageWrapper>
      <HistoryLinks links={ [
        {
          value: 'Главная',
          path: '/',
        },
        {
          value: 'Шаблоны показателей',
          path: PORTFOLIO_TEMPLATES,
        },
      ] } loc="Оцениваемые показатели" />
      <br />
      <BackToButton
        className={ styles.backButton }
        title={ 'Вернуться к шаблонам показателей' }
        onClick={ () => redirect(PORTFOLIO_TEMPLATES) }
      />
      <br />
      <TitleH2>
        Оцениваемые показатели
      </TitleH2>

      <div>
        { achievementsTypes.achievementTypesList.map((type: any) => <Fragment key={ type.key }>
          <TemplateItem
            available={ type.status === 'available' }
            onChangeStatus={ (status: any) => onChangeStatus({
              ...type,
              direction_key: Number(searchCatid),
              newStatus: status ? 'available' : 'notavailable',
            }) }
            onRenameItem={ (newName: any) => onRenameItem({
              ...type,
              direction_key: Number(searchCatid),
              newName,
            }) }
            onDeleteItem={ () => onDeleteItem({
              key: Number(type.key),
              direction_key: Number(searchCatid),
            }) }
            linkTo={ `${ PORTFOLIO_EDIT_INDICATOR }?catid=${ type?.catid }&key=${ type?.key }` }
            { ...type }
          />
          <br />
        </Fragment>) }
      </div>

      <br />
      <CreateNewItemBlock onCreateNewItem={ (name: any) => onCreateNewItem({
        direction_key: Number(searchCatid),
        name,
      }) } />
    </NewPageWrapper>
  )
}


export default PortfolioIndicators
