import CardAchievement from '@/legacy/components/EffectiveContract/cards/CardAchievement/CardAchievement'
import { EcTableName, } from '@/legacy/components/EffectiveContract/constants'
import { tableSource as tableSource_1_1, } from '@/legacy/components/EffectiveContract/constants/tablesFormData/table_1_1'
import { tableSource as tableSource_1_11, } from '@/legacy/components/EffectiveContract/constants/tablesFormData/table_1_11'
import { tableSource as tableSource_1_12, } from '@/legacy/components/EffectiveContract/constants/tablesFormData/table_1_12'
import { tableSource as tableSource_1_2, } from '@/legacy/components/EffectiveContract/constants/tablesFormData/table_1_2'
import { tableSource as tableSource_1_3, } from '@/legacy/components/EffectiveContract/constants/tablesFormData/table_1_3'
import { tableSource as tableSource_1_4, } from '@/legacy/components/EffectiveContract/constants/tablesFormData/table_1_4'
import { tableSource as tableSource_1_41, } from '@/legacy/components/EffectiveContract/constants/tablesFormData/table_1_41'
import { tableSource as tableSource_1_5, } from '@/legacy/components/EffectiveContract/constants/tablesFormData/table_1_5'
import { tableSource as tableSource_2_1, } from '@/legacy/components/EffectiveContract/constants/tablesFormData/table_2_1'
import { tableSource as tableSource_2_2, } from '@/legacy/components/EffectiveContract/constants/tablesFormData/table_2_2'
import { tableSource as tableSource_2_3, } from '@/legacy/components/EffectiveContract/constants/tablesFormData/table_2_3'
import { tableSource as tableSource_2_4, } from '@/legacy/components/EffectiveContract/constants/tablesFormData/table_2_4'
import { tableSource as tableSource_2_41, } from '@/legacy/components/EffectiveContract/constants/tablesFormData/table_2_41'
import { tableSource as tableSource_2_5, } from '@/legacy/components/EffectiveContract/constants/tablesFormData/table_2_5'
import { tableSource as tableSource_2_6, } from '@/legacy/components/EffectiveContract/constants/tablesFormData/table_2_6'
import { tableSource as tableSource_2_7, } from '@/legacy/components/EffectiveContract/constants/tablesFormData/table_2_7'
import { tableSource as tableSource_2_8, } from '@/legacy/components/EffectiveContract/constants/tablesFormData/table_2_8'
import { tableSource as tableSource_2_9, } from '@/legacy/components/EffectiveContract/constants/tablesFormData/table_2_9'
import { tableSource as tableSource_2_10, } from '@/legacy/components/EffectiveContract/constants/tablesFormData/table_2_10'
import { tableSource as tableSource_3_1, } from '@/legacy/components/EffectiveContract/constants/tablesFormData/table_3_1'
import { tableSource as tableSource_3_2, } from '@/legacy/components/EffectiveContract/constants/tablesFormData/table_3_2'
import { tableSource as tableSource_3_3, } from '@/legacy/components/EffectiveContract/constants/tablesFormData/table_3_3'
import { tableSource as tableSource_3_31, } from '@/legacy/components/EffectiveContract/constants/tablesFormData/table_3_31'
import { tableSource as tableSource_3_4, } from '@/legacy/components/EffectiveContract/constants/tablesFormData/table_3_4'
import { tableSource as tableSource_4_1, } from '@/legacy/components/EffectiveContract/constants/tablesFormData/table_4_1'
import { memo, useEffect, useState, } from 'react'
import { format, } from 'date-fns'
import { table_cat_1_4, } from '@/legacy/components/EffectiveContract/popUpInputsCatalogs'
import { useSelector, } from 'react-redux'
import { defineViceHeadOfDepartments, } from '@/legacy/components/EffectiveContract/defineViceHeadOfDepartments'
import useGetUserInfo from '@/legacy/hooks/useGetUserInfo'
import AddNewAchievementCard from '@/legacy/components/EffectiveContract/cards/AddNewAchievementCard/AddNewAchievementCard'

interface CardsContainerProps {
  tableData: any;
  stage: any;
  handlers: any;
  dataType:any;
  year: string | null;
}


const CardsContainer: React.FC<CardsContainerProps> = memo(({ tableData, stage, handlers, dataType, year, }) => {
  const { userGuid, } = useGetUserInfo()
  const personPositions = useSelector(
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    state => state.auth.me.positions.filter((item: any) => item.position_name).map((item: any) => item.position_name.split(', ')))
  const [
    isHeadOfDepartment,
    setIsHeadOfDepartment,
  ] = useState(null)

  useEffect(() => {
    if (personPositions[0]) {
      for (let i = 0; i < personPositions.length; i++) {
        if (
          personPositions[i]?.some((item: any) => item === 'Заведующий кафедрой')
          || defineViceHeadOfDepartments(userGuid)
        ) {
          // @ts-expect-error TS(2345): Argument of type 'true' is not assignable to param... Remove this comment to see the full error message
          return setIsHeadOfDepartment(true)
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const tableSourceMap: any = {
    [EcTableName.TABLE_1_1]: tableSource_1_1,
    [EcTableName.TABLE_1_11]: tableSource_1_11,
    [EcTableName.TABLE_1_12]: tableSource_1_12,
    [EcTableName.TABLE_1_2]: tableSource_1_2,
    [EcTableName.TABLE_1_3]: tableSource_1_3,
    [EcTableName.TABLE_1_4]: tableSource_1_4,
    [EcTableName.TABLE_1_41]: tableSource_1_41,
    [EcTableName.TABLE_1_5]: tableSource_1_5,
    [EcTableName.TABLE_2_1]: tableSource_2_1,
    [EcTableName.TABLE_2_2]: tableSource_2_2,
    [EcTableName.TABLE_2_3]: tableSource_2_3,
    [EcTableName.TABLE_2_4]: tableSource_2_4,
    [EcTableName.TABLE_2_41]: tableSource_2_41,
    [EcTableName.TABLE_2_5]: tableSource_2_5,
    [EcTableName.TABLE_2_6]: tableSource_2_6,
    [EcTableName.TABLE_2_7]: tableSource_2_7,
    [EcTableName.TABLE_2_8]: tableSource_2_8,
    [EcTableName.TABLE_2_9]: tableSource_2_9,
    [EcTableName.TABLE_2_10]: tableSource_2_10,
    [EcTableName.TABLE_3_1]: tableSource_3_1,
    [EcTableName.TABLE_3_2]: tableSource_3_2,
    [EcTableName.TABLE_3_3]: tableSource_3_3,
    [EcTableName.TABLE_3_31]: tableSource_3_31,
    [EcTableName.TABLE_3_4]: tableSource_3_4,
    [EcTableName.TABLE_4_1]: tableSource_4_1,
  }

  const CardAchievementWithHandlers = memo((props:any) => {
    const { dataType, ...restProps } = props
    const getTitle = () => {
      switch (dataType) {
        case EcTableName.TABLE_1_11:
          return props.subject_name
        case EcTableName.TABLE_1_12:
          return (
            <>
              {props.indicator} <br />
              <hr/>
              Октябрь: {props.monthOct}%<br />
              Декабрь: {props.monthDec}%<br />
              Февраль: {props.monthFeb}%<br />
              Апрель: {props.monthApr}%<br />
              <hr/>
              Средний % выполнения: {props.percentDone}%
            </>
          )
        case EcTableName.TABLE_1_3:
          return props?.authors?.[0]?.fio || ''
        case EcTableName.TABLE_1_4:
          // eslint-disable-next-line no-case-declarations
          const articleKey = Object.keys(props).find(
            key => table_cat_1_4.articleName.find(
              articleName => articleName === key)
          )
          return articleKey ? props[articleKey] : ''
        case EcTableName.TABLE_1_5:
          return props?.authors?.[0]?.fio || ''
        case EcTableName.TABLE_2_1:
          return props.presentName
        case EcTableName.TABLE_2_2:
          return props.language
        case EcTableName.TABLE_2_3:
          return props.book_title
        case EcTableName.TABLE_2_4:
          return props.courseName
        case EcTableName.TABLE_2_5:
          return props.competitionName
        case EcTableName.TABLE_2_6:
          return props.univercityName
        case EcTableName.TABLE_2_7:
          return props.article
        case EcTableName.TABLE_2_8:
          return props.category
        case EcTableName.TABLE_2_9:
          return props.work_title
        case EcTableName.TABLE_2_10:
          return props.magazineName
        case EcTableName.TABLE_3_1:
          return props.membership
        case EcTableName.TABLE_3_2:
          return props.awardName
        case EcTableName.TABLE_3_3:
          return props.organizationName
        case EcTableName.TABLE_3_31:
          return props.organizationName
        case EcTableName.TABLE_3_4:
          return props.projectName
        case EcTableName.TABLE_4_1:
          return props.basis
        default:
          return props.title || props.book_title || props.course || props.theme
      }
    }

    const getSubtitle = () => {
      switch (dataType) {
        case EcTableName.TABLE_1_1:
          return props.disciplineName
        case EcTableName.TABLE_1_11:
          return (
            <>
              {props.education_program_number} <br />
              {/* <hr/> */}
              {props.name_specialty}<br />
              {props.name_profile}<br />
              Курс: {props.course} <br />
              Семестр: {props.semester}
            </>
          )
        case EcTableName.TABLE_1_12:

        case EcTableName.TABLE_1_2:
          return props.course_of_category
        case EcTableName.TABLE_1_3:
        case EcTableName.TABLE_1_5:
          return props.confirmingDocument[0].name || null
        case EcTableName.TABLE_1_4:
          // eslint-disable-next-line no-case-declarations
          const journalKey = Object.keys(props).find(
            key => table_cat_1_4.journalName.find(
              journalName => journalName === key)
          )
          return journalKey ? props[journalKey] : ''
        case EcTableName.TABLE_2_1:
          return props.documentName[0]?.name
        case EcTableName.TABLE_2_2:
          return props.level
        case EcTableName.TABLE_2_3:
          return props.type_work
        case EcTableName.TABLE_2_4:
          return props.courseType
        case EcTableName.TABLE_2_6:
          return props.membership
        case EcTableName.TABLE_2_10:
          return props.role
        case EcTableName.TABLE_3_1:
          return props.category
        case EcTableName.TABLE_3_4:
          return props.category
        default:
          return props.subtitle
      }
    }

    const getDescription = () => {
      switch (dataType) {
        case EcTableName.TABLE_1_1:
          return props.major
        case EcTableName.TABLE_1_2:
          return props.kind_of_education
        case EcTableName.TABLE_1_3:
        case EcTableName.TABLE_1_5:
          return props.userRole
        case EcTableName.TABLE_1_4:
          return (
            props?.quartile?.journal_quartile
            || props?.publisher
          )
        case EcTableName.TABLE_1_41:
          return props.programm
        case EcTableName.TABLE_2_1:
          return props.documentNumber
        case EcTableName.TABLE_2_2:
          return props.sertificate
        case EcTableName.TABLE_2_3:
          return props.major
        case EcTableName.TABLE_2_4:
          return props.courseLanguage
        case EcTableName.TABLE_2_5:
          return props.vector
        case EcTableName.TABLE_2_6:
          return props.format
        case EcTableName.TABLE_3_31:
          return props.indicator
        default:
          return props.description
      }
    }

    const getDate = () => {
      if (dataType === EcTableName.TABLE_1_11) {
        return null
      }
      if (dataType === EcTableName.TABLE_4_1) {
        return null
      }
      const date = props.date || props.publication_year
      return date ? format(new Date(String(date)), 'yyyy') : null
    }

    const tableSource: any = tableSourceMap[dataType]
    // console.log(props.scores)
    return (
      <>
        <CardAchievement
          stage={stage}
          values={{ ...restProps, }}
          getReasonFailure={handlers.getReasonFailure}
          getAppealStatus={handlers.getAppealStatus}
          onEditAchievement={handlers.onEditAchievement}
          onRebaseAchievement={handlers.onRebaseAchievement}
          onSupplyApeal={handlers.onSupplyApeal}
          achievementFieldsOnly={{ ...restProps, }}
          dataType={dataType}
          description={getDescription()}
          status={props.status}
          title={getTitle()}
          subtitle={getSubtitle()}
          date={getDate()}
          scores={props.scores != '0' ? props.scores : null}
          tableSource={tableSource}
          isEditable={isHeadOfDepartment}
        />
      </>
    )
  })


  const possibleDataTypes = [
    EcTableName.TABLE_2_1,
    EcTableName.TABLE_2_2,
    EcTableName.TABLE_2_41,
    EcTableName.TABLE_2_5,
    EcTableName.TABLE_2_6,
    EcTableName.TABLE_3_1,
    EcTableName.TABLE_3_2,
    EcTableName.TABLE_3_3,
    EcTableName.TABLE_3_31,
    EcTableName.TABLE_3_4,
  ]

  const renderAddNewAchievementCard = (dataType:any) => {
    const tableSource: any = tableSourceMap[dataType]
    const isAllowed =
    (dataType === EcTableName.TABLE_1_3 || dataType === EcTableName.TABLE_1_5) &&
    isHeadOfDepartment &&
    stage === 'stage-2' ||
    (possibleDataTypes.includes(dataType) &&
      year === '2024' &&
      stage === 'stage-2')
    if (isAllowed) {
      return <AddNewAchievementCard dataType={dataType} tableSource={tableSource} />
    }

    return null
  }
  return (
    <>
      {renderAddNewAchievementCard(dataType)}
      {tableData.map((card:any, index:number) => (
        <>
          <CardAchievementWithHandlers
            key={card.rec_id || index}
            dataType={dataType}
            rec_id={card.rec_id}
            {...card}
          />
        </>
      ))}
    </>
  )
})

export default CardsContainer
