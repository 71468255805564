import { Divider, } from 'antd'
import { TitleH3, TitleH4, } from '../../../common/GeneralElements/GeneralElements'
import LinkButton from '../../../common/LinkButton/LinkButton'
import PaperContainerWrapper from '../../../common/PaperContainer/PaperContainerWrapper'
// @ts-expect-error TS(2307): Cannot find module './Exports.module.css' or its c... Remove this comment to see the full error message
import styles from './Exports.module.css'
import { IconChartBar, } from '@tabler/icons'
import useLazyExport, { EDUCATION_OF_INDIVIDUALS_MUTATION, } from '../../Hooks/useLazyExport/useLazyExport'
import { useEcExportSubscription, } from '../../../../gql/hooks/effectiveContractHooks/useEcSubscription'
import { useEffect, useState, } from 'react'
import { useLazyQuery, } from '@apollo/client'
import { downloadFile, } from '../../../../hooks/useDownloadFile'
import {
  QUERY_EXPORT_TABLE_1,
  QUERY_EXPORT_TABLE_1_1,
  QUERY_EXPORT_TABLE_2_AND_3_ALL,
  QUERY_EXPORT_TABLE_2_AND_3_MONTHLY,
  QUERY_EXPORT_TABLE_2_AND_3_ONETIME,
  QUERY_EXPORT_TABLE_3_5,
} from '../../../../gql/hooks/effectiveContractHooks/exportQueries'


const Exports = () => {
  const exportSubcripton = useEcExportSubscription()

  const [
    queryExportTable_1,
  ] = useLazyQuery(QUERY_EXPORT_TABLE_1, {
    fetchPolicy: 'no-cache',
  })

  const [
    queryExportTable_1_1,
  ] = useLazyQuery(QUERY_EXPORT_TABLE_1_1, {
    fetchPolicy: 'no-cache',
  })

  const [
    queryExportTable_2_AND_3_ALL,
  ] = useLazyQuery(QUERY_EXPORT_TABLE_2_AND_3_ALL, {
    fetchPolicy: 'no-cache',
  })

  const [
    queryExportTable_2_AND_3_MONTHLY,
  ] = useLazyQuery(QUERY_EXPORT_TABLE_2_AND_3_MONTHLY, {
    fetchPolicy: 'no-cache',
  })

  const [
    queryExportTable_2_AND_3_ONETIME,
  ] = useLazyQuery(QUERY_EXPORT_TABLE_2_AND_3_ONETIME, {
    fetchPolicy: 'no-cache',
  })

  const [
    queryExportTable_3_5,
  ] = useLazyQuery(QUERY_EXPORT_TABLE_3_5, {
    fetchPolicy: 'no-cache',
  })


  const [
    isLoadingExportTable_1,
    setIsLoadingExportTable_1,
  ] = useState(false)
  const [
    isLoadingExportTable_1_1,
    setIsLoadingExportTable_1_1,
  ] = useState(false)
  const [
    isLoadingExportTable_2_AND_3_ALL,
    setIsLoadingExportTable_2_AND_3_ALL,
  ] = useState(false)
  const [
    isLoadingExportTable_2_AND_3_MONTHLY,
    setIsLoadingExportTable_2_AND_3_MONTHLY,
  ] = useState(false)
  const [
    isLoadingExportTable_2_AND_3_ONETIME,
    setIsLoadingExportTable_2_AND_3_ONETIME,
  ] = useState(false)
  const [
    isLoadingExportTable_3_5,
    setIsLoadingExportTable_3_5,
  ] = useState(false)

  const [
    errorExport_1,
    setErrorExport_1,
  ] = useState(false)
  const [
    errorExport_1_1,
    setErrorExport_1_1,
  ] = useState(false)
  const [
    errorExport_2_AND_3_ALL,
    setErrorExport_2_AND_3_ALL,
  ] = useState(false)
  const [
    errorExport_2_AND_3_MONTHLY,
    setErrorExport_2_AND_3_MONTHLY,
  ] = useState(false)
  const [
    errorExport_2_AND_3_ONETIME,
    setErrorExport_2_AND_3_ONETIME,
  ] = useState(false)
  const [
    errorExport_3_5,
    setErrorExport_3_5,
  ] = useState(false)


  // 1c:
  const [
    getData_1_2,
    getDataLoading_1_2,
    result,
  ] = useLazyExport(EDUCATION_OF_INDIVIDUALS_MUTATION)


  useEffect(() => {
    if (exportSubcripton.data) {
      const data = exportSubcripton.data.ecExportsProgress

      if (data.state === 'start' && data.type === 'baseStats') {
        setIsLoadingExportTable_1(true)
      }

      if (data.state === 'start' && data.type === '1_1') {
        setIsLoadingExportTable_1_1(true)
      }

      if (data.state === 'start' && data.type === 'effectiveStats') {
        setIsLoadingExportTable_2_AND_3_ALL(true)
        setIsLoadingExportTable_2_AND_3_MONTHLY(true)
        setIsLoadingExportTable_2_AND_3_ONETIME(true)
      }

      if (data.state === 'start' && data.type === '3_5') {
        setIsLoadingExportTable_3_5(true)
      }


      if (data.state === 'done' && data.type === 'baseStats') {
        setIsLoadingExportTable_1(false)

        if (data.result.error) {
          return setErrorExport_1(true)
        }


        return downloadFile({
          ...data.result,
          name: 'Базовые показатели',
        })
      }


      if (data.state === 'done' && data.type === '1_1') {
        setIsLoadingExportTable_1_1(false)

        if (data.result.error) {
          return setErrorExport_1_1(true)
        }


        return downloadFile({
          ...data.result,
          name: 'Учебно-методическое обеспечение образовательного процесса',
        })
      }


      if (data.state === 'done' && data.type === 'effectiveStats') {
        setIsLoadingExportTable_2_AND_3_ALL(false)
        setIsLoadingExportTable_2_AND_3_MONTHLY(false)
        setIsLoadingExportTable_2_AND_3_ONETIME(false)

        if (data.result.error) {
          return (
            // @ts-expect-error TS(1345): An expression of type 'void' cannot be tested for ... Remove this comment to see the full error message
            setErrorExport_2_AND_3_ALL(true)
            // @ts-expect-error TS(1345): An expression of type 'void' cannot be tested for ... Remove this comment to see the full error message
            && setErrorExport_2_AND_3_MONTHLY(true)
            && setErrorExport_2_AND_3_ONETIME(true)
          )
        }


        return downloadFile({
          ...data.result,
          name: 'Высокая производительность + бонусные баллы',
        })
      }


      if (data.state === 'done' && data.type === '3_5') {
        setIsLoadingExportTable_3_5(false)

        if (data.result.error) {
          return setErrorExport_3_5(true)
        }


        return downloadFile({
          ...data.result,
          name: 'Отдельные достижения',
        })
      }
    }
  }, [
    exportSubcripton.loading,
    exportSubcripton.data,
  ])


  return (
    <PaperContainerWrapper>
      <TitleH3>
        Выгрузка отдельных показателей
      </TitleH3>

      <div className={ styles.wrapper }>
        <div className={ styles.column }>
          <>
            <TitleH4>
              Выгрузки в 1С
            </TitleH4>

            <Divider />

            <LinkButton
              customType={ {
                text: 'Плановое повышение квалификации',
                icon: <IconChartBar />,
              } }
              onClickHandler={ getData_1_2 }
              isLoading={ getDataLoading_1_2 }
            />

            { result }
          </>
        </div>

        <div className={ styles.column }>
          <TitleH4>
            Выгрузки в Excel
          </TitleH4>

          <Divider />

          <LinkButton
            isError={ errorExport_1 }
            errorMessage="Попробуйте повторить запрос позже"
            customType={ {
              text: 'Базовые показатели',
              icon: <IconChartBar />,
            } }
            onClickHandler={ queryExportTable_1 }
            isLoading={ isLoadingExportTable_1 }
          />

          <br />

          <LinkButton
            isError={ errorExport_1_1 }
            errorMessage="Попробуйте повторить запрос позже"
            customType={ {
              text: 'Учебно-методическое обеспечение образовательного процесса',
              icon: <IconChartBar />,
            } }
            onClickHandler={ queryExportTable_1_1 }
            isLoading={ isLoadingExportTable_1_1 }
          />

          <br />

          <LinkButton
            isError={ errorExport_2_AND_3_ALL }
            errorMessage="Попробуйте повторить запрос позже"
            customType={ {
              text: 'Высокая производительность + бонусные баллы (все)',
              icon: <IconChartBar />,
            } }
            onClickHandler={ queryExportTable_2_AND_3_ALL }
            isLoading={ isLoadingExportTable_2_AND_3_ALL }
          />

          <br />

          <LinkButton
            isError={ errorExport_2_AND_3_MONTHLY }
            errorMessage="Попробуйте повторить запрос позже"
            customType={ {
              text: 'Высокая производительность + бонусные баллы (ежемесячный)',
              icon: <IconChartBar />,
            } }
            onClickHandler={ queryExportTable_2_AND_3_MONTHLY }
            isLoading={ isLoadingExportTable_2_AND_3_MONTHLY }
          />

          <br />

          <LinkButton
            isError={ errorExport_2_AND_3_ONETIME }
            errorMessage="Попробуйте повторить запрос позже"
            customType={ {
              text: 'Высокая производительность + бонусные баллы (единовременный)',
              icon: <IconChartBar />,
            } }
            onClickHandler={ queryExportTable_2_AND_3_ONETIME }
            isLoading={ isLoadingExportTable_2_AND_3_ONETIME }
          />

          <br />

          <LinkButton
            isError={ errorExport_3_5 }
            errorMessage="Попробуйте повторить запрос позже"
            customType={ {
              text: 'Отдельные достижения',
              icon: <IconChartBar />,
            } }
            onClickHandler={ queryExportTable_3_5 }
            isLoading={ isLoadingExportTable_3_5 }
          />

        </div>
      </div>
    </PaperContainerWrapper>
  )
}


export default Exports
