import HistoryLinks from '../../common/HistoryLinks/HistoryLinks'
import TeacherRatingTable from './TeacherRatingTable/TeacherRatingTable'
import { EFFECTIVE_CONTRACT_ROUTE, } from '../../../Utils/routes'
import LinkButton from '../../common/LinkButton/LinkButton'
import ButtonSimple from '../../common/ButtonSimple/ButtonSimple'
import FilterDropDown from '../../common/FilterDropDown/FilterDropDown'
import { useEffect, useState, } from 'react'
import CollapseContainer2, { AccordionInnder, } from '../../common/CollapseContainer2/CollapseContainer2'
import { useDispatch, useSelector, } from 'react-redux'
import { setTeacherRatingFilterInstituteFilter, } from '../../../redux/efectiveContractSlices/efectiveContractSlices'
import useGetInstitutes from '../../../gql/hooks/common/useGetInstitutes'


const TeacherRating = () => {
  const [
    institutesList,
    setInstitutesList,
  ] = useState([])
  const getInstitutesQuery = useGetInstitutes()

  useEffect(() => {
    if (getInstitutesQuery?.data) {
      setInstitutesList(getInstitutesQuery.data.catalogs.ecInstList)
    }
  }, [
    getInstitutesQuery.loading,
    getInstitutesQuery.data,
  ])


  const dispatch = useDispatch()
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const instituteFilter = useSelector(state => state.efContract.adminUserlistFilter.instituteFilter)


  return (
    <div className="effective-contract">
      <HistoryLinks
        links={ [
          {
            value: 'Главная',
            path: '/',
          },
          {
            value: 'Эффективный контракт',
            path: EFFECTIVE_CONTRACT_ROUTE,
          },
        ] }
        loc="Рейтинг преподавателей"
      />

      <h2 className="effective-contract__title">Рейтинг преподавателей</h2>

      <CollapseContainer2 styles={ { marginBottom: '2rem', } }>
        <AccordionInnder
          isOpened
          headerText={ (
            <span className="effective-contract__subtitle">
              Фильтрация пользователей
            </span>
          ) }
        >
          <UsersFilter
            institutesList={ institutesList }
            // allTeachersPositions={ allTeachersPositions }
            instituteFilter={ instituteFilter }
            // positionNameFilter={ positionNameFilter }
            setInstituteFilter={ (data: any) => dispatch(setTeacherRatingFilterInstituteFilter(data)) }
          // setPositionNameFilter={ data => dispatch(setAdminUserlistFilterPositionNameFilter(data)) }
          />
        </AccordionInnder>
      </CollapseContainer2>

      <TeacherRatingTable />
    </div>
  )
}


const UsersFilter = ({
  institutesList,
  instituteFilter,
  setInstituteFilter,
  positionNameFilter,
  setPositionNameFilter,
}: any) => {
  const [
    instituteValue,
    setInstituteValue,
  ] = useState(instituteFilter)
  const [
    positionNameValue,
    setPositionNameValue,
  ] = useState(positionNameFilter)

  const [
    clearFilter,
    setClearFilter,
  ] = useState(false)

  return (
    <div className="effective-contract-user-list__inputs-inner">
      <div className="effective-contract-user-list__filters-inner">
        <div className="effective-contract-user-list__filters-wrapper">
          <FilterDropDown
            defaultHint={ instituteValue ? instituteValue : 'Институт' }
            withBorder
            customWidth="27.4rem"
            items={ [
              'Все',
              ...institutesList,
            ] }
            clearFilter={ clearFilter }
            onClickHandler={ (answer: any) => {
              if (answer === 'Все') {
                setInstituteValue('')
              } else {
                setInstituteValue([
                  answer,
                ])
              }
            } }
            maxWidthOfinput="24rem"
          />

          {/* <FilterDropDown
            defaultHint={ positionNameValue ? positionNameValue : "Должность" }
            withBorder
            customWidth="27.4rem"
            items={ ['Все', ...allTeachersPositions] }
            clearFilter={ clearFilter }
            onClickHandler={ answer => {
              if (answer === 'Все') {
                setPositionNameValue('')
              } else {
                setPositionNameValue([answer])
              }
            } }
            maxWidthOfinput="24rem"
          /> */}
        </div>


        <div
          style={ {
            display: 'flex',
            alignItems: 'center',
          } }
        >
          <LinkButton
            style={ { marginRight: '1rem', } }
            type="clearFilters"
            onClickHandler={
              () => {
                setInstituteFilter('')
                setPositionNameFilter('')
                setClearFilter(!clearFilter)
                setInstituteValue('')
                setPositionNameValue('')
              }
            }
          />

          <ButtonSimple
            value="Применить"
            variant="fill"
            onClickHandler={ () => {
              setInstituteFilter(instituteValue)
              setPositionNameFilter(positionNameValue)
            } }
          />
        </div>
      </div>
    </div>
  )
}


export default TeacherRating
