/* eslint-disable array-callback-return */
import { useMemo, } from 'react'
import { useCatalogs, } from '../../../../../gql/hooks/useCatalogs'
import PreLoader from '../../../../common/PreLoader/Preloader'
import ChooseField from '../../ChooseField/ChooseField'

const ReferenceCategoryList = ({ type, }: any) => {
  const { data, error, loading, } = useCatalogs('referenceTypes')


  const authorityTypes = useMemo(() => {
    if (error) {
      return <div>...error</div>
    } else if (loading || !data) {
      return <PreLoader />
    }

    const refTypes = data.catalogs?.referenceTypes?.map((i: any) => {
      if (i?.name !== 'Справка, подтверждающая выдачу документа об образовании') {
        return i?.name
      }
    })

    return <ChooseField items={ refTypes } value={ type } title="Выберите категорию справки" />
  }, [
    data,
    error,
    loading,
    type,
  ])

  return authorityTypes
}

export default ReferenceCategoryList
