/* eslint-disable no-console */
import { useEffect, useMemo, useState, useCallback, } from 'react'
import {
  NewPageWrapper,
  TitleH3,
} from '../../../../common/GeneralElements/GeneralElements'
import HistoryLinks from '../../../../common/HistoryLinks/HistoryLinks'
import PaperContainerWrapper from '../../../../common/PaperContainer/PaperContainerWrapper'
import CollapseContainer from '../../../../common/CollapseContainer/CollapseContainer'
import ButtonSimple from '../../../../common/ButtonSimple/ButtonSimple'
import EditTrackMainContent from './EditTrackMainContent'
import ThemeCard from './ThemeCard'
import { useLocation, } from 'react-router-dom'
import { useGetTrackDisciplines, useUpdateTrackDiscipline, } from '../../../../../gql/hooks/useMyEducation'
import Preloader from '../../../../common/PreLoader/Preloader'
import _ from 'lodash'
import { useAppContext, } from '@/App/providers/AppProvider'


const EditDiscipline = () => {
  const location = useLocation()

  const trac_id: any = new URLSearchParams(location.search).get('id')
  const discipline_id = new URLSearchParams(location.search).get('discipline_id')
  const name = new URLSearchParams(location.search).get('name')
  const [
    payload,
    setPayload,
  ] = useState<any>(null)
  const [
    isDisabled,
    setIsDisabled,
  ] = useState(false)

  const { data, error, loading, } = useGetTrackDisciplines({
    filter: {
      track_id: +trac_id,
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      discipline_id: +discipline_id,
    },
  })

  const { openToast, } = useAppContext()

  useEffect(() => {
    if (data) {
      const dataWithoutType = _.omit(data?.esTrackDisciplines[0], '__typename', 'discipline')
      setPayload({
        ...dataWithoutType,
        track_id: +trac_id,
        // @ts-expect-error TS(2531): Object is possibly 'null'.
        discipline_id: +discipline_id,
        name: data?.esTrackDisciplines[0]?.discipline,
      })
    }
  }, [
    data,
    discipline_id,
    trac_id,
  ])


  const [
    esTrackUpdateDiscipline,
  ] = useUpdateTrackDiscipline(payload)

  const handleDisciplineUpdate = useCallback(() => {
    setIsDisabled(true)

    esTrackUpdateDiscipline()
      .then(() => {
        setIsDisabled(false)
        openToast?.({ message: 'Изменения внесены', })
      })
      .catch(e => console.log(e))
  }, [
    esTrackUpdateDiscipline,
    openToast,
  ])

  const editContent = useMemo(() => {
    if (error) {
      return <div>...error..</div>
    }
    if (loading) {
      return <Preloader />
    } else if (data) {
      const themeContent = data.esTrackDisciplines[0].themes.map((i: any, index: any) => {
        return {
          headerText: `${ index + 1 }. ${ i.name }`,
          cardType: ThemeCard,
          cardContent: [
            {
              title: i.name,
              description: i.content,
              results: i.results,
              payload,
              index,
              setPayload,
            },
          ],
        }
      })

      return <div style={ {
        marginTop: '40px',
        display: 'flex',
        flexDirection: 'column',
      } }>
        { payload && <EditTrackMainContent discipline={ data.esTrackDisciplines[0] } payload={ payload } setPayload={ setPayload } /> }
        <PaperContainerWrapper>
          <TitleH3>
            Содержание тематики дисциплины
          </TitleH3>

          { payload && <CollapseContainer
            styles={ { marginBottom: '1rem', } }
            content={ themeContent }
            accordionStyle={ { background: '#F5F6FA', } }
          /> }
        </PaperContainerWrapper>
        <ButtonSimple
          styles={ {
            marginRight: '.6rem',
            width: 'fit-content',
            alignSelf: 'flex-end',
            marginTop: '40px',
          } }
          active
          value="Сохранить изменения"
          isDisabled={ isDisabled }
          onClickHandler={ handleDisciplineUpdate }
        />
      </div>
    }
  }, [
    data,
    error,
    loading,
    payload,
    handleDisciplineUpdate,
    isDisabled,
  ])

  const trackName = new URLSearchParams(location.search).get('trackName')

  return (
    <NewPageWrapper>
      <HistoryLinks
        links={
          [
            {
              value: 'Главная',
              path: '/',
            },
            {
              value: 'Высшее образование',
              path: '/high-education',
            },
            {
              value: 'Выбор дисциплин и факультативов',
              path: '/learning-pathway',
            },
            {
              value: `${ trackName }`,
              path: '/learning-pathway',
            },
          ]
        }
        loc={ name } />
      { editContent }
    </NewPageWrapper>
  )
}


export default EditDiscipline
