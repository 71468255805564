/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState, } from 'react'
import Card from './Card/Card'
import './Navigation.less'

import first from '../../../../assets/images/navigation/noun_portfolio_4290964 1.svg'
import second from '../../../../assets/images/navigation/noun_contact_4290938 1.svg'
import third from '../../../../assets/images/navigation/noun_timesheet_4290810 1.svg'
// import four from '../../../../assets/images/navigation/noun_Learn Online_3479140 1.svg';
// import five from '../../../../assets/images/navigation/noun_chat_4068870 1.svg';
import six from '../../../../assets/images/navigation/noun_Document_4069010 1.svg'
import { useReferenceStats, } from '../../../../gql/hooks/mfc/useReferences'

import { MyReferences_Route, RECORD_BOOK_ROUTE, SCHEDULE_ROUTE, } from '../../../../Utils/routes'
import NotificationCards from './NotificationCards'
import useGetRole from '@/legacy/redux/hooks/common/useGetRole'


const Navigation = () => {
  const references = useReferenceStats()

  const role = useGetRole()

  const [
    active,
    setActive,
  ] = useState(0)
  const [
    rejected,
  ] = useState(0)
  const [
    cards,
    setCards,
  ] = useState([])

  useEffect(() => {
    references?.data?.references.forEach((i: any) => {
      i.status === 'ready' && setActive(i.count)
    })
  }, [
    references.data,
  ])

  useEffect(() => {
    setCards([
      // { id: 1, name: "Портфолио", desc: "Перейти к портфолио", path: "/portfolio", img: first, role: ['student'] },
      // @ts-expect-error TS(2322): Type 'number' is not assignable to type 'never'.
      {
        id: 2,
        name: 'Зачетная книжка',
        desc: 'Перейти в разделы',
        path: RECORD_BOOK_ROUTE,
        img: second,
        role: [
          'student',
          'student-rakus',
        ],
      },
      // @ts-expect-error TS(2322): Type 'number' is not assignable to type 'never'.
      {
        id: 3,
        name: 'Расписание',
        desc: 'Перейти к расписанию',
        path: SCHEDULE_ROUTE,
        img: third,
        role: [
          'student',
          'student-rakus',
        ],
      },
      // { id: 4, name: "Обучение", desc: "Подробнее", path: "/portfolio", img: four, role: ['student'] },
      // { id: 5, name: "Общение", desc: "Перейти", path: "/portfolio", img: five, role: [ 'student'] },
      // @ts-expect-error TS(2322): Type 'number' is not assignable to type 'never'.
      {
        id: 6,
        name: 'Справки',
        desc: 'Подробнее о справках',
        path: MyReferences_Route,
        img: six,
        good: active,
        bad: rejected,
        role: [
          'student',
          'student-rakus',
        ],
      },
    ])
  }, [
    active,
    rejected,
  ])

  if (references.loading) {
    return <div className="Navigation">
      <div className="Card"></div>
      <div className="Card"></div>
      <div className="Card"></div>
      <div className="Card"></div>
      <div className="Card"></div>
      <div className="Card"></div>
    </div>
  }

  return (
    <div className="Navigation">
      <NotificationCards />
      { cards.map(i => {
        // @ts-expect-error TS(2339): Property 'role' does not exist on type 'never'.
        if (i.role.includes(role)) {
          // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
          return <Card key={ i.id } props={ i } />
        } return null
      }
      ) }
    </div>
  )
}

export default Navigation
