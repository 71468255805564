/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { NotificationMetaType, } from '@/App/types'
import { useMemo, } from 'react'
import { useAppContext, } from '@/App/providers/AppProvider'
import NotifyCard from './NotifyCard/NotifyCard'
import { useNotifications, } from '@/shared/api/notifications'


const NotificationCards = () => {
  const payload = {
    metaType: NotificationMetaType.dynamic,
  }

  const { updateNotification, } = useAppContext()

  const { data, error, } = useNotifications?.(payload)


  const notifyCards = useMemo(() => {
    if (error) {
      return <div>error</div>
    } else if (data) {
      return data.notificationList.items.map((i: any, index: any) => <NotifyCard key={ index } i={ i.meta.card } links={ i.body.links } />)
    }
  }, [
    data,
    error,
  ])

  return <div style={ { display: data?.notificationList?.items?.length > 0 ? 'block' : 'none', } }>
    { notifyCards }
  </div>
}

export default NotificationCards
