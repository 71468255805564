import { useEffect, useMemo, } from 'react'
import { useSelector, } from 'react-redux'
import { useGetFacultatives, } from '../../../../../gql/hooks/useMyEducation'
import Preloader from '../../../../common/PreLoader/Preloader'
import PaperContainerWrapper from '../../../../common/PaperContainer/PaperContainerWrapper'
import FacultativeHeader from '../DetailPageComponents/HeaderBlock/FacultativeHeader'
import TrackStatistics from '../DetailPageComponents/TrackStatistics/TrackStatistics'
import StudentsDistribution from '../DetailPageComponents/StudentsDistribution/StudentsDistribution'
import FacultativeGrades from '../DetailPageComponents/FacultativeGrades/FacultativeGrades'

const FacultativeDetailPage = ({
  id,
  role,
  type,
}: any) => {
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const refresh = useSelector(state => state.myEd.updateFacultatives)

  const { data, error, loading, refetch, } = useGetFacultatives({
    filter: {
      facultative_id: Number(id),
      isModerator: true,
    },
    pagination: {
      'offset': 0,
      'limit': 1000,
    },
  })

  useEffect(() => {
    refetch()
  }, [
    refresh,
    refetch,
  ])


  const facInfo = useMemo(() => {
    if (error) {
      return <div>error</div>
    } else if (loading) {
      return <Preloader />
    } else if (data) {
      return <>
        <PaperContainerWrapper
          styles={ {
            display: 'flex',
            flexDirection: 'column',
          } }
        >
          <FacultativeHeader role={ role } type={ type }
            /* attachedFiles={attachedFiles} */ res={ data?.esFacultative?.items[0] } />
        </PaperContainerWrapper>

        { role === 'osrv-moder' ? (
          <>
            <TrackStatistics type={ type } payloadType="facultative" />

            <StudentsDistribution type={ type } facInfo={ data } />
          </>
        ) : false }
        { ((role === 'student' || role === 'student-rakus' || role === 'prosmotr-moe-obuchenie') && data.esFacultative.items[0].level) && <ul>
          { data.esFacultative.items[0].level.map((i: any) => <li key={ i } className="gradeLi"><FacultativeGrades info={ i } id={ Number(id) }
            isSigned={ data.esFacultative.items[0].is_signed } isActive={ data.esFacultative.items[0].is_active } role={ role } /></li>) }
        </ul> }
      </>
    }
  }, [
    data,
    error,
    loading,
    role,
    type,
    id,
  ])


  return facInfo
}

export default FacultativeDetailPage
