import { useState, } from 'react'
import { useDispatch, useSelector, } from 'react-redux'

import Arrow from '../../../../../assets/images/arrow.svg'
import WhiteArrow from '../../../../../assets/images/white-arrow.svg'
import { setAdsActive, } from '../../../../../redux/MainSlices/MainSlice'

const ArrowToRightClick = (props: any) => {
  const { onClick, } = props

  const [
    right,
    setRight,
  ] = useState(false)

  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const AdsActive = useSelector((state => state.main.AdsActive))

  const dispatch = useDispatch()

  return (
    <div onClick={ () => {
      onClick(); dispatch(setAdsActive(AdsActive + 1))
    } } className={ `Arrow d-arrow-bg right ${ right && 'd-arrow-bg-active' }` } onMouseOver={ () => setRight(true) } onMouseOut={ () => setRight(false) }>
      <img src={ right ? WhiteArrow : Arrow } alt={ 'Стрелка вправо' } />
    </div>
  )
}

export default ArrowToRightClick
