import { useMemo, useEffect, useState } from 'react';
import TreckCard from './TreckCard/TreckCard';
import { useGetTracks } from '../../../../../../gql/hooks/useMyEducation';
import Preloader from '../../../../../common/PreLoader/Preloader';
import { useSelector } from 'react-redux';
import useRedirect from '@/legacy/hooks/useRedirect';
import cls from './TreckList.module.less'
import ButtonSimple from '@/legacy/components/common/ButtonSimple/ButtonSimple';

const TrecksList = ({ role, nameSpec, currentUserId }:any) => {
  const [visibleYears, setVisibleYears] = useState<any>({});
  const redirect = useRedirect();
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const refresh = useSelector(state => state.myEd.updateTracks)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleNavigate = (e:any, i:any) => {
    if (role === 'student' || role === 'student-rakus') {
      redirect(`/learning-pathway/detail?type=Трек&id=${i.track_id}&name=${i.name}&namespec=${nameSpec?.namespec}&course=${nameSpec?.course}`);
    } else {
      redirect(`/learning-pathway/detail?type=Трек&id=${i.track_id}&name=${i.name}`);
    }
  };

  const payload = {
    filter: null,
    pagination: {
      offset: 0,
      limit: 1000,
    },
    sort: [
      {
        column: 'is_visible',
        order: 'desc',
      },
      {
        column: 'created',
        order: 'desc',
      },
    ],
  };

  const params = (role === 'student' || role === 'student-rakus') ? {
    ...payload,
    filter: {
      namespec: nameSpec.namespec,
      department: nameSpec.department_name,
      nameprof: nameSpec.nameprof,
      group: nameSpec.group,
      course: nameSpec.course,
      student_id: currentUserId,
      eform: nameSpec.eform,
      is_moderator: false,
      elevel: nameSpec.elevel,
    },
    pagination: {
      offset: 0,
      limit: 1000,
    },
  } : {
    ...payload,
    filter: {
      is_moderator: true,
    },
    pagination: {
      offset: 0,
      limit: 1000,
    },
  };

  const { data, error, loading, refetch } = useGetTracks(params);

  useEffect(() => {
    refetch();
  }, [refetch, refresh]);

  const groupedTracks = useMemo(() => {
    if (error) {
      return <div>..error..</div>;
    } else if (loading) {
      return <Preloader />;
    } else if (data?.esTrackList?.items) {
      const grouped = data.esTrackList.items.reduce((acc:any, track:any) => {
        const year = track.data.recruitmentYear;
        if (!acc[year]) {
          acc[year] = [];
        }
        acc[year].push(track);
        return acc;
      }, {});
      const sortedYears = Object.keys(grouped).sort((a:any, b:any) => b - a);
      return <>
      {sortedYears.map((year:any) => (
        <div key={year}className={cls.yearContainer}>
          <ButtonSimple onClickHandler={ () => setVisibleYears((prev:any) => ({ ...prev, [year]: !prev[year] }))} value={`Год набора: ${year}`} variant="fill" active={ true } styles={ { marginBottom: '10px', } } />
          {visibleYears[year] && (
            <div className={cls.tracksContainer}>
              {grouped[year].map((i:any) => (
                <TreckCard
                  name={i.name}
                  type="Трек"
                  role={role}
                  is_visible={i.is_visible}
                  is_signed={i.is_signed}
                  id={i.track_id}
                  key={i.track_id}
                  is_active={i.is_active}
                  handleNavigate={(e:any) => handleNavigate(e, i)}
                  nameSpec={nameSpec}
                />
              ))}
            </div>
          )}
        </div>
      ))}
      </>
    }

    return null;
  }, [error, loading, data, role, nameSpec, handleNavigate, visibleYears]);

  return <div className={cls.trecksListContainer}>{groupedTracks}</div>;
};

export default TrecksList;
  