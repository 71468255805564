import React, { useContext, } from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import {
  IconChevronDown,
} from '@tabler/icons'
import Typography from '@mui/material/Typography'
import { EcTableName, } from '@/legacy/components/EffectiveContract/constants'
import TableContainer from '@/legacy/components/EffectiveContract/TableContainer/TableContainer'
import useGetTableData from '@/legacy/gql/hooks/effectiveContractHooks/useGetTableData'
import { UUID, } from '@/legacy/types'
import { EcSubscriptionContext, } from '@/features'
import {
  tableSource as headerFields_1_1,
} from '../../../../../constants/tablesFormData/table_1_1'
import {
  tableSource as headerFields_1_11,
} from '../../../../../constants/tablesFormData/table_1_11'
import {
  tableSource as headerFields_1_12,
} from '../../../../../constants/tablesFormData/table_1_12'

import {
  tableSource as headerFields_1_2,
} from '../../../../../constants/tablesFormData/table_1_2'
import {
  tableSource as headerFields_1_3,
} from '../../../../../constants/tablesFormData/table_1_3'
import {
  tableSource as headerFields_1_4,
} from '../../../../../constants/tablesFormData/table_1_4'
import {
  tableSource as headerFields_1_41,
} from '../../../../../constants/tablesFormData/table_1_41'
import {
  tableSource as headerFields_1_5,
} from '../../../../../constants/tablesFormData/table_1_5'
import {
  tableSource as headerFields_2_1,
} from '../../../../../constants/tablesFormData/table_2_1'
import {
  tableSource as headerFields_2_2,
} from '../../../../../constants/tablesFormData/table_2_2'
import {
  tableSource as headerFields_2_3,
} from '../../../../../constants/tablesFormData/table_2_3'
import {
  tableSource as headerFields_2_4,
} from '../../../../../constants/tablesFormData/table_2_4'
import {
  tableSource as headerFields_2_41,
} from '../../../../../constants/tablesFormData/table_2_41'
import {
  tableSource as headerFields_2_5,
} from '../../../../../constants/tablesFormData/table_2_5'
import {
  tableSource as headerFields_2_6,
} from '../../../../../constants/tablesFormData/table_2_6'
import {
  tableSource as headerFields_2_7,
} from '../../../../../constants/tablesFormData/table_2_7'
import {
  tableSource as headerFields_2_8,
} from '../../../../../constants/tablesFormData/table_2_8'
import {
  tableSource as headerFields_2_9,
} from '../../../../../constants/tablesFormData/table_2_9'
import {
  tableSource as headerFields_2_10,
} from '../../../../../constants/tablesFormData/table_2_10'
import {
  tableSource as headerFields_3_1,
} from '../../../../../constants/tablesFormData/table_3_1'
import {
  tableSource as headerFields_3_2,
} from '../../../../../constants/tablesFormData/table_3_2'
import {
  tableSource as headerFields_3_3,
} from '../../../../../constants/tablesFormData/table_3_3'
import {
  tableSource as headerFields_3_31,
} from '../../../../../constants/tablesFormData/table_3_31'
import {
  tableSource as headerFields_3_4,
} from '../../../../../constants/tablesFormData/table_3_4'
import {
  tableSource as headerFields_4_1,
} from '../../../../../constants/tablesFormData/table_4_1'

interface IconProps {
  src: string;
}

interface AccordionInnerProps {
  Icon?: React.ElementType;
  accordionStyle?: React.CSSProperties;
  accordionDirection?: string;
  headerText: string;
  subHeaderText?: string;
  isOpened?: boolean;
  achievementData?: any;
  children?: React.ReactNode;
  iconProps?: IconProps;
  moderateId: UUID;
  dataType: EcTableName;
}

const headerFieldsMap = {
  [EcTableName.TABLE_1_1]: headerFields_1_1,
  [EcTableName.TABLE_1_11]: headerFields_1_11,
  [EcTableName.TABLE_1_12]: headerFields_1_12,
  [EcTableName.TABLE_1_2]: headerFields_1_2,
  [EcTableName.TABLE_1_3]: headerFields_1_3,
  [EcTableName.TABLE_1_4]: headerFields_1_4,
  [EcTableName.TABLE_1_41]: headerFields_1_41,
  [EcTableName.TABLE_1_5]: headerFields_1_5,
  [EcTableName.TABLE_2_1]: headerFields_2_1,
  [EcTableName.TABLE_2_2]: headerFields_2_2,
  [EcTableName.TABLE_2_3]: headerFields_2_3,
  [EcTableName.TABLE_2_4]: headerFields_2_4,
  [EcTableName.TABLE_2_41]: headerFields_2_41,
  [EcTableName.TABLE_2_5]: headerFields_2_5,
  [EcTableName.TABLE_2_6]: headerFields_2_6,
  [EcTableName.TABLE_2_7]: headerFields_2_7,
  [EcTableName.TABLE_2_8]: headerFields_2_8,
  [EcTableName.TABLE_2_9]: headerFields_2_9,
  [EcTableName.TABLE_2_10]: headerFields_2_10,
  [EcTableName.TABLE_3_1]: headerFields_3_1,
  [EcTableName.TABLE_3_2]: headerFields_3_2,
  [EcTableName.TABLE_3_3]: headerFields_3_3,
  [EcTableName.TABLE_3_31]: headerFields_3_31,
  [EcTableName.TABLE_3_4]: headerFields_3_4,
  [EcTableName.TABLE_4_1]: headerFields_4_1,
}
const dates = [
  { year: '2024', },
  { year: '2023', },
  { year: '2022', },
]

const tablesDictionary: any = {
  [EcTableName.TABLE_1_1]: null,
  [EcTableName.TABLE_1_11]: null,
  [EcTableName.TABLE_1_12]: null,
  [EcTableName.TABLE_1_2]: null,
  [EcTableName.TABLE_1_3]: null,
  [EcTableName.TABLE_1_4]: null,
  [EcTableName.TABLE_1_41]: null,
  [EcTableName.TABLE_1_5]: null,
  [EcTableName.TABLE_2_1]: dates,
  [EcTableName.TABLE_2_2]: dates,
  [EcTableName.TABLE_2_3]: dates,
  [EcTableName.TABLE_2_4]: dates,
  [EcTableName.TABLE_2_41]: dates,
  [EcTableName.TABLE_2_5]: dates,
  [EcTableName.TABLE_2_6]: dates,
  [EcTableName.TABLE_2_7]: dates,
  [EcTableName.TABLE_2_8]: dates,
  [EcTableName.TABLE_2_9]: dates,
  [EcTableName.TABLE_2_10]: dates,
  [EcTableName.TABLE_3_1]: dates,
  [EcTableName.TABLE_3_2]: dates,
  [EcTableName.TABLE_3_3]: dates,
  [EcTableName.TABLE_3_31]: dates,
  [EcTableName.TABLE_3_4]: dates,
  [EcTableName.TABLE_4_1]: null,
}

const AccordionInner: React.FC<AccordionInnerProps> = ({
  Icon,
  accordionStyle,
  headerText,
  subHeaderText,
  isOpened = false,
  children,
  dataType,
  moderateId,
}) => {
  const hasChildren = React.Children.count(children) > 0
  const dataTypeUpdated = useContext(EcSubscriptionContext)
  // @ts-ignore
  const headerFields = headerFieldsMap[dataType]

  const useGetData = (dataType: EcTableName, date: { year: string;}) => {
    return useGetTableData({
      dataType,
      user_id: moderateId,
      status: null,
      date: date,
      dataTypeUpdated,
    })
  }
  return (
    <Accordion
      defaultExpanded={isOpened}
      style={{
        borderRadius: '1rem',
        boxShadow:
          '0px 12.521552085876465px 6.636422634124756px 0px rgba(0, 0, 0, 0.015)',
        marginBottom: '.7rem',
        maxWidth: '98%',
        ...accordionStyle,
      }}
    >
      <AccordionSummary
        className="collapsed-container__accordion-summary"
        style={{
          padding: Icon ? '0 2.6rem 0 .5rem' : '1.2rem 2.6rem .8rem 1.9rem',
        }}
        expandIcon={<IconChevronDown/>}
      >
        {Icon ? (
          <div className="collapsed-container__icon-wrapper">
            <Icon
              style={{
                height: '3.2rem',
                width: '3.2rem',
                color: '#bfc6e0',
              }}
            />
          </div>
        ) : (
          false
        )}

        <Typography
          style={{
            alignSelf: 'center',
            fontSize: '1.2rem',
            fontWeight: 'bold',
            letterSpacing: 0.35,
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <span className="collapsed-container__header-text">{headerText}</span>

          {subHeaderText && (
            <span className="collapsed-container__header-text collapsed-container__header-text_subheader">
              {subHeaderText}
            </span>
          )}
        </Typography>
      </AccordionSummary>

      {hasChildren ? (
        <AccordionDetails
          className="collapsed-container__accordion-details"
          style={{
            padding: Icon ? '0 .6rem 2rem 7rem' : '0 1.9rem 2rem 1.9rem',
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          {children}
        </AccordionDetails>
      ) : (
        <AccordionDetails
          className="collapsed-container__accordion-details"
          style={{
            padding: Icon ? '0 .6rem 2rem 7rem' : '0 1.9rem 2rem 1.9rem',
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          {tablesDictionary[dataType] ? (
            tablesDictionary[dataType].map((date: any, index: any) => (
              <React.Fragment key={index}>
                <Typography
                  style={{
                    width: '100%',
                    fontSize: '1.2rem',
                    fontWeight: 'bold',
                    letterSpacing: '0.35px',
                  }}
                  variant="h6"
                >
                  {date.year}
                </Typography>
                <TableContainer
                  dataType={dataType}
                  // eslint-disable-next-line react-hooks/rules-of-hooks
                  ecTableData={useGetData(dataType, date)}
                  headerFields={headerFields}
                />
              </React.Fragment>
            ))
          ) : (
            <TableContainer
              dataType={dataType}
              // eslint-disable-next-line react-hooks/rules-of-hooks
              ecTableData={useGetTableData({
                dataType: dataType,
                user_id: moderateId,
                status: null,
                date: null,
                dataTypeUpdated,
              })}
              headerFields={headerFields}
            />
          )}
        </AccordionDetails>
      )}
    </Accordion>
  )
}

export default AccordionInner
