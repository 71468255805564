/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  MAIN_ROUTE,
  MyReferences_Route,
  NEWS_ROUTE,
  GET_REFERENCE_ROUTE,
  SCOLARSHIP,
  PAYMENT,
  EFFECTIVE_CONTRACT_ROUTE,
  EFFECTIVE_CONTRACT_AMS_ROUTE,
  EFFECTIVE_CONTRACT_MODERATE_ACHIEVEMENTS_AMS_ROUTE,
  EFFECTIVE_CONTRACT_APPEALS_ROUTE,
  EFFECTIVE_CONTRACT_STEPS_ROUTE,
  // TEACHER_RATING_ROUTE,
  EFFECTIVE_CONTRACT_MODERATE_ACHIEVEMENT_ROUTE,
  EFFECTIVE_CONTRACT_APPEALS_AMS_ROUTE,
  EFFECTIVE_CONTRACT_MODERATE_THRESHOLD_VALUES_ROUTE,
  PORTFOLIO_TEMPLATES,
  PORTFOLIO_RATING,
  PORTFOLIO,
  MODERATE_PORTFOLIO,
  MODERATE_NOTIFICATIONS,
  MFC_MY_APPLICATIONS_ROUTE,
  PROJECT_SEMINAR,
  MY_DUTY_NOTES,
  MFC_LOGS,
  TEST_ROUTE,
} from './routes'

export const URL = process.env.REACT_APP_URL
export const dev = 'lk-dev.tsutmb.ru'
export const isDev = URL === dev

export const navItems = [
  {
    value: 'Главная',
    role: [
      'student',
      'student-rakus',
      'prosmotr-moe-obuchenie',
      'dms-moder',
      'ufpn-moder',
      'osrv-moder',
      'tester',
      'pa_moder',
      'worker',
      'teacherGPH',
      'uonid-moder',
      'dm-moder',
      'ukp-moder',
      'tpd-moder',
      'urtos-moder',
      'urvo-moder',
      'urvo-admin',
      'aup-admin',
      'director-Institute',
      'director-faculty',
      'aup-pfu-moder',
      'aup-pk-moder',
      'aup-dms-moder',
      'aup-ck-moder',
      'selfsignup',
      'employee',
      'portfolio-mfc-moder',
      'portfolio-kurator',
      'unknown',
    ],
    path: MAIN_ROUTE,
    icon: 'home',
  },
  {
    value: 'Учебный процесс',
    role: [
      'student',
      'prosmotr-moe-obuchenie',
      'student-rakus',
      'pa_moder',
      'worker',
      'teacherGPH',
      'tester',
      'osrv-moder',
      // 'employee',
    ],
    icon: 'notebook',
    items: [
      {
        value: 'Расписание',
        icon: 'calendar-event',
        path: '/schedule-teacher',
        role: [
          'worker',
          'teacherGPH',
          'tester',
        ],
      },
      {
        value: 'Мое обучение',
        depth: 2,
        role: [
          'student',
          'student-rakus',
          'osrv-moder',
          'prosmotr-moe-obuchenie',
          'tester',
        ],
        icon: 'contacts-book',
        items: [
          {
            value: 'Высшее образование',
            path: '/high-education',
            role: [
              'student',
              'student-rakus',
              'osrv-moder',
              'prosmotr-moe-obuchenie',
              'tester',
            ],
            icon: 'journal',
          },
          {
            value: 'Направления подготовки',
            path: '/specialties-list',
            role: [
              // 'student',
              // 'student-rakus',
              // 'osrv-moder',
              // 'prosmotr-moe-obuchenie',
              'tester',
            ],
            icon: 'journal',
            environment: dev,
          },
          {
            value: 'ДПО',
            path: '/dpo-education',
            role: [],
            icon: 'journal',
          },
        ],
      },
      {
        value: 'Образовательные сервисы',
        depth: 2,
        role: [
          'student',
          'student-rakus',
          'pa_moder',
          'worker',
          'teacherGPH',
          'tester',
          'osrv-moder',
          'osrv-moder',
          'employee',
        ],
        icon: 'contacts-book',
        items: [
          {
            value: 'Расписание',
            icon: 'calendar-event',
            path: '/schedule',
            role: [
              'student',
              'student-rakus',
            ],
          },
          {
            value: 'Зачетная книжка',
            icon: 'book',
            path: '/record-book',
            role: [
              'student',
              'student-rakus',
            ],
          },
          {
            value: 'Электронный журнал',
            path: '/study-journal',
            role: [
              'worker',
              'teacherGPH',
              /* "student", 'student-rakus',"pa_moder", "worker", 'employee', "teacherGPH", */
              'tester',
            ],
            icon: 'journal',
          },
          {
            value: 'Проектная деятельность',
            depth: 2,
            role: [
              'student',
              'student-rakus',
              'worker',
              'teacherGPH',
              'pa_moder',
              'tester',
            ],
            icon: 'briefcase',
            items: [
              {
                value: 'Биржа проектов',
                path: '/projects-all',
                role: [
                  'student',
                  'student-rakus',
                  'worker',
                  'teacherGPH',
                  'pa_moder',
                  'tester',
                ],
                icon: 'projects-all',
              },
              {
                value: 'Мои проекты',
                path: '/my-projects',
                role: [
                  'student',
                  'student-rakus',
                  'worker',
                  'teacherGPH',
                  'tester',
                  'employee',
                ],
                icon: 'my-projects',
              },
              {
                value: 'Мои заявки',
                path: '/my-applications',
                role: [
                  'worker',
                  'teacherGPH',
                  'tester',
                  'employee',
                ],
                icon: 'application-process',
              },
              {
                value: 'Проектный семинар',
                path: PROJECT_SEMINAR,
                role: [
                  'worker',
                  'teacherGPH',
                ],
                icon: 'projects-all',
              },
              /*  {
               value: "Модерация проектов",
               path: "/new-project",
               role: ["pa_moder"],
               icon: "new-project"
             }, */
              {
                value: 'Модерация заявок',
                path: '/moderate-applications',
                role: [
                  'pa_moder',
                  'tester',
                ],
                icon: 'new-project',
              },
            ],
          },
        ],
      },
      /* {
        value: "Библиотека",
        path: "/library",
        role: ["student",'student-rakus',  "tester"],
        icon: "notebook"
      }, */
      // {
      //   value: "ОПВО",
      //   path: "/opvo",
      //   icon: "archive",
      //   role: ["student",'student-rakus', /* "pa_moder" */]
      // },
      {
        value: 'Курсовые и дипломные',
        path: '/coursework',
        role: [
          // 'student',
          // 'student-rakus',
          // 'osrv-moder',
          'tester',
        ],
        icon: 'file-analytics',
      },
    ],
  },
  {
    value: 'МФЦ',
    role: [
      'student',
      'student-rakus',
      'worker',
      // 'employee',
    ],
    icon: 'license',
    items: [
      {
        value: 'Мои заявления',
        path: MFC_MY_APPLICATIONS_ROUTE,
        icon: 'ref',
        role: [
          'student',
          'student-rakus',
          'worker',
          'employee',
        ],
        environment: dev,
      },
      {
        value: 'Мои справки',
        path: MyReferences_Route,
        icon: 'ref',
        role: [
          'student',
          'student-rakus',
        ],
      },
      {
        value: 'Служебные записки',
        path: MY_DUTY_NOTES,
        icon: 'ref',
        role: [
          'worker',
          'employee',
        ],
        environment: dev,
      },
      {
        value: 'История активности',
        path: MFC_LOGS,
        icon: 'ref',
        role: [
          'worker',
          'employee',
        ],
        environment: dev,
      },
    ],
  },
  {
    value: 'Портфолио',
    icon: 'briefcase',
    role: [
      'urtos-moder',
      'student',
      'student-rakus',
      'portfolio-mfc-moder',
      'portfolio-kurator',
    ],
    //    environment: dev,
    items: [
      {
        value: 'Портфолио',
        path: PORTFOLIO,
        icon: 'portfolioCase',
        role: [
          'student',
          'student-rakus',
        ],
      },
      {
        value: 'Модерация портфолио',
        path: MODERATE_PORTFOLIO,
        icon: 'portfolioCase',
        role: [
          'urtos-moder',
          'portfolio-mfc-moder',
          'portfolio-kurator',
        ],
      },
      {
        value: 'Рейтинг обучающихся',
        path: PORTFOLIO_RATING,
        icon: 'personStar',
        role: [
          'student',
          'student-rakus',
          'urtos-moder',
          'portfolio-kurator',
        ],
      },
      {
        value: 'Шаблоны показателей',
        path: PORTFOLIO_TEMPLATES,
        icon: 'list',
        role: [
          'urtos-moder',
        ],
      },
    ],
  },
  {
    value: 'Финансы',
    role: [
      'student',
      'student-rakus',
    ],
    icon: 'report-money',
    items: [
      {
        value: 'Оплата и задолженность',
        path: PAYMENT,
        icon: 'credit',
        role: [
          'student',
        ],
      },
      {
        value: 'Стипендии и выплаты',
        path: SCOLARSHIP,
        icon: 'wallet',
        role: [
          'student',
          'student-rakus',
        ],
      },
    ],
  },
  {
    value: 'Биржа труда',
    role: [
      'tester',
    ],
    icon: 'search',
    items: [],
  },

  {
    value: 'Эффективный контракт',
    role: [
      'worker',
      // 'employee',
      'tester',
      'dms-moder',
      'ufpn-moder',
      'uonid-moder',
      'dm-moder',
      'ukp-moder',
      'tpd-moder',
      'urvo-moder',
      'urvo-2-moder',
      'urvo-admin',
      'aup-admin',
      'director-Institute',
      'director-faculty',
      'aup-pfu-moder',
      'aup-pk-moder',
      'aup-dms-moder',
      'aup-ck-moder',
    ],
    icon: 'tablet',
    items: [
      {
        value: 'Эффективный контракт',
        path: EFFECTIVE_CONTRACT_ROUTE,
        icon: 'tablet',
        role: [
          'worker',
          'employee',
          'tester',
        ],
      },
      {
        value: 'Эффективный контракт АУП',
        path: EFFECTIVE_CONTRACT_AMS_ROUTE,
        icon: 'tablet-fill',
        role: [
          'director-Institute',
          'director-faculty',
        ],
      },
      // {
      //   value: "Рейтинг преподавателей",
      //   path: TEACHER_RATING_ROUTE,
      //   icon: "personStar",
      //   role: ["worker", "employee", "tester"],
      // },
      {
        value: 'Модерация достижений',
        path: EFFECTIVE_CONTRACT_MODERATE_ACHIEVEMENT_ROUTE,
        icon: 'tablet',
        role: [
          'tester',
          'dms-moder',
          'ufpn-moder',
          'uonid-moder',
          'dm-moder',
          'ukp-moder',
          'tpd-moder',
          'urvo-moder',
          'urvo-2-moder',
          'urvo-admin',
        ],
      },
      {
        value: 'Модерация достижений АУП',
        path: EFFECTIVE_CONTRACT_MODERATE_ACHIEVEMENTS_AMS_ROUTE,
        icon: 'tablet-fill',
        role: [
          'aup-admin',
          'aup-pfu-moder',
          'aup-pk-moder',
          'aup-dms-moder',
          'aup-ck-moder',
        ],
      },
      {
        value: 'Модерация апелляций АУП',
        path: EFFECTIVE_CONTRACT_APPEALS_AMS_ROUTE,
        icon: 'folderGearBlack',
        role: [
          'aup-admin',
        ],
      },
      {
        value: 'Модерация апелляций',
        path: EFFECTIVE_CONTRACT_APPEALS_ROUTE,
        icon: 'folderGear',
        role: [
          'tester' /* "uonid-moder", "dm-moder", "ukp-moder", "tpd-moder", "urvo-moder", */,
          'urvo-admin',
        ],
      },
      {
        value: 'Этапы эффективного контракта',
        path: EFFECTIVE_CONTRACT_STEPS_ROUTE,
        icon: 'path',
        role: [
          'urvo-admin',
        ],
      },
      {
        value: 'Пороговые значения',
        path: EFFECTIVE_CONTRACT_MODERATE_THRESHOLD_VALUES_ROUTE,
        icon: 'threshold',
        role: [
          'aup-admin',
        ],
      },
    ],
  },

  {
    value: 'Информация',
    role: [
      'student',
      'prosmotr-moe-obuchenie',
      'student-rakus',
      'osrv-moder',
      'pa_moder',
      'worker',
      'teacherGPH',
      'uonid-moder',
      'dm-moder',
      'ukp-moder',
      'tpd-moder',
      'urtos-moder',
      'urvo-moder',
      'employee',
      'tester',
      'selfsignup',
      'portfolio-mfc-moder',
      'portfolio-kurator',
    ],
    icon: 'info-circle',
    items: [
      {
        value: 'Новости',
        path: NEWS_ROUTE,
        icon: 'news',
        role: [
          'student',
          'prosmotr-moe-obuchenie',
          'student-rakus',
          'osrv-moder',
          'pa_moder',
          'worker',
          'teacherGPH',
          'uonid-moder',
          'dm-moder',
          'ukp-moder',
          'tpd-moder',
          'urtos-moder',
          'urvo-moder',
          'employee',
          'tester',
          'selfsignup',
          'portfolio-mfc-moder',
          'portfolio-kurator',
        ],
      },
      {
        value: 'Объявления',
        path: '/anounces',
        icon: 'anounces',
        role: [
          'student',
          'prosmotr-moe-obuchenie',
          'student-rakus',
          'osrv-moder',
          'pa_moder',
          'worker',
          'teacherGPH',
          'uonid-moder',
          'dm-moder',
          'ukp-moder',
          'tpd-moder',
          'urtos-moder',
          'urvo-moder',
          'employee',
          'tester',
          'selfsignup',
          'portfolio-mfc-moder',
          'portfolio-kurator',
        ],
      },
    ],
  },

  {
    value: 'Уведомления',
    role: [
      'notification-moder',
      'portfolio-mfc-moder',
      'portfolio-kurator',
    ],
    icon: 'bell',
    items: [
      {
        value: 'Добавление уведомлений',
        path: MODERATE_NOTIFICATIONS,
        icon: 'bell',
        role: [
          'notification-moder',
        ],
      },
    ],
  },
  {
    value: 'Тестирование',
    role: [
      'tester',
    ],
    icon: 'info-circle',
    items: [
      {
        value: 'Тестовая страница',
        path: TEST_ROUTE,
        icon: 'info-circle',
        role: [
          'tester',
        ],
      },
    ],
    environment: dev,
  },
]

export const profilePopup = [
  {
    value: 'Профиль',
    role: [
      'student',
      'student-rakus',
    ],
    path: '/profile',
  },
]

export const refTypes = [
  'Справка об обучении',
  'Справка для органов Соц.защиты',
  'Справка для Пенсионного Фонда',
  'Справка о проживании в общежитии',
  'Справка о невыплате пособий при рождении ребенка',
  'Справка о среднем балле',
  'Справка-вызов',
  'Архивная справка о периоде обучения',
  'Справка о периоде и месте прохождения практики',
  'Справка, подтверждающая выдачу документа об образовании',
  'Характеристика',
  'Заверенные копии лицензии и свидетельства',
  'Выписка из зачетной книжки',
]

export const authorityTypes = [
  'ИФНС',
  'Органы социальной защиты',
  'Пенсионный фонд',
  'Военный комиссариат',
  'По месту требования',
]

export const placeToGet = [
  'Забрать в МФЦ оригинал',
  'В личный кабинет скан-копия',
  'На электронную почту скан-копия',
]

export const refAmount = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
]


