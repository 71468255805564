import StudentInner from './StudentJournal/StudentInner/StudentInner'
import './InnerJournal.less'
import HistoryLinks from '../common/HistoryLinks/HistoryLinks'
import WorkerInner from './WorkerJournal/WorkerInner/WorkerInner'
import { useSelector, } from 'react-redux'

function JournalInner () {
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const user = useSelector(state => state.auth.userType)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const group = useSelector(state => state.studJour.group)

  return (
    <div className="InnerPage">
      <HistoryLinks links={ [
        {
          value: 'Главная',
          path: '/',
        },
        {
          value: 'Электронный журнал',
          path: '/study-journal',
        },
      ] } loc="Электронный журнал" />
      <div className="studentInner">
        <h2 className="innerTitle">Электронный журнал дисциплины <span>"Финансовый анализ" </span>для группы <span>{ `№ ${ group }` }</span></h2>
        { user === 'student' ? <StudentInner /> : <WorkerInner /> }
      </div>
    </div>
  )
}

export default JournalInner
