import { useMemo, useEffect, useState, } from 'react'
import { useSelector, } from 'react-redux'
import { useGetAllProjects, } from '@/legacy/gql/hooks/useProjects'
import ModerCard from '@/entities/PAapplications/ui/ModerCard/ModerCard'
import PaginationSimple from '@/legacy/components/common/PaginationSimple/PaginationSimple'
import { InputNumber, } from 'antd'
import ButtonSimple from '@/legacy/components/common/ButtonSimple/ButtonSimple'
import NoContracts from '@/legacy/components/Finances/PaymentPage/NoContracts/NoContracts'
import Preloader from '@/legacy/components/common/PreLoader/Preloader'

function Themes ({
  filter,
  params,
}: any): JSX.Element {
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const updateStatus = useSelector(state => state.subscriptions.initiates)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const updateApplys = useSelector(state => state.subscriptions.applyStatus)

  const [
    offs,
    setOffs,
  ] = useState(0)
  const [
    page,
    setPage,
  ] = useState<number | null>(0)

  const pagination = {
    offset: offs,
    limit: 10,
  }

  const { refetch, data, error, loading, } = useGetAllProjects(params, pagination)

  function handlePaginate (e: any, value: any) {
    const page = document.querySelector('.main-scroll-bar')?.firstChild
    setOffs((value * 10) - 10)
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    page.scrollTo(0, 0)
  }


  const choosePage = (i: any) => {
    if (i > 0) {
      goTop()
      if (i === 1) {
        setOffs(0)
      } else {
        setOffs(((i - 1) * 10))
      }
    }
  }

  const goTop = () => {
    const page = document.querySelector('.main-scroll-bar')?.firstChild
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    page.scrollTo(0, 0)
  }

  const applications = useMemo(() => {
    if (error) {
      return <div>.....errrr</div>
    } else if (loading && !data?.paGetAll) {
      return <Preloader />
    }
    return (
      <ul className="moderCards">
        { data?.paGetAll?.items.map((i: any, index: any) => <li key={ index }><ModerCard type={ filter } data={ i } /></li>) }
        { data?.paGetAll?.pagination.total > 10 && <div style={ {
          display: 'flex',
          alignItems: 'center',
        } } className="choosePageWrap">
          <InputNumber
            min={ 1 }
            max={ 300 }
            placeholder="Введите номер страницы"
            autoFocus={ false }
            onChange={ setPage }
          />

          <ButtonSimple
            onClickHandler={ () => choosePage(page) }
            value="перейти"
            variant="fill"
            active={ true }
            styles={ { marginLeft: '10px', } }
          />
        </div> }
        { data?.paGetAll?.pagination.total > 10 && <PaginationSimple count={ Math.ceil(data.paGetAll.pagination.total / 10) } onChange={ handlePaginate } page={ offs ? Math.round((offs + 10) / 10) : 1 } /> }
        { data.paGetAll.items.length < 1 && <NoContracts title="Нет подходящих проектов по выбранным критериям, попробуйте изменить фильтры поиска" /> }
      </ul>
    )


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    error,
    loading,
    offs,
    page,
  ])

  useEffect(() => {
    refetch()
  }, [
    updateStatus,
    updateApplys,
    refetch,
  ])


  return (applications)
}

export default Themes
