import React from 'react'
import './PortfolioRating.less'
import HistoryLinks from '../../common/HistoryLinks/HistoryLinks'
import StudentGeneralRate from './StudentGeneralRate/StudentGeneralRate'
import RatingFilters from './RatingFilters/RatingFilters'
import RatingTable from './RatingTable/RatingTable'
import useGetRole from '../../../redux/hooks/common/useGetRole'
import { URL, } from '../../../Utils/constants'

function PortfolioRating () {
  const role = useGetRole()

  return (
    <div className="InnerPage allProjects Rating" >
      <HistoryLinks links={ [
        {
          value: 'Главная',
          path: '/',
        },
      ] } loc="Рейтинг обучающихся" />
      <div className="studentInner scroll">
        <h2 className="profileTitle">
          Рейтинг обучающихся
          { role === 'urtos-moder' && (
            <div
              onClick={ () => window.open(`https://${ URL }/api/portfolio/all`) }
            >
              Выгрузить в Excel
              <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.859023 1.87722L13.429 0.0822151C13.5 0.0720385 13.5723 0.0772309 13.641 0.0974403C13.7098 0.11765 13.7734 0.152404 13.8275 0.199348C13.8817 0.246291 13.9251 0.304327 13.9549 0.369523C13.9846 0.434718 14 0.505549 14 0.577215V21.4232C14 21.4948 13.9846 21.5655 13.9549 21.6306C13.9252 21.6957 13.8819 21.7537 13.8279 21.8007C13.7738 21.8476 13.7103 21.8824 13.6417 21.9026C13.5731 21.9229 13.5009 21.9282 13.43 21.9182L0.858024 20.1232C0.619642 20.0893 0.401516 19.9704 0.243712 19.7886C0.0859086 19.6067 -0.000974252 19.374 -0.000976562 19.1332V2.86722C-0.000974252 2.62643 0.0859086 2.39373 0.243712 2.21186C0.401516 2.02999 0.619642 1.91117 0.858024 1.87722H0.859023ZM2.00002 3.73522V18.2652L12 19.6942V2.30622L2.00002 3.73522ZM15 18.0002H18V4.00022H15V2.00022H19C19.2652 2.00022 19.5196 2.10557 19.7071 2.29311C19.8947 2.48064 20 2.735 20 3.00022V19.0002C20 19.2654 19.8947 19.5198 19.7071 19.7073C19.5196 19.8949 19.2652 20.0002 19 20.0002H15V18.0002ZM8.20002 11.0002L11 15.0002H8.60002L7.00002 12.7142L5.40002 15.0002H3.00002L5.80002 11.0002L3.00002 7.00022H5.40002L7.00002 9.28622L8.60002 7.00022H11L8.20002 11.0002Z" fill="#7671DD" />
              </svg>
            </div>
          ) }
        </h2>
        { /^student/.test(role) && <StudentGeneralRate /> }
        <RatingFilters />
        <RatingTable />
      </div>
    </div>
  )
}

export default PortfolioRating
