// TODO: Clear ts-ignore

import {
  IconAlertCircle,
  IconBook,
  IconBulb,
  IconFileInvoice,
  IconMedal,
  IconNews,
  IconNotes,
  IconPlayerPlay,
  IconSchool,
  IconShield,
  IconTrendingUp,
  IconTrophy,
  IconUser,
  IconUsers,
  IconWallet,
  IconWorld,
  IconSquareCheck,
} from '@tabler/icons'
import { format, } from 'date-fns'
import { useEffect, useState, } from 'react'
import { useEcSubscription, } from '../../gql/hooks/effectiveContractHooks/useEcSubscription'
import { useGetEcListData, } from '../../gql/hooks/effectiveContractHooks/useEffectiveContract'
import { useUserInfo, } from '../../gql/hooks/useME'
import ButtonSimple from '../common/ButtonSimple/ButtonSimple'
import CalendarSimple from '../common/CalendarSimple/CalendarSimple'
import CollapseContainer2, { AccordionInnder, } from '../common/CollapseContainer2/CollapseContainer2'
import FilterDropDown from '../common/FilterDropDown/FilterDropDown'
import Popup from '../common/Popup/Popup'
import HistoryLinks from '../common/HistoryLinks/HistoryLinks'
import PopupContentReject from './popupContent/PopupContentReject/PopupContentReject'
import TableContainer from './TableContainer/TableContainer'

import {
  appealTableSource as headerFields_1_1,
} from './constants/tablesFormData/table_1_1'
import {
  appealTableSource as headerFields_1_11,
} from './constants/tablesFormData/table_1_11'
import {
  appealTableSource as headerFields_1_12,
} from './constants/tablesFormData/table_1_12'
import {
  appealTableSource as headerFields_1_2,
} from './constants/tablesFormData/table_1_2'
import {
  appealTableSource as headerFields_1_3,
} from './constants/tablesFormData/table_1_3'
import {
  appealTableSource as headerFields_1_4,
} from './constants/tablesFormData/table_1_4'
import {
  appealTableSource as headerFields_1_41,
} from './constants/tablesFormData/table_1_41'
import {
  appealTableSource as headerFields_1_5,
} from './constants/tablesFormData/table_1_5'
import {
  appealTableSource as headerFields_2_1,
} from './constants/tablesFormData/table_2_1'
import {
  appealTableSource as headerFields_2_2,
} from './constants/tablesFormData/table_2_2'
import {
  appealTableSource as headerFields_2_3,
} from './constants/tablesFormData/table_2_3'
import {
  appealTableSource as headerFields_2_4,
} from './constants/tablesFormData/table_2_4'
import {
  appealTableSource as headerFields_2_41,
} from './constants/tablesFormData/table_2_41'
import {
  appealTableSource as headerFields_2_5,
} from './constants/tablesFormData/table_2_5'
import {
  appealTableSource as headerFields_2_6,
} from './constants/tablesFormData/table_2_6'
import {
  appealTableSource as headerFields_2_7,
} from './constants/tablesFormData/table_2_7'
import {
  appealTableSource as headerFields_2_8,
} from './constants/tablesFormData/table_2_8'
import {
  appealTableSource as headerFields_2_9,
} from './constants/tablesFormData/table_2_9'
import {
  appealTableSource as headerFields_2_10,
} from './constants/tablesFormData/table_2_10'
import {
  appealTableSource as headerFields_3_1,
} from './constants/tablesFormData/table_3_1'
import {
  appealTableSource as headerFields_3_2,
} from './constants/tablesFormData/table_3_2'
import {
  appealTableSource as headerFields_3_3,
} from './constants/tablesFormData/table_3_3'
import {
  appealTableSource as headerFields_3_31,
} from './constants/tablesFormData/table_3_31'
import {
  appealTableSource as headerFields_3_4,
} from './constants/tablesFormData/table_3_4'
// import {
//   headerAppealFields as headerFields_3_5,
// } from './TableContainer/tablesData/table_3_5';
import {
  appealTableSource as headerFields_4_1,
} from './constants/tablesFormData/table_4_1'

import POP_UP_APPEAL_CONTENT_1_1 from './pages/AdministratorTemplate/PopUpAppealContent/PopUpAppealContent_1_1'
import POP_UP_APPEAL_CONTENT_1_11 from './pages/AdministratorTemplate/PopUpAppealContent/PopUpAppealContent_1_11'
import POP_UP_APPEAL_CONTENT_1_2 from './pages/AdministratorTemplate/PopUpAppealContent/PopUpAppealContent_1_2'
import POP_UP_APPEAL_CONTENT_1_41 from './pages/AdministratorTemplate/PopUpAppealContent/PopUpAppealContent_1_41'
import POP_UP_APPEAL_CONTENT_2_1 from './pages/AdministratorTemplate/PopUpAppealContent/PopUpAppealContent_2_1'
import POP_UP_APPEAL_CONTENT_2_2 from './pages/AdministratorTemplate/PopUpAppealContent/PopUpAppealContent_2_2'
import POP_UP_APPEAL_CONTENT_2_4 from './pages/AdministratorTemplate/PopUpAppealContent/PopUpAppealContent_2_4'
import POP_UP_APPEAL_CONTENT_2_41 from './pages/AdministratorTemplate/PopUpAppealContent/PopUpAppealContent_2_41'
import POP_UP_APPEAL_CONTENT_2_5 from './pages/AdministratorTemplate/PopUpAppealContent/PopUpAppealContent_2_5'
import POP_UP_APPEAL_CONTENT_2_6 from './pages/AdministratorTemplate/PopUpAppealContent/PopUpAppealContent_2_6'
import POP_UP_APPEAL_CONTENT_2_8 from './pages/AdministratorTemplate/PopUpAppealContent/PopUpAppealContent_2_8'
import POP_UP_APPEAL_CONTENT_2_10 from './pages/AdministratorTemplate/PopUpAppealContent/PopUpAppealContent_2_10'
import POP_UP_APPEAL_CONTENT_3_1 from './pages/AdministratorTemplate/PopUpAppealContent/PopUpAppealContent_3_1'
import POP_UP_APPEAL_CONTENT_3_2 from './pages/AdministratorTemplate/PopUpAppealContent/PopUpAppealContent_3_2'
import POP_UP_APPEAL_CONTENT_3_3 from './pages/AdministratorTemplate/PopUpAppealContent/PopUpAppealContent_3_3'
import POP_UP_APPEAL_CONTENT_3_4 from './pages/AdministratorTemplate/PopUpAppealContent/PopUpAppealContent_3_4'
import POP_UP_APPEAL_CONTENT_4_1 from './pages/AdministratorTemplate/PopUpAppealContent/PopUpAppealContent_4_1'
import PaperContainerWrapper from '../common/PaperContainer/PaperContainerWrapper'
import { Divider, } from '@mui/material'
import POP_UP_APPEAL_CONTENT_1_4 from './pages/AdministratorTemplate/PopUpAppealContent/PopUpAppealContent_1_4'
import POP_UP_APPEAL_CONTENT_2_7 from './pages/AdministratorTemplate/PopUpAppealContent/PopUpAppealContent_2_7'
import POP_UP_APPEAL_CONTENT_1_3 from './pages/AdministratorTemplate/PopUpAppealContent/PopUpAppealContent_1_3'
import useUrlSearch from '../../hooks/useUrlSearch'
import { EcTableName, } from '@/legacy/components/EffectiveContract/constants'


const ModerateAppeals = () => {
  const [
    achievementType,
    setAchievementType,
  ] = useState('all')
  const [
    achievementStatus,
    setAchievementStatus,
  ] = useState('')
  const [
    achievementDate,
    setAchievementDate,
  ] = useState(null)

  const [
    isPopupOpen,
    setIsPopUpOpen,
  ] = useState(false)
  const [
    popUpType,
    setPopUpType,
  ] = useState(null)
  const [
    popUpData,
    setPopUpData,
  ] = useState<any>(null)

  const moderateId = useUrlSearch('id')

  const fullName = useUserInfo(moderateId)?.data?.userInfo?.full_name


  const queryTable_1_1 = useGetEcListData({
    dataType: 'appeal_1_1',
    user_id: moderateId,
    status: achievementStatus,
    date: achievementDate,
  })
  console.log(queryTable_1_1)
  const queryTable_1_11 = useGetEcListData({
    dataType: 'appeal_1_11',
    user_id: moderateId,
    status: achievementStatus,
    date: achievementDate,
  })
  const queryTable_1_12 = useGetEcListData({
    dataType: 'appeal_1_12',
    user_id: moderateId,
    status: achievementStatus,
    date: achievementDate,
  })
  const queryTable_1_2 = useGetEcListData({
    dataType: 'appeal_1_2',
    user_id: moderateId,
    status: achievementStatus,
    date: achievementDate,
  })
  const queryTable_1_3 = useGetEcListData({
    dataType: 'appeal_1_3',
    user_id: moderateId,
    status: achievementStatus,
    date: achievementDate,
  })
  const queryTable_1_4 = useGetEcListData({
    dataType: 'appeal_1_4',
    user_id: moderateId,
    status: achievementStatus,
    date: achievementDate,
  })
  const queryTable_1_41 = useGetEcListData({
    dataType: 'appeal_1_41',
    user_id: moderateId,
    status: achievementStatus,
    date: achievementDate,
  })
  const queryTable_1_5 = useGetEcListData({
    dataType: 'appeal_1_5',
    user_id: moderateId,
    status: achievementStatus,
    date: achievementDate,
  })
  const queryTable_2_1 = useGetEcListData({
    dataType: 'appeal_2_1',
    user_id: moderateId,
    status: achievementStatus,
    date: achievementDate,
  })
  const queryTable_2_2 = useGetEcListData({
    dataType: 'appeal_2_2',
    user_id: moderateId,
    status: achievementStatus,
    date: achievementDate,
  })
  const queryTable_2_3 = useGetEcListData({
    dataType: 'appeal_2_3',
    user_id: moderateId,
    status: achievementStatus,
    date: achievementDate,
  })
  const queryTable_2_4 = useGetEcListData({
    dataType: 'appeal_2_4',
    user_id: moderateId,
    status: achievementStatus,
    date: achievementDate,
  })
  const queryTable_2_41 = useGetEcListData({
    dataType: 'appeal_2_41',
    user_id: moderateId,
    status: achievementStatus,
    date: achievementDate,
  })
  const queryTable_2_5 = useGetEcListData({
    dataType: 'appeal_2_5',
    user_id: moderateId,
    status: achievementStatus,
    date: achievementDate,
  })
  const queryTable_2_6 = useGetEcListData({
    dataType: 'appeal_2_6',
    user_id: moderateId,
    status: achievementStatus,
    date: achievementDate,
  })
  const queryTable_2_7 = useGetEcListData({
    dataType: 'appeal_2_7',
    user_id: moderateId,
    status: achievementStatus,
    date: achievementDate,
  })
  const queryTable_2_8 = useGetEcListData({
    dataType: 'appeal_2_8',
    user_id: moderateId,
    status: achievementStatus,
    date: achievementDate,
  })
  const queryTable_2_9 = useGetEcListData({
    dataType: 'appeal_2_9',
    user_id: moderateId,
    status: achievementStatus,
    date: achievementDate,
  })
  const queryTable_2_10 = useGetEcListData({
    dataType: 'appeal_2_10',
    user_id: moderateId,
    status: achievementStatus,
    date: achievementDate,
  })
  const queryTable_3_1 = useGetEcListData({
    dataType: 'appeal_3_1',
    user_id: moderateId,
    status: achievementStatus,
    date: achievementDate,
  })
  const queryTable_3_2 = useGetEcListData({
    dataType: 'appeal_3_2',
    user_id: moderateId,
    status: achievementStatus,
    date: achievementDate,
  })
  const queryTable_3_3 = useGetEcListData({
    dataType: 'appeal_3_3',
    user_id: moderateId,
    status: achievementStatus,
    date: achievementDate,
  })
  const queryTable_3_31 = useGetEcListData({
    dataType: 'appeal_3_31',
    user_id: moderateId,
    status: achievementStatus,
    date: achievementDate,
  })
  const queryTable_3_4 = useGetEcListData({
    dataType: 'appeal_3_4',
    user_id: moderateId,
    status: achievementStatus,
    date: achievementDate,
  })
  const queryTable_3_5 = useGetEcListData({
    dataType: 'appeal_3_5',
    user_id: moderateId,
    status: achievementStatus,
    date: achievementDate,
  })
  const queryTable_4_1 = useGetEcListData({
    dataType: 'appeal_4_1',
    user_id: moderateId,
    status: achievementStatus,
    date: achievementDate,
  })

  const [
    table_1_1,
    setTable_1_1,
  ] = useState([])
  const [
    table_1_11,
    setTable_1_11,
  ] = useState([])
  const [
    table_1_12,
    setTable_1_12,
  ] = useState([])
  const [
    table_1_2,
    setTable_1_2,
  ] = useState([])
  const [
    table_1_3,
    setTable_1_3,
  ] = useState([])
  const [
    table_1_4,
    setTable_1_4,
  ] = useState([])
  const [
    table_1_41,
    setTable_1_41,
  ] = useState([])
  const [
    table_1_5,
    setTable_1_5,
  ] = useState([])

  const [
    table_2_1,
    setTable_2_1,
  ] = useState([])
  const [
    table_2_2,
    setTable_2_2,
  ] = useState([])
  const [
    table_2_3,
    setTable_2_3,
  ] = useState([])
  const [
    table_2_4,
    setTable_2_4,
  ] = useState([])
  const [
    table_2_41,
    setTable_2_41,
  ] = useState([])
  const [
    table_2_5,
    setTable_2_5,
  ] = useState([])
  const [
    table_2_6,
    setTable_2_6,
  ] = useState([])
  const [
    table_2_7,
    setTable_2_7,
  ] = useState([])
  const [
    table_2_8,
    setTable_2_8,
  ] = useState([])
  const [
    table_2_9,
    setTable_2_9,
  ] = useState([])
  const [
    table_2_10,
    setTable_2_10,
  ] = useState([])

  const [
    table_3_1,
    setTable_3_1,
  ] = useState([])
  const [
    table_3_2,
    setTable_3_2,
  ] = useState([])
  const [
    table_3_3,
    setTable_3_3,
  ] = useState([])
  const [
    table_3_31,
    setTable_3_31,
  ] = useState([])
  const [
    table_3_4,
    setTable_3_4,
  ] = useState([])
  // const [table_3_5, setTable_3_5] = useState([]);

  const [
    table_4_1,
    setTable_4_1,
  ] = useState([])


  useEffect(() => {
    if (queryTable_1_1.data?.ecListData) {
      setTable_1_1(
        queryTable_1_1.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            isAppeal: item.isAppeal,
            reason: item.reason,
            textAppeal: item.textAppeal,
            status: item.status,
            user_id: item.user_id,
            appealLinkId: item.appealLinkId,
            appealConclusion: item.appealConclusion,
          }
        }))
    }

    if (queryTable_1_11.data?.ecListData) {
      setTable_1_11(
        queryTable_1_11.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            isAppeal: item.isAppeal,
            reason: item.reason,
            textAppeal: item.textAppeal,
            user_id: item.user_id,
            appealLinkId: item.appealLinkId,
            status: item.status,
            appealConclusion: item.appealConclusion,
          }
        }))
    }
    if (queryTable_1_12.data?.ecListData) {
      setTable_1_12(
        queryTable_1_12.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            isAppeal: item.isAppeal,
            reason: item.reason,
            textAppeal: item.textAppeal,
            status: item.status,
            user_id: item.user_id,
            appealLinkId: item.appealLinkId,
            appealConclusion: item.appealConclusion,
          }
        }))
    }

    if (queryTable_1_2.data?.ecListData) {
      setTable_1_2(
        queryTable_1_2.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            isAppeal: item.isAppeal,
            reason: item.reason,
            textAppeal: item.textAppeal,
            status: item.status,
            user_id: item.user_id,
            appealLinkId: item.appealLinkId,
            appealConclusion: item.appealConclusion,
          }
        }))
    }

    if (queryTable_1_3.data?.ecListData) {
      setTable_1_3(
        queryTable_1_3.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            isAppeal: item.isAppeal,
            reason: item.reason,
            textAppeal: item.textAppeal,
            status: item.status,
            user_id: item.user_id,
            appealLinkId: item.appealLinkId,
            appealConclusion: item.appealConclusion,
          }
        }))
    }

    if (queryTable_1_4.data?.ecListData) {
      setTable_1_4(
        queryTable_1_4.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            isAppeal: item.isAppeal,
            reason: item.reason,
            textAppeal: item.textAppeal,
            status: item.status,
            user_id: item.user_id,
            appealLinkId: item.appealLinkId,
            appealConclusion: item.appealConclusion,
          }
        }))
    }

    if (queryTable_1_41.data?.ecListData) {
      setTable_1_41(
        queryTable_1_41.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            isAppeal: item.isAppeal,
            reason: item.reason,
            textAppeal: item.textAppeal,
            status: item.status,
            user_id: item.user_id,
            appealLinkId: item.appealLinkId,
            appealConclusion: item.appealConclusion,
          }
        }))
    }

    if (queryTable_1_5.data?.ecListData) {
      setTable_1_5(
        queryTable_1_5.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            isAppeal: item.isAppeal,
            reason: item.reason,
            textAppeal: item.textAppeal,
            status: item.status,
            user_id: item.user_id,
            appealLinkId: item.appealLinkId,
            appealConclusion: item.appealConclusion,
          }
        }))
    }

    if (queryTable_2_1.data?.ecListData) {
      setTable_2_1(
        queryTable_2_1.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            isAppeal: item.isAppeal,
            reason: item.reason,
            textAppeal: item.textAppeal,
            status: item.status,
            user_id: item.user_id,
            appealLinkId: item.appealLinkId,
            appealConclusion: item.appealConclusion,
          }
        }))
    }

    if (queryTable_2_2.data?.ecListData) {
      setTable_2_2(
        queryTable_2_2.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            isAppeal: item.isAppeal,
            reason: item.reason,
            textAppeal: item.textAppeal,
            status: item.status,
            user_id: item.user_id,
            appealLinkId: item.appealLinkId,
            appealConclusion: item.appealConclusion,
          }
        }))
    }

    if (queryTable_2_3.data?.ecListData) {
      setTable_2_3(
        queryTable_2_3.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            isAppeal: item.isAppeal,
            reason: item.reason,
            textAppeal: item.textAppeal,
            status: item.status,
            user_id: item.user_id,
            appealLinkId: item.appealLinkId,
            appealConclusion: item.appealConclusion,
          }
        }))
    }

    if (queryTable_2_4.data?.ecListData) {
      setTable_2_4(
        queryTable_2_4.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            isAppeal: item.isAppeal,
            reason: item.reason,
            textAppeal: item.textAppeal,
            status: item.status,
            user_id: item.user_id,
            appealLinkId: item.appealLinkId,
            appealConclusion: item.appealConclusion,
          }
        }))
    }

    if (queryTable_2_41.data?.ecListData) {
      setTable_2_41(
        queryTable_2_41.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            isAppeal: item.isAppeal,
            reason: item.reason,
            textAppeal: item.textAppeal,
            status: item.status,
            user_id: item.user_id,
            appealLinkId: item.appealLinkId,
            appealConclusion: item.appealConclusion,
          }
        }))
    }

    if (queryTable_2_5.data?.ecListData) {
      setTable_2_5(
        queryTable_2_5.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            isAppeal: item.isAppeal,
            reason: item.reason,
            textAppeal: item.textAppeal,
            status: item.status,
            user_id: item.user_id,
            appealLinkId: item.appealLinkId,
            appealConclusion: item.appealConclusion,
          }
        }))
    }

    if (queryTable_2_6.data?.ecListData) {
      setTable_2_6(
        queryTable_2_6.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            isAppeal: item.isAppeal,
            reason: item.reason,
            textAppeal: item.textAppeal,
            status: item.status,
            user_id: item.user_id,
            appealLinkId: item.appealLinkId,
            appealConclusion: item.appealConclusion,
          }
        }))
    }

    if (queryTable_2_7.data?.ecListData) {
      setTable_2_7(
        queryTable_2_7.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            isAppeal: item.isAppeal,
            reason: item.reason,
            textAppeal: item.textAppeal,
            status: item.status,
            user_id: item.user_id,
            appealLinkId: item.appealLinkId,
            appealConclusion: item.appealConclusion,
          }
        }))
    }

    if (queryTable_2_8.data?.ecListData) {
      setTable_2_8(
        queryTable_2_8.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            isAppeal: item.isAppeal,
            reason: item.reason,
            textAppeal: item.textAppeal,
            status: item.status,
            user_id: item.user_id,
            appealLinkId: item.appealLinkId,
            appealConclusion: item.appealConclusion,
          }
        }))
    }

    if (queryTable_2_9.data?.ecListData) {
      setTable_2_9(
        queryTable_2_9.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            isAppeal: item.isAppeal,
            reason: item.reason,
            textAppeal: item.textAppeal,
            status: item.status,
            user_id: item.user_id,
            appealLinkId: item.appealLinkId,
            appealConclusion: item.appealConclusion,
          }
        }))
    }

    if (queryTable_2_10.data?.ecListData) {
      setTable_2_10(
        queryTable_2_10.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            isAppeal: item.isAppeal,
            reason: item.reason,
            textAppeal: item.textAppeal,
            status: item.status,
            user_id: item.user_id,
            appealLinkId: item.appealLinkId,
            appealConclusion: item.appealConclusion,
          }
        }))
    }

    if (queryTable_3_1.data?.ecListData) {
      setTable_3_1(
        queryTable_3_1.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            isAppeal: item.isAppeal,
            reason: item.reason,
            textAppeal: item.textAppeal,
            status: item.status,
            user_id: item.user_id,
            appealLinkId: item.appealLinkId,
            appealConclusion: item.appealConclusion,
          }
        }))
    }

    if (queryTable_3_2.data?.ecListData) {
      setTable_3_2(
        queryTable_3_2.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            isAppeal: item.isAppeal,
            reason: item.reason,
            textAppeal: item.textAppeal,
            status: item.status,
            user_id: item.user_id,
            appealLinkId: item.appealLinkId,
            appealConclusion: item.appealConclusion,
          }
        }))
    }

    if (queryTable_3_3.data?.ecListData) {
      setTable_3_3(
        queryTable_3_3.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            isAppeal: item.isAppeal,
            reason: item.reason,
            textAppeal: item.textAppeal,
            status: item.status,
            user_id: item.user_id,
            appealLinkId: item.appealLinkId,
            appealConclusion: item.appealConclusion,
          }
        }))
    }

    if (queryTable_3_31.data?.ecListData) {
      setTable_3_31(
        queryTable_3_31.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            isAppeal: item.isAppeal,
            reason: item.reason,
            textAppeal: item.textAppeal,
            status: item.status,
            user_id: item.user_id,
            appealLinkId: item.appealLinkId,
            appealConclusion: item.appealConclusion,
          }
        }))
    }

    if (queryTable_3_4.data?.ecListData) {
      setTable_3_4(
        queryTable_3_4.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            isAppeal: item.isAppeal,
            reason: item.reason,
            textAppeal: item.textAppeal,
            status: item.status,
            user_id: item.user_id,
            appealLinkId: item.appealLinkId,
            appealConclusion: item.appealConclusion,
          }
        }))
    }

    // if (queryTable_3_5.data?.ecListData) {
    //   setTable_3_5(
    //     queryTable_3_5.data.ecListData.map(item => {
    //       return {
    //         ...item.data,
    //         rec_id: item.rec_id,
    //       };
    //     }));
    // }

    if (queryTable_4_1.data?.ecListData) {
      setTable_4_1(
        queryTable_4_1.data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            isAppeal: item.isAppeal,
            reason: item.reason,
            textAppeal: item.textAppeal,
            status: item.status,
            user_id: item.user_id,
            appealLinkId: item.appealLinkId,
            appealConclusion: item.appealConclusion,
          }
        }))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    queryTable_1_1.loading,
    queryTable_1_11.loading,
    queryTable_1_12.loading,
    queryTable_1_2.loading,
    queryTable_1_3.loading,
    queryTable_1_4.loading,
    queryTable_1_41.loading,
    queryTable_2_1.loading,
    queryTable_2_2.loading,
    queryTable_2_3.loading,
    queryTable_2_4.loading,
    queryTable_2_41.loading,
    queryTable_2_5.loading,
    queryTable_2_6.loading,
    queryTable_2_7.loading,
    queryTable_2_8.loading,
    queryTable_2_9.loading,
    queryTable_2_10.loading,
    queryTable_3_1.loading,
    queryTable_3_2.loading,
    queryTable_3_3.loading,
    queryTable_3_31.loading,
    queryTable_3_4.loading,
    queryTable_3_5.loading,
    queryTable_4_1.loading,

    queryTable_1_1.data,
    queryTable_1_11.data,
    queryTable_1_12.data,
    queryTable_1_2.data,
    queryTable_1_3.data,
    queryTable_1_4.data,
    queryTable_1_41.data,
    queryTable_2_1.data,
    queryTable_2_2.data,
    queryTable_2_3.data,
    queryTable_2_4.data,
    queryTable_2_41.data,
    queryTable_2_5.data,
    queryTable_2_6.data,
    queryTable_2_7.data,
    queryTable_2_8.data,
    queryTable_2_9.data,
    queryTable_2_10.data,
    queryTable_3_1.data,
    queryTable_3_2.data,
    queryTable_3_3.data,
    queryTable_3_31.data,
    queryTable_3_4.data,
    queryTable_3_5.data,
    queryTable_4_1.data,
  ])

  const ecSubscription = useEcSubscription()
  useEffect(() => {
    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'appeal_1_1') {
      queryTable_1_1.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'appeal_1_11') {
      queryTable_1_11.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'appeal_1_12') {
      queryTable_1_12.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'appeal_1_2') {
      queryTable_1_2.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'appeal_1_3') {
      queryTable_1_3.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'appeal_1_4') {
      queryTable_1_4.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'appeal_1_41') {
      queryTable_1_41.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'appeal_1_5') {
      queryTable_1_5.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'appeal_2_1') {
      queryTable_2_1.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'appeal_2_2') {
      queryTable_2_2.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'appeal_2_3') {
      queryTable_2_3.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'appeal_2_4') {
      queryTable_2_4.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'appeal_2_41') {
      queryTable_2_41.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'appeal_2_5') {
      queryTable_2_5.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'appeal_2_6') {
      queryTable_2_6.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'appeal_2_7') {
      queryTable_2_7.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'appeal_2_8') {
      queryTable_2_8.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'appeal_2_9') {
      queryTable_2_9.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'appeal_2_10') {
      queryTable_2_10.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'appeal_3_1') {
      queryTable_3_1.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'appeal_3_2') {
      queryTable_3_2.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'appeal_3_3') {
      queryTable_3_3.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'appeal_3_31') {
      queryTable_3_31.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'appeal_3_4') {
      queryTable_3_4.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'appeal_3_5') {
      queryTable_3_5.refetch()
    }

    if (ecSubscription?.data?.contractsChanged?.result?.dataType === 'appeal_4_1') {
      queryTable_4_1.refetch()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    ecSubscription.loading,
    ecSubscription.data,
  ])


  const popUpSelector = {
    displayRejectReason: <PopupContentReject title={ popUpData?.achievement } reason={ popUpData?.reason } onClickHandler={ () => setIsPopUpOpen(false) } />,
    displayAppealRejectReason: <PopupContentReject type='appeal' reason={ popUpData?.reason } onClickHandler={ () => setIsPopUpOpen(false) } />,

    appeal_1_1: <POP_UP_APPEAL_CONTENT_1_1 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
    appeal_1_11: <POP_UP_APPEAL_CONTENT_1_11 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
    appeal_1_2: <POP_UP_APPEAL_CONTENT_1_2 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
    appeal_1_3: <POP_UP_APPEAL_CONTENT_1_3 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
    appeal_1_4: <POP_UP_APPEAL_CONTENT_1_4 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
    appeal_1_41: <POP_UP_APPEAL_CONTENT_1_41 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
    appeal_2_1: <POP_UP_APPEAL_CONTENT_2_1 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
    appeal_2_2: <POP_UP_APPEAL_CONTENT_2_2 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
    appeal_2_3: <POP_UP_APPEAL_CONTENT_1_1 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
    appeal_2_4: <POP_UP_APPEAL_CONTENT_2_4 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
    appeal_2_41: <POP_UP_APPEAL_CONTENT_2_41 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
    appeal_2_5: <POP_UP_APPEAL_CONTENT_2_5 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
    appeal_2_6: <POP_UP_APPEAL_CONTENT_2_6 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
    appeal_2_7: <POP_UP_APPEAL_CONTENT_2_7 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
    appeal_2_8: <POP_UP_APPEAL_CONTENT_2_8 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
    appeal_2_10: <POP_UP_APPEAL_CONTENT_2_10 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
    appeal_3_1: <POP_UP_APPEAL_CONTENT_3_1 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
    appeal_3_2: <POP_UP_APPEAL_CONTENT_3_2 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
    appeal_3_3: <POP_UP_APPEAL_CONTENT_3_3 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
    appeal_3_4: <POP_UP_APPEAL_CONTENT_3_4 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
    appeal_4_1: <POP_UP_APPEAL_CONTENT_4_1 data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
  }

  return (
    <div className="effective-contract">
      <HistoryLinks
        links={ [
          {
            value: 'Главная',
            path: '/',
          },
          // { value: "Эффективный контракт", path: EFFECTIVE_CONTRACT_MODERATE_ACHIEVEMENT_ROUTE },
        ] }
        loc="Модерация апелляций"
      />

      <h2 className="effective-contract__title">
        Модерация апелляций: { fullName }
      </h2>

      <CollapseContainer2 styles={ { marginBottom: '2rem', } }>
        <AccordionInnder
          headerText={ (
            <span className="effective-contract__subtitle">
              Фильтрация апелляций
            </span>
          ) }
        >
          <Filters
            setAchievementType={ setAchievementType }
            setAchievementStatus={ setAchievementStatus }
            setAchievementDate={ setAchievementDate }
          />
        </AccordionInnder>
      </CollapseContainer2>


      { (
        table_1_1.length > 0
        || table_1_11.length > 0
        || table_1_12.length > 0
        || table_1_2.length > 0
        || table_1_3.length > 0
        || table_1_4.length > 0
        || table_1_41.length > 0
        || table_1_5.length > 0
      )
        && (
          achievementType === 'table_1_1'
          || achievementType === 'table_1_11'
          || achievementType === 'table_1_12'
          || achievementType === 'table_1_2'
          || achievementType === 'table_1_3'
          || achievementType === 'table_1_4'
          || achievementType === 'table_1_41'
          || achievementType === 'table_1_5'
          || achievementType === 'all'
        )
        && (
          <h3 className="effective-contract-add-chievement__subtitle">
            Базовые показатели
          </h3>
        ) }


      { (table_1_1.length > 0 || table_1_11.length > 0 || table_1_12.length > 0) && (achievementType === 'table_1_1' || achievementType === 'all') ? (
        <AccordionInnder
          Icon={ IconBook }
          headerText="Учебно-методическое обеспечение образовательного процесса (показатель качества)"
        >
          <PaperContainerWrapper
            style={ { width: '100%', } }
            variant="sub-paper"
          >
            <AccordionInnder
              headerText="Учебные издания"
            >
              <TableContainer
                dataType={ EcTableName.APPEAL_1_1 }
                headerFields={ headerFields_1_1 }
                // @ts-ignore
                ecTableData={ table_1_1 }
                isAppealMode
                // popUpControllHandler={ () => {
                //   // @ts-expect-error TS(2345): Argument of type '"appeal_1_1"' is not assignable ... Remove this comment to see the full error message
                //   setPopUpType('appeal_1_1')
                //   setIsPopUpOpen(!isPopupOpen)
                // } }
                // getRowData={ (answer: any) => setPopUpData(answer) }
                // extra={ {
                //   returnDataForDisplayRejectReason: (row: any) => {
                //     // @ts-expect-error TS(2345): Argument of type '"displayAppealRejectReason"' is ... Remove this comment to see the full error message
                //     setPopUpType('displayAppealRejectReason')
                //     setIsPopUpOpen(true)
                //     setPopUpData({
                //       reason: row.appealConclusion,
                //     })
                //   },
                // } }
              />
            </AccordionInnder>
          </PaperContainerWrapper>

          <PaperContainerWrapper
            style={ { width: '100%', } }
            variant="sub-paper"
          >
            <AccordionInnder
              headerText="Moodle"
            >
              <TableContainer
                dataType={ EcTableName.APPEAL_1_11 }
                headerFields={ headerFields_1_11 }
                // @ts-ignore
                ecTableData={ table_1_11 }
                isAppealMode
              // popUpControllHandler={ () => {
              //   // @ts-expect-error TS(2345): Argument of type '"appeal_1_11"' is not assignable... Remove this comment to see the full error message
              //   setPopUpType('appeal_1_11')
              //   setIsPopUpOpen(!isPopupOpen)
              // } }
              // getRowData={ (answer: any) => setPopUpData(answer) }
              // extra={ {
              //   returnDataForDisplayRejectReason: (row: any) => {
              //     // @ts-expect-error TS(2345): Argument of type '"displayAppealRejectReason"' is ... Remove this comment to see the full error message
              //     setPopUpType('displayAppealRejectReason')
              //     setIsPopUpOpen(true)
              //     setPopUpData({
              //       reason: row.appealConclusion,
              //     })
              //   },
              // } }
              />
            </AccordionInnder>
          </PaperContainerWrapper>
          <PaperContainerWrapper
            style={ { width: '100%', } }
            variant="sub-paper"
          >
            <AccordionInnder
              headerText="Дневник.ру"
            >
              <TableContainer
                dataType={ EcTableName.APPEAL_1_12 }
                headerFields={ headerFields_1_12 }
                // @ts-ignore
                ecTableData={ table_1_12 }
                isAppealMode={true}
              />
            </AccordionInnder>
          </PaperContainerWrapper>
        </AccordionInnder>
      ) : false }


      { table_1_2.length > 0 && (achievementType === 'table_1_2' || achievementType === 'all') ? (
        <AccordionInnder
          Icon={ IconTrendingUp }
          headerText="Плановое повышение квалификации (показатель качества)"
        >
          <TableContainer
            dataType={ EcTableName.APPEAL_1_2 }
            headerFields={ headerFields_1_2 }
            // @ts-ignore
            ecTableData={ table_1_2 }
            isAppealMode
          // popUpControllHandler={ () => {
          //   // @ts-expect-error TS(2345): Argument of type '"appeal_1_2"' is not assignable ... Remove this comment to see the full error message
          //   setPopUpType('appeal_1_2')
          //   setIsPopUpOpen(!isPopupOpen)
          // } }
          // getRowData={ (answer: any) => setPopUpData(answer) }
          // extra={ {
          //   returnDataForDisplayRejectReason: (row: any) => {
          //     // @ts-expect-error TS(2345): Argument of type '"displayAppealRejectReason"' is ... Remove this comment to see the full error message
          //     setPopUpType('displayAppealRejectReason')
          //     setIsPopUpOpen(true)
          //     setPopUpData({
          //       reason: row.appealConclusion,
          //     })
          //   },
          // } }
          />
        </AccordionInnder>
      ) : false }


      { table_1_3.length > 0 && (achievementType === 'table_1_3' || achievementType === 'all') ? (
        <AccordionInnder
          Icon={ IconUser }
          headerText="Участие в профориентационных мероприятиях, организованных кафедрой, институтом/факультетом, Университетом (показатель качества)"
        >
          <TableContainer
            dataType={ EcTableName.APPEAL_1_3 }
            headerFields={ headerFields_1_3 }
            // @ts-ignore
            ecTableData={ table_1_3 }
            isAppealMode
          // popUpControllHandler={ () => {
          //   // @ts-expect-error TS(2345): Argument of type '"appeal_1_3"' is not assignable ... Remove this comment to see the full error message
          //   setPopUpType('appeal_1_3')
          //   setIsPopUpOpen(!isPopupOpen)
          // } }
          // getRowData={ (answer: any) => setPopUpData(answer) }
          // extra={ {
          //   returnDataForDisplayRejectReason: (row: any) => {
          //     // @ts-expect-error TS(2345): Argument of type '"displayAppealRejectReason"' is ... Remove this comment to see the full error message
          //     setPopUpType('displayAppealRejectReason')
          //     setIsPopUpOpen(true)
          //     setPopUpData({
          //       reason: row.appealConclusion,
          //     })
          //   },
          // } }
          />
        </AccordionInnder>
      ) : false }


      { (table_1_4.length > 0 || table_1_41.length > 0) && (achievementType === 'table_1_4' || achievementType === 'all') ? (
        <AccordionInnder
          Icon={ IconBulb }
          headerText="Участие в научно-исследовательской работе по профилю кафедры, института (факультета) и(или) преподаваемого курса, дисциплины (модуля) (показатель интенсивности)"
        >
          { table_1_4.length > 0 && (
            <>
              <TableContainer
                dataType={ EcTableName.APPEAL_1_4 }
                headerFields={ headerFields_1_4 }
                // @ts-ignore
                ecTableData={ table_1_4 }
                isAppealMode
              // popUpControllHandler={ () => {
              //   // @ts-expect-error TS(2345): Argument of type '"appeal_1_4"' is not assignable ... Remove this comment to see the full error message
              //   setPopUpType('appeal_1_4')
              //   setIsPopUpOpen(!isPopupOpen)
              // } }
              // getRowData={ (answer: any) => setPopUpData(answer) }
              // extra={ {
              //   returnDataForDisplayRejectReason: (row: any) => {
              //     // @ts-expect-error TS(2345): Argument of type '"displayAppealRejectReason"' is ... Remove this comment to see the full error message
              //     setPopUpType('displayAppealRejectReason')
              //     setIsPopUpOpen(true)
              //     setPopUpData({
              //       reason: row.appealConclusion,
              //     })
              //   },
              // } }
              />

              <Divider /><br />
            </>
          ) }

          { table_1_41.length > 0 && (
            <PaperContainerWrapper
              style={ { width: '100%', } }
              variant="sub-paper"
            >
              <AccordionInnder
                headerText="Программы магистратуры / аспирантуры"
              >
                <TableContainer
                  dataType={ EcTableName.APPEAL_1_41 }
                  headerFields={ headerFields_1_41 }
                  // @ts-ignore
                  ecTableData={ table_1_41 }
                  isAppealMode
                // popUpControllHandler={ () => {
                //   // @ts-expect-error TS(2345): Argument of type '"appeal_1_41"' is not assignable... Remove this comment to see the full error message
                //   setPopUpType('appeal_1_41')
                //   setIsPopUpOpen(!isPopupOpen)
                // } }
                // getRowData={ (answer: any) => setPopUpData(answer) }
                // extra={ {
                //   returnDataForDisplayRejectReason: (row: any) => {
                //     // @ts-expect-error TS(2345): Argument of type '"displayAppealRejectReason"' is ... Remove this comment to see the full error message
                //     setPopUpType('displayAppealRejectReason')
                //     setIsPopUpOpen(true)
                //     setPopUpData({
                //       reason: row.appealConclusion,
                //     })
                //   },
                // } }
                />
              </AccordionInnder>
            </PaperContainerWrapper>
          ) }
        </AccordionInnder>
      ) : false }

      { table_1_5.length > 0 && (achievementType === 'table_1_5' || achievementType === 'all') ? (
        <AccordionInnder
          Icon={ IconMedal }
          headerText="Организация и участие в социально-воспитательных мероприятиях Университета (только для должностей учитель, преподаватель)"
        >
          <TableContainer
            dataType={ EcTableName.APPEAL_1_5 }
            headerFields={ headerFields_1_5 }
            // @ts-ignore
            ecTableData={ table_1_5 }
            isAppealMode
            // popUpControllHandler={ () => {
            //   // @ts-expect-error TS(2345): Argument of type '"appeal_1_3"' is not assignable ... Remove this comment to see the full error message
            //   setPopUpType('appeal_1_3')
            //   setIsPopUpOpen(!isPopupOpen)
            // } }
            // getRowData={ (answer: any) => setPopUpData(answer) }
            // extra={ {
            //   returnDataForDisplayRejectReason: (row: any) => {
            //     // @ts-expect-error TS(2345): Argument of type '"displayAppealRejectReason"' is ... Remove this comment to see the full error message
            //     setPopUpType('displayAppealRejectReason')
            //     setIsPopUpOpen(true)
            //     setPopUpData({
            //       reason: row.appealConclusion,
            //     })
            //   },
            // } }
          />
        </AccordionInnder>
      ) : false }

      { (
        table_2_1.length > 0
        || table_2_2.length > 0
        || table_2_3.length > 0
        || table_2_4.length > 0
        || table_2_41.length > 0
        || table_2_5.length > 0
        || table_2_6.length > 0
        || table_2_7.length > 0
        || table_2_8.length > 0
        || table_2_9.length > 0
        || table_2_10.length > 0
      )
        && (
          achievementType === 'table_2_1'
          || achievementType === 'table_2_2'
          || achievementType === 'table_2_3'
          || achievementType === 'table_2_4'
          || achievementType === 'table_2_41'
          || achievementType === 'table_2_5'
          || achievementType === 'table_2_6'
          || achievementType === 'table_2_7'
          || achievementType === 'table_2_8'
          || achievementType === 'table_2_9'
          || achievementType === 'table_2_10'
          || achievementType === 'all'
        )
        && (
          <h3 className="effective-contract-add-chievement__subtitle">
            Показатели высокой эффективности труда
          </h3>
        ) }


      { table_2_1.length > 0 && (achievementType === 'table_2_1' || achievementType === 'all') ? (
        <AccordionInnder
          Icon={ IconWallet }
          headerText="Государственные премии"
        >
          <TableContainer
            dataType={ EcTableName.APPEAL_2_1 }
            headerFields={ headerFields_2_1 }
            // @ts-ignore
            ecTableData={ table_2_1 }
            isAppealMode
          // popUpControllHandler={ () => {
          //   // @ts-expect-error TS(2345): Argument of type '"appeal_2_1"' is not assignable ... Remove this comment to see the full error message
          //   setPopUpType('appeal_2_1')
          //   setIsPopUpOpen(!isPopupOpen)
          // } }
          // getRowData={ (answer: any) => setPopUpData(answer) }
          // extra={ {
          //   returnDataForDisplayRejectReason: (row: any) => {
          //     // @ts-expect-error TS(2345): Argument of type '"displayAppealRejectReason"' is ... Remove this comment to see the full error message
          //     setPopUpType('displayAppealRejectReason')
          //     setIsPopUpOpen(true)
          //     setPopUpData({
          //       reason: row.appealConclusion,
          //     })
          //   },
          // } }
          />
        </AccordionInnder>
      ) : false }


      { table_2_2.length > 0 && (achievementType === 'table_2_2' || achievementType === 'all') ? (
        <AccordionInnder
          Icon={ IconWorld }
          headerText="Владение иностранным языком"
        >
          <TableContainer
            dataType={ EcTableName.APPEAL_2_2 }
            headerFields={ headerFields_2_2 }
            // @ts-ignore
            ecTableData={ table_2_2 }
            isAppealMode
          // popUpControllHandler={ () => {
          //   // @ts-expect-error TS(2345): Argument of type '"appeal_2_2"' is not assignable ... Remove this comment to see the full error message
          //   setPopUpType('appeal_2_2')
          //   setIsPopUpOpen(!isPopupOpen)
          // } }
          // getRowData={ (answer: any) => setPopUpData(answer) }
          // extra={ {
          //   returnDataForDisplayRejectReason: (row: any) => {
          //     // @ts-expect-error TS(2345): Argument of type '"displayAppealRejectReason"' is ... Remove this comment to see the full error message
          //     setPopUpType('displayAppealRejectReason')
          //     setIsPopUpOpen(true)
          //     setPopUpData({
          //       reason: row.appealConclusion,
          //     })
          //   },
          // } }
          />
        </AccordionInnder>
      ) : false }


      { table_2_3.length > 0 && (achievementType === 'table_2_3' || achievementType === 'all') ? (
        <AccordionInnder
          Icon={ IconBook }
          headerText="Издание учебной и научной литературы"
        >
          <TableContainer
            dataType={ EcTableName.APPEAL_2_3 }
            headerFields={ headerFields_2_3 }
            // @ts-ignore
            ecTableData={ table_2_3 }
            isAppealMode
          // popUpControllHandler={ () => {
          //   // @ts-expect-error TS(2345): Argument of type '"appeal_2_3"' is not assignable ... Remove this comment to see the full error message
          //   setPopUpType('appeal_2_3')
          //   setIsPopUpOpen(!isPopupOpen)
          // } }
          // getRowData={ (answer: any) => setPopUpData(answer) }
          // extra={ {
          //   returnDataForDisplayRejectReason: (row: any) => {
          //     // @ts-expect-error TS(2345): Argument of type '"displayAppealRejectReason"' is ... Remove this comment to see the full error message
          //     setPopUpType('displayAppealRejectReason')
          //     setIsPopUpOpen(true)
          //     setPopUpData({
          //       reason: row.appealConclusion,
          //     })
          //   },
          // } }
          />
        </AccordionInnder>
      ) : false }


      { (table_2_4.length > 0 || table_2_41.length > 0)
        && (achievementType === 'table_2_4' || achievementType === 'all')
        && (
          <AccordionInnder
            Icon={ IconPlayerPlay }
            headerText="Издание мультимедийной учебной литературы и (или) разработка нового онлайн-курса по программам ВО и (или) ДО (показатель интенсивности)"
          >
            { table_2_4.length > 0 && (
              <TableContainer
                dataType={ EcTableName.APPEAL_2_4 }
                headerFields={ headerFields_2_4 }
                // @ts-ignore
                ecTableData={ table_2_4 }
                isAppealMode
              // popUpControllHandler={ () => {
              //   // @ts-expect-error TS(2345): Argument of type '"appeal_2_4"' is not assignable ... Remove this comment to see the full error message
              //   setPopUpType('appeal_2_4')
              //   setIsPopUpOpen(!isPopupOpen)
              // } }
              // getRowData={ (answer: any) => setPopUpData(answer) }
              // extra={ {
              //   returnDataForDisplayRejectReason: (row: any) => {
              //     // @ts-expect-error TS(2345): Argument of type '"displayAppealRejectReason"' is ... Remove this comment to see the full error message
              //     setPopUpType('displayAppealRejectReason')
              //     setIsPopUpOpen(true)
              //     setPopUpData({
              //       reason: row.appealConclusion,
              //     })
              //   },
              // } }
              />
            ) }

            { table_2_41.length > 0 && (
              <TableContainer
                dataType={ EcTableName.APPEAL_2_41 }
                headerFields={ headerFields_2_41 }
                // @ts-ignore
                ecTableData={ table_2_41 }
                isAppealMode
              // popUpControllHandler={ () => {
              //   // @ts-expect-error TS(2345): Argument of type '"appeal_2_41"' is not assignable... Remove this comment to see the full error message
              //   setPopUpType('appeal_2_41')
              //   setIsPopUpOpen(!isPopupOpen)
              // } }
              // getRowData={ (answer: any) => setPopUpData(answer) }
              // extra={ {
              //   returnDataForDisplayRejectReason: (row: any) => {
              //     // @ts-expect-error TS(2345): Argument of type '"displayAppealRejectReason"' is ... Remove this comment to see the full error message
              //     setPopUpType('displayAppealRejectReason')
              //     setIsPopUpOpen(true)
              //     setPopUpData({
              //       reason: row.appealConclusion,
              //     })
              //   },
              // } }
              />
            ) }
          </AccordionInnder>
        ) }


      { table_2_5.length > 0 && (achievementType === 'table_2_5' || achievementType === 'all') ? (
        <AccordionInnder
          Icon={ IconUsers }
          headerText="Подготовка студентов-победителей и призеров олимпиад/конкурсов"
        >
          <TableContainer
            dataType={ EcTableName.APPEAL_2_5 }
            headerFields={ headerFields_2_5 }
            // @ts-ignore
            ecTableData={ table_2_5 }
            isAppealMode
          // popUpControllHandler={ () => {
          //   // @ts-expect-error TS(2345): Argument of type '"appeal_2_5"' is not assignable ... Remove this comment to see the full error message
          //   setPopUpType('appeal_2_5')
          //   setIsPopUpOpen(!isPopupOpen)
          // } }
          // getRowData={ (answer: any) => setPopUpData(answer) }
          // extra={ {
          //   returnDataForDisplayRejectReason: (row: any) => {
          //     // @ts-expect-error TS(2345): Argument of type '"displayAppealRejectReason"' is ... Remove this comment to see the full error message
          //     setPopUpType('displayAppealRejectReason')
          //     setIsPopUpOpen(true)
          //     setPopUpData({
          //       reason: row.appealConclusion,
          //     })
          //   },
          // } }
          />
        </AccordionInnder>
      ) : false }


      { table_2_6.length > 0 && (achievementType === 'table_2_6' || achievementType === 'all') ? (
        <AccordionInnder
          Icon={ IconSchool }
          headerText="Визит в партнерский университет для чтения курсов, в рамках образовательных программ принимающего университета"
        >
          <TableContainer
            dataType={ EcTableName.APPEAL_2_6 }
            headerFields={ headerFields_2_6 }
            // @ts-ignore
            ecTableData={ table_2_6 }
            isAppealMode
          // popUpControllHandler={ () => {
          //   // @ts-expect-error TS(2345): Argument of type '"appeal_2_6"' is not assignable ... Remove this comment to see the full error message
          //   setPopUpType('appeal_2_6')
          //   setIsPopUpOpen(!isPopupOpen)
          // } }
          // getRowData={ (answer: any) => setPopUpData(answer) }
          // extra={ {
          //   returnDataForDisplayRejectReason: (row: any) => {
          //     // @ts-expect-error TS(2345): Argument of type '"displayAppealRejectReason"' is ... Remove this comment to see the full error message
          //     setPopUpType('displayAppealRejectReason')
          //     setIsPopUpOpen(true)
          //     setPopUpData({
          //       reason: row.appealConclusion,
          //     })
          //   },
          // } }
          />
        </AccordionInnder>
      ) : false }


      { table_2_7.length > 0 && (achievementType === 'table_2_7' || achievementType === 'all') ? (
        <AccordionInnder
          Icon={ IconNotes }
          headerText="Публикации  в изданиях,  индексируемых  в базах данных  «Web of Science»,  «Scopus»"
        >
          <TableContainer
            dataType={ EcTableName.APPEAL_2_7 }
            headerFields={ headerFields_2_7 }
            // @ts-ignore
            ecTableData={ table_2_7 }
            isAppealMode
          // popUpControllHandler={ () => {
          //   // @ts-expect-error TS(2345): Argument of type '"appeal_2_7"' is not assignable ... Remove this comment to see the full error message
          //   setPopUpType('appeal_2_7')
          //   setIsPopUpOpen(!isPopupOpen)
          // } }
          // getRowData={ (answer: any) => setPopUpData(answer) }
          // extra={ {
          //   returnDataForDisplayRejectReason: (row: any) => {
          //     // @ts-expect-error TS(2345): Argument of type '"displayAppealRejectReason"' is ... Remove this comment to see the full error message
          //     setPopUpType('displayAppealRejectReason')
          //     setIsPopUpOpen(true)
          //     setPopUpData({
          //       reason: row.appealConclusion,
          //     })
          //   },
          // } }
          />
        </AccordionInnder>
      ) : false }


      { table_2_8.length > 0 && (achievementType === 'table_2_8' || achievementType === 'all') ? (
        <AccordionInnder
          Icon={ IconTrophy }
          headerText="Научные гранты и проекты университета, получившие финансовую поддержку"
        >
          <TableContainer
            dataType={ EcTableName.APPEAL_2_8 }
            headerFields={ headerFields_2_8 }
            // @ts-ignore
            ecTableData={ table_2_8 }
            isAppealMode
          // popUpControllHandler={ () => {
          //   // @ts-expect-error TS(2345): Argument of type '"appeal_2_8"' is not assignable ... Remove this comment to see the full error message
          //   setPopUpType('appeal_2_8')
          //   setIsPopUpOpen(!isPopupOpen)
          // } }
          // getRowData={ (answer: any) => setPopUpData(answer) }
          // extra={ {
          //   returnDataForDisplayRejectReason: (row: any) => {
          //     // @ts-expect-error TS(2345): Argument of type '"displayAppealRejectReason"' is ... Remove this comment to see the full error message
          //     setPopUpType('displayAppealRejectReason')
          //     setIsPopUpOpen(true)
          //     setPopUpData({
          //       reason: row.appealConclusion,
          //     })
          //   },
          // } }
          />
        </AccordionInnder>
      ) : false }

      { table_2_9.length > 0 && (achievementType === 'table_2_9' || achievementType === 'all') ? (
        <AccordionInnder
          Icon={ IconSquareCheck }
          headerText="Результаты инновационной деятельности, прошедшей утверждение на НТС университета"
        >
          <TableContainer
            dataType={ EcTableName.APPEAL_2_9 }
            headerFields={ headerFields_2_9 }
            // @ts-ignore
            ecTableData={ table_2_9 }
            isAppealMode
          // popUpControllHandler={ () => {
          //   // @ts-expect-error TS(2345): Argument of type '"appeal_2_8"' is not assignable ... Remove this comment to see the full error message
          //   setPopUpType('appeal_2_8')
          //   setIsPopUpOpen(!isPopupOpen)
          // } }
          // getRowData={ (answer: any) => setPopUpData(answer) }
          // extra={ {
          //   returnDataForDisplayRejectReason: (row: any) => {
          //     // @ts-expect-error TS(2345): Argument of type '"displayAppealRejectReason"' is ... Remove this comment to see the full error message
          //     setPopUpType('displayAppealRejectReason')
          //     setIsPopUpOpen(true)
          //     setPopUpData({
          //       reason: row.appealConclusion,
          //     })
          //   },
          // } }
          />
        </AccordionInnder>
      ) : false }
      { table_2_10.length > 0 && (achievementType === 'table_2_10' || achievementType === 'all') ? (
        <AccordionInnder
          Icon={ IconUser }
          headerText="Членство в составе редколлегий российских и зарубежных журналов WoS и/или Scopus, журналах из списка ВАК"
        >
          <TableContainer
            dataType={ EcTableName.APPEAL_2_10 }
            headerFields={ headerFields_2_10 }
            // @ts-ignore
            ecTableData={ table_2_10 }
            isAppealMode
          // popUpControllHandler={ () => {
          //   // @ts-expect-error TS(2345): Argument of type '"appeal_2_10"' is not assignable... Remove this comment to see the full error message
          //   setPopUpType('appeal_2_10')
          //   setIsPopUpOpen(!isPopupOpen)
          // } }
          // getRowData={ (answer: any) => setPopUpData(answer) }
          // extra={ {
          //   returnDataForDisplayRejectReason: (row: any) => {
          //     // @ts-expect-error TS(2345): Argument of type '"displayAppealRejectReason"' is ... Remove this comment to see the full error message
          //     setPopUpType('displayAppealRejectReason')
          //     setIsPopUpOpen(true)
          //     setPopUpData({
          //       reason: row.appealConclusion,
          //     })
          //   },
          // } }
          />
        </AccordionInnder>
      ) : false }


      { (
        table_3_1.length > 0
        || table_3_2.length > 0
        || table_3_3.length > 0
        || table_3_31.length > 0
        || table_3_4.length > 0
      )
        && (
          achievementType === 'table_3_1'
          || achievementType === 'table_3_2'
          || achievementType === 'table_3_3'
          || achievementType === 'table_3_31'
          || achievementType === 'table_3_4'
          || achievementType === 'all'
        )
        && (
          <h3 className="effective-contract-add-chievement__subtitle">
            Бонусные баллы
          </h3>
        ) }


      { table_3_1.length > 0 && (achievementType === 'table_3_1' || achievementType === 'all') ? (
        <AccordionInnder
          Icon={ IconShield }
          headerText="Диссертационная деятельность"
        >
          <TableContainer
            dataType={ EcTableName.APPEAL_3_1 }
            headerFields={ headerFields_3_1 }
            // @ts-ignore
            ecTableData={ table_3_1 }
            isAppealMode
          // popUpControllHandler={ () => {
          //   // @ts-expect-error TS(2345): Argument of type '"appeal_3_1"' is not assignable ... Remove this comment to see the full error message
          //   setPopUpType('appeal_3_1')
          //   setIsPopUpOpen(!isPopupOpen)
          // } }
          // getRowData={ (answer: any) => setPopUpData(answer) }
          // extra={ {
          //   returnDataForDisplayRejectReason: (row: any) => {
          //     // @ts-expect-error TS(2345): Argument of type '"displayAppealRejectReason"' is ... Remove this comment to see the full error message
          //     setPopUpType('displayAppealRejectReason')
          //     setIsPopUpOpen(true)
          //     setPopUpData({
          //       reason: row.appealConclusion,
          //     })
          //   },
          // } }
          />
        </AccordionInnder>
      ) : false }


      { table_3_2.length > 0 && (achievementType === 'table_3_2' || achievementType === 'all') ? (
        <AccordionInnder
          Icon={ IconMedal }
          headerText="Получение степени Phd, признаваемой в России"
        >
          <TableContainer
            dataType={ EcTableName.APPEAL_3_2 }
            headerFields={ headerFields_3_2 }
            // @ts-ignore
            ecTableData={ table_3_2 }
            isAppealMode
          // popUpControllHandler={ () => {
          //   // @ts-expect-error TS(2345): Argument of type '"appeal_3_2"' is not assignable ... Remove this comment to see the full error message
          //   setPopUpType('appeal_3_2')
          //   setIsPopUpOpen(!isPopupOpen)
          // } }
          // getRowData={ (answer: any) => setPopUpData(answer) }
          // extra={ {
          //   returnDataForDisplayRejectReason: (row: any) => {
          //     // @ts-expect-error TS(2345): Argument of type '"displayAppealRejectReason"' is ... Remove this comment to see the full error message
          //     setPopUpType('displayAppealRejectReason')
          //     setIsPopUpOpen(true)
          //     setPopUpData({
          //       reason: row.appealConclusion,
          //     })
          //   },
          // } }
          />
        </AccordionInnder>
      ) : false }


      { table_3_3.length > 0 || table_3_31.length > 0 && (achievementType === 'table_3_3' || achievementType === 'all') ? (
        <AccordionInnder
          Icon={ IconFileInvoice }
          headerText="Экспертная деятельность, членство в жюри, судейство федерального или международного уровней"
        >
          { table_3_3.length > 0 && (
            <>
              <TableContainer
                dataType={ EcTableName.APPEAL_3_3 }
                headerFields={ headerFields_3_3 }
                // @ts-ignore
                ecTableData={ table_3_3 }
                isAppealMode
                // popUpControllHandler={ () => {
                //   // @ts-expect-error TS(2345): Argument of type '"appeal_3_3"' is not assignable ... Remove this comment to see the full error message
                //   setPopUpType('appeal_3_3')
                //   setIsPopUpOpen(!isPopupOpen)
                // } }
                // getRowData={ (answer: any) => setPopUpData(answer) }
                // extra={ {
                //   returnDataForDisplayRejectReason: (row: any) => {
                //     // @ts-expect-error TS(2345): Argument of type '"displayAppealRejectReason"' is ... Remove this comment to see the full error message
                //     setPopUpType('displayAppealRejectReason')
                //     setIsPopUpOpen(true)
                //     setPopUpData({
                //       reason: row.appealConclusion,
                //     })
                //   },
                // } }
              />
              <Divider /><br />
            </>
          )

          }
          { table_3_31.length > 0 && (
            <PaperContainerWrapper
              style={ { width: '100%', } }
              variant="sub-paper"
            >
              <AccordionInnder
                headerText="Членство в жюри, судейство"
              >
                <TableContainer
                  dataType={ EcTableName.APPEAL_3_31 }
                  headerFields={ headerFields_3_31 }
                  // @ts-ignore
                  ecTableData={ table_3_31 }
                  isAppealMode
                  // popUpControllHandler={ () => {
                  //   // @ts-expect-error TS(2345): Argument of type '"appeal_3_3"' is not assignable ... Remove this comment to see the full error message
                  //   setPopUpType('appeal_3_3')
                  //   setIsPopUpOpen(!isPopupOpen)
                  // } }
                  // getRowData={ (answer: any) => setPopUpData(answer) }
                  // extra={ {
                  //   returnDataForDisplayRejectReason: (row: any) => {
                  //     // @ts-expect-error TS(2345): Argument of type '"displayAppealRejectReason"' is ... Remove this comment to see the full error message
                  //     setPopUpType('displayAppealRejectReason')
                  //     setIsPopUpOpen(true)
                  //     setPopUpData({
                  //       reason: row.appealConclusion,
                  //     })
                  //   },
                  // } }
                />
              </AccordionInnder>
            </PaperContainerWrapper>
          )

          }

        </AccordionInnder>
      ) : false }


      { table_3_4.length > 0 && (achievementType === 'table_3_4' || achievementType === 'all') ? (
        <AccordionInnder
          Icon={ IconNews }
          headerText="Участие в стратегических, инновационных и иных проектах Университета"
        >
          <TableContainer
            dataType={ EcTableName.APPEAL_3_4 }
            headerFields={ headerFields_3_4 }
            // @ts-ignore
            ecTableData={ table_3_4 }
            isAppealMode
          // popUpControllHandler={ () => {
          //   // @ts-expect-error TS(2345): Argument of type '"appeal_3_4"' is not assignable ... Remove this comment to see the full error message
          //   setPopUpType('appeal_3_4')
          //   setIsPopUpOpen(!isPopupOpen)
          // } }
          // getRowData={ (answer: any) => setPopUpData(answer) }
          // extra={ {
          //   returnDataForDisplayRejectReason: (row: any) => {
          //     // @ts-expect-error TS(2345): Argument of type '"displayAppealRejectReason"' is ... Remove this comment to see the full error message
          //     setPopUpType('displayAppealRejectReason')
          //     setIsPopUpOpen(true)
          //     setPopUpData({
          //       reason: row.appealConclusion,
          //     })
          //   },
          // } }
          />
        </AccordionInnder>
      ) : false }


      { (table_4_1.length > 0) && (achievementType === 'table_4_1' || achievementType === 'all') ? (
        <h3 className="effective-contract-add-chievement__subtitle">
          Основания для снижения выплат стимулирующего характера
        </h3>
      ) : false }


      { table_4_1.length > 0 ? (
        <AccordionInnder
          Icon={ IconAlertCircle }
          headerText="Неисполнение индивидуального плана развития"
        >
          <TableContainer
            dataType={ EcTableName.APPEAL_4_1 }
            headerFields={ headerFields_4_1 }
            // @ts-ignore
            ecTableData={ table_4_1 }
            isAppealMode
          // popUpControllHandler={ () => {
          //   // @ts-expect-error TS(2345): Argument of type '"appeal_4_1"' is not assignable ... Remove this comment to see the full error message
          //   setPopUpType('appeal_4_1')
          //   setIsPopUpOpen(!isPopupOpen)
          // } }
          // getRowData={ (answer: any) => setPopUpData(answer) }
          // extra={ {
          //   returnDataForDisplayRejectReason: (row: any) => {
          //     // @ts-expect-error TS(2345): Argument of type '"displayAppealRejectReason"' is ... Remove this comment to see the full error message
          //     setPopUpType('displayAppealRejectReason')
          //     setIsPopUpOpen(true)
          //     setPopUpData({
          //       reason: row.appealConclusion,
          //     })
          //   },
          // } }
          />
        </AccordionInnder>
      ) : false }


      <Popup
        isOpen={ isPopupOpen }
        handlePopup={ () => setIsPopUpOpen(false) }
        withScroll
      >
        {
          // @ts-expect-error TS(2538): Type 'null' cannot be used as an index type.
          popUpSelector[popUpType]
        }
      </Popup>
    </div>
  )
}


const Filters = ({
  setAchievementType,
  setAchievementStatus,
  setAchievementDate,
}: any) => {
  const achievementTypes = [
    {
      itemName: 'all',
      value: 'Все',
    },
    {
      itemName: 'table_1_1',
      value: 'Учебно - методическое обеспечение образовательного процесса',

    },
    {
      itemName: 'table_1_2',
      value: 'Плановое повышение квалификации',
    },
    {
      itemName: 'table_1_3',
      value: 'Участие в профориентационных мероприятиях, организованных кафедрой, институтом/факультетом, Университетом',
    },
    {
      itemName: 'table_1_4',
      value: 'Участие в научно-исследовательской работе по профилю кафедры, института (факультета) и(или) преподаваемого курса, дисциплины',
    },
    {
      itemName: 'table_2_1',
      value: 'Государственные премии',

    },
    {
      itemName: 'table_2_2',
      value: 'Владение иностранным языком',
    },
    {
      itemName: 'table_2_3',
      value: 'Издание учебной и научной литературы',
    },
    {
      itemName: 'table_2_4',
      value: 'Издание мультимедийной учебной литературы и (или) разработка нового онлайн-курса по программам ВО и (или) ДО',
    },
    {
      itemName: 'table_2_5',
      value: 'Подготовка студентов-победителей и призеров олимпиад/конкурсов',
    },
    {
      itemName: 'table_2_6',
      value: 'Визит в партнерский университет для чтения курсов, в рамках образовательных программ принимающего университета',
    },
    {
      itemName: 'table_2_7',
      value: 'Публикации  в изданиях,  индексируемых  в базах данных  «Web of Science»,  «Scopus»',
    },
    {
      itemName: 'table_2_8',
      value: 'Научные гранты и проекты университета, получившие финансовую поддержку',
    },
    {
      itemName: 'table_2_9',
      value: 'Результаты инновационной деятельности, прошедшей утверждение на НТС университета',
    },
    {
      itemName: 'table_2_10',
      value: 'Членство в составе редколлегий российских и зарубежных журналов WoS и/или Scopus, журналах из списка ВАК',
    },
    {
      itemName: 'table_3_1',
      value: 'Диссертационная деятельность',

    },
    {
      itemName: 'table_3_2',
      value: 'Получение степени PhD, признаваемой в России',
    },
    {
      itemName: 'table_3_3',
      value: 'Экспертная деятельность, членство в жюри, судейство федерального или международного уровней.',
    },
    {
      itemName: 'table_3_4',
      value: 'Участие в стратегических, инновационных и иных проектах университета',
    },
    // {
    //   itemName: 'table_3_5',
    //   value: 'Отдельные достижения, имеющие значимость для Университета',
    // },
    {
      itemName: 'table_4_1',
      value: 'Неисполнение индивидуального плана развития',
    },
  ]

  const achievementStatuses = [
    {
      itemName: '',
      value: 'Все',
    },
    {
      itemName: 'appealPending',
      value: 'Ожидает проверки',
    },
    {
      itemName: 'appealRejected',
      value: 'Отклонено',
    },
    {
      itemName: 'appealApproved',
      value: 'Подтверждено',
    },
  ]

  const [
    achievementTypeFilter,
    setAchievementTypeFilter,
  ] = useState('all')
  const [
    achievementStatusFilter,
    setAchievementStatusFilter,
  ] = useState('')
  const [
    achievementDateFilter,
    setAchievementDateFilter,
  ] = useState('')

  return (
    <div className="effective-contract__filters-wrapper">
      <FilterDropDown
        customWidth="36rem"
        maxWidthOfinput="33rem"
        defaultHint="Тип"
        items={ achievementTypes.map(type => type.value) }
        onClickHandler={ (answer: any) => {
          setAchievementTypeFilter(
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            achievementTypes.find(item => item.value === answer).itemName)
        } }
      />

      <FilterDropDown
        customWidth="21rem"
        defaultHint="Статус"
        items={ achievementStatuses.map(type => type.value) }
        onClickHandler={ (answer: any) => {
          setAchievementStatusFilter(
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            achievementStatuses.find(item => item.value === answer).itemName)
        } }
      />

      <CalendarSimple
        styles={ { width: '18rem', } }
        returnRange={ (range: any) => {
          setAchievementDateFilter({
            // @ts-expect-error TS(2345): Argument of type '{ from: string; to: string; }' i... Remove this comment to see the full error message
            from: format(new Date(range[0]), 'yyyy-MM-dd'),
            to: format(new Date(range[1]), 'yyyy-MM-dd'),
          })
        } }
      />

      <ButtonSimple
        value="Применить"
        variant="fill"
        onClickHandler={ () => {
          setAchievementType(achievementTypeFilter)
          setAchievementStatus(achievementStatusFilter)
          setAchievementDate(achievementDateFilter)
        } }
      />
    </div>
  )
}


export default ModerateAppeals
