import { useMemo, useState, } from 'react'
import useGetEcDocuments from '../../gql/hooks/effectiveContractHooks/useGetEcDocuments'
import useGetEcStages from '../../gql/hooks/effectiveContractHooks/useGetEcStages'
// import { EFFECTIVE_CONTRACT_MODERATE_ACHIEVEMENT_ROUTE, } from '../../Utils/routes';
import ButtonSimple from '../common/ButtonSimple/ButtonSimple'
import RadioButtonFacade from '../common/RadioButtonFacade/RadioButtonFacade'
import HistoryLinks from '../common/HistoryLinks/HistoryLinks'
import Documents from './EffectiveContractStages/Documents/Documents'
import Exports from './EffectiveContractStages/Exports/Exports'
import Stages from './EffectiveContractStages/Stages/Stages'


const EffectiveContractStages = () => {
  const [
    contentIndex,
    setContentIndex,
  ] = useState(0)

  const { stages, active_stage, } = useGetEcStages()
  const { documents, } = useGetEcDocuments()


  const buttons = [
    { text: 'Этапы эффективного контракта', },
    { text: 'Выгрузка отдельных показателей', },
    { text: 'Документы', },
  ]

  const mainContent = useMemo(() => [
    <Stages
      stages={ stages }
      active_stage={ active_stage }
    />,
    <Exports />,
    <Documents documents={ documents } />,
  ], [
    stages,
    active_stage,
    documents,
  ])

  return (
    <div className="effective-contract">
      <HistoryLinks
        links={ [
          {
            value: 'Главная',
            path: '/',
          },
          // { value: "Эффективный контракт", path: EFFECTIVE_CONTRACT_MODERATE_ACHIEVEMENT_ROUTE },
        ] }
        loc="Этапы эффективного контракта"
      />

      <h2 className="effective-contract__title">Этапы и выгрузки</h2>

      <div className="effective-contract__buttons-wrapper">
        <RadioButtonFacade
          content={ buttons }
          BtnComponent={ ButtonSimple }
          activeIndex={ contentIndex }
          setActiveIndex={ setContentIndex }
        />
      </div>

      { mainContent[contentIndex] }
    </div>
  )
}


export default EffectiveContractStages
