/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect, useCallback, } from 'react'
import './MyEducation.less'
import AllDisciplines from './AllDisciplines'
import { useDispatch, useSelector, } from 'react-redux'
import HistoryLinks from '../../../common/HistoryLinks/HistoryLinks'
import FilterDropDown from '../../../common/FilterDropDown/FilterDropDown'
// import RadioButtonFacade from '../../../common/RadioButtonFacade/RadioButtonFacade';
// import ButtonSimple from '../../../common/ButtonSimple/ButtonSimple';
/* import SemesterSchema from './SemesterSchema/SemesterSchema';
import AdditionalEducationSchema from './AdditionalEducationSchema/AdditionalEducationSchema'; */
// import { semester1, semester2, additionalEducation } from './mockData/mockData';
import useRedirect from '@/legacy/hooks/useRedirect'
import { setSelectedTabIndex, } from '@/legacy/redux/learningPathwaySlices/learningPathwaySlices'


/* const buttons = [
  {text: '1 семестр'},
  {text: '2 семестр'},
  {text: 'Дополнительное образование'},
];
 */


const MyEducation = () => {
  const redirect = useRedirect()

  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const studentInfo = useSelector(state => state.prof.studentInfo)
  const namesSpec = studentInfo.map((info: any) => info)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const ePlans = useSelector(state => state.myEd.ePlan)
  const [
    plansId,
    setPlansId,
  ] = useState([])
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const guid = useSelector(state => state.auth.me.data.guid)

  // const [contentIndex, setContentIndex] = useState(0);
  const [
    filters,
    setFilters,
  ] = useState({
    semester: [
      1,
      2,
      3,
      4,
      5,
      6,
      7,
    ],
    nameSpec: studentInfo[0],
    disciplineType: { plan: `(Б1.О)(.(\\d){1,2})?$|(Б1.В)(.(\\d){1,2})?$|(Б1.Б)(.(\\d){1,2})?$|(Б1.В.ОД)(.(\\d){1,2})?$`, },
  })
  const [
    offs,
    setOffs,
  ] = useState(0)


  useEffect(() => {
    if (ePlans[0]) {
      setPlansId(ePlans[0].plans.map((i: any) => i.ep_number))
    }
  }, [
    ePlans,
  ])

  const dispatch = useDispatch()

  const setSelectedTabHandler = useCallback((tabIndex: any) => {
    dispatch(setSelectedTabIndex(tabIndex))
  }, [
    dispatch,
  ])

  /* const mainConent = [
    <SemesterSchema cardContent={ semester1 }  />,
    <SemesterSchema cardContent={ semester2 } />,
    <AdditionalEducationSchema cardContent={ additionalEducation } />,
  ]; */

  const chooseDiscipline = useCallback((tabIndex: any) => {
    setSelectedTabHandler(tabIndex)
    redirect('/learning-pathway')
  }, [
    redirect,
    setSelectedTabHandler,
  ]
  )


  function handlePaginate (e: any, value: any) {
    setOffs(value * 20)
    const page = document.querySelector('.main-scroll-bar')?.firstChild
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    page.scrollTo(0, 0)
  }


  return (
    <div className="InnerPage my-education" >
      <HistoryLinks
        links={
          [
            {
              value: 'Главная',
              path: '/',
            },
            {
              value: 'Высшее образование',
              path: '/high-education',
            },
          ]
        }
        loc="Обязательные дисциплины"
      />

      <h2 className="my-education__title">Обязательные дисциплины</h2>

      <div className='my-education__navigation-wrapper'>
        <FilterDropDown
          items={ namesSpec }
          field="namespec"
          defaultHint={ namesSpec[0].namesSpec }
          onClickHandler={ (i: any) => setFilters({
            ...filters,
            nameSpec: i,
          }) }
          firstIsSelected
          customWidth="20%"
        />
        {/* <FilterDropDown
          items={[1, 2, 3, 4, 5, 6, 7, 8]}
          defaultHint="Выберите семестр"
          onClickHandler={(i) => setFilters({ ...filters, semester: [i] })}
          firstIsSelected
          customWidth="20%"
        /> */}

        {/*  <div className="my-education__buttons-wrapper">
          <RadioButtonFacade
            content={ buttons }
            BtnComponent={ ButtonSimple }
            activeIndex={ contentIndex }
            setActiveIndex={ setContentIndex }
          />
        </div> */}
      </div>

      <div className='my-education__main-content'>
        {/*  <FilterDropDown
          withBorder={true}
          field="name"
          items={[{ name: 'Дисциплины', plan: `(Б1.О)(.(\\d){1,2})?$|(Б1.В)(.(\\d){1,2})?$|(Б1.Б)(.(\\d){1,2})?$|(Б1.В.ОД)(.(\\d){1,2})?$` }, { name: 'Дисциплины по выбору(майнеры)', plan: `(Б1.О)(.(\\d){1,2})?$|(Б1.В)(.(\\d){1,2})?$|(Б1.Б)(.(\\d){1,2})?$|(Б1.В.ОД)(.(\\d){1,2})?$` }, { name: 'Дисциплины по выбору(элективы)', plan: `(Б1.О)(.(\\d){1,2})?$|(Б1.В)(.(\\d){1,2})?$|(Б1.Б)(.(\\d){1,2})?$|(Б1.В.ОД)(.(\\d){1,2})?$` }, { name: 'Факультативы', plan: `(Б1.О)(.(\\d){1,2})?$|(Б1.В)(.(\\d){1,2})?$|(Б1.Б)(.(\\d){1,2})?$|(Б1.В.ОД)(.(\\d){1,2})?$` }, { name: 'Все дисциплины', plan: `(Б1.О)(.(\\d){1,2})?$|(Б1.В)(.(\\d){1,2})?$|(Б1.Б)(.(\\d){1,2})?$|(Б1.В.ОД)(.(\\d){1,2})?$` }]}
          defaultHint="Все дисциплины"
          onClickHandler={(i) => setFilters({ ...filters, disciplineType: i })}
          firstIsSelected
          customWidth="30%"
        /> */}
        <AllDisciplines plansId={ plansId } filters={ filters }
          offs={ offs } chooseDiscipline={ chooseDiscipline } handlePaginate={ handlePaginate }
          stud={ filters.nameSpec }
        />
      </div>
    </div>
  )
}


export default MyEducation
