import styles from './ModeratePage.module.less'
import HistoryLinks from '../../../common/HistoryLinks/HistoryLinks'
import { IconChartBar, } from '@tabler/icons'
import RatingFilters from '../../PortfolioRating/RatingFilters/RatingFilters'
import { useSelector, } from 'react-redux'
import ModerTabs from './ModerTabs/ModerTabs'
import { useAchievements, } from '../../../../gql/hooks/useAchievements'
import { useMemo, useState, } from 'react'
import DetailsCard from '../../DetailsCard/DetailsCard'
import ArchiveAchivments from '../../AchivmentDetails/ArchiveAchivments/ArchiveAchivments'
import { TitleH3, } from '../../../common/GeneralElements/GeneralElements'
import { CardAddItem, } from '@/shared'
import AddAchivmentPopup from '../../AddAchivmentPopup/AddAchivmentPopup'
import useAchievementDirections from '../../../../gql/hooks/portfolio/useAchievementDirections'
import { IconArrowBarToDown, } from '@tabler/icons'
import useAchievementChanged from '../../../../gql/hooks/portfolio/useAchievementChanged'
import { URL, } from '../../../../Utils/constants'
import StudentInfo from '../../RatingById/StudentInfo/StudentInfo'
import useRedirect from '@/legacy/hooks/useRedirect'
import useGetRole from '@/legacy/redux/hooks/common/useGetRole'
import HtmlToPdf from '@/legacy/components/Portfolio/ModeratePortfolio/HtmlToPdf/HtmlToPdf'

const historyLinks = [
  {
    value: 'Главная',
    path: '/',
  },
]

function ModeratePage () {
  const redirect = useRedirect()

  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const localFilter = useSelector(state => state.port.filters.achievements)
  const [
    isAdd,
    setIsAdd,
  ] = useState(false)
  const [
    directionName,
    setDirectionName,
  ] = useState(null)
  const { directions: directionKey, } = useAchievementDirections({
    toReturn: directionName ? 'keyByName' : null,
    name: directionName,
  })
  const userRole = useGetRole()
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const userRatingInfo = useSelector(state => state.port.ratingId)

  const getAchievements = useAchievements({
    filter: {
      id: userRatingInfo.id,
      direction: [
        directionName,
      ],
      type: localFilter.type,
      status: localFilter.status,
      dateStart: localFilter?.dateRange?.from,
      dateEnd: localFilter?.dateRange?.to,
    },
    limit: 1000,
  })

  useAchievementChanged(getAchievements.refetch)

  const achivments = useMemo(() => {
    if (getAchievements.loading) {
      return <div>.....loading...</div>
    } else if (getAchievements.error) {
      return <div>....error...</div>
    } else if (getAchievements.data) {
      const data = getAchievements.data
      const actualAchievements = data.achievements.items
        .filter((item: any) => item.status !== 'archived')
        .map((item: any, index: any) => (
          <DetailsCard key={ index } item={ item } />
        ))

      const archievedAchievements = data.achievements.items.filter(
        (item: any) => item.status === 'archived'
      )

      return (
        <>
          <div className={ styles.cardWrapper }>
            {userRole !== 'portfolio-kurator' && <CardAddItem
              title="Добавить достижение"
              onClick={ () => setIsAdd(!isAdd) }
            />}

            { actualAchievements }
          </div>
          { !!archievedAchievements.length && (
            <ArchiveAchivments archive={ archievedAchievements } />
          ) }
        </>
      )
    }
  }, [
    getAchievements.data,
    getAchievements.error,
    getAchievements.loading,
    isAdd,
    userRole,
  ])

  return (
    <div className="InnerPage allProjects moderatePortfolio">
      <HistoryLinks links={ historyLinks } loc="Модерация портфолио" />

      <div className="studentInner scroll">
        <div
          style={ {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'baseline',
            margin: '20px 0',
          } }
        >
          <TitleH3>Модерация портфолио: { userRatingInfo.full_name }</TitleH3>

          <div style={ { display: 'flex', } }>
            <div>
              <HtmlToPdf id={userRatingInfo.id}/>
            </div>
            <div
              style={ {
                marginBottom: '0',
                marginRight: '2rem',
              } }
              className="showRate"
              onClick={ () => window.open(
                `https://${ URL }/api/portfolio/xls/${ userRatingInfo.id }`
              )
              }
            >
              Выгрузить портфолио
              <IconArrowBarToDown color="#7671DD" size={ 25 } />
            </div>
            <div
              className="showRate"
              onClick={ () => redirect('/portfolio-rating') }
            >
              Смотреть рейтинг обучающихся
              <IconChartBar color="#7671DD" size={ 25 } />
            </div>
          </div>
        </div>

        <StudentInfo i={ userRatingInfo } />

        <RatingFilters directionTypeText={ directionName } type="moderPage" />

        <br />

        <ModerTabs
          status={ localFilter.status }
          returnDirection={ setDirectionName }
        />

        <br />

        { achivments }
      </div>

      { isAdd && (
        <AddAchivmentPopup
          isOpen={ isAdd }
          setIsOpen={ setIsAdd }
          category={ {
            key: directionKey,
            name: directionName,
          } }
        />
      ) }
    </div>
  )
}

export default ModeratePage
