import { legacy_effectiveContractModerGroupe, } from '@/legacy/components/EffectiveContract/constants/effectiveContractModersAndAdmins-constants'
import { format, } from 'date-fns'
import { useEffect, useState, } from 'react'
import { useSelector, } from 'react-redux'
import { useLocation, } from 'react-router-dom'
import { useCreateEcData, useUpdateEcData, } from '../../../../../gql/hooks/effectiveContractHooks/useEffectiveContract'
import { useAppContext, } from '@/App/providers/AppProvider'
import ButtonSimple from '../../../../common/ButtonSimple/ButtonSimple'
import CalendarSimple from '../../../../common/CalendarSimple/CalendarSimple'
import { TitleH5, } from '../../../../common/GeneralElements/GeneralElements'
import InputTextDeprecated from '../../../../common/InputTextDeprecated/InputTextDeprecated'
import { defineUserGroupe, } from '../../../defineUserGroupe'

const Inputs_2_1 = ({
  onClickHandler,
  hint,
  defaultValues,
}: any) => {
  const { addNotification, }: any = useAppContext()


  const { search, } = useLocation()
  const moderateId = new URLSearchParams(search).get('id')
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const currentRole = useSelector(state => state.auth.choosenRole)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const currentUserType = useSelector(state => state.auth.userType)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const currentUserId = useSelector(state => state.auth.me.data.guid)

  const [
    user_ids,
  ] = useState(
    (
      defineUserGroupe(currentRole, legacy_effectiveContractModerGroupe)
      || defineUserGroupe(currentUserType, legacy_effectiveContractModerGroupe)
    )
      ? moderateId
      : currentUserId)


  const [
    onAddNewEcData,
  ] = useCreateEcData()
  const onAddNewEcDataHandler = (params: any) => {
    onAddNewEcData(params)
      .then(() => {
        addNotification(
          'Эффективный контракт',
          (
            (
              defineUserGroupe(currentRole, legacy_effectiveContractModerGroupe)
              || defineUserGroupe(currentUserType, legacy_effectiveContractModerGroupe)
            )
              ? [
                moderateId,
              ]
              : [
                currentUserId,
              ]
          ),
          { message: 'Ваше достижение добавлено!', },
          {
            path: '/effective-contract',
            from: 'Эффективный контракт',
          }
        )
      })
  }

  const [
    onUpdateRow,
  ] = useUpdateEcData()
  const onUpdateRowHandler = (id: any, newData: any) => onUpdateRow({
    variables: {
      rec_id: id,
      new_data: newData,
    },
  })


  const [
    presentName,
    setPresentName,
  ] = useState('')
  const [
    price,
    setPrice,
  ] = useState('')
  const [
    documentName,
    setDocumentName,
  ] = useState('')
  const [
    documentNumber,
    setDocumentNumber,
  ] = useState('')
  const [
    date,
    setDate,
  ] = useState('')


  useEffect(() => {
    if (hint === 'edit') {
      setPresentName(defaultValues.presentName)
      setPrice(defaultValues.price)
      setDocumentName(defaultValues.documentName)
      setDocumentNumber(defaultValues.documentNumber)
      setDate(format(new Date(defaultValues.date), 'yyyy-MM-dd'))
    }
  }, [
    hint,
    defaultValues,
  ])


  const fieldsToDefault = () => {
    setPresentName('')
    setPrice('')
    setDocumentName('')
    setDocumentNumber('')
    setDate('')
  }


  return (
    <>
      <InputTextDeprecated
        defaultHint="Название премии/награды"
        value={ presentName }
        onChangeValue={ setPresentName }
      />

      <InputTextDeprecated
        defaultHint="Сумма"
        value={ price }
        onChangeValue={ setPrice }
        numbersOnly
      />

      <InputTextDeprecated
        defaultHint="Наименование документа"
        value={ documentName }
        onChangeValue={ setDocumentName }
      />

      <InputTextDeprecated
        defaultHint="Номер документа"
        value={ documentNumber }
        onChangeValue={ setDocumentNumber }
      />


      <TitleH5>
        Дата
      </TitleH5>

      <CalendarSimple
        selectRange={ false }
        withBorder
        styles={ { height: '4.56rem', } }
        defaultSingleDate={
          defaultValues.date
            ? format(new Date(defaultValues.date), 'dd.MM.yyyy')
            : date
              ? format(new Date(date), 'dd.MM.yyyy')
              : false }
        returnSingle={ (date: any) => setDate(
          format(new Date(date), 'yyyy-MM-dd')) }
        calendarPositionStatic
        clickableAreaPositioAbsolute
      />

      <br />

      <ButtonSimple
        isDisabled={ (
          presentName.trim().length < 1
          || price.trim().length < 1
          || documentName.trim().length < 1
          || documentNumber.trim().length < 1
          || date.length < 1
        ) }
        value={ hint === 'edit' ? 'Сохранить изменения' : 'Добавить достижение' }
        variant="full-filled"
        onClickHandler={ () => {
          if (hint === 'edit') {
            onUpdateRowHandler(defaultValues.rec_id, {
              presentName: presentName,
              price: price,
              documentName: documentName,
              documentNumber: documentNumber,
              date: format(new Date(date), 'yyyy-MM-dd'),
              status: 'pending',
              reason: '',
              scores: null,
            })

            fieldsToDefault()
            onClickHandler()
          } else {
            onAddNewEcDataHandler({
              variables: {
                user_ids: [
                  user_ids,
                ],
                dataType: 'table_2_1',
                data: {
                  presentName: presentName,
                  price: price,
                  documentName: documentName,
                  documentNumber: documentNumber,
                  date: format(new Date(date), 'yyyy-MM-dd'),
                  status: 'pending',
                  reason: '',
                  scores: null,
                  isAddedByModer: defineUserGroupe(currentRole, legacy_effectiveContractModerGroupe),
                },
              },
            })

            fieldsToDefault()
            onClickHandler()
          };
        } }
      />
    </>
  )
}


export default Inputs_2_1
