/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useMemo, useEffect, } from 'react'
import { IconPaperclip, } from '@tabler/icons'
import clsx from 'clsx'
import ButtonSimple from '../../../../../common/ButtonSimple/ButtonSimple'
import LinkButton from '../../../../../common/LinkButton/LinkButton'
import { IconFile, IconCircleX, } from '@tabler/icons'
import { useGetProcedureInfo, useUpdateProcedure, } from '../../../../../../gql/hooks/useMyEducation'
import Preloader from '../../../../../common/PreLoader/Preloader'
import { downloadFile, } from '../../../../../../hooks/useDownloadFile'
import { useSelector, } from 'react-redux'
import { EndPoints, } from '../../../../../../lib/endpoints'
import { useAppContext, } from '@/App/providers/AppProvider'


const tabContent: any = {
  important: {
    title: 'Модерация треков',
    subtitle: 'Процедура записи на треки',
    graph: 'miner',
  },
  additional: {
    title: 'Модерация треков',
    subtitle: 'Процедура записи на курсы',
  },
  lesser: {
    title: 'Модерация элективов',
    subtitle: 'Процедура записи на элективы',
    graph: 'elective',
  },
  elective: {
    title: 'Модерация факультативов',
    subtitle: 'Процедура записи на факультативы',
    graph: 'facultative',
  },
}

const Header = ({
  attachedFiles,
  role,
  type,
  nameSpec,
}: any): JSX.Element => {
  const [
    isEditMode,
    setIsEditMode,
  ] = useState<any>(null)
  const [
    descriptionText,
    setDescriptionText,
  ] = useState()
  const [
    file,
    setFile,
  ] = useState<any>(null)
  const [
    createdFile,
    setCreatedFile,
  ] = useState<any>(null)

  const [
    esEditDisciplineProcedure,
  ] = useUpdateProcedure({
    type: tabContent[type].graph,
    procedureInfo: descriptionText,
    procedureData: {
      file: createdFile,
    },
  })

  const { openToast, } = useAppContext()


  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSubmit = () => {
    if (file) {
      const formData = new FormData()
      formData.append(`my-education/facultative-procedure/`, file[0])

      fetch(EndPoints.upload, {
        method: 'POST',
        body: formData,
        credentials: 'include',
      }).then(res => {
        return res.json()
      })
        .then(doc => {
          setCreatedFile({
            upload_id: doc[0].upload_id,
            name: doc[0].name,
          })
          setFile(null)
        })
        .then(() => {
          esEditDisciplineProcedure()
            .then(res => {
              setIsEditMode(false)
            })
            .then(() => openToast?.({ message: 'Изменена информация процедуры записи', }))
            .catch(e => console.log('errorPopup', e))
        })
        .catch(e => console.log('errorPopup', e))
    } else {
      esEditDisciplineProcedure()
        .then(res => {
          setIsEditMode(false)
          setFile(null)
        })
        .then(() => openToast?.({ message: 'Изменена информация процедуры записи', }))
        .catch(e => console.log('errorPopup', e))
    }
  }

  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const refresh = useSelector(state => state.myEd.updateProcedure)

  const { data, error, loading, refetch, } = useGetProcedureInfo(tabContent[type].graph)

  useEffect(() => {
    refetch()
  }, [
    refresh,
    refetch,
    type,
  ])

  useEffect(() => {
    if (data?.esDisciplineProcedure) {
      setDescriptionText(data.esDisciplineProcedure.procedure_info)
      setCreatedFile(data.esDisciplineProcedure.procedure_data.file)
    }
  }, [
    data,
  ])

  const procedure = useMemo(() => {
    if (error) {
      return <div>...error</div>
    }
    if (loading) {
      return <Preloader />
    } else if (data) {
      return <>
        <p className='learning-pathway-tab__description'>
          { isEditMode ? (
            <textarea
              className='learning-pathway-tab__description-textarea'
              // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'number'.
              rows="5"
              value={ descriptionText }
              // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
              onChange={ event => setDescriptionText(event.target.value) }
            >
            </textarea>
          ) : (
            <div dangerouslySetInnerHTML={ { __html: data.esDisciplineProcedure?.procedure_info ? data.esDisciplineProcedure?.procedure_info : 'Добавьте описание', } }></div>
          ) }
        </p>

        <div
          className={ clsx({
            'learning-pathway-tab__attached-files-wrapper': true,
            'learning-pathway-tab__attached-files-wrapper_edit': isEditMode,
          }) }
        >
          <div className="fileWrapFac">
            { isEditMode && <div className="fileWrapES" >
              <input required style={ { display: 'none', } } type="file" id="procedure" onChange={ e => setFile([
                e?.target?.files?.[0],
              ]) } />
              <label htmlFor="procedure" className="dash">Прикрепить файл<IconPaperclip color="#7671DD" /></label>
            </div> }
            { (isEditMode && (createdFile || file)) && <div className="dash full">
              <IconFile color="#7671DD" />
              <p className="activity">{ file ? file[0].name : createdFile.name }</p>
              <IconCircleX color="#C5C5C5" onClick={ file ? () => setFile(null) : () => setCreatedFile(null) } />
            </div> }
          </div>
          { (!isEditMode && data.esDisciplineProcedure?.procedure_data.file) && <div onClick={ () => downloadFile(data.esDisciplineProcedure?.procedure_data?.file) } className="dash full">
            <IconFile color="#7671DD" />
            <p className="activity">{ data.esDisciplineProcedure?.procedure_data?.file.name }</p>
          </div> }
          { isEditMode ? (
            <div className='learning-pathway-tab__attached-buttons-wrapper'>
              <ButtonSimple value="Отменить" variant="groupe" onClickHandler={ () => setIsEditMode(false) } />
              <ButtonSimple value="Сохранить изменения" active onClickHandler={ onSubmit } />
            </div>
          ) : false }
        </div>

      </>
    }
  }, [
    data,
    error,
    loading,
    isEditMode,
    onSubmit,
    descriptionText,
  ])


  return (
    <header className='learning-pathway-tab__header'>
      { role === 'osrv-moder' ? (
        <div className='learning-pathway-tab__title-wrapper'>
          <h2 className='learning-pathway-tab__title'>
            { tabContent[type].title }
          </h2>

          { isEditMode ? false : (
            <LinkButton
              type="edit"
              onClickHandler={ () => setIsEditMode(true) }
            />
          ) }
        </div>
      ) : false }
      <p className='learning-pathway-tab__subtitle'>
        { tabContent[type].subtitle }
      </p>
      { procedure }
    </header>
  )
}


export default Header
