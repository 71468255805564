import React, { useMemo, useEffect, useState, } from 'react'
import './FormGenerator.less'
import Preloader from '@/legacy/components/common/PreLoader/Preloader'
import { useSelector, } from 'react-redux'
import { getNameWithPostfix, } from '@/legacy/hooks/useUploadFiles'
import { EndPoints, } from '@/legacy/lib/endpoints'
import FormField from '@/legacy/components/Mfc/FormGenerator/FormField'
import useGetRole from '@/legacy/redux/hooks/common/useGetRole'
import { useGetForm } from '@/legacy/gql/hooks/mfc/useApplications'

const FormGenerator = ({ id, payload, setPayload, }: any) => {
  const { surname, name, patronymic, document_series, document_number, } =
    useSelector((state: any) => state.auth.me.data)
  const [
    performerValue,
    setPerformerValue,
  ] = useState('')
  const [
    currentField,
    setCurrentField,
  ] = useState(null)

  const defaults: any = {
    CurrentSurname: surname,
    CurrentName: name,
    CurrentMiddleName: patronymic,
    PassportSeries: document_series,
    PassportNumber: document_number,
  }

  const role = useGetRole()
  // eslint-disable-next-line react-hooks/exhaustive-deps

  const uploadFile = (files: FileList, field: string) => {
    const formData = new FormData()
    Array.from(files).forEach((file: any) => formData.append(
      `mfc/get/application`,
      file,
      getNameWithPostfix(file.name)
    )
    )

    fetch(EndPoints.upload, {
      method: 'POST',
      body: formData,
      credentials: 'include',
    })
      .then(res => {
        return res.json()
      })
      .then(doc => {
        setPayload({
          ...payload,
          [field]: doc,
        })
      })
      .catch(e => console.log(e))
  }

  const { data, error, loading, } = useGetForm(id)

  const defaultPayload = useMemo(() => {
    let obj: any = {}

    if (data && data.mfcGetForm /* duty[2] */.fields) {
      const handleRender = (i: any) => {
        if (i.default) {
          obj = {
            ...obj,
            [i.jsonKey]: i.default.value,
          }
        } else {
          obj = {
            ...obj,
            [i.jsonKey]: defaults[i.jsonKey],
          }
        }
      }

      data.mfcGetForm /* duty[2] */.fields
        .forEach((i: any) => {
          if (i.children) {
            i.children.map((i: any) => handleRender(i))
          } else {
            handleRender(i)
          }
        })
    }
    return obj
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
  ])

  /*   console.log("default", payload); */

  useEffect(() => {
    setPayload(defaultPayload)
  }, [
    defaultPayload,
    setPayload,
  ])

  const getForm = useMemo(() => {
    if (loading) {
      return <Preloader />
    }
    if (error) {
      return <div>Произошла ошибка, повторите позже</div>
    } else if (data) {
      if (data.mfcGetForm /* duty[2] */.fields) {
        return data.mfcGetForm /* duty[2] */.fields
          .map((i: any, index: any) => {
            if (
              (!i.dependency || payload[i.dependency]) &&
              (!i.role || role === i.role) &&
              (!i.dependendOnValue ||
                payload[i.dependendOnValue.dep] === i.dependendOnValue.value ||
                i.items?.every(
                  (item: string) => item !== i.dependendOnValue.value
                ))
            ) {
              return (
                <div key={ index } className="fieldItemWrap">
                  <FormField
                    type={ i.type }
                    index={ index }
                    label={ i.label }
                    jsonKey={ i.jsonKey }
                    title={ i.title }
                    children={ i.children }
                    items={ i.items }
                    query={ i.auery }
                    setPayload={ setPayload }
                    payload={ payload }
                    defaults={ defaults }
                    setPerformerValue={ setPerformerValue }
                    performerValue={ performerValue }
                    currentField={ currentField }
                    uploadFile={ uploadFile }
                    setCurrentField={ setCurrentField }
                    multiple={ i.multiple }
                    sum={ i.sum }
                  />
                </div>
              )
            }
            return null
          })
      } else if (data.mfcGetForm.fields.length < 1) {
        return <div>стандартные поля</div>
      }
      return <div>no match</div>
    }
  }, [
    data,
    error,
    loading,
    payload,
    currentField,
    defaults,
    setPayload,
    performerValue,
    uploadFile,
  ])

  return <ul className="mfcApplicationGeneratorWrap">{ getForm }</ul>
}

export default FormGenerator
