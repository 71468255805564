/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useMemo, useEffect, useState, } from 'react'
import CardDiscipline from './CardDiscipline/CardDiscipline'
import Preloader from '../../../common/PreLoader/Preloader'
import { useSelector, } from 'react-redux'
import { getSemester, } from '../../../../Utils/functions'
import { useGetMoodleCourses, useGetSignStatus, } from '../../../../gql/hooks/useMyEducation'
import NoDisciplineCard from './CardDiscipline/NoDisciplineCard'
import format from 'date-fns/format'
import { URL, } from '../../../../Utils/constants'

const AllDisciplines = ({
  plansId,
  filters,
  offs,
  chooseDiscipline,
  handlePaginate,
  stud,
}: any) => {
  const [
    semester,
    setSemester,
  ] = useState(getSemester(stud.course))
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const guid = useSelector(state => state.auth.me.data.guid)


  useEffect(() => {
    setSemester(getSemester(stud.course))
  }, [
    stud,
  ])

  const { data, error, loading, } = useGetMoodleCourses({
    user_id: guid,
    filter: {
      semester: semester,
      namespec: stud.namespec,
      status: [
        'ЗаписанНаКурс',
        'ЗаписаНаКурс',
      ],
    },
  })

  console.log('stud', stud?.department_name.toLowerCase() !== 'институт математики, физики и информационных технологий')

  const partStatus = useGetSignStatus({ id: guid, })

  const warningCards = useMemo(() => {
    const { loading, error, data, } = partStatus

    if (loading) {
      return null
    }
    if (error) {
      return null
    } else if (data) {
      const { elective, facultative, miner, } = data.esGetSignStatus

      return <>
        { (miner == 0 && stud?.department_name.toLowerCase() !== 'медицинский институт') && <NoDisciplineCard index={ 0 } description="«Вы не выбрали ни одну дисциплину по выбору (майнор) для изучения в следующем семестре. Пожалуйста, выберите дисциплину.»" /> }
        { elective == 0 && <NoDisciplineCard index={ 1 } description="«Вы не выбрали ни одну дисциплину по выбору (электив) для изучения в следующем семестре. Пожалуйста, выберите дисциплину.»" /> }
      </>
    }
  }, [
    partStatus,
    stud,
  ])
  /* const { data, error, loading } = useGetDisciplines({
    ep_number: plansId,
    filter: {
      study_plan: filters.disciplineType.plan,
      semester: filters.semester
    },
     groupBy: ["discipline_name", "ep_number", "study_plan", "semester"],
    grouped: true,
    pagination: {
      offset: offs === 0 ? 0 : offs - 20,
      limit: 20
    },

  }) */


  const cardsDiscipline = useMemo(() => {
    if (error) {
      return <div>...error</div>
    } else if (loading) {
      return <div style={ {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      } }><Preloader /></div>
    } else if (data) {
      if ((data?.bySemester.length > 0 || data?.common.length > 0)) {
        return (
          <div className='my-education__main-content_cards'>
            {
              (format(new Date(Date.now()), 'M') === '11')
                && (+format(new Date(Date.now()), 'd') < 16)
                && stud.course == 1 && stud?.eform.toLowerCase() === 'очная'
                && stud?.department_name.toLowerCase() !== 'институт дополнительного образования'
                && stud?.department_name.toLowerCase() !== 'институт военного образования'
                && stud?.department_name.toLowerCase() !== 'военный учебный центр' ? warningCards : null
            }

            { data?.bySemester.length > 0 ? data.bySemester.map((pack: any) => pack.disciplines.map((i: any, index: any) => <CardDiscipline key={ index } title={ i } debtSemester={ pack.semester } semester={ semester } description="дисциплина" dept={ pack.semester < semester } />)) : null }
            { data?.common.length > 0 ? data.common.map((pack: any) => pack.disciplines.map((i: any, index: any) => <CardDiscipline key={ index } title={ i } semester={ -1 } description="дисциплина" dept={ false } />)) : null }
          </div>
        )
      }
      return !loading && <div className="noRequiredDisciplines">У вас нет активных дисциплин</div>
    }
  }, [
    data,
    error,
    loading,
    semester,
    warningCards,
    stud,
  ])

  return (<>
    { cardsDiscipline }
    {/* {data?.educationPlanDisciplines?.pagination?.total > 20 && <PaginationSimple count={Math.ceil((data?.educationPlanDisciplines?.pagination?.total) / 20)} onChange={handlePaginate} page={offs ? Math.round(offs / 20) : 1} />} */ }
  </>)
}

export default AllDisciplines
