import React from 'react'
import './TagsFilter.less'
import Checkbox from '@mui/material/Checkbox'


function TagsFilter ({
  onClick,
  items,
}: any) {
  return (
    <div className="tagsFilter">
      <h2 className="tagsFilterTitle">Фильтр</h2>
      <div className="tagsContainer">
        <ul>
          { items.map((i: any, index: any) => <li key={ index }><Checkbox sx={ {
            '&.Mui-checked': {
              color: '#7671DD',
            },
          } } onChange={ () => onClick(i) } />{ i }</li>) }
        </ul>
      </div>
    </div>
  )
}

export default TagsFilter
