import { NewPageWrapper, } from '../../common/GeneralElements/GeneralElements'
import HistoryLinks from '../../common/HistoryLinks/HistoryLinks'
import TabButtons from '../../common/TabButtons/TabButtons'
import { mfcButtons, } from '../MfcUtils'
import { useSelector, useDispatch, } from 'react-redux'
import ButtonSimple from '../../common/ButtonSimple/ButtonSimple'
import StudentApplications from '../StudentMfc/StudentApplications/StudentApplications'
import useRedirect from '@/legacy/hooks/useRedirect'
import useGetRole from '@/legacy/redux/hooks/common/useGetRole'
import EmployeeApplications from '@/legacy/components/Mfc/EmployeeMfc/EmployeeApplications'
import { setStatus, } from '@/legacy/redux/MfcSlices/MfcSlice'


const MyApplicationsMFC = () => {
  const redirect = useRedirect()

  const dispatch = useDispatch()
  const role = useGetRole()

  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const activeStatus = useSelector(state => state.mfc.activeStatus)
  function isActive (key: any) {
    return activeStatus.name === key.name
  }

  return (
    <NewPageWrapper className='projects_introduction'>
      <HistoryLinks
        links={
          [
            {
              value: 'Главная',
              path: '/',
            },
          ]
        }
        loc="Мои заявления"
      />
      <h1 className="page-main-title">Мои заявления</h1>
      <div className="battonsWrap">
        <TabButtons items={ mfcButtons } isActive={ isActive } clickHandler={ (i: any) => dispatch(setStatus(i)) } />
        <ButtonSimple value={ 'Подать заявление' } active={ true } variant="alone" onClickHandler={ () => redirect('/mfc-get-application') } />
      </div>
      {(role === 'student' || role === 'student-rakus') ? <StudentApplications /> : <EmployeeApplications />}
    </NewPageWrapper>
  )
}


export default MyApplicationsMFC
