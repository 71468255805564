/* eslint-disable no-console */
import { useEffect, useMemo, useState, } from 'react'
import clsx from 'clsx'
import { useSelector, } from 'react-redux'
import { IconCirclePlus, } from '@tabler/icons'
import { useDeleteStage, useGetTasks, } from '../../../../gql/hooks/useProjects'
import compareAsc from 'date-fns/compareAsc'
import AnimatedTrash from '../../../common/AnimatedTrash/AnimatedTrash'
import DeletePopup from '../../../common/DeletePopup/DeletePopup'
import useGetRole from '@/legacy/redux/hooks/common/useGetRole'
import { useAppContext, } from '@/App/providers/AppProvider'


function StepCard ({
  addTask,
  i,
}: any) {
  const [
    isDeletePopup,
    setIsDeletePopup,
  ] = useState(false)

  const role = useGetRole()

  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const newTask = useSelector(state => state.subscriptions.taskChanged)
  const { stage_num, stage_id, req_id, } = i
  const [
    completed,
    setCompleted,
  ] = useState(0)

  /* console.log('one', i) */

  function handleNewTask () {
    addTask(i)
  }

  const params = {
    stage_id,
  }

  const { refetch, data, error, loading, } = useGetTasks(params)

  useEffect(() => {
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    newTask,
  ])

  const tasks = useMemo(() => {
    if (error) {
      setCompleted(0)
      return <div>.....error....</div>
    } else if (loading) {
      setCompleted(0)
      return <div>....loading...</div>
    }
    setCompleted(0)
    return (
      <ul>{
        data.paTasks.map((i: any) => {
          if (i.status === 'completed') {
            setCompleted(completed + 1)
          }
          return <li key={ i.task_id }><Task i={ i } /></li>
        })
      }
      </ul>
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    error,
    loading,
  ])

  /* console.log('tasks', data) */

  function getStatus (i: any) {
    if (i === 'new') {
      return 'В работе'
    } else if (i === 'finished') {
      return 'На проверке'
    } else if (i === 'completed') {
      return 'Завершена'
    }
  }

  function Task ({
    type,
    i,
  }: any) {
    const isExpire = compareAsc(new Date(i.data.date.replaceAll('-', ',')), new Date(Date.now()))

    return (
      <div className="taskWrap">
        { isExpire < 0 ? <p className={ clsx('failed') }>Просрочена</p> : <p className={ clsx((i.status === 'new' || i.status === 'finished') && 'inProcess', type === 'Просрочена' && 'failed', i.status === 'completed' && 'finished') }>{ getStatus(i.status) }</p> }
        <h2>{ i.data.taskName }</h2>
        <div className="date">{ `до ${ i.data.date }` }</div>
      </div>
    )
  }

  console.log('project?', i)

  const [
    paDeleteStage,
  ] = useDeleteStage()

  const { openToast, } = useAppContext()

  const handleDeleteStage = () => {
    paDeleteStage({
      variables:
      {
        req_id,
        stage_num,
        event: 'stageChanged',
      },
    })
      .then(() => {
        setIsDeletePopup(false)
        openToast?.({ message: 'Этап удален', })
      }
      )
      .catch(e => console.log('Stage isnt Deleted', e))
  }


  return (
    <div className="stepContainer">
      <div className="stepCardHeader">
        <h3 className="numberStage">{ `Этап ${ stage_num }` }</h3>
        { role !== 'student' && <AnimatedTrash styles={ {
          width: '20px',
          height: '25px',
          marginTop: '5px',
        } } dataTip="Удалить этап" clickHandler={ () => setIsDeletePopup(true) } /> }
      </div>
      <h2>{ i.data.title }</h2>
      <div className="processProject">
        <p>{ `Выполнено ${ completed }/${ data?.paTasks.length }` }</p>
        <div className="scale"><div style={ { width: `${ data?.paTasks.length > 0 ? ((100 / data?.paTasks.length) * completed) : 0 }%`, } }></div></div>
      </div>
      { role !== 'student' && <div className="plusStep" onClick={ handleNewTask }>Добавить быструю задачу<IconCirclePlus color="#C5C5C5" /></div> }
      { tasks }
      <DeletePopup title={ `Удалить этап ${ i.data.title }?` } onClickHandler={ handleDeleteStage } isDeletePopup={ isDeletePopup } setIsDeletePopup={ setIsDeletePopup } />
    </div>
  )
}

export default StepCard
