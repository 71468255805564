import React, { useState, useEffect, useMemo, } from 'react'
import { DebounceInput, } from 'react-debounce-input'
import { format, } from 'date-fns'
import useGetRole from '@/legacy/redux/hooks/common/useGetRole'
import { IconAdjustmentsHorizontal, IconBackspace, IconCalendarMinus, IconCircleX, IconSortAscending, IconSortDescending, IconX, } from '@tabler/icons'
import CheckBoxItem from '@/legacy/components/common/CheckBox/CheckBoxItem'
import { Col, InputNumber, Row, } from 'antd'
import FilterPopup from '@/legacy/components/Projects/FilterPopup/FilterPopup'
import Calendar from 'react-calendar'
import { ru, } from 'date-fns/locale'
import Persons from '@/legacy/components/common/PersonsInput/PersonsInput'
import SpecsList from '@/legacy/components/common/SpecsSearch/SpecsSearch'
import Field from '@/legacy/components/Projects/AllProjects/Field/Field'
import Cards from '@/legacy/components/Projects/MyProjects/MyProjectCards/MyProjectCards'
import { useSelector, } from 'react-redux'

function Filter (): JSX.Element {
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const currentUserId = useSelector(state => state.auth.me.data.guid)
  const [
    isFilters,
    setIsFilters,
  ] = useState(false)

  const [
    currentField,
    setCurrentField,
  ] = useState<any>(null)

  const [
    projectType,
    setProjectType,
  ] = useState(null)

  const [
    participants,
    setParticipants,
  ] = useState(null)

  const [
    calendar,
    setCalendar,
  ] = useState(false)

  const [
    rangeDate,
    setRangeDate,
  ] = useState<any>(null)

  const [
    projectId,
    setProjectId,
  ] = useState(null)

  const [
    semester,
    setSemester,
  ] = useState(null)

  const [
    projectName,
    setProjectName,
  ] = useState<any>(null)

  const [
    customerType,
    setCustomerType,
  ] = useState<any>(null)

  const [
    workType,
    setWorkType,
  ] = useState<any>(null)


  const [
    personsValue,
    setPersonsValue,
  ] = useState('')
  const [
    userName,
    setUserName,
  ] = useState<any>(null)

  const [
    specValue,
    setSpecValue,
  ] = useState('')
  const [
    nameSpec,
    setNameSpec,
  ] = useState<any>(null)
  const [
    status,
    setStatus,
  ] = useState([])
  const statusCheckbox = [
    {
      title: 'Действующие',
      statusValues: [
        'ready',
        'completed',
      ],
    },
    {
      title: 'Архивные',
      statusValues: [
        'archive',
      ],
    },
  ]
  const role = useGetRole()
  const [
    params,
    setParams,
  ] = useState({
    godMode: !((role === 'student' || role === 'student-rakus')),
    status: (status.length > 0) ? status : [
      'ready',
      'completed',
      'archive',
    ],
    sort: [
      {
        column: 'updated',
        order: 'desc',
      },
    ],
    filter: {
      creatorId: currentUserId,
      student_namespec: null,
      course: null,
    },
  })


  useEffect(() => {
    setParams({
      ...params,
      filter: {
        creatorId: currentUserId,
        student_namespec: null,
        course: null,
      },
      status: (status.length > 0) ? status : [
        'ready',
        'completed',
        'archive',
      ],
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentUserId,
    status,
  ])


  function debounceHandler (i: any) {
    setNameSpec(i)
    setCurrentField(null)
  }

  const payload = {
    name: projectName,
    namespec: nameSpec,
    projectType,
    participants,
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    req_id: +projectId,
    dateEndr: rangeDate ? format(new Date(rangeDate[1]), 'yyyy-MM-dd') : null,
    dateStart: rangeDate ? format(new Date(rangeDate[0]), 'yyyy-MM-dd') : null,
    student_namespec: null,
    creatorId: currentUserId,
    semester,
    customerType,
    workType,
  }


  const allFields = [
    [
      {
        type: 'search',
        span: (role === 'student' || role === 'student-rakus') ? 8 : 6,
        handler: setNameSpec,
        role: [
          'worker',
          'teacherGPH',
          'pa_moder',
        ],
      },
      {
        title: 'Тип проекта',
        value: projectType,
        span: (role === 'student' || role === 'student-rakus') ? 8 : 6,
        items: [
          'Исследовательские проекты',
          'Прикладные проекты',
          'Социальные проекты',
        ],
        handler: setProjectType,
        role: [
          'worker',
          'pa_moder',
          'student',
          'student-rakus',
        ],
      },
      {
        title: 'Участников(1-300)',
        type: 'number',
        value: participants,
        span: (role === 'student' || role === 'student-rakus') ? 8 : 6,
        items: Array.from(Array(300).keys()),
        handler: setParticipants,
        role: [
          'worker',
          'teacherGPH',
          'pa_moder',
          'student',
          'student-rakus',
        ],
      },
      {
        title: 'Семестр',
        value: semester,
        span: (role === 'student' || role === 'student-rakus') ? 8 : 6,
        items: [
          1,
          2,
          3,
          4,
          5,
          6,
          7,
          8,
        ],
        handler: setSemester,
        role: [
          'worker',
          'teacherGPH',
          'pa_moder',
          'student',
          'student-rakus',
        ],
      },
    ],
    [
      {
        title: 'ID проекта',
        value: projectId,
        span: role === 'pa_moder' ? 4 : 4,
        type: 'input',
        handler: setProjectId,
        role: [
          'worker',
          'teacherGPH',
          'pa_moder',
          'student',
          'student-rakus',
        ],
      },
      {
        title: 'Сроки',
        value: 'выберите сроки',
        span: role === 'pa_moder' ? 5 : 5,
        type: 'input',
        calendar: true,
        handler: setRangeDate,
        role: [
          'worker',
          'teacherGPH',
          'pa_moder',
          'student',
          'student-rakus',
        ],
      },
      {
        title: 'Заказчик',
        value: customerType,
        span: (role === 'student' || role === 'student-rakus') ? 5 : 5,
        items: [
          'Университет',
          'Технопарк Державинский',
          'Внешний заказчик',
        ],
        handler: setCustomerType,
        role: [
          'worker',
          'teacherGPH',
          'pa_moder',
          'student',
          'student-rakus',
        ],
      },
      {
        title: 'Тип работы',
        value: workType,
        span: (role === 'student' || role === 'student-rakus') ? 4 : 4,
        items: [
          'ВКР',
          'Курсовая работа',
        ],
        handler: setWorkType,
        role: [
          'worker',
          'teacherGPH',
          'pa_moder',
          'student',
          'student-rakus',
        ],
      },
    ],
  ]

  const clearAll = () => {
    // eslint-disable-next-line array-callback-return
    allFields.map(i => i.map(item => {
      if (item.type === 'search') {
        item.handler(null)
        // @ts-expect-error TS(2339): Property 'mode' does not exist on type '{ type: st... Remove this comment to see the full error message
      } else if (item.type === 'number' || item.mode === 'person') {
        item.handler(null)
      } else {
        // @ts-expect-error TS(2345): Argument of type '""' is not assignable to paramet... Remove this comment to see the full error message
        item.handler('')
      }
      setParams({
        godMode: true,
        status: [
          'ready',
          'completed',
          'archive',
        ],
        sort: [
          {
            column: 'updated',
            order: 'desc',
          },
        ],
        filter: {
          creatorId: currentUserId,
          student_namespec: null,
          course: null,
        },
      })
    }))
    setSpecValue('')
  }

  function onFilteredSearch () {
    if (Object.values(payload).every((i: any) => !i)) {
      setParams({ ...params, })
    } else {
      setParams({
        ...params,
        filter: {
          ...params.filter,
          ...payload,
          creatorId: currentUserId,
        },
      })
    }
  }
  const cards = useMemo(() => {
    return <Cards params={ params } />
  }, [
    params,
  ])


  const handleStatusCheck = (statuses: any) => {
    // @ts-expect-error TS(2345): Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
    if (status.includes(statuses[0])) {
      setStatus(status.filter(i => !statuses.includes(i)))
    } else {
      setStatus(status.concat(statuses))
    }
  }
  return (
    <div className="w-100">
      {(role === 'worker' || role === 'pa_moder') && <><div className="filterWrap">
        {/* Debounced input for project name */ }
        <DebounceInput
          debounceTimeout={ 300 }
          type="text"
          placeholder='Введите название проекта'
          onChange={ e => setProjectName(e.target.value) } />
        {/* Toggle filters visibility */ }
        <p onClick={ () => setIsFilters(!isFilters) }>
          { isFilters ? 'Свернуть фильтры' : 'Развернуть фильтры' }
        </p>
        {/* Toggle button for filters visibility */ }
        { isFilters ?
          <IconX color="#7671DD" size={ 20 } onClick={ () => setIsFilters(!isFilters) } /> :
          <IconAdjustmentsHorizontal color="#7671DD" size={ 29 } onClick={ () => setIsFilters(!isFilters) } /> }
        {/* Button to trigger filtered search */ }
        <button className="searchBut" onClick={ onFilteredSearch }>
          Поиск проектов
        </button>

        {/* Additional filters */ }
        <div className="additionalFilters">
          {/* Sorting by date */ }
          <div className="k-dateSort" style={{ marginBottom: 20, }} onClick={ params.sort[0].order === 'desc' ?
            () => setParams({
              ...params,
              sort: [
                {
                  column: 'updated',
                  order: 'asc',
                },
              ],
            }) :
            () => setParams({
              ...params,
              sort: [
                {
                  column: 'updated',
                  order: 'desc',
                },
              ],
            }) }>
            {/* Sorting icons and label */ }
            { params.sort[0].order === 'desc' ?
              <IconSortAscending color="#7671DD" size={ 30 } /> :
              <IconSortDescending color="#7671DD" size={ 30 } /> }
            Сортировать по дате заявки
          </div>
        </div>
      </div>
      <div
        className={ isFilters ? 'filterFields' : 'filtersHid' }
      >
        { allFields.map((input, index) => (
          <Row
            gutter={ 16 }
            key={ index }
          >
            { input.map((field: any, index: number) => (
              (((role !== 'pa_moder' && field.title !== 'ID проекта') || role === 'pa_moder') && field.role.includes(role)) && <Col
                span={ field.span }
                key={ field.title + index }
              >
                { field.type === 'input' ? (<> { field.mode !== 'person' ?
                  ((role !== 'pa_moder' && field.title !== 'ID проекта') || role === 'pa_moder') && <div className="dateWrap">
                    <div className="chooseField inp" onClick={ field.calendar ? () => setCalendar(!calendar) : () => console.log('net') }><label className="inpTitle">{ field.title }</label><DebounceInput
                      debounceTimeout={ field.timeout ? 300 : 0 }
                      onChange={ e => field.handler(e.target.value) }
                      // className="chooseField inp"
                      placeholder={ field.title !== 'ID проекта' ? `выберите ${ field.title }` : `введите ${ field.title }` }
                      disabled={ field.calendar }
                      value={ field.calendar && rangeDate ? `С ${ format(new Date(rangeDate[0]), 'dd MMM yy', { locale: ru, }) } по ${ format(new Date(rangeDate[1]), 'dd MMM yy', { locale: ru, }) }` : field.value }
                      onClick={ field.calendar ? () => setCalendar(!calendar) : () => setCurrentField(field.title) } /></div>

                    { field.timeout && <FilterPopup onClickAway={ () => setCurrentField(null) } isOpen={ currentField === field.title } handler={ debounceHandler } items={ [
                      1,
                      2,
                      3,
                    ] } /> }

                    { field.calendar && (<>
                      <IconCalendarMinus
                        color="#7F8191"
                        onClick={ () => setCalendar(!calendar) } />

                      <Calendar
                        formatMonthYear={ (locale: any, date: any) => format(date, 'dd MMMM yyyy', { locale: ru, }).slice(0, 3) + format(date, 'dd MMMM yyyy', { locale: ru, })[3].toUpperCase() + format(date, 'dd MMMM yyyy', { locale: ru, }).slice(4) }
                        locale={ 'Ru' }
                        className={ calendar ? 'ScheduleCalendar ' : 'ScheduleCalendar off' }
                        value={ rangeDate }
                        onChange={ setRangeDate }
                        selectRange={ true } />
                    </>) }
                  </div> : <div className="dateWrap"><div className="chooseField inp"><label className="inpTitle">ФИО пользователя</label><DebounceInput onChange={ e => setPersonsValue(e.target.value) } debounceTimeout={ 300 } type="text" placeholder='выберите пользователя' value={ userName ? userName?.full_name : '' } onClick={ () => setCurrentField('ФИО пользователя') } /></div>{ personsValue?.length > 3 && <Persons field='ФИО пользователя' mode="person" handler={ (i: any) => setUserName(i) } setCurrentField={ () => setCurrentField('') } currentField={ currentField } value={ personsValue } clear={ setPersonsValue } /> }</div> }
                </>
                ) : (<>
                  { field.type === 'search' ? (
                    <div className="dateWrap multiple chooseField">
                      <span>
                        { nameSpec?.map((i: any) => <div
                          className="specTag"
                          key={ i }
                        >
                          { i }
                          <IconCircleX
                            onClick={ () => setNameSpec(nameSpec.filter((field: any) => field !== i)) }
                            color="#C5C5C5" />
                        </div>) }
                      </span>

                      <DebounceInput
                        onChange={ e => setSpecValue(e.target.value) }
                        debounceTimeout={ 300 }
                        type="text"
                        placeholder="Направление подготовки / специальность"
                        onClick={ () => setCurrentField('Направление подготовки / специальность') }
                        value={ specValue } />

                      { specValue?.length > 1 && <SpecsList handler={ (i: any) => setNameSpec([
                        i,
                      ]) } currentField={ currentField } value={ specValue } setCurrentField={ () => setCurrentField('Направление подготовки / специальность') } field="Направление подготовки / специальность" clear={ () => setSpecValue('') } /> }
                    </div>
                  ) : (
                    field.type !== 'number' ? <Field
                      title={ field.title }
                      value={ field.value }
                      onClick={ setCurrentField }
                      handler={ field.handler }
                      activeField={ currentField }
                      items={ field.items } /> : <InputNumber min={ 1 } max={ 300 } formatter={ value => `Количество участников: ${ value }` } value={ participants } bordered={ false } autoFocus={ false } onChange={ field.handler } />) }
                </>) }
              </Col>)
            ) }
          </Row>
        )) }
        <div className="filtersBottom">
          <ul className="StatusCheckboxWrap">
            { statusCheckbox.map(i => <CheckBoxItem value={ i.title } key={ i.title } onClick={ () => handleStatusCheck(i.statusValues) } />) }
          </ul>
          <div className="clear" onClick={ clearAll }>Очистить фильтры<IconBackspace size={ 30 } /></div>
        </div>
      </div></>}
      {cards}
    </div>
  )
}

export default Filter
