/* eslint-disable no-console */
import { useState, } from 'react'
import PaperContainerWrapper from '../../../../../common/PaperContainer/PaperContainerWrapper'
import UsersTable from '../../UsersTable/UsersTable'
import AddStudentFacultative from '../../UsersTable/AddStudentFacultative/AddStudentFacultative'
import FacultativeStudentsTable from '../../UsersTable/FacultativeStudentsTable'
import AddStudentPopup from '../../../../../common/AddStudentPopup/AddStudentPopup'
import { useSignUpTrack, } from '../../../../../../gql/hooks/useMyEducation'
import { getSemester, } from '../../../../../../Utils/functions'
import ElectiveStudentsTable from '../../UsersTable/ElectiveStudentsTable'
import { useAppContext, } from '@/App/providers/AppProvider'

const StudentsDistribution = ({
  type,
  facInfo,
  track_id,
  disciplines,
}: any) => {
  const [
    isAddStudent,
    setIsAddStudent,
  ] = useState<boolean>(false)
  const [
    isAddTrackDisabled,
    setIstrackDisabled,
  ] = useState<boolean>(false)

  const [
    esSignUpTrack,
  ] = useSignUpTrack()

  const name = new URLSearchParams(location.search).get('name')


  const { addNotification, openToast, } = useAppContext()

  const handleAddTrackStudents = (newStudents: any) => {
    setIstrackDisabled(true)
    esSignUpTrack({
      variables: {
        i: {
          track_id: +track_id,
          participants: newStudents.map((i: any) => {
            const student = i.studentInfo
            addNotification?.(
              'Мое обучение',
              [
                i.id,
              ],
              { message: `Вас записали на трек "${ name }"`, },
              { path: '/learning-pathway', }
            )
            return {
              ...student,
              id: i.id,
              course: i.course,
            }
          }),
          semester: getSemester(newStudents[0].course),
        },
      },
    })
      .then(() => {
        setIsAddStudent(false)
        setIstrackDisabled(false)
        openToast?.({ message: 'Студенты добавлены', })
      })
      .catch(e => console.log(e))
  }

  const studentsTable: any = {
    Факультатив: (
      <FacultativeStudentsTable
        setIsAddStudent={ setIsAddStudent }
        isAddStudent={ isAddStudent }
      />
    ),
    Трек: (
      <UsersTable
        setIsAddStudent={ setIsAddStudent }
        isAddStudent={ isAddStudent }
        disciplines={ disciplines }
        track_id={ track_id }
      />
    ),
    Электив: (
      <ElectiveStudentsTable
        setIsAddStudent={ setIsAddStudent }
        isAddStudent={ isAddStudent }
      />
    ),
  }

  const addStudentsPopup: any = {
    Факультатив: (
      <AddStudentFacultative
        isOpen={ isAddStudent }
        type={ type }
        handlePopup={ setIsAddStudent }
        facInfo={ facInfo }
      />
    ),
    Трек: (
      <AddStudentPopup
        isOpen={ isAddStudent }
        handlePopup={ setIsAddStudent }
        handleSubmit={ handleAddTrackStudents }
        isSubmitDisabled={ isAddTrackDisabled }
      />
    ),
    Электив: (
      <AddStudentFacultative
        type={ type }
        isOpen={ isAddStudent }
        handlePopup={ setIsAddStudent }
        facInfo={ facInfo }
      />
    ),
  }

  return (
    <PaperContainerWrapper variant="sub-paper">
      <div className="discipline-detail-card__students-distribution">
        <h3 className="discipline-detail-card__description-title">
          Распределение студентов
        </h3>
        { studentsTable[type] }
        { addStudentsPopup[type] }
      </div>
    </PaperContainerWrapper>
  )
}

export default StudentsDistribution
