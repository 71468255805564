import { legacy_effectiveContractModerGroupe, } from '@/legacy/components/EffectiveContract/constants/effectiveContractModersAndAdmins-constants'
import { useEffect, useState, } from 'react'
import { useSelector, } from 'react-redux'
import { useLocation, } from 'react-router-dom'
import { useCreateEcData, useUpdateEcData, } from '../../../../../gql/hooks/effectiveContractHooks/useEffectiveContract'
import { useAppContext, } from '@/App/providers/AppProvider'
import AttachedFiles from '../../../../common/AttachedFiles/AttachedFiles'
import ButtonSimple from '../../../../common/ButtonSimple/ButtonSimple'
import { TitleH5, } from '../../../../common/GeneralElements/GeneralElements'
import InputFile from '../../../../common/InputFile/InputFile'
import InputTextDeprecated from '../../../../common/InputTextDeprecated/InputTextDeprecated'
import { defineUserGroupe, moderGroupe_1_4, } from '../../../defineUserGroupe'
import SpecsSearch from '../../../Hooks/SpecsSearch/SpecsSearch'


const Inputs_1_41 = ({
  onClickHandler,
  hint,
  defaultValues,
}: any) => {
  const { addNotification, }: any = useAppContext()


  const { search, } = useLocation()
  const moderateId = new URLSearchParams(search).get('id')
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const currentRole = useSelector(state => state.auth.choosenRole)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const currentUserId = useSelector(state => state.auth.me.data.guid)
  const [
    ids,
  ] = useState(
    moderGroupe_1_4.some(moderRole => moderRole === currentRole)
      ? [
        moderateId,
      ]
      : [
        currentUserId,
      ]
  )

  const [
    onAddNewEcData,
  ] = useCreateEcData()
  const onAddNewEcDataHandler = (params: any) => {
    onAddNewEcData(params)
      .then(() => {
        addNotification(
          'Эффективный контракт',
          ((
            currentRole === 'urvo-moder'
            || currentRole === 'urvo-admin'
          )
            ? [
              moderateId,
            ]
            : [
              currentUserId,
            ]
          ),
          { message: 'Ваше достижение добавлено!', },
          {
            path: '/effective-contract',
            from: 'Эффективный контракт',
          }
        )
      })
  }

  const [
    onUpdateRow,
  ] = useUpdateEcData()
  const onUpdateRowHandler = (rec_id: any, newData: any, user_ids: any) => onUpdateRow({
    variables: {
      rec_id: rec_id,
      new_data: newData,
      user_ids: user_ids,
    },
  })


  const [
    yearOfRecruitment,
    setYearOfRecruitment,
  ] = useState('')
  const [
    major,
    setMajor,
  ] = useState('')
  const [
    formOfEducation,
    setFormOfEducation,
  ] = useState('')
  const [
    programm,
    setProgramm,
  ] = useState('')
  const [
    theme,
    setTheme,
  ] = useState('')
  const [
    document,
    setDocument,
  ] = useState([])


  useEffect(() => {
    if (hint === 'edit') {
      // setEventName(defaultValues.eventName)
      // setTimeStart(format(new Date(defaultValues.timeStart), 'yyyy-MM-dd'))
      // setFaculty(defaultValues.faculty)
      // setDepartment(defaultValues.department)
      // setUserRole(defaultValues.userRole)
      // @ts-expect-error TS(2345): Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
      setDocument(defaultValues.document ? [
        defaultValues.document,
      ] : [])
    }
  }, [
    hint,
    defaultValues,
  ])


  const fieldsToDefault = () => {
    // setEventName('')
    // setTimeStart('')
    // setFaculty('')
    // setDepartment('')
    // setUserRole('')
    setDocument([])
  }


  return <>
    <InputTextDeprecated
      defaultHint="Год набора"
      value={ yearOfRecruitment }
      onChangeValue={ setYearOfRecruitment }
      numbersOnly
    />

    <SpecsSearch
      defaultHint="Код и направление подготовки"
      withListPositionAbsolute
      value={ major }
      onChangeValue={ setMajor }
      handler={ (answer: any) => {
        setMajor(answer)
      } }
    />

    <InputTextDeprecated
      defaultHint="Форма обучения"
      value={ formOfEducation }
      onChangeValue={ setFormOfEducation }
    />

    <InputTextDeprecated
      defaultHint="Программа магистратуры/аспирантуры"
      value={ programm }
      onChangeValue={ setProgramm }
    />

    <InputTextDeprecated
      defaultHint="Тематика научного исследования"
      value={ theme }
      onChangeValue={ setTheme }
    />

    <TitleH5>
      Подтверждающий документ
    </TitleH5>

    { document.length < 1 ? (
      <InputFile
        onChange={ event => {
          // TODO: сделать uploadFile
          // uploadFile(...event.target.files, setDocument)
        } }
      />
    ) : false }

    <AttachedFiles
      isDeleteFilesAllowed={ true }
      fileList={ document }
      getIndexFile={ (index: any) => {
        setDocument(document.filter((file, fIndex) => fIndex !== index))
      } }
    />

    <br />

    <ButtonSimple
      isDisabled={ (
        yearOfRecruitment.trim().length < 1
        || major.length < 1
        || formOfEducation.trim().length < 1
        || programm.trim().length < 1
        || theme.trim().length < 1
      ) }
      value={ hint === 'edit' ? 'Сохранить изменения' : 'Добавить достижение' }
      variant="full-filled"
      onClickHandler={ () => {
        if (hint === 'edit') {
          onUpdateRowHandler(
            defaultValues.rec_id,
            {
              yearOfRecruitment: yearOfRecruitment,
              major: major,
              formOfEducation: formOfEducation,
              programm: programm,
              theme: theme,
              document: document ? document[0] : null,
              status: 'pending',
              reason: '',
              scores: null,
            },
            ids
          )

          fieldsToDefault()
          onClickHandler()
        } else {
          onAddNewEcDataHandler({
            variables: {
              dataType: 'table_1_41',
              data: {
                yearOfRecruitment: yearOfRecruitment,
                major: major,
                formOfEducation: formOfEducation,
                programm: programm,
                theme: theme,
                document: document ? document[0] : null,
                status: 'pending',
                reason: '',
                scores: null,
                isAddedByModer: defineUserGroupe(currentRole, legacy_effectiveContractModerGroupe),
              },
              user_ids: ids,
            },
          })

          fieldsToDefault()
          onClickHandler()
        };
      } }
    />
  </>
}


export default Inputs_1_41
