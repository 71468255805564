import { TableCellType, } from '@/legacy/types'
import { table_cat_1_1, } from '../../popUpInputsCatalogs'


export const tableSource: TableCellType[] = [
  {
    title: '№',
    index: 'number',
    editable: false,
    style: {
      minWidth: '3rem',
    },
  },
  {
    title: 'ФИО автора (авторов)',
    index: 'authors',
    editable: true,
    editType: 'participants',
    style: {
      minWidth: '7rem',
      wordBreak: 'break-all',
    },
  },
  {
    title: 'Название работы',
    index: 'book_title',
    editable: true,
    editType: 'input',
    style: { minWidth: '5rem', },
  },
  {
    title: 'Вид работы',
    index: 'type_work',
    editable: true,
    editType: 'select',
    selectOptions: table_cat_1_1.projectType,
    style: { maxWidth: '1rem', },
  },
  {
    title: 'Издательство',
    index: 'publish',
    editable: true,
    editType: 'select',
    selectOptions: table_cat_1_1.publish,
    style: {
      minWidth: '2rem',
      wordBreak: 'break-all',
    },
  },
  {
    title: 'Издатель',
    index: 'publisher',
    editable: true,
    editType: 'input',
    style: { minWidth: '2rem', },
  },
  {
    title: 'Год публикации',
    index: 'publication_year',
    editable: true,
    editType: 'inputNumbers',
    style: {
      minWidth: '5rem',
      wordBreak: 'break-all',
    },
  },
  {
    title: 'Количество авторов',
    index: 'countAuthors',
    editType: 'inputNumbers',
    editable: true,
    style: {
      minWidth: '5rem',
      wordBreak: 'break-all',
    },
  },
  // {
  //   title: 'Шифр и наименование направления подготовки / специальности',
  //   index: 'major',
  //   editable: true,
  //   editType: 'inputWithSpecsSearch',
  //   style: { minWidth: '5rem', },
  // },
  // {
  //   title: 'Название дисциплины',
  //   index: 'disciplineName',
  //   editable: true,
  //   editType: 'input',
  //   style: {
  //     minWidth: '5rem',
  //     wordBreak: 'break-all',
  //   },
  // },
  {
    title: 'Объем, п.л',
    index: 'volume',
    editable: true,
    editType: 'inputNumbers',
    style: {
      minWidth: '4rem',
      wordBreak: 'break-all',
    },
  },
  // {
  //   title: 'Формат издания',
  //   index: 'format',
  //   editable: true,
  //   editType: 'select',
  //   selectOptions: table_cat_1_1.format,
  //   style: {
  //     minWidth: '5rem',
  //     wordBreak: 'break-all',
  //   },
  // },
  // {
  //   title: 'Источник финансирования',
  //   index: 'financeSource',
  //   editable: true,
  //   editType: 'select',
  //   selectOptions: table_cat_1_1.financeSource,
  //   style: {
  //     minWidth: '5rem',
  //     wordBreak: 'break-all',
  //   },
  // },
  // {
  //   title: 'Тираж',
  //   index: 'print_run',
  //   editable: true,
  //   editType: 'inputNumbers',
  //   style: {
  //     minWidth: '3rem',
  //     wordBreak: 'break-all',
  //   },
  // },
  {
    title: 'Документ',
    index: 'document',
    editable: true,
    editType: 'upload',
    style: { minWidth: '15rem', },
  },
  // {
  //   title: 'Баллы',
  //   index: 'scores',
  // },
  {
    title: 'Статус',
    index: 'status',
    editable: false,
    style: { minWidth: '11rem', },
  },
]


export const appealTableSource: TableCellType[] = [
  {
    title: '№',
    index: 'number',
    editable: false,
  },
  {
    title: 'ФИО автора (авторов)',
    index: 'authors',
    editable: true,
    editType: 'participants',
    style: {
      minWidth: '15rem',
      wordBreak: 'break-all',
    },
  },
  {
    title: 'Название работы',
    index: 'book_title',
    editable: true,
    editType: 'input',
    style: { maxWidth: '5rem', },
  },
  {
    title: 'Вид работы',
    index: 'type_work',
    editable: true,
    style: { maxWidth: '3rem', },
    editType: 'select',
    selectOptions: table_cat_1_1.projectType,
  },
  // {
  //   title: 'Издательство',
  //   index: 'publish',
  //   editable: true,
  //   editType: 'select',
  //   selectOptions: table_cat_1_1.publish,
  //   style: {
  //     minWidth: '5rem',
  //     wordBreak: 'break-all',
  //   },
  // },
  // {
  //   title: 'Издатель',
  //   index: 'publisher',
  //   editable: true,
  //   editType: 'input',
  //   style: { minWidth: '5rem', },
  // },
  {
    title: 'Год публикации',
    index: 'publication_year',
    editable: true,
    editType: 'inputNumbers',
    style: {
      minWidth: '5rem',
      wordBreak: 'break-all',
    },
  },
  {
    title: 'Количество авторов',
    index: 'countAuthors',
    editType: 'inputNumbers',
    editable: false,
    style: {
      minWidth: '10rem',
      wordBreak: 'break-all',
    },
  },
  // {
  //   title: 'Шифр и наименование направления подготовки / специальности',
  //   index: 'major',
  //   editable: true,
  //   editType: 'inputWithSpecsSearch',
  //   style: { minWidth: '5rem', },
  // },
  // {
  //   title: 'Название дисциплины',
  //   index: 'disciplineName',
  //   editable: true,
  //   editType: 'input',
  //   style: {
  //     minWidth: '5rem',
  //     wordBreak: 'break-all',
  //   },
  // },
  {
    title: 'Объем, п.л',
    index: 'volume',
    editable: true,
    editType: 'inputNumbers',
    style: {
      minWidth: '8rem',
      wordBreak: 'break-all',
    },
  },
  // {
  //   title: 'Формат издания',
  //   index: 'format',
  //   editable: true,
  //   editType: 'select',
  //   selectOptions: table_cat_1_1.format,
  //   style: {
  //     minWidth: '5rem',
  //     wordBreak: 'break-all',
  //   },
  // },
  // {
  //   title: 'Источник финансирования',
  //   index: 'financeSource',
  //   editable: true,
  //   editType: 'select',
  //   selectOptions: table_cat_1_1.financeSource,
  //   style: {
  //     minWidth: '5rem',
  //     wordBreak: 'break-all',
  //   },
  // },
  // {
  //   title: 'Тираж',
  //   index: 'print_run',
  //   editable: true,
  //   editType: 'inputNumbers',
  //   style: {
  //     minWidth: '3rem',
  //     wordBreak: 'break-all',
  //   },
  // },
  {
    title: 'Документ',
    index: 'document',
    editable: true,
    editType: 'upload',
    style: { minWidth: '15rem', },
  },
  // {
  //   title: 'Баллы',
  //   index: 'scores',
  //   editable: true,
  //   editType: 'input',
  //   inputDescription: 'numbersOnly',
  // },
  {
    title: 'Причина отказа',
    index: 'reason',
    editable: false,
    style: { minWidth: '10rem', },
    editType: 'input',
  },
  {
    editable: false,
    title: 'Комментарий',
    index: 'textAppeal',
    style: { minWidth: '10rem', },
    editType: 'input',
  },
  {
    title: 'Статус',
    editable: false,
    index: 'status',
    style: { minWidth: '11rem', },
  },
]
