import { useMemo, } from 'react'
import Preloader from '../../../../../common/PreLoader/Preloader'
import { useSelector, } from 'react-redux'
import { useEffect, } from 'react'
import ElectiveCard from './ElectiveCard/ElectiveCard'
import { useGetFacultatives, } from '../../../../../../gql/hooks/useMyEducation'
import { useCallback, } from 'react'
import useRedirect from '@/legacy/hooks/useRedirect'


const ElectiveList = ({
  role,
  nameSpec,
  currentUserId,
}: any) => {
  const redirect = useRedirect()

  const payload = {
    filter: null,
    pagination: {
      offset: 0,
      limit: 1000,
    },
    sort: [
      {
        column: 'is_visible',
        order: 'desc',
      },
      {
        column: 'created',
        order: 'desc',
      },
    ],
  }

  const params = (role === 'student' || role === 'student-rakus') ? {
    ...payload,
    filter: {
      namespec: nameSpec.namespec,
      department: nameSpec.department_name,
      nameprof: nameSpec.nameprof,
      group: nameSpec.group,
      course: nameSpec.course,
      student_id: currentUserId,
      eform: nameSpec.eform,
      isModerator: false,
      elevel: nameSpec.elevel,
      type: 'elective',
    },
    pagination: {
      'offset': 0,
      'limit': 1000,
    },
  } : {
    ...payload,
    filter: {
      isModerator: true,
      type: 'elective',
    },
    pagination: {
      'offset': 0,
      'limit': 1000,
    },
  }

  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const refresh = useSelector(state => state.myEd.updateFacultatives)

  const handleNavigate = useCallback((e: any, i: any) => {
    if ((role === 'student' || role === 'student-rakus')) {
      redirect(`/learning-pathway/detail?type=Электив&id=${ i.facultative_id }&name=${ i.name }&namespec=${ nameSpec?.namespec }&course=${ nameSpec?.course }`)
    } else {
      redirect(`/learning-pathway/detail?type=Электив&id=${ i.facultative_id }&name=${ i.name }`)
    }
  }, [
    role,
    nameSpec,
    redirect,
  ])

  const { data, error, loading, refetch, } = useGetFacultatives(params)

  useEffect(() => {
    refetch()
  }, [
    refresh,
    refetch,
  ])

  const electiveList = useMemo(() => {
    if (loading) {
      return <Preloader />
    }
    if (error) {
      return <div>...Произошла ошибка, попробуйте позже</div>
    } else if (data) {
      return data.esFacultative.items.map((i: any, index: any) => <ElectiveCard key={ index } name={ i.name } type="Электив"
        nameSpec={ nameSpec } role={ role } id={ i.facultative_id } is_signed={ i.is_signed }
        is_visible={ i.is_visible } is_active={ i.is_active } handleNavigate={ (e: any) => handleNavigate(e, i) } />
      )
    }
  }, [
    data,
    error,
    loading,
    handleNavigate,
    role,
    nameSpec,
  ])


  return electiveList
}

export default ElectiveList
