/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState, } from 'react'
import {
  NewPageWrapper,
} from '../../../../common/GeneralElements/GeneralElements'
import HistoryLinks from '../../../../common/HistoryLinks/HistoryLinks'
import ButtonSimple from '../../../../common/ButtonSimple/ButtonSimple'
import { useLocation, } from 'react-router-dom'
import Preloader from '../../../../common/PreLoader/Preloader'
import _ from 'lodash'
import { useSelector, } from 'react-redux'
import { useGetTracks, } from '../../../../../gql/hooks/useMyEducation'
import InputTextDeprecated from '../../../../common/InputTextDeprecated/InputTextDeprecated'
import { useUpdateTrack, } from '../../../../../gql/hooks/useMyEducation'
import SectionWrapper from '../../../../common/SectionWrapper/SectionWrapper'
import RelatedDiscipline from '../DetailPageComponents/RelatedDiscipline/RelatedDiscipline'
import { useAppContext, } from '@/App/providers/AppProvider'

const EditTrackPage = () => {
  const location = useLocation()

  const trac_id: any = new URLSearchParams(location.search).get('id')
  const discipline_id = new URLSearchParams(location.search).get('discipline_id')
  const name = new URLSearchParams(location.search).get('name')
  const [
    isDisabled,
    setIsDisabled,
  ] = useState(false)
  const [
    newPayload,
    setNewPayload,
  ] = useState<any>(null)


  const payload = {
    filter: null,
    pagination: {
      offset: 0,
      limit: 1000,
    },
    sort: [
      {
        column: 'is_visible',
        order: 'desc',
      },
      {
        column: 'created',
        order: 'desc',
      },
    ],
  }

  const params = {
    ...payload,
    filter: {
      track_id: Number(trac_id),
      is_moderator: true,
    },
    pagination: {
      'offset': 0,
      'limit': 1000,
    },
  }


  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const refresh = useSelector(state => state.myEd.updateTracks)
  const { data, error, loading, refetch, } = useGetTracks(params)

  useEffect(() => {
    if (data) {
      const dataWithoutType = _.omit(data?.esTrackList.items[0], '__typename', 'disciplines', 'is_signed', 'sign_count')
      setNewPayload({
        ...dataWithoutType,
        track_id: +trac_id,
      })
    }
  }, [
    data,
    trac_id,
  ])

  useEffect(() => {
    refetch()
  }, [
    refetch,
    refresh,
  ])

  const [
    esTrackUpdate,
  ] = useUpdateTrack(newPayload)

  const { openToast, } = useAppContext()

  const handleEditTrack = () => {
    setIsDisabled(true)
    esTrackUpdate()
      .then(() => {
        setIsDisabled(false)
        openToast?.({ message: 'Изменения внесены', })
      })
      .catch(e => {
        setIsDisabled(false)
        console.log(e)
      })
  }

  const editData = useMemo(() => {
    if (error) {
      return <div>...error..</div>
    }
    if (loading) {
      return <Preloader />
    } else if (data) {
      return (
        <div style={ { marginTop: '30px', } }>
          <InputTextDeprecated
            defaultHint="Название трека"
            styles={ {
              width: '100%',
              marginRight: '1rem',
            } }
            value={ newPayload?.file?.trackName }
            onChangeValue={ (e: any) => setNewPayload({
              ...newPayload,
              file: {
                ...newPayload.file,
                trackName: e,
              },
              name: e,
            }) }
          />
          <SectionWrapper content={ <div>
            <h2 className="discipline-detail-card__title">Связанные дисциплины</h2>
            <div style={ {
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            } }>
              { newPayload?.file?.associated.map((i: any, index: any) => <RelatedDiscipline newPayload={ newPayload } position={ index } setNewPayload={ setNewPayload } editMode={ true } key={ index } dis={ i } />
              ) }
            </div>
          </div> } />
          <SectionWrapper content={ <div>
            <h2 className="discipline-detail-card__title">Конечная цель трека</h2>
            <InputTextDeprecated
              defaultHint="Конечная цель"
              styles={ {
                width: '100%',
                marginRight: '1rem',
              } }
              value={ newPayload?.file?.target }
              onChangeValue={ (e: any) => setNewPayload({
                ...newPayload,
                file: {
                  ...newPayload.file,
                  target: e,
                },
              }) }
            />
          </div> } />
          <SectionWrapper content={ <div>
            <h2 className="discipline-detail-card__title">Резюме после изучения дисциплин курса (трека)</h2>
            { newPayload?.file?.resume.map((i: any, index: any) => {
              return (
                <InputTextDeprecated key={ index }
                  defaultHint={ `Пункт резюме № ${ index + 1 }` }
                  styles={ {
                    width: '100%',
                    marginRight: '1rem',
                    marginBottom: '10px',
                  } }
                  value={ i }
                  onChangeValue={ (e: any) => setNewPayload({
                    ...newPayload,
                    file: {
                      ...newPayload.file,
                      resume: newPayload.file.resume.map((i: any, place: any) => {
                        if (place === index) {
                          return e
                        }
                        return i
                      }),
                    },
                  }) }
                />
              )
            }) }
          </div> } />
          <ButtonSimple
            styles={ {
              marginRight: '.6rem',
              width: 'fit-content',
              alignSelf: 'flex-end',
              marginTop: '40px',
            } }
            active
            value="Сохранить изменения"
            isDisabled={ isDisabled }
            onClickHandler={ handleEditTrack }
          />
        </div>
      )
    }
  }, [
    data,
    error,
    loading,
    newPayload,
  ])


  return (
    <NewPageWrapper>
      <HistoryLinks
        links={
          [
            {
              value: 'Главная',
              path: '/',
            },
            {
              value: 'Высшее образование',
              path: '/high-education',
            },
            {
              value: 'Выбор дисциплин и факультативов',
              path: '/learning-pathway',
            },
          ]
        }
        loc={ name } />
      { editData }
    </NewPageWrapper>
  )
}


export default EditTrackPage
