import { EcTableName, } from '@/legacy/components/EffectiveContract/constants/effectiveContractTableName-constants'
import { UserRoles, } from '@/legacy/constants'


export const legacy_effectiveContractModerGroupe = [ // TODO: Выпилить этот объект, все должно работать через новую мапу
  UserRoles.URVO_ADMIN,
  UserRoles.AUP_ADMIN,
  UserRoles.DMS_MODER,
  UserRoles.DMS_MODER,
  UserRoles.UFPN_MODER,
  UserRoles.DM_MODER,
  UserRoles.UONID_MODER,
  UserRoles.UKP_MODER,
  UserRoles.URVO_MODER,
  UserRoles.URVO_TWO_MODER,
  UserRoles.TPD_MODER,
  UserRoles.AUP_PFU_MODER,
  UserRoles.AUP_PK_MODER,
  UserRoles.AUP_DMS_MODER,
  UserRoles.AUP_PK_MODER,
  UserRoles.AUP_CK_MODER,
]


type EffectiveContractModerGroupe = {
  [key in UserRoles]?: {
    canSee: EcTableName[],
    canEditAchievement: EcTableName[],
    canChangeStatus: EcTableName[],
    canAddNewRecords: EcTableName[],
  }
}


export const EC_MODER_GROUPE: EffectiveContractModerGroupe = {
  [UserRoles.URVO_ADMIN]: {
    canSee: Object.values(EcTableName),
    canChangeStatus: Object.values(EcTableName),
    canEditAchievement: Object.values(EcTableName)
      .filter(tableName =>
        // tableName !== EcTableName.TABLE_1_1
        // tableName !== EcTableName.TABLE_1_11
        // tableName !== EcTableName.TABLE_1_4
        tableName !== EcTableName.TABLE_2_79
        && tableName !== EcTableName.TABLE_2_8
        && tableName !== EcTableName.TABLE_2_9),
    canAddNewRecords: Object.values(EcTableName)
      .filter(tableName =>
      // tableName !== EcTableName.TABLE_1_1
        // tableName !== EcTableName.TABLE_1_11
        tableName !== EcTableName.TABLE_1_4
        && tableName !== EcTableName.TABLE_2_79
        && tableName !== EcTableName.TABLE_2_8
        && tableName !== EcTableName.TABLE_2_9),
  },


  [UserRoles.DM_MODER]: {
    canSee: [
      EcTableName.TABLE_1_1,
      EcTableName.TABLE_1_3,
    ],
    canChangeStatus: [
      EcTableName.TABLE_1_1,
      EcTableName.TABLE_1_3,
    ],
    canEditAchievement: [
      EcTableName.TABLE_1_1,
      EcTableName.TABLE_1_3,
    ],
    canAddNewRecords: [
      EcTableName.TABLE_1_1,
      EcTableName.TABLE_1_3,
    ],
  },


  [UserRoles.UFPN_MODER]: {
    canSee: [
      EcTableName.TABLE_1_4,
      EcTableName.TABLE_2_3,
      EcTableName.TABLE_2_7,
      EcTableName.TABLE_2_8,
      EcTableName.TABLE_2_9,
      EcTableName.TABLE_2_10,
      EcTableName.TABLE_3_1,
    ],
    canChangeStatus: [
      EcTableName.TABLE_1_4,
      EcTableName.TABLE_2_3,
      EcTableName.TABLE_2_7,
      EcTableName.TABLE_2_8,
      EcTableName.TABLE_2_9,
      EcTableName.TABLE_2_10,
      EcTableName.TABLE_3_1,
    ],
    canEditAchievement: [
      EcTableName.TABLE_2_9,
    ],
    canAddNewRecords: [
      EcTableName.TABLE_2_9,
    ],
  },


  [UserRoles.URVO_MODER]: {
    canSee: [
      // EcTableName.TABLE_2_2,
      EcTableName.TABLE_2_3,
      EcTableName.TABLE_2_4,
      EcTableName.TABLE_2_5,
      EcTableName.TABLE_2_6,
      EcTableName.TABLE_3_1,
      EcTableName.TABLE_3_2,
      EcTableName.TABLE_3_3,
      EcTableName.TABLE_3_4,
      EcTableName.TABLE_3_5,
      EcTableName.TABLE_4_1,
    ],
    canChangeStatus: [
      // EcTableName.TABLE_2_2,
      EcTableName.TABLE_2_3,
      EcTableName.TABLE_2_4,
      EcTableName.TABLE_2_5,
      EcTableName.TABLE_2_6,
      EcTableName.TABLE_3_1,
      EcTableName.TABLE_3_2,
      EcTableName.TABLE_3_3,
      EcTableName.TABLE_3_4,
      EcTableName.TABLE_3_5,
      EcTableName.TABLE_4_1,
    ],
    canEditAchievement: [
      // EcTableName.TABLE_2_2,
      EcTableName.TABLE_2_3,
      EcTableName.TABLE_2_4,
      EcTableName.TABLE_2_5,
      EcTableName.TABLE_2_6,
      EcTableName.TABLE_3_1,
      EcTableName.TABLE_3_2,
      EcTableName.TABLE_3_3,
      EcTableName.TABLE_3_4,
      EcTableName.TABLE_3_5,
    ],
    canAddNewRecords: [
      EcTableName.TABLE_2_2,
      EcTableName.TABLE_2_3,
      EcTableName.TABLE_2_4,
      EcTableName.TABLE_2_5,
      EcTableName.TABLE_2_6,
      EcTableName.TABLE_3_1,
      EcTableName.TABLE_3_2,
      EcTableName.TABLE_3_3,
      EcTableName.TABLE_3_4,
      EcTableName.TABLE_3_5,
    ],
  },


  [UserRoles.UONID_MODER]: {
    canSee: [
      EcTableName.TABLE_2_5,
    ],
    canChangeStatus: [
      EcTableName.TABLE_2_5,
    ],
    canEditAchievement: [
      EcTableName.TABLE_2_5,
    ],
    canAddNewRecords: [
      EcTableName.TABLE_2_5,
    ],
  },


  [UserRoles.UKP_MODER]: {
    canSee: [
      EcTableName.TABLE_2_5,
    ],
    canChangeStatus: [
      EcTableName.TABLE_2_5,
    ],
    canEditAchievement: [
      EcTableName.TABLE_2_5,
    ],
    canAddNewRecords: [
      EcTableName.TABLE_2_5,
    ],
  },


  [UserRoles.TPD_MODER]: {
    canSee: [
      EcTableName.TABLE_2_5,
    ],
    canChangeStatus: [
      EcTableName.TABLE_2_5,
    ],
    canEditAchievement: [
      EcTableName.TABLE_2_5,
    ],
    canAddNewRecords: [
      EcTableName.TABLE_2_5,
    ],
  },


  [UserRoles.URVO_TWO_MODER]: {
    canSee: Object.values(EcTableName),
    canChangeStatus: [], // read only role type
    canEditAchievement: [], // read only role type
    canAddNewRecords: [], // read only role type
  },

  [UserRoles.AUP_PFU_MODER]: {
    canSee: [
      EcTableName.TABLE_22_7,
      EcTableName.TABLE_22_8,
    ],
    canChangeStatus: [],
    canEditAchievement: [
      EcTableName.TABLE_22_7,
      EcTableName.TABLE_22_8,
    ],
    canAddNewRecords: [],
  },

  [UserRoles.AUP_ADMIN]: {
    canSee: [
      EcTableName.TABLE_22_7,
      EcTableName.TABLE_22_8,
    ],
    canChangeStatus: [
      EcTableName.TABLE_21_2,
      EcTableName.TABLE_22_1,
      EcTableName.TABLE_22_2,
      EcTableName.TABLE_22_4,
      EcTableName.TABLE_22_5,
      EcTableName.TABLE_22_6,
      EcTableName.TABLE_22_7,
      EcTableName.TABLE_22_8,
      EcTableName.TABLE_22_14,
      EcTableName.TABLE_22_15,
      EcTableName.TABLE_22_16,
      EcTableName.TABLE_22_17,
      EcTableName.TABLE_22_18,
    ],
    canEditAchievement: [
      EcTableName.TABLE_21_2,
      EcTableName.TABLE_22_1,
      EcTableName.TABLE_22_2,
      EcTableName.TABLE_22_3,
      EcTableName.TABLE_22_4,
      EcTableName.TABLE_22_5,
      EcTableName.TABLE_22_6,
      EcTableName.TABLE_22_7,
      EcTableName.TABLE_22_8,
      EcTableName.TABLE_22_13,
      EcTableName.TABLE_22_14,
      EcTableName.TABLE_22_15,
      EcTableName.TABLE_22_16,
      EcTableName.TABLE_22_17,
      EcTableName.TABLE_22_18,
    ],
    canAddNewRecords: [],
  },
}
