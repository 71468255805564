import { useEffect, useState, } from 'react'
import { useSelector, } from 'react-redux'
import { useLocation, } from 'react-router-dom'
import { useCreateEcData, useUpdateEcData, } from '../../../../../gql/hooks/effectiveContractHooks/useEffectiveContract'
import { useAppContext, } from '@/App/providers/AppProvider'
import AttachedFiles from '../../../../common/AttachedFiles/AttachedFiles'
import ButtonSimple from '../../../../common/ButtonSimple/ButtonSimple'
import { TitleH5, } from '../../../../common/GeneralElements/GeneralElements'
import InputFile from '../../../../common/InputFile/InputFile'
import InputSelectDeprecated from '../../../../common/InputSelectDeprecated/InputSelectDeprecated'
import InputTextDeprecated from '../../../../common/InputTextDeprecated/InputTextDeprecated'
import { defineUserGroupe, } from '../../../defineUserGroupe'
import { table_cat_2_41, } from '../../../popUpInputsCatalogs'
import { legacy_effectiveContractModerGroupe, } from '@/legacy/components/EffectiveContract/constants/effectiveContractModersAndAdmins-constants'

const Inputs_2_41 = ({
  onClickHandler,
  hint,
  defaultValues,
}: any) => {
  const { addNotification, } = useAppContext()


  const { search, } = useLocation()
  const moderateId = new URLSearchParams(search).get('id')
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const currentRole = useSelector(state => state.auth.choosenRole)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const currentUserType = useSelector(state => state.auth.userType)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const currentUserId = useSelector(state => state.auth.me.data.guid)

  const [
    user_ids,
  ] = useState(
    (
      defineUserGroupe(currentRole, legacy_effectiveContractModerGroupe)
      || defineUserGroupe(currentUserType, legacy_effectiveContractModerGroupe)
    )
      ? moderateId
      : currentUserId)


  const [
    onAddNewEcData,
  ] = useCreateEcData()
  const onAddNewEcDataHandler = (params: any) => {
    onAddNewEcData(params)
      .then(() => {
        addNotification?.(
          'Эффективный контракт',
          (
            (
              defineUserGroupe(currentRole, legacy_effectiveContractModerGroupe)
              || defineUserGroupe(currentUserType, legacy_effectiveContractModerGroupe)
            )
              ? moderateId
              : currentUserId
          ),
          { message: 'Ваше достижение добавлено!', },
          {
            path: '/effective-contract',
            from: 'Эффективный контракт',
          }
        )
      })
  }

  const [
    onUpdateRow,
  ] = useUpdateEcData()
  const onUpdateRowHandler = (id: any, newData: any) => onUpdateRow({
    variables: {
      rec_id: id,
      new_data: newData,
    },
  })


  const [
    courseName,
    setCourseName,
  ] = useState('')
  const [
    language,
    setLanguage,
  ] = useState('')
  const [
    countAuthors,
    setCountAuthors,
  ] = useState('')
  const [
    document,
    setDocument,
  ] = useState([])


  useEffect(() => {
    if (hint === 'edit') {
      setCourseName(defaultValues.courseName)
      setLanguage(defaultValues.language)
      setCountAuthors(defaultValues.countAuthors)
      // @ts-expect-error TS(2345): Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
      setDocument(defaultValues.document ? [
        defaultValues.document,
      ] : [])
    }
  }, [
    hint,
    defaultValues,
  ])


  const fieldsToDefault = () => {
    setCourseName('')
    setLanguage('')
    setCountAuthors('')
    setDocument([])
  }


  return <>
    <InputTextDeprecated
      defaultHint="Название онлайн-курса"
      value={ courseName }
      onChangeValue={ setCourseName }
    />

    <InputSelectDeprecated
      defaultHint="Язык, на котором разработан курс"
      items={ table_cat_2_41.language }
      defaultValue={ language }
      withResetValue={ true }
      callBack={ setLanguage }
    />

    <InputTextDeprecated
      defaultHint="Количество авторов"
      value={ countAuthors }
      onChangeValue={ setCountAuthors }
      numbersOnly
    />

    <br />

    <TitleH5>
      Копия страницы/скрина с образовательного сервера
    </TitleH5>

    { document.length < 1 ? (
      <InputFile
        onChange={ event => {
          // TODO: сделать uploadFile
          // uploadFile(...event.target.files, setDocument)
        } }
      />
    ) : false }


    <AttachedFiles
      isDeleteFilesAllowed={ true }
      fileList={ document }
      getIndexFile={ (index: any) => {
        setDocument(document.filter((file, fIndex) => fIndex !== index))
      } }
    />

    <br />

    <ButtonSimple
      isDisabled={ (
        courseName.trim().length < 1
        || language.trim().length < 1
        || countAuthors.trim().length < 1
        || document.length < 1
      ) }
      value={ hint === 'edit' ? 'Сохранить изменения' : 'Добавить достижение' }
      variant="full-filled"
      onClickHandler={ () => {
        if (hint === 'edit') {
          onUpdateRowHandler(defaultValues.rec_id, {
            courseName: courseName,
            language: language,
            countAuthors: countAuthors,
            document: document[0],
            status: 'pending',
            reason: '',
            scores: null,
          })

          fieldsToDefault()
          onClickHandler()
        } else {
          onAddNewEcDataHandler({
            variables: {
              user_ids: [
                user_ids,
              ],
              dataType: 'table_2_41',
              data: {
                courseName: courseName,
                language: language,
                countAuthors: countAuthors,
                document: document[0],
                status: 'pending',
                reason: '',
                scores: null,
                isAddedByModer: defineUserGroupe(currentRole, legacy_effectiveContractModerGroupe),
              },
            },
          })

          fieldsToDefault()
          onClickHandler()
        };
      } }
    />
  </>
}


export default Inputs_2_41
