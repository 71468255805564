import { NewPageWrapper, TitleH2, } from '@/legacy/components/common/GeneralElements/GeneralElements'
import HistoryLinks from '@/legacy/components/common/HistoryLinks/HistoryLinks'
import { PROJECT_SEMINAR, } from '@/legacy/Utils/routes'
import { Table, } from 'antd'
import { ColumnsType, } from 'antd/es/table'
// import styles from './ProjectSeminarByGroup.module.less'
import useUrlSearch from '@/legacy/hooks/useUrlSearch'
import useGetProjectSeminarGroupDetail from '@/legacy/gql/hooks/projects/useGetProjectSeminarGroupDetail'


export interface DataTypeProjectSeminarTable {
  studentName: string,
  status: string,
  projectSupervisorName: string,
}

const columns: ColumnsType<DataTypeProjectSeminarTable> = [
  {
    title: 'ФИО студента',
    dataIndex: 'studentName',
    key: 'studentName',
  },
  {
    title: 'Оценка',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'ФИО руководителя проекта',
    dataIndex: 'projectSupervisorName',
    key: 'projectSupervisorName',
  },
]


const historyLinks = [
  {
    value: 'Главная',
    path: '/',
  },
  {
    value: 'Проектный семинар',
    path: PROJECT_SEMINAR,
  },
]


const ProjectSeminarByGroup = (): JSX.Element => {
  const groupName = useUrlSearch('groupName')
  const data = useGetProjectSeminarGroupDetail(groupName)


  return (
    <NewPageWrapper>
      <HistoryLinks links={ historyLinks } loc="Информация по группе" />
      <TitleH2>
        Информация по группе
      </TitleH2>

      <br />

      <Table columns={ columns } dataSource={ data } pagination={ false } />
    </NewPageWrapper>
  )
}


export default ProjectSeminarByGroup
