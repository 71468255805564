/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { createApi, fetchBaseQuery, } from '@reduxjs/toolkit/query/react'
import { EndPoints, } from '../../lib/endpoints'
import { URL, } from '../../Utils/constants'
import { Buffer, } from 'buffer'

export const imageApi = createApi({
  reducerPath: 'image',
  baseQuery: fetchBaseQuery({
    baseUrl: `https://${ URL }/api`,
    credentials: 'include',
    prepareHeaders: (headers, { getState, }) => {
      if (localStorage.getItem('access_token')) {
        // @ts-expect-error TS(2345): Type 'null' is not assignable to type 'string'
        headers.set('x-access-token', localStorage.getItem('access_token'))
      }
      console.log('Setting headers', Array.from(headers))
      return headers
    },
  }),
  endpoints: build => ({
    // @ts-expect-error TS(2345): Type '(response: any) => { data: string; }' is not assignable to type 'undefined'
    avatar: build.query({
      queryFn: () => {
        return fetch(EndPoints.avatar, {
          credentials: 'include',
        })
          .then(res => res.arrayBuffer())
          .then(buffer => Buffer.from(buffer).toString('base64'))
          .then(base64 => ({ data: base64, }))
      },
      transformResponse: (response: any) => {
        console.log('*********** RESPINONSE', response)
        return { data: 'Hello', }
      },
    }),
    userpic: build.query({
      // @ts-expect-error TS(2345): Type '(response: any) => { data: string; }' is not assignable to type 'undefined'
      queryFn: guid => {
        return fetch(`${ EndPoints.userpic }/${ guid }`, {
          credentials: 'include',
        })
          .then(res => res.arrayBuffer())
          .then(buffer => Buffer.from(buffer).toString('base64'))
          .then(base64 => ({ data: base64, }))
      },
      transformResponse: (response: any) => {
        console.log('*********** RESPINONSE', response)
        return { data: 'Hello', }
      },
    }),
    photos: build.query({
      // @ts-expect-error TS(2322): Type '(id: any) => Promise<{ error: FetchBaseQuery... Remove this comment to see the full error message
      queryFn: id => {
        return fetch(`${ EndPoints.download }/${ id }`, {
          credentials: 'include',
        })
          .then(res => res.arrayBuffer())
          .then(buffer => Buffer.from(buffer).toString('base64'))
          .then(base64 => ({ data: base64, }))
      },
      transformResponse: (response: any) => {
        console.log('*********** RESPINONSE', response)
        return { data: 'Hello', }
      },
    }),
    uploadFormData: build.mutation({
      queryFn: body => fetch(EndPoints.upload, {
        method: 'POST',
        body: body,
        credentials: 'include',
      })
        .then(async res => {
          if (!res.ok) {
            throw Error(await res.text())
          }
          return res
        })
        .then(res => res.json())
        .then(res => {
          if (res.error) {
            throw Error(res.error)
          }
          return { data: res, }
        })
        .catch(e => {
          return { error: e.message, }
        }),
    }),
  }),
})

export const {
  useAvatarQuery,
  usePhotosQuery,
  useUploadFormDataMutation,
  useUserpicQuery,
} = imageApi
