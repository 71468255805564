import { useEffect, useState, } from 'react'
import { useGetTeachersWithAchievement, } from '../../../gql/hooks/effectiveContractHooks/useGetTeacher'
import HistoryLinks from '../../common/HistoryLinks/HistoryLinks'
import TableContainer from '../UserList/UsersTable/UsersTable'

const AmsUserList = () => {
  const [
    usersTable,
    setUsersTable,
  ] = useState([])
  const [
    sortColumn,
    setSortColumn,
  ] = useState('pending')
  const [
    sortOrder,
    setSortOrder,
  ] = useState('desc')

  const getTeachersWithAchievement = useGetTeachersWithAchievement(
    undefined,
    {
      offset: 0,
      limit: 50,
    },
    [
      {
        column: sortColumn,
        order: sortOrder,
      },
    ],
    'ams'
  )

  useEffect(() => {
    if (!getTeachersWithAchievement.loading && getTeachersWithAchievement.data) {
      const data = getTeachersWithAchievement.data.ecDataTableByUserAndStatus.items

      setUsersTable(data.map((user: any) => {
        return {
          id: user.user_id,
          fullName: user.full_name,
          ...user,
        }
      }))
    }
  }, [
    getTeachersWithAchievement,
  ])


  const onClickSort = (pagination: any, filters: any, sorter: any) => {
    if (sorter.field === 'fullName') {
      setSortColumn('full_name')
    }

    if (sorter.field === 'unConfirmCount') {
      setSortColumn('pending')
    }

    if (sorter.field === 'confirmCount') {
      setSortColumn('approved')
    }

    if (sorter.field === 'date') {
      setSortColumn('lastUpdate')
    }

    if (sorter.order === 'ascend') {
      setSortOrder('asc')
    }

    if (sorter.order === 'descend' || !sorter.order) {
      setSortOrder('desc')
    }

    if (!sorter.order) {
      setSortColumn('pending')
      setSortOrder('desc')
    }
  }


  return (
    <div className="effective-contract">
      <HistoryLinks
        links={ [
          {
            value: 'Главная',
            path: '/',
          },
        ] }
        loc="Модерация достижений АУП"
      />

      <h2 className="effective-contract__title">Модерация достижений АУП</h2>

      <TableContainer
        mode="AMS"
        isLoading={ getTeachersWithAchievement?.loading }
        users={ usersTable }
        onClickSort={ onClickSort }
      />
    </div>
  )
}


export default AmsUserList
