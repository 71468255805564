/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo, } from 'react'
import './Notifications.less'
import HistoryLinks from '../common/HistoryLinks/HistoryLinks'
import TypeFilter from '../TypeFilter/TypeFilter'
import { IconChevronUp, IconChevronDown, } from '@tabler/icons'
import { useSelector, } from 'react-redux'
import CardNotify from './CardNotify/CardNotify'
import { useAppContext, } from '@/App/providers/AppProvider'
import NoContracts from '../Finances/PaymentPage/NoContracts/NoContracts'
import Preloader from '../common/PreLoader/Preloader'
import { NotificationMetaType, } from '@/App/types'
import { useNotifications, } from '@/shared/api/notifications'


function Notifications () {
  const [
    isTypes,
    setIsTypes,
  ] = useState(false)
  // const [typeAmount, setTypeAmount] = useState(false)


  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const lessonType: string = useSelector(state => state.notify.type)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const updateNotif = useSelector(state => state.subscriptions.notifChanged)

  const notifTypes = [
    'Все',
    'Администратор',
    'Расписание',
    'МФЦ',
    'Финансы',
    'Биржа труда',
    'Услуги',
    'Проектная деятельность',
    'Библиотека',
    'Мое обучение',
    'Эффективный контракт',
  ]

  const payload = lessonType === 'Все' ? {
    /* metaType: NotificationMetaType.dynamic, */
  } : {
    source: [
      lessonType,
    ],
    /* metaType: NotificationMetaType.dynamic, */
  }

  const { updateNotification, } = useAppContext()

  const { data, error, loading, refetch, } = useNotifications?.(payload)


  useEffect(() => {
    refetch()
  }, [
    updateNotif,
    refetch,
  ])

  const notifications = useMemo(() => {
    if (error) {
      return <div>...error...</div>
    } else if (loading) {
      return <Preloader />
    } else if (data) {
      return data.notificationList.items.length > 0 ? data.notificationList.items.map((i: any) => <li key={ i.msg_id }>
        <CardNotify i={ i } />
      </li>) : <NoContracts title="У вас нет оповещений по выбранному разделу" />
    }
  }, [
    data,
    error,
    loading,
  ])


  return (
    <div className="InnerPage Notifications">
      <HistoryLinks
        links={ [
          {
            value: 'Главная',
            path: '/',
          },
        ] }
        loc="Уведомления"
      />

      <div className="studentInner">
        <div className="tableSet">
          <h2 className="profileTitle">Уведомления</h2>

          <div className="N-filter">
            <h3>Фильтр</h3>

            <div
              className="lectures"
              onClick={ () => setIsTypes(!isTypes) }
            >
              { lessonType }

              { isTypes ? (
                <IconChevronUp size={ 20 } />
              ) : (
                <IconChevronDown size={ 20 } />
              ) }

              <TypeFilter
                isOpen={ isTypes }
                items={ notifTypes }
                type="Уведомления"
              /></div>
          </div>
        </div>

        <ul>
          { notifications }
        </ul>
      </div>
    </div>
  )
}


export default Notifications
