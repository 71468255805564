import { NotificationStatus, } from '@/__generated__/graphql'
import { gql, useMutation, useQuery, useSubscription, } from '@apollo/client'

const ON_NOTIFICATION = gql(`
    subscription OnUiNotification {
        uiNotification
    }
`)

const LIST_NOTIFICATIONS = gql(`
    query ListNotification($i:NotificationListInput) {
      notificationList(input:$i){
        items{
          body
          meta
          from
          msg_id
          status
          created
          source
        }
      }
    }
`)

const NOTIFICATION_STATS = gql(`
    query NotificationStats {
        uiNotificationsStats
    }
`)

const ADD_NOTIFICATION = gql(`
    mutation AddNotification($i:UINotificationInput) {
      notificationCreate(input:$i)
    }
`)

const UPDATE_NOTIFICATION = gql(`
    mutation UpdateNotification($message_ids: [Int!]!, $status: NotificationStatus) {
      notificationUpdateStatus(msg_id: $message_ids, status: $status)
    }
`)

interface PaginationType {
  offset: number
  limit: number
}

interface IAddNotificationBody {
  message: string;
  tab?: string;
  reqId?: number
}

enum NotificationMetaType {
  message = 'message',
  dynamic = 'dynamic',
}

enum SortOrder {
  asc,
  desc
}

interface SortField {
  column: string
  order: SortOrder
  nulls?: string
}

interface INotificationListInput {
  pagination?: PaginationType
  status?: [NotificationStatus]
  metaType?: NotificationMetaType
  source?: string[]
  sort?: [SortField]
}

export const useAddNotification = () => useMutation(ADD_NOTIFICATION)
export const useUpdateNotification = () => useMutation(UPDATE_NOTIFICATION)
export const useNotificationStats = () => useQuery(NOTIFICATION_STATS)
export const useNotifications = (i: INotificationListInput) => useQuery(LIST_NOTIFICATIONS, { variables: { i, }, })
export const useNotificationsSub = () => useSubscription(ON_NOTIFICATION)
