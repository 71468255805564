import ButtonSimple from '@/legacy/components/common/ButtonSimple/ButtonSimple'
import { NewPageWrapper, } from '@/legacy/components/common/GeneralElements/GeneralElements'
import HistoryLinks from '@/legacy/components/common/HistoryLinks/HistoryLinks'
import TabButtons from '@/legacy/components/common/TabButtons/TabButtons'
import React from 'react'
import { useDispatch, useSelector, } from 'react-redux'
import useRedirect from '@/legacy/hooks/useRedirect'
import { mfcButtons, } from '@/legacy/components/Mfc/MfcUtils'
import { setStatus, } from '@/legacy/redux/MfcSlices/MfcSlice'
import { GET_DUTY_NOTE, } from '@/legacy/Utils/routes'

const MyDutyNotes = () => {
  const dispatch = useDispatch()
  const redirect = useRedirect()

  const activeStatus = useSelector((state: any) => state.mfc.activeStatus)
  function isActive (key: any) {
    return activeStatus.name === key.name
  }

  return (
    <NewPageWrapper className="projects_introduction">
      <HistoryLinks
        links={ [
          {
            value: 'Главная',
            path: '/',
          },
        ] }
        loc="Мои служебные записки"
      />
      <h1 className="page-main-title">Мои служебные записки</h1>
      <div className="battonsWrap">
        <TabButtons
          items={ mfcButtons }
          isActive={ isActive }
          clickHandler={ (i: any) => dispatch(setStatus(i)) }
        />
        <ButtonSimple
          value={ 'Подать служебную записку' }
          active={ true }
          variant="alone"
          onClickHandler={ () => redirect(GET_DUTY_NOTE) }
        />
      </div>
    </NewPageWrapper>
  )
}

export default MyDutyNotes
