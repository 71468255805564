import { NewPageWrapper, TitleH2, } from '@/legacy/components/common/GeneralElements/GeneralElements'
import HistoryLinks from '@/legacy/components/common/HistoryLinks/HistoryLinks'
import GroupCard from '@/legacy/components/Projects/ProjectSeminar/components/GroupCard/GroupCard'
import styles from './ProjectSeminar.module.less'
import useGetProjectSeminarGroups from '@/legacy/gql/hooks/projects/useGetProjectSeminarGroups'
import useGetUserInfo from '@/legacy/hooks/useGetUserInfo'

const historyLinks = [
  {
    value: 'Главная',
    path: '/',
  },
]


const ProjectSeminar = (): JSX.Element => {
  const { userGuid, } = useGetUserInfo()
  const groupNames = useGetProjectSeminarGroups(userGuid)


  return (
    <NewPageWrapper>
      <HistoryLinks links={ historyLinks } loc="Проектный семинар" />
      <TitleH2>
        Проектный семинар
      </TitleH2>

      <h3 className={ styles.subtitle }>Группы, у которых я веду дисциплину «Проектный семинар»</h3>

      <br />

      { groupNames.length > 0 && (
        <div className={ styles.cardsWrapper }>
          { groupNames.map((name, index) => (
            <GroupCard value={ name } key={ index } />
          )) }
        </div>
      ) }

      { groupNames.length < 1 && (
        <p>У вас нет данной дисциплины в расписании</p>
      ) }
    </NewPageWrapper>
  )
}


export default ProjectSeminar
