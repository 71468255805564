/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, } from 'react'
import '../../../CardDisciplinePathway/CardDisciplinePathway.less'
import { IconCircleCheck, } from '@tabler/icons'
import clsx from 'clsx'
import DeletePopup from '../../../../../../common/DeletePopup/DeletePopup'
import AnimatedTrash from '../../../../../../common/AnimatedTrash/AnimatedTrash'
import { useUpdateStats, } from '../../../../../../../gql/hooks/useMyEducation'
import { useDeleteFacultative, } from '../../../../../../../gql/hooks/useMyEducation'
import { IconLockOpen, IconLock, } from '@tabler/icons'
import { useUpdateOneActiveStatus, } from '../../../../../../../gql/hooks/useMyEducation'
import './ElectiveCard.less'
import { DisciplineType, } from '@/__generated__/graphql'
import TooltipTgu from '@/shared/ui/TooltipTgu/TooltipTgu'
import { TooltipPlacementVariant, } from '@/shared/ui/TooltipTgu/types'
import { useAppContext, } from '@/App/providers/AppProvider'


const ElectiveCard = ({
  type,
  id,
  is_signed,
  is_visible,
  handleNavigate,
  name,
  styles,
  active = false,
  role = false,
  nameSpec,
  is_active,
}: any) => {
  const [
    isDeletePopup,
    setIsDeletePopup,
  ] = useState(false)

  const [
    esUpdateDisciplineStats,
  ] = useUpdateStats({
    ref_id: Number(id),
    namespec: nameSpec?.namespec,
    type: 'elective',
    counter: 'view',
  })

  const { openToast, } = useAppContext()

  const [
    esDeleteFacultative,
  ] = useDeleteFacultative({ facultative_id: id, })

  const handleDeleteDiscipline = (e: any) => {
    e.stopPropagation()
    esDeleteFacultative()
      .then(res => {
        setIsDeletePopup(!isDeletePopup)
        openToast?.({ message: 'Дисциплина успешно удалена', })
      })
      .catch(e => console.log(e))
  }

  const handleRedirect = (e: any) => {
    if (role === 'student' || role === 'student-rakus') {
      esUpdateDisciplineStats()
        .then(() => handleNavigate(e))
        .catch(e => console.log(e))
    } else {
      handleNavigate(e)
    }
  }

  const [
    esDisciplineActivate,
  ] = useUpdateOneActiveStatus()

  const handleActivateOne = (e: any, facultative_id: any, is_active: any) => {
    e.stopPropagation()
    esDisciplineActivate({
      variables: {
        is_active,
        facultative_id,
        type: DisciplineType.Elective,
      },
    })
  }

  return (
    <>
      <div
        className={ clsx({
          'card-discipline-pathway': true,
          'card-discipline-pathway_active': is_signed,
          'unvisible-card': !is_visible,
        }) }
        style={ styles }
        onClick={ e => handleRedirect(e) }
      >
        <div className="card-discipline-pathway__title-wrapper">
          <div
            className="card-discipline-pathway__name"
            style={ { width: '100%', } }
          >
            <div
              style={ {
                display: 'flex',
                justifyContent: 'space-between',
              } }
            >
              { name }
              { is_signed ? (
                <IconCircleCheck
                  style={ {
                    color: '#7671DD',
                    fontSize: '1.5rem',
                    marginLeft: '.5rem',
                  } }
                />
              ) : (
                false
              ) }
              { role === 'osrv-moder' ? (
                <div className="moderStatusesWrapElective">
                  { is_active ? (
                    <TooltipTgu title='Закрыть запись'
                      children={ <IconLockOpen
                        size={ 27 }
                        color="#7671dd"
                        onClick={ e => handleActivateOne(e, [
                          id,
                        ], !is_active) } /> }
                      placement={ TooltipPlacementVariant.bottom } />
                  ) : (
                    <TooltipTgu placement={ TooltipPlacementVariant.bottom } title={ 'Открыть запись' }
                      children={
                        <IconLock
                          color="#7F8190"
                          onClick={ e => handleActivateOne(e, [
                            id,
                          ], !is_active) }
                          size={ 27 }
                        />
                      }
                    />
                  ) }
                </div>
              ) : (
                false
              ) }
            </div>
          </div>
        </div>

        <div className="card-discipline-pathway__type">
          { type }
          { role === 'osrv-moder' && (
            <AnimatedTrash
              clickHandler={ (e: any) => {
                e.stopPropagation()
                setIsDeletePopup(true)
              } }
              styles={ {
                width: '15px',
                height: '20px',
              } }
            />
          ) }
        </div>
      </div>
      <DeletePopup
        title={ `Удалить ${ type } ${ name }?` }
        onClickHandler={ (e: any) => handleDeleteDiscipline(e) }
        isDeletePopup={ isDeletePopup }
        setIsDeletePopup={ setIsDeletePopup }
      />
    </>
  )
}

export default ElectiveCard
