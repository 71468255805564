import './UserList.less'

import HistoryLinks from '../../common/HistoryLinks/HistoryLinks'
import FilterDropDown from '../../common/FilterDropDown/FilterDropDown'
import ButtonSimple from '../../common/ButtonSimple/ButtonSimple'
import UsersTable from './UsersTable/UsersTable'
import PaginationSimple from '../../common/PaginationSimple/PaginationSimple'

import { useEffect, useState, } from 'react'
import CollapseContainer2, { AccordionInnder, } from '../../common/CollapseContainer2/CollapseContainer2'
import { GET_TEACHERS, useGetTeachersWithAchievement, } from '../../../gql/hooks/effectiveContractHooks/useGetTeacher'
import InputTextWithDebounce from '../../common/InputTextWithDebounce/InputTextWithDebounce'
import { useGetModeratorPositionsFilter, } from '../../../gql/hooks/effectiveContractHooks/useEcCatalogs'
import LinkButton from '../../common/LinkButton/LinkButton'
import { useLazyQuery, } from '@apollo/client'

import { useDispatch, useSelector, } from 'react-redux'
import ChooseField from '../../common/Fields/ChooseField/ChooseField'
import { useEcExportSubscription, } from '../../../gql/hooks/effectiveContractHooks/useEcSubscription'
import { QUERY_EXPORT_TABLE_3_5, } from '../../../gql/hooks/effectiveContractHooks/exportQueries'
import { downloadFile, } from '../../../hooks/useDownloadFile'
import useGetInstitutes from '../../../gql/hooks/common/useGetInstitutes'
import useGetRole from '@/legacy/redux/hooks/common/useGetRole'
import { setTeacherRatingFilterSortColumn, setTeacherRatingFilterSortOrder, setAdminUserlistFilterUsersSearch, setAdminUserlistFilterSectionFilter, setAdminUserlistFilterStatusFilter, setAdminUserlistFilterInstituteFilter, setAdminUserlistFilterPositionNameFilter, setAdminUserlistFilterTableRowsLength, } from '@/legacy/redux/efectiveContractSlices/efectiveContractSlices'


const UserList = () => {
  const exportSubcripton = useEcExportSubscription()
  const [
    queryExportTable_3_5,
  ] = useLazyQuery(QUERY_EXPORT_TABLE_3_5, {
    fetchPolicy: 'no-cache',
  })

  const [
    isLoadingExportTable_3_5,
    setIsLoadingExportTable_3_5,
  ] = useState(false)
  const [
    errorExport_3_5,
    setErrorExport_3_5,
  ] = useState(false)

  useEffect(() => {
    if (exportSubcripton.data) {
      const data = exportSubcripton.data.ecExportsProgress

      if (data.state === 'start' && data.type === '3_5') {
        setIsLoadingExportTable_3_5(true)
      }

      if (data.state === 'done' && data.type === '3_5') {
        setIsLoadingExportTable_3_5(false)

        if (data.result.error) {
          return setErrorExport_3_5(true)
        }

        return downloadFile({
          ...data.result,
          name: 'Отдельные достижения',
        })
      }
    }
  }, [
    exportSubcripton.loading,
    exportSubcripton.data,
  ])

  const role = useGetRole()

  const [
    usersTable,
    setUsersTable,
  ] = useState([])
  const [
    offset,
    setOffset,
  ] = useState(0)
  const [
    countPages,
    setCountPages,
  ] = useState(1)
  const [
    page,
    setPage,
  ] = useState(1)

  const dispatch = useDispatch()
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const usersSearch = useSelector(state => state.efContract.adminUserlistFilter.usersSearch)

  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const sectionFilter = useSelector(state => state.efContract.adminUserlistFilter.section)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const statusFilter = useSelector(state => state.efContract.adminUserlistFilter.status)

  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const instituteFilter = useSelector(state => state.efContract.adminUserlistFilter.instituteFilter)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const positionNameFilter = useSelector(state => state.efContract.adminUserlistFilter.positionNameFilter)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const tableRowsLength = useSelector(state => state.efContract.adminUserlistFilter.tableRowsLength)
  const sortOrder = useSelector((state: any) => state.efContract.teacherRatingFilter.sortOrder)
  const sortColumn = useSelector((state: any) => state.efContract.teacherRatingFilter.sortColumn)
  const onClickSort = (pagination: any, filters: any, sorter: any) => {
    if (sorter.field === 'fullName') {
      dispatch(setTeacherRatingFilterSortColumn('full_name'))
    }

    if (sorter.field === 'unConfirmCount') {
      dispatch(setTeacherRatingFilterSortColumn('pending'))
    }

    if (sorter.field === 'confirmCount') {
      dispatch(setTeacherRatingFilterSortColumn('approved'))
    }

    if (sorter.field === 'date') {
      dispatch(setTeacherRatingFilterSortColumn('lastUpdate'))
    }

    if (sorter.order === 'ascend') {
      dispatch(setTeacherRatingFilterSortOrder('asc'))
    }

    if (sorter.order === 'descend' || !sorter.order) {
      dispatch(setTeacherRatingFilterSortOrder('desc'))
    }

    if (!sorter.order) {
      dispatch(setTeacherRatingFilterSortColumn('pending'))
      dispatch(setTeacherRatingFilterSortOrder('desc'))
    }
  }
  // @ts-expect-error TS(2554): Expected 4 arguments, but got 3.
  const getTeachersWithAchievement = useGetTeachersWithAchievement(
    {
      dataType: sectionFilter,
      search: usersSearch,
      institutes: instituteFilter,
      positions: positionNameFilter,
      status: statusFilter,
    },
    {
      offset: offset,
      limit: tableRowsLength,
    },
    [
      {
        column: sortColumn,
        order: sortOrder,
      },
    ]
  )

  const [
    searchTeacher,
    searchTeacherResult,
  ] = useLazyQuery(
    GET_TEACHERS,
    {
      variables: {
        search: usersSearch,
        pagination: {
          offset: 0,
          limit: 15,
        },
      },
    }
  )

  useEffect(() => {
    if (searchTeacherResult?.data && usersTable.length === 0) {
      setUsersTable(
        searchTeacherResult?.data?.teacherList?.items.map((user: any) => {
          return {
            id: user.id,
            fullName: user.full_name,
            approved: user.approved ? user.approved : 0,
            pending: user.pending ? user.pending : 0,
          }
        }))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchTeacherResult.loading,
    searchTeacherResult.data,
  ])


  useEffect(() => {
    if (getTeachersWithAchievement?.data?.ecDataTableByUserAndStatus?.items) {
      const total = getTeachersWithAchievement.data.ecDataTableByUserAndStatus.pagination.total
      const items = getTeachersWithAchievement.data.ecDataTableByUserAndStatus.items

      setCountPages(Math.ceil(total / tableRowsLength))

      setUsersTable(
        items.map((user: any) => {
          return {
            id: user.user_id,
            fullName: user.full_name,
            approved: user.approved ? user.approved : 0,
            pending: user.pending ? user.pending : 0,
            lastUpdate: user.lastUpdate,
          }
        }))
    }

    if (getTeachersWithAchievement?.data?.ecDataTableByUserAndStatus?.items?.length < 1) {
      searchTeacher()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getTeachersWithAchievement.loading,
    getTeachersWithAchievement.data,
  ])


  useEffect(() => {
    setOffset(0)
    setPage(1)
  }, [
    usersSearch,
  ])


  const [
    institutesList,
    setInstitutesList,
  ] = useState([])
  const getInstitutesQuery = useGetInstitutes()

  useEffect(() => {
    if (getInstitutesQuery?.data) {
      setInstitutesList(getInstitutesQuery.data.catalogs.ecInstList)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getInstitutesQuery.loading,
  ])


  const [
    allTeachersPositions,
    setAllTeachersPositions,
  ] = useState([])
  const getModeratorPositionsFilter = useGetModeratorPositionsFilter()

  useEffect(() => {
    if (getModeratorPositionsFilter?.data) {
      setAllTeachersPositions(
        getModeratorPositionsFilter.data.catalogs.ecModeratorPositionsFilter)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getModeratorPositionsFilter.loading,
  ])


  return (
    <div className="effective-contract">
      <HistoryLinks
        links={ [
          {
            value: 'Главная',
            path: '/',
          },
        ] }
        loc="Модерация достижений"
      />

      <div style={ {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'baseline',
      } }>
        <h2 className="effective-contract__title">Модерация достижений</h2>

        { role === 'urvo-admin' ? (
          <LinkButton
            isError={ errorExport_3_5 }
            errorMessage="Попробуйте повторить запрос позже"
            type='exportAchievement'
            onClickHandler={ queryExportTable_3_5 }
            isLoading={ isLoadingExportTable_3_5 }
          />
        ) : false }
      </div>

      <CollapseContainer2 styles={ { marginBottom: '2rem', } }>
        <AccordionInnder
          isOpened
          headerText={ (
            <span className="effective-contract__subtitle">
              Фильтрация пользователей
            </span>
          ) }
        >
          <UsersFilter
            usersSearch={ usersSearch }
            setUsersSearch={ (data: any) => dispatch(setAdminUserlistFilterUsersSearch(data)) }

            sectionFilter={ sectionFilter }
            setSectionFilter={ (data: any) => dispatch(setAdminUserlistFilterSectionFilter(data)) }
            statusFilter={ statusFilter }
            setStatusFilter={ (data: any) => dispatch(setAdminUserlistFilterStatusFilter(data)) }

            institutesList={ institutesList }
            allTeachersPositions={ allTeachersPositions }
            instituteFilter={ instituteFilter }
            positionNameFilter={ positionNameFilter }
            setInstituteFilter={ (data: any) => dispatch(setAdminUserlistFilterInstituteFilter(data)) }
            setPositionNameFilter={ (data: any) => dispatch(setAdminUserlistFilterPositionNameFilter(data)) }
          />
        </AccordionInnder>
      </CollapseContainer2>

      <UsersTable
        isLoading={ getTeachersWithAchievement?.loading }
        users={ usersTable }
        onClickSort={ onClickSort }
      />


      <div className='paginationWrap'>
        <PaginationSimple
          page={ page }
          count={ countPages }
          onChange={ (event: any, newPage: any) => {
            setOffset((newPage - 1) * tableRowsLength)
            setPage(newPage)
          } }
          style={ { zIndex: 0, } }
        />

        <div className="limitWrap">
          Записей на странице
          <ChooseField
            items={ [
              20,
              40,
              60,
              80,
              100,
            ] }
            value={ tableRowsLength }
            handler={ (data: any) => dispatch(setAdminUserlistFilterTableRowsLength(data)) }
          />
        </div>
      </div>

    </div>
  )
}


const UsersFilter = ({
  usersSearch,
  setUsersSearch,
  sectionFilter,
  setSectionFilter,
  statusFilter,
  setStatusFilter,
  institutesList,
  allTeachersPositions,
  instituteFilter,
  setInstituteFilter,
  positionNameFilter,
  setPositionNameFilter,
}: any) => {
  const [
    majorFilter,
    setMajorFilter,
  ] = useState(
    // @ts-expect-error TS(2550): Property 'entries' does not exist on type 'ObjectC... Remove this comment to see the full error message
    (sectionFilter && sectionFilter[0]?.major) ? Object.entries(mapOfDataTypes).find((item: any) => item[1].major === sectionFilter[0].major)[0] : ''
  )
  const [
    minorFilter,
    setMinorFilter,
  ] = useState(
    // @ts-expect-error TS(2550): Property 'entries' does not exist on type 'ObjectC... Remove this comment to see the full error message
    (sectionFilter && sectionFilter[0]?.minor) ? Object.entries(Object.entries(mapOfDataTypes).find((item: any) => item[1].major === sectionFilter[0].major)[1].types).find((item: any) => item[1].minor === sectionFilter[0].minor)[0] : ''
  )
  const [
    status,
    setStatus,
  ] = useState(statusFilter)

  const [
    instituteValue,
    setInstituteValue,
  ] = useState(instituteFilter)
  const [
    positionNameValue,
    setPositionNameValue,
  ] = useState(positionNameFilter)

  const [
    clearFilter,
    setClearFilter,
  ] = useState(false)

  return (
    <div className="effective-contract-user-list__inputs-inner">
      <div className="effective-contract-user-list__inputs-wrapper">
        <InputTextWithDebounce
          debounceTimeout={ 700 }
          value={ usersSearch }
          onChange={ setUsersSearch }
          defaultHint={ 'Поиск по ФИО преподавателя' }
          style={ { width: '100%', } }
        />
      </div>


      <div className="effective-contract-user-list__filters-inner">
        <FilterDropDown
          isWithOptionsWrap
          defaultHint={ majorFilter ? majorFilter : 'Раздел' }
          withBorder
          customWidth="27.4rem"
          items={ [
            'Все',
            ...Object.keys(mapOfDataTypes).filter(item => item !== 'Все' && item !== ''),
          ] }
          clearFilter={ clearFilter }
          onClickHandler={ (answer: any) => {
            if (answer === 'Все') {
              setMajorFilter('')
              setMinorFilter('')
            } else {
              setMajorFilter(answer)
              setMinorFilter('')
            }
          } }
          maxWidthOfinput="24rem"
        />

        <FilterDropDown
          isWithOptionsWrap
          defaultHint={ minorFilter ? minorFilter : 'Тип' }
          withBorder
          customWidth="27.4rem"
          items={ [
            'Все',
            ...Object.keys(mapOfDataTypes[majorFilter]?.types),
          ] }
          clearFilter={ clearFilter }
          onClickHandler={ (answer: any) => {
            if (answer === 'Все') {
              setMinorFilter('')
            } else {
              setMinorFilter(answer)
            }
          } }
          maxWidthOfinput="24rem"
        />

        <FilterDropDown
          defaultHint={ status ? (
            (status.length === 1 && status[0] === 'pending')
              ? 'Неподтвержденные'
              : (status.length === 1 && status[0] === 'approved')
                ? 'Подтвержденные'
                : 'Статус'
          ) : 'Статус' }
          withBorder
          customWidth="27.4rem"
          items={ [
            'Все',
            'Подтвержденные',
            'Неподтвержденные',
          ] }
          clearFilter={ clearFilter }
          onClickHandler={ (answer: any) => {
            if (answer === 'Все') {
              setStatus([
                'approved',
                'rejected',
              ])
            } else if (answer === 'Подтвержденные') {
              setStatus([
                'approved',
              ])
            } else if (answer === 'Неподтвержденные') {
              setStatus([
                'pending',
              ])
            }
          } }
          maxWidthOfinput="24rem"
        />
      </div>


      <br />


      <div className="effective-contract-user-list__filters-inner">
        <div className="effective-contract-user-list__filters-wrapper">
          <FilterDropDown
            defaultHint={ instituteValue.length ? instituteValue : 'Институт' }
            withBorder
            customWidth="27.4rem"
            items={ [
              'Все',
              ...institutesList,
            ] }
            clearFilter={ clearFilter }
            onClickHandler={ (answer: any) => {
              if (answer === 'Все') {
                setInstituteValue('')
              } else {
                setInstituteValue([
                  answer,
                ])
              }
            } }
            maxWidthOfinput="24rem"
          />

          <FilterDropDown
            defaultHint={ positionNameValue.length ? positionNameValue : 'Должность' }
            withBorder
            customWidth="27.4rem"
            items={ [
              'Все',
              ...allTeachersPositions,
            ] }
            clearFilter={ clearFilter }
            onClickHandler={ (answer: any) => {
              if (answer === 'Все') {
                setPositionNameValue('')
              } else {
                setPositionNameValue([
                  answer,
                ])
              }
            } }
            maxWidthOfinput="24rem"
          />
        </div>


        <div
          style={ {
            display: 'flex',
            alignItems: 'center',
          } }
        >
          <LinkButton
            style={ { marginRight: '1rem', } }
            type="clearFilters"
            onClickHandler={
              () => {
                setInstituteFilter([])
                setPositionNameFilter([])
                setClearFilter(!clearFilter)
                setInstituteValue([])
                setPositionNameValue([])
                setMajorFilter('')
                setMinorFilter('')
                setStatus([
                  'approved',
                  'rejected',
                ])
                setSectionFilter([])
              }
            }
          />

          <ButtonSimple
            value="Применить"
            variant="fill"
            onClickHandler={ () => {
              setInstituteFilter(instituteValue)
              setPositionNameFilter(positionNameValue)

              setStatusFilter(status)
              if (mapOfDataTypes[majorFilter]?.major === 1 && mapOfDataTypes[majorFilter]?.types[minorFilter]?.minor === 4) {
                setSectionFilter([
                  {
                    major: 1,
                    minor: 4,
                  },
                  {
                    major: 1,
                    minor: 41,
                  },
                ])
              } else if (minorFilter) {
                setSectionFilter([
                  {
                    major: mapOfDataTypes[majorFilter].major,
                    minor: mapOfDataTypes[majorFilter].types[minorFilter].minor,
                  },
                ])
              } else if (majorFilter) {
                setSectionFilter([
                  {
                    major: mapOfDataTypes[majorFilter].major,
                  },
                ])
              } else if (!majorFilter && !minorFilter) {
                setSectionFilter([])
              }
            } }
          />
        </div>
      </div>
    </div>
  )
}


const mapOfDataTypes: any = {
  'Все': {
    types: {
      // 'Все': {},
    },
  },

  '': {
    types: {
      // 'Все': {},
    },
  },

  'Базовые показатели': {
    major: 1,
    types: {
      'Учебно-методическое обеспечение образовательного процесса (показатель качества)': {
        minor: 1,
      },
      'Плановое повышение квалификации (показатель качества)': {
        minor: 2,
      },
      'Участие в профориентационных мероприятиях, организованных кафедрой, институтом/факультетом, Университетом (показатель качества)': {
        minor: 3,
      },
      'Участие в научно-исследовательской работе по профилю кафедры, института (факультета) и(или) преподаваемого курса, дисциплины (модуля) (показатель интенсивности)': {
        minor: 4,
      },
    },
  },

  'Показатели высокой эффективности труда': {
    major: 2,
    types: {
      'Государственные премии': {
        minor: 1,
      },
      'Владение иностранным языком': {
        minor: 2,
      },
      'Издание учебной и научной литературы': {
        minor: 3,
      },
      'Издание мультимедийной учебной литературы и (или) разработка нового онлайн-курса по программам ВО и (или) ДО (показатель интенсивности)': {
        minor: 4,
      },
      'Подготовка студентов-победителей и призеров олимпиад/конкурсов': {
        minor: 5,
      },
      'Визит в партнерский университет для чтения курсов, в рамках образовательных программ принимающего университета': {
        minor: 6,
      },
      'Публикации  в изданиях,  индексируемых  в базах данных  «Web of Science»,  «Scopus»': {
        minor: 7,
      },
      'Научные гранты и проекты университета, получившие финансовую поддержку': {
        minor: 8,
      },
      'Результаты инновационной деятельности, прошедшей утверждение на НТС университета': {
        minor: 9,
      },
      'Членство в составе редколлегий российских и зарубежных журналов WoS и/или Scopus, журналах из списка ВАК': {
        minor: 10,
      },
    },
  },

  'Бонусные баллы': {
    major: 3,
    types: {
      'Диссертационная деятельность': {
        minor: 1,
      },
      'Получение степени Phd, признаваемой в России': {
        minor: 2,
      },
      'Экспертная деятельность федерального или международного уровней': {
        minor: 3,
      },
      'Членство в жюри, судейство федерального или международного уровней': {
        minor: 31,
      },
      'Участие в стратегических, инновационных и иных проектах Университета': {
        minor: 4,
      },
      // 'Отдельные достижения, имеющие значимость для Университета': {
      //   minor: 5,
      // },
    },
  },

  'Основания для снижения выплат стимулирующего характера': {
    major: 4,
    types: {
      'Неисполнение индивидуального плана развития': {
        minor: 1,
      },
    },
  },
}


export default UserList
