import {
  ADS_ROUTE,
  COURSEWORK_ROUTE,
  MyReferences_Route,
  MFC_MY_APPLICATIONS_ROUTE,
  NOT_FOUND_ROUTE,
  MY_EDUCATION_ROUTE,
  LEARNING_PATHWAY_ROUTE,
  DETAIL_PAGE_ROUTE,
  EDIT_DISCIPLINE_ROUTE,
  LIBRARY_ROUTE,
  LIBRARY_BOOK_DETAILS,
  LOGIN_ROUTE,
  MAIN_ROUTE,
  NEWS_ROUTE,
  OPVO_ROUTE,
  PROFILE_ROUTE,
  SCHEDULE_ROUTE,
  STUDY_JOURNAL_ROUTE,
  GET_REFERENCE_ROUTE,
  RECORD_BOOK_ROUTE,
  JOURNAL_INNER,
  SCOLARSHIP,
  PAYMENT,
  EFFECTIVE_CONTRACT_ROUTE,
  EFFECTIVE_CONTRACT_AMS_ROUTE,
  EFFECTIVE_CONTRACT_MODERATE_ACHIEVEMENTS_AMS_ROUTE,
  EFFECTIVE_CONTRACT_ADD_ACHIEVEMENT_ROUTE,
  EFFECTIVE_CONTRACT_MODERATE_ACHIEVEMENT_ROUTE,
  EFFECTIVE_CONTRACT_MODERATE_ACHIEVEMENT_BY_ROUTE,
  EFFECTIVE_CONTRACT_APPEALS_ROUTE,
  EFFECTIVE_CONTRACT_MODERATE_APPEAL_BY_ROUTE,
  EFFECTIVE_CONTRACT_STEPS_ROUTE,
  EFFECTIVE_CONTRACT_TEACHER_RATING_ROUTE,
  EFFECTIVE_CONTRACT_MODERATE_ACHIEVEMENT_BY_AMS_ROUTE,
  EFFECTIVE_CONTRACT_APPEALS_AMS_ROUTE,
  EFFECTIVE_CONTRACT_MODERATE_APPEAL_BY_AMS_ROUTE,
  EFFECTIVE_CONTRACT_MODERATE_THRESHOLD_VALUES_ROUTE,
  PROJECTS_ALL,
  MY_PROJECTS,
  PROJECT,
  MY_APPLICATIONS,
  PROJECT_APPLY,
  MODERATE_APPLICATIONS,
  TASK,
  NOTIFICATIONS,
  PORTFOLIO,
  MODERATE_PORTFOLIO,
  PORTFOLIO_RATING,
  PORTFOLIO_TEMPLATES,
  PORTFOLIO_INDICATORS,
  PORTFOLIO_EDIT_INDICATOR,
  RATING_BY_ID,
  ANOUNCES,
  ANOUNCE_INNER,
  HIGH_EDUCATION,
  DPO_EDUCATION,
  LOGOUT,
  PRACTICE,
  MODERATE_ACHIVMENTS,
  PROJECTS_INTRODACTION,
  EDIT_TRACK_PAGE,
  MODERATE_NOTIFICATIONS,
  MFC_APL_CONSTRACTOR_ROUTE,
  PROJECT_SEMINAR,
  PROJECT_SEMINAR_BY,
  MY_DUTY_NOTES,
  GET_DUTY_NOTE,
  MFC_LOGS,
  SCHEDULE_TEACHER_ROUTE,
  MAJOR_ROUTE,
  SPECIALTIES_LIST,
  EDIT_MAJOR,
  TEST_ROUTE
} from '@/legacy/Utils/routes'

import MyEducation from '@/legacy/components/MyEducation/HighEducation/RequiredDisciplines/MyEducation'
import LearningPathway from '@/legacy/components/MyEducation/HighEducation/LearningPathway/LearningPathway'
import DetailPage from '@/legacy/components/MyEducation/HighEducation/LearningPathway/DetailPage/DetailPage'
import EditDiscipline from '@/legacy/components/MyEducation/HighEducation/LearningPathway/EditDiscipline/EditDiscipline'
import Library from '@/legacy/components/Library/Library'
import Login from '@/legacy/components/Login/Login'
import Main from '@/legacy/components/Main/Main'
import Opvo from '@/legacy/components/Opvo/Opvo'
import Schedule from '@/legacy/components/Schedule/Schedule'
import ScheduleTeacher from '@/legacy/components/teachersSchedule/Schedule'
import CourseWork from '@/legacy/components/CourseWork/CourseWork'
import StudyJournal from '@/legacy/components/StudyJournal/StudyJournal'
import News from '@/legacy/components/Main/News/News'
import Profile from '@/legacy/components/Profile/Profile'
import Ads from '@/legacy/components/Ads/Ads'
import MyReferences from '@/legacy/components/Mfc/MyReferences/MyReferences'
import GetReference from '@/legacy/components/Mfc/GetReference/GetReference'
import RecordBook from '@/legacy/components/RecordBook/RecordBook'
import NotFound from '@/legacy/components/NotFound/NotFound'
import JournalInner from '@/legacy/components/StudyJournal/InnerJornal'
import Portfolio from '@/legacy/components/Portfolio/Portfolio'
import CurrentNews from '@/legacy/components/Main/News/CurrentNews/CurrentNews'
import ScolarshipPage from '@/legacy/components/Finances/ScolarshipPage/ScolarshipPage'
import PaymentPage from '@/legacy/components/Finances/PaymentPage/PaymentPage'
import EffectiveContract from '@/legacy/components/EffectiveContract/EffectiveContract'
import Appeals from '@/legacy/components/EffectiveContract/Appeals'
import AddAchievement from '@/legacy/components/EffectiveContract/AddAchievement'
import ModerateAchievements from '@/legacy/components/EffectiveContract/ModerateAchievements'
import ModerateAppeals from '@/legacy/components/EffectiveContract/ModerateAppeals'
import AmsModerateAppeals from '@/legacy/components/EffectiveContract/AmsModerateAppeals'
import EffectiveContractStages from '@/legacy/components/EffectiveContract/EffectiveContractStages'
import TeacherRating from '@/legacy/components/EffectiveContract/TeacherRating/TeacherRating'
import AllProjects from '@/legacy/components/Projects/AllProjects/AllProjects'
import MyProjects from '@/legacy/components/Projects/MyProjects/MyProjects'
import ProjectPage from '@/legacy/components/Projects/ProjectPage/ProjectPage'
import MyApplications from '@/legacy/components/Projects/MyApplications/MyApplications'
import NewProject from '@/legacy/components/Projects/NewProject/NewProject'
import PAmoderApplications from '@/pages/PAmoderApplications/PAmoderApplicationsList'
import TaskPage from '@/legacy/components/Projects/TaskPage/TaskPage'
import Notifications from '@/legacy/components/Notifications/Notifications'
import ModeratePortfolio from '@/legacy/components/Portfolio/ModeratePortfolio/ModeratePortfolio'
import PortfolioRating from '@/legacy/components/Portfolio/PortfolioRating/PortfolioRating'
import RatingById from '@/legacy/components/Portfolio/RatingById/RatingById'
import ModeratePage from '@/legacy/components/Portfolio/ModeratePortfolio/ModeratePage/ModeratePage'
import Anounces from '@/legacy/components/Anounces/Anounces'
import AnnounceInner from '@/legacy/components/Anounces/AnnounceInner/AnnounceInner'
import HighEducation from '@/legacy/components/MyEducation/HighEducation/HighEducation'
import DPOEducation from '@/legacy/components/MyEducation/DPOEducation/DPOEducation'
import Logout from '@/legacy/components/Logout/Logout'
import Practice from '@/legacy/components/MyEducation/HighEducation/Practice/Practice'
import AmsEffectiveContract from '@/legacy/components/EffectiveContract/AmsEffectiveContract'
import ModerateThresholdValues from '@/legacy/components/EffectiveContract/ModerateThresholdValues/ModerateThresholdValues'
import PortfolioTemplates from '@/legacy/components/Portfolio/PortfolioTemplates/PortfolioTemplates'
import PortfolioIndicators from '@/legacy/components/Portfolio/PortfolioIndicators/PortfolioIndicators'
import PortfolioEditIndicator from '@/legacy/components/Portfolio/PortfolioEditIndicator/PortfolioEditIndicator'
import ProjectActivityIntroduction from '@/legacy/components/MyEducation/HighEducation/ProjectActivityIntroduction/ProjectActivityIntroduction'
import EditTrackPage from '@/legacy/components/MyEducation/HighEducation/LearningPathway/EditTrackPage/EditTrackPage'
import ModerateNotifications from '@/legacy/components/ModerateNotifications/ModerateNotifications'
import BookDetailsPage from '@/legacy/components/Library/BookDetailsPage/BookDetailsPage'
import MyApplicationsMFC from '@/legacy/components/Mfc/MyApplications/MyApplications'
import GetApplication from '@/legacy/components/Mfc/MyApplications/GetApplication/GetApplication'
import ProjectSeminar from '@/legacy/components/Projects/ProjectSeminar/ProjectSeminar'
import ProjectSeminarByGroup from '@/legacy/components/Projects/ProjectSeminar/pages/ProjectSeminarByGroup/ProjectSeminarByGroup'
import MyDutyNotes from '@/legacy/components/Mfc/MyDutyNotes/MyDutyNotes'
import GetDutyNote from '@/legacy/components/Mfc/MyDutyNotes/GetDutyNote/GetDutyNote'
import ActivityHistory from '@/legacy/components/Mfc/ActivityHistory/ActivityHistory'
import { SpeacialtyPage, } from '@/pages/SpecialtyPage'
import { MajorPage, } from '@/pages/MajorPage'
import { EditMajorPage, } from '@/pages/EditMajorPage'
import { TestPage } from '@/pages/TestPage'

export const AuthRoutes = [
  {
    path: MAIN_ROUTE,
    Component: Main,
    roles: [
      'student',
      'student-rakus',
      'dms-moder',
      'ufpn-moder',
      'osrv-moder',
      'tester',
      'pa_moder',
      'worker',
      'teacherGPH',
      'uonid-moder',
      'dm-moder',
      'ukp-moder',
      'tpd-moder',
      'urtos-moder',
      'urvo-moder',
      'urvo-2-moder',
      'osrv-moder',
      'urvo-admin',
      'aup-admin',
      'director-Institute',
      'director-faculty',
      'aup-pfu-moder',
      'aup-pk-moder',
      'aup-dms-moder',
      'aup-ck-moder',
      'selfsignup',
      'employee',
      'prosmotr-moe-obuchenie',
      'notification-moder',
      'portfolio-mfc-moder',
      'portfolio-kurator',
      'unknown',
    ],
  },
  {
    path: TEST_ROUTE,
    Component: TestPage,
    roles: [
      'tester',
    ],
  },
  {
    path: SCHEDULE_ROUTE,
    Component: Schedule,
    roles: [
      'student',
      'student-rakus',
    ],
  },
  {
    path: SCHEDULE_TEACHER_ROUTE,
    Component: ScheduleTeacher,
    roles: [
      'tester',
      'worker',
      'teacherGPH',
    ],
  },
  {
    path: MY_EDUCATION_ROUTE,
    Component: MyEducation,
    roles: [
      'student',
      'student-rakus',
      'tester',
      'osrv-moder',
      'prosmotr-moe-obuchenie',
    ],
  },
  {
    path: LEARNING_PATHWAY_ROUTE,
    Component: LearningPathway,
    roles: [
      'student',
      'student-rakus',
      'osrv-moder',
      'prosmotr-moe-obuchenie',
    ],
  },
  {
    path: SPECIALTIES_LIST,
    Component: SpeacialtyPage,
    roles: [
      // 'student',
      // 'student-rakus',
      'osrv-moder',
      'prosmotr-moe-obuchenie',
      'tester',
    ],
  },
  {
    path: MAJOR_ROUTE,
    Component: MajorPage,
    roles: [
      // 'student',
      // 'student-rakus',
      'osrv-moder',
      'prosmotr-moe-obuchenie',
      'tester',
    ],
  },
  {
    path: EDIT_MAJOR,
    Component: EditMajorPage,
    roles: [
      // 'student',
      // 'student-rakus',
      'osrv-moder',
      'prosmotr-moe-obuchenie',
      'tester',
    ],
  },
  {
    path: PROJECTS_INTRODACTION,
    Component: ProjectActivityIntroduction,
    roles: [
      'student',
      'osrv-moder',
      'student-rakus',
      'prosmotr-moe-obuchenie',
    ],
  },
  {
    path: DETAIL_PAGE_ROUTE,
    Component: DetailPage,
    roles: [
      'student',
      'student-rakus',
      'osrv-moder',
      'prosmotr-moe-obuchenie',
    ],
  },
  {
    path: EDIT_DISCIPLINE_ROUTE,
    Component: EditDiscipline,
    roles: [
      'student',
      'student-rakus',
      'osrv-moder',
      'prosmotr-moe-obuchenie',
    ],
  },
  {
    path: LIBRARY_ROUTE,
    Component: Library,
    roles: [
      'student',
      'student-rakus',
      'tester',
    ],
  },
  {
    path: OPVO_ROUTE,
    Component: Opvo,
    roles: [
      'tester',
    ],
  },
  {
    path: COURSEWORK_ROUTE,
    Component: CourseWork,
    roles: [
      'student',
      'student-rakus',
      'osrv-moder',
      'tester',
    ],
  },
  {
    path: STUDY_JOURNAL_ROUTE,
    Component: StudyJournal,
    roles: [
      'tester',
    ],
  },
  {
    path: NEWS_ROUTE,
    Component: News,
    roles: [
      'student',
      'prosmotr-moe-obuchenie',
      'student-rakus',
      'osrv-moder',
      'tester',
      'pa_moder',
      'worker',
      'teacherGPH',
      'uonid-moder',
      'dm-moder',
      'ukp-moder',
      'tpd-moder',
      'urtos-moder',
      'urvo-moder',
      'selfsignup',
      'employee',
      'portfolio-mfc-moder',
      'portfolio-kurator',
    ],
  },
  {
    path: `${ NEWS_ROUTE }/:link`,
    Component: CurrentNews,
    roles: [
      'student',
      'prosmotr-moe-obuchenie',
      'student-rakus',
      'osrv-moder',
      'pa_moder',
      'tester',
      'worker',
      'teacherGPH',
      'uonid-moder',
      'dm-moder',
      'ukp-moder',
      'tpd-moder',
      'urtos-moder',
      'urvo-moder',
      'selfsignup',
      'employee',
      'portfolio-mfc-moder',
      'portfolio-kurator',
    ],
  },
  {
    path: PROFILE_ROUTE,
    Component: Profile,
    roles: [
      'student',
      'student-rakus',
      'pa_moder',
      'tester',
      'worker',
      'teacherGPH',
      'uonid-moder',
      'dm-moder',
      'ukp-moder',
      'tpd-moder',
      'urtos-moder',
      'urvo-moder',
      'selfsignup',
      'employee',
      'portfolio-mfc-moder',
      'portfolio-kurator',
    ],
  },
  {
    path: ADS_ROUTE,
    Component: Ads,
    roles: [
      'student',
      'prosmotr-moe-obuchenie',
      'student-rakus',
      'osrv-moder',
      'pa_moder',
      'tester',
      'worker',
      'teacherGPH',
      'uonid-moder',
      'dm-moder',
      'ukp-moder',
      'tpd-moder',
      'urtos-moder',
      'urvo-moder',
      'selfsignup',
      'employee',
      'portfolio-mfc-moder',
      'portfolio-kurator',
    ],
  },
  {
    path: MyReferences_Route,
    Component: MyReferences,
    roles: [
      'student',
      'student-rakus',
      'worker',
      'employee',
    ],
  },
  {
    path: GET_REFERENCE_ROUTE,
    Component: GetReference,
    roles: [
      'student',
      'student-rakus',
    ],
  },
  {
    path: RECORD_BOOK_ROUTE,
    Component: RecordBook,
    roles: [
      'student',
      'student-rakus',
    ],
  },
  {
    path: NOT_FOUND_ROUTE,
    Component: NotFound,
    roles: [
      'student',
      'student-rakus',
      'tester',
      'pa_moder',
      'worker',
      'teacherGPH',
      'uonid-moder',
      'dm-moder',
      'ukp-moder',
      'tpd-moder',
      'urtos-moder',
      'urvo-moder',
      'selfsignup',
      'employee',
      'portfolio-mfc-moder',
      'portfolio-kurator',
    ],
  },
  {
    path: RECORD_BOOK_ROUTE,
    Component: RecordBook,
    roles: [
      'student',
      'student-rakus',
    ],
  },
  {
    path: JOURNAL_INNER,
    Component: JournalInner,
    roles: [
      'tester',
    ],
  },
  {
    path: SCOLARSHIP,
    Component: ScolarshipPage,
    roles: [
      'student',
      'student-rakus',
    ],
  },
  {
    path: PAYMENT,
    Component: PaymentPage,
    roles: [
      'student',
    ],
  },

  {
    path: EFFECTIVE_CONTRACT_ROUTE,
    Component: EffectiveContract,
    roles: [
      'worker',
      'employee',
      'tester',
    ],
  },
  {
    path: EFFECTIVE_CONTRACT_AMS_ROUTE,
    Component: AmsEffectiveContract,
    roles: [
      'director-Institute',
      'director-faculty',
    ],
  },
  {
    path: EFFECTIVE_CONTRACT_MODERATE_ACHIEVEMENTS_AMS_ROUTE,
    Component: AmsEffectiveContract,
    roles: [
      'aup-admin',
      'aup-pfu-moder',
      'aup-pk-moder',
      'aup-dms-moder',
      'aup-ck-moder',
    ],
  },
  {
    path: EFFECTIVE_CONTRACT_MODERATE_ACHIEVEMENT_BY_AMS_ROUTE,
    Component: AmsEffectiveContract,
    roles: [
      'aup-admin',
      'aup-pfu-moder',
      'aup-pk-moder',
      'aup-dms-moder',
      'aup-ck-moder',
    ],
  },
  {
    path: EFFECTIVE_CONTRACT_MODERATE_ACHIEVEMENT_ROUTE,
    Component: EffectiveContract,
    roles: [
      'worker',
      'employee',
      'tester',
      'dms-moder',
      'ufpn-moder',
      'uonid-moder',
      'dm-moder',
      'ukp-moder',
      'tpd-moder',
      'urtos-moder',
      'urvo-moder',
      'urvo-2-moder',
      'urvo-admin',
    ],
  },
  {
    path: EFFECTIVE_CONTRACT_APPEALS_ROUTE,
    Component: Appeals,
    roles: [
      'tester',
      'uonid-moder',
      'dm-moder',
      'ukp-moder',
      'tpd-moder',
      'urtos-moder',
      'urvo-moder',
      'urvo-admin',
    ],
  },
  {
    path: EFFECTIVE_CONTRACT_APPEALS_AMS_ROUTE,
    Component: Appeals,
    roles: [
      'aup-admin',
    ],
  },
  {
    path: EFFECTIVE_CONTRACT_ADD_ACHIEVEMENT_ROUTE,
    Component: AddAchievement,
    roles: [
      'worker',
      'employee',
      'tester',
    ],
  },
  {
    path: EFFECTIVE_CONTRACT_MODERATE_ACHIEVEMENT_BY_ROUTE,
    Component: ModerateAchievements,
    roles: [
      'uonid-moder',
      'dms-moder',
      'ufpn-moder',
      'dm-moder',
      'tester',
      'ukp-moder',
      'tpd-moder',
      'urtos-moder',
      'urvo-moder',
      'urvo-2-moder',
      'urvo-admin',
    ],
  },
  {
    path: EFFECTIVE_CONTRACT_MODERATE_APPEAL_BY_ROUTE,
    Component: ModerateAppeals,
    roles: [
      'uonid-moder',
      'dm-moder',
      'tester',
      'ukp-moder',
      'tpd-moder',
      'urtos-moder',
      'urvo-moder',
      'urvo-admin',
    ],
  },
  {
    path: EFFECTIVE_CONTRACT_MODERATE_APPEAL_BY_AMS_ROUTE,
    Component: AmsModerateAppeals,
    roles: [
      'aup-admin',
    ],
  },
  {
    path: EFFECTIVE_CONTRACT_STEPS_ROUTE,
    Component: EffectiveContractStages,
    roles: [
      'urvo-admin',
    ],
  },
  {
    path: EFFECTIVE_CONTRACT_TEACHER_RATING_ROUTE,
    Component: TeacherRating,
    roles: [
      'worker',
      'employee',
      'tester',
    ],
  },
  {
    path: EFFECTIVE_CONTRACT_MODERATE_THRESHOLD_VALUES_ROUTE,
    Component: ModerateThresholdValues,
    roles: [
      'aup-admin',
    ],
  },

  {
    path: PROJECTS_ALL,
    Component: AllProjects,
    roles: [
      'student',
      'student-rakus',
      'worker',
      'teacherGPH',
      'pa_moder',
      'tester',
    ],
  },
  {
    path: MY_PROJECTS,
    Component: MyProjects,
    roles: [
      'student',
      'student-rakus',
      'worker',
      'teacherGPH',
      'tester',
    ],
  },
  {
    path: PROJECT,
    Component: ProjectPage,
    roles: [
      'student',
      'student-rakus',
      'worker',
      'teacherGPH',
      'pa_moder',
      'tester',
    ],
  },
  {
    path: PROJECT_SEMINAR,
    Component: ProjectSeminar,
    roles: [
      'worker',
      'teacherGPH',
    ],
  },
  {
    path: PROJECT_SEMINAR_BY,
    Component: ProjectSeminarByGroup,
    roles: [
      'worker',
      'teacherGPH',
    ],
  },
  {
    path: MY_APPLICATIONS,
    Component: MyApplications,
    roles: [
      'worker',
      'teacherGPH',
      'tester',
    ],
  },
  {
    path: PROJECT_APPLY,
    Component: NewProject,
    roles: [
      'worker',
      'teacherGPH',
      'pa_moder',
      'tester',
    ],
  },
  {
    path: MODERATE_APPLICATIONS,
    Component: PAmoderApplications,
    roles: [
      'pa_moder',
      'tester',
    ],
  },
  {
    path: TASK,
    Component: TaskPage,
    roles: [
      'worker',
      'teacherGPH',
      'pa_moder',
      'student',
      'student-rakus',
      'tester',
    ],
  },
  {
    path: NOTIFICATIONS,
    Component: Notifications,
    roles: [
      'student',
      'student-rakus',
      'pa_moder',
      'tester',
      'worker',
      'teacherGPH',
      'uonid-moder',
      'dm-moder',
      'ukp-moder',
      'tpd-moder',
      'urtos-moder',
      'urvo-moder',
      'selfsignup',
      'employee',
      'portfolio-mfc-moder',
      'portfolio-kurator',
      'osrv-moder',
    ],
  },
  {
    path: MODERATE_NOTIFICATIONS,
    Component: ModerateNotifications,
    roles: [
      'notification-moder',
    ],
  },
  {
    path: PORTFOLIO,
    Component: Portfolio,
    roles: [
      'student',
      'student-rakus',
    ],
  },
  {
    path: MODERATE_PORTFOLIO,
    Component: ModeratePortfolio,
    roles: [
      'urtos-moder',
      'portfolio-mfc-moder',
      'portfolio-kurator',
    ],
  },
  {
    path: PORTFOLIO_RATING,
    Component: PortfolioRating,
    roles: [
      'student',
      'student-rakus',
      'urtos-moder',
    ],
  },
  {
    path: PORTFOLIO_TEMPLATES,
    Component: PortfolioTemplates,
    roles: [
      'urtos-moder',
    ],
  },
  {
    path: PORTFOLIO_INDICATORS,
    Component: PortfolioIndicators,
    roles: [
      'urtos-moder',
    ],
  },
  {
    path: PORTFOLIO_EDIT_INDICATOR,
    Component: PortfolioEditIndicator,
    roles: [
      'urtos-moder',
    ],
  },
  {
    path: RATING_BY_ID,
    Component: RatingById,
    roles: [
      'student',
      'student-rakus',
      'urtos-moder',
    ],
  },
  {
    path: MODERATE_ACHIVMENTS,
    Component: ModeratePage,
    roles: [
      'student',
      'student-rakus',
      'urtos-moder',
      'portfolio-mfc-moder',
      'portfolio-kurator',
    ],
  },
  {
    path: ANOUNCES,
    Component: Anounces,
    roles: [
      'student',
      'student-rakus',
      'osrv-moder',
      'tester',
      'pa_moder',
      'worker',
      'teacherGPH',
      'uonid-moder',
      'dm-moder',
      'ukp-moder',
      'tpd-moder',
      'urtos-moder',
      'urvo-moder',
      'selfsignup',
      'employee',
    ],
  },
  {
    path: ANOUNCE_INNER,
    Component: AnnounceInner,
    roles: [
      'student',
      'student-rakus',
      'osrv-moder',
      'tester',
      'pa_moder',
      'worker',
      'teacherGPH',
      'uonid-moder',
      'dm-moder',
      'ukp-moder',
      'tpd-moder',
      'urtos-moder',
      'urvo-moder',
      'selfsignup',
      'employee',
    ],
  },
  {
    path: HIGH_EDUCATION,
    Component: HighEducation,
    roles: [
      'student',
      'student-rakus',
      'osrv-moder',
      'prosmotr-moe-obuchenie',
    ],
  },
  {
    path: EDIT_TRACK_PAGE,
    Component: EditTrackPage,
    roles: [
      'osrv-moder',
      'prosmotr-moe-obuchenie' /* "student", "osrv-moder" */,
    ],
  },
  {
    path: DPO_EDUCATION,
    Component: DPOEducation,
    roles: [
      'student',
      'student-rakus',
      'osrv-moder',
    ],
  },
  {
    path: PRACTICE,
    Component: Practice,
    roles: [
      'student',
      'student-rakus',
      'osrv-moder',
    ],
  },
  {
    path: LIBRARY_BOOK_DETAILS,
    Component: BookDetailsPage,
    roles: [
      'student',
    ],
  },
  {
    path: MFC_MY_APPLICATIONS_ROUTE,
    Component: MyApplicationsMFC,
    roles: [
      'employee',
      'worker',
      'student',
      'student-rakus',
    ],
  },
  {
    path: MFC_APL_CONSTRACTOR_ROUTE,
    Component: GetApplication,
    roles: [
      'employee',
      'worker',
      'student',
      'student-rakus',
    ],
  },
  {
    path: MY_DUTY_NOTES,
    Component: MyDutyNotes,
    roles: [
      'employee',
      'worker',
    ],
  },
  {
    path: GET_DUTY_NOTE,
    Component: GetDutyNote,
    roles: [
      'employee',
      'worker',
    ],
  },
  {
    path: MFC_LOGS,
    Component: ActivityHistory,
    roles: [
      'employee',
      'worker',
    ],
  },
]

export const publicRoutes = [
  {
    path: LOGIN_ROUTE,
    Component: Login,
  },
  {
    path: LOGOUT,
    Component: Logout,
  },
]
