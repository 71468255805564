/* eslint-disable no-console */
import { useMemo, } from 'react'
import './CardDiscipline.less'
import clsx from 'clsx'
import { useGetOneMoodle, } from '../../../../../gql/hooks/useMyEducation'
import Preloader from '../../../../common/PreLoader/Preloader'

const CardDiscipline = ({
  title,
  subtitle,
  description,
  isElectiva,
  isTrack,
  onClickHandler,
  dept,
  semester,
  debtSemester,
}: any): JSX.Element => {
  const { data, error, loading, } = useGetOneMoodle(title, dept ? debtSemester : semester)

  const checkLinkEnding = (str: string) => {
    if (str.endsWith('/')) {
      return str
    } else if (!str.endsWith('/')) {
      return `${str}/`
    }
  }

  const card = useMemo(() => {
    if (error) {
      return <div>...error...</div>
    } else if (loading && !data) {
      return <Preloader />
    }
    const redirectToMoodle = () => {
      try {
        data.esMoodleCoursesByDiscipline[0] ? window.open(`${ checkLinkEnding(data.esMoodleCoursesByDiscipline[0].site_name) }course/view.php?id=${ data.esMoodleCoursesByDiscipline[0].course_on_site_id }`, '_blank') : window.open('https://moodle1.tsutmb.ru/my/', '_blank')
      } catch (e) {
        console.log(e)
      }
    }

    return <div onClick={ title ? redirectToMoodle : onClickHandler } className={ clsx('card-discipline', (isElectiva || isTrack) && 'card-discipline__warn', dept && 'card-discipline__warn') }>
      { title && <>
        <div className='card-discipline__title'>{ title }</div>
        { dept && <div className='card-discipline__subtitle'>Имеется задолженность</div> }
      </> }
      { isTrack && <div className='card-discipline__subtitle '>"Вы не выбрали не одну дисциплину по выбору, пожалуйста выберите дисциплину"</div> }
      { isElectiva && <div className='card-discipline__subtitle'>"Вы не выбрали не одну элективу, пожалуйста выберите элективу"</div> }
    </div>


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    error,
    loading,
    isTrack,
    isElectiva,
    title,
    subtitle,
    description,
  ])


  return (card)
}


export default CardDiscipline
