import clsx from 'clsx'
import styles from './Table.module.less'

interface Weekdays {
  ru: string;
  en: string;
}

export const weekdays: Weekdays[] = [
  {
    ru: 'Понедельник',
    en: 'Monday',
  },
  {
    ru: 'Вторник',
    en: 'Tuesday',
  },
  {
    ru: 'Среда',
    en: 'Wednesday',
  },
  {
    ru: 'Четверг',
    en: 'Thursday',
  },
  {
    ru: 'Пятница',
    en: 'Friday',
  },
  {
    ru: 'Суббота',
    en: 'Saturday',
  },
  {
    ru: 'Воскресенье',
    en: 'Sunday',
  },
]

export const months = [
  {
    check: 'январь',
    ru: 'Январь',
    en: 'January',
  },
  {
    check: 'февраль',
    ru: 'Февраль',
    en: 'February',
  },
  {
    check: 'март',
    ru: 'Март',
    en: 'March',
  },
  {
    check: 'апрель',
    ru: 'Апрель',
    en: 'April',
  },
  {
    check: 'май',
    ru: 'Май',
    en: 'May',
  },
  {
    check: 'июнь',
    ru: 'Июнь',
    en: 'June',
  },
  {
    check: 'июль',
    ru: 'Июль',
    en: 'July',
  },
  {
    check: 'август',
    ru: 'Август',
    en: 'August',
  },
  {
    check: 'сентябрь',
    ru: 'Сентябрь',
    en: 'September',
  },
  {
    check: 'октябрь',
    ru: 'Октябрь',
    en: 'October',
  },
  {
    check: 'ноябрь',
    ru: 'Ноябрь',
    en: 'November',
  },
  {
    check: 'декабрь',
    ru: 'Декабрь',
    en: 'December',
  },
]

export const returnWeek = (str: any) => {
  return String(str)
}

const TableSchedule = ({
  props,
  date,
  status,
}: any) => {
  let weekday
  const chislitel = props?.[0]?.chislitel

  if (!status) {
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    weekday = weekdays.find(day => day.en === date.weekday).ru
  }

  if (date.weekday === 'Суббота' || date.weekday === 'Воскресенье') {
    return <></>
  }

  return (
    <div className="Table d-scrolloff" key={ Math.random() }>
      <h2 className={ clsx('DateS d-scrolloff p-20 heading-text-style', styles.dateDescription) }>
        <span>
          { date && `${ status ? date.month : weekday }, ${ date.day } ${ status ? date.weekday : date.month } ` }
        </span>

        <span>
          { (chislitel !== null && chislitel !== undefined) && (chislitel ? 'Неделя: Числитель' : 'Неделя: Знаменатель') }
        </span>
      </h2>

      <table >
        <thead>
          <tr className="info heading-text-style">
            <td className="date Bold" style={ { fontWeight: '700', } }>Пара</td>
            <td className="time Bold" style={ { fontWeight: '700', } }>Время</td>
            <td className="lesson Bold" style={ { fontWeight: '700', } }>Предмет</td>
            <td className="coach Bold" style={ { fontWeight: '700', } }>Преподаватель</td>
            <td className="type Bold" style={ { fontWeight: '700', } }>Тип</td>
            <td className="audince Bold" style={ { fontWeight: '700', } }>Аудитория</td>
          </tr>
        </thead>

        { props && props.length > 0 ? props.map((i: any) => <tr key={ props.indexOf(i) } className="row">
          <td className="date">{ i?.couple }</td>
          <td className="time">{ i.start ? i.start : 'Не указано' }-{ i.end ? i.end : 'Не указано' }</td>
          <td className="lesson">{ i?.discipline }</td>
          <td className="coach">{ i?.teacher }</td>
          <td className="type">{ i?.type }</td>
          <td className="audince">{ i?.room }</td>
        </tr>
        ) : (
          <tr className="None">
            <td>
              <h2 className="p-20">Пары не указаны</h2>
            </td>
          </tr>
        ) }
      </table>
    </div>
  )
}

export default TableSchedule
