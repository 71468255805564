/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import './Calendar.less'
import './Schedule.less'
import styles from './Table.module.less'

import { useEffect, useState, } from 'react'
import {
  GETUSERGROUPS_QUERY,
  useStudentTimetable,
} from '../../gql/hooks/useTimetable'
import { useQuery, } from '@apollo/client'

import HistoryLinks from '../common/HistoryLinks/HistoryLinks'

import { changeWeekDays, } from './Fn'
import { format, } from 'date-fns'

import ru from 'date-fns/locale/ru'

import Calendar from 'react-calendar'
import CalendarSvg from '../../assets/images/Group.svg'
import { IconChevronDown, IconChevronUp, } from '@tabler/icons'
import TableSchedule, { /* months, */ weekdays, } from './Table'
import addDays from 'date-fns/addDays'
import { useSelector, } from 'react-redux'
import clsx from 'clsx'

function Schedule () {
  const [
    , /* arr */ setArr,
  ] = useState([])
  const [
    chooseDay,
    setChooseDay,
  ] = useState(true)
  const [
    calendar,
    setCalendar,
  ] = useState(false)
  const [
    arr2,
    setArr2,
  ] = useState([])
  const [
    singleDate,
    setSignleDate,
  ] = useState<any>(new Date())
  const [
    rangeDate,
    setRangeDate,
  ] = useState<any>(null)
  const [
    groups,
    setGroups,
  ] = useState([])
  const [
    date,
  ] = useState(new Date())
  const [
    startDate,
    setStartDate,
  ] = useState(
    new Date(date.setDate(date.getDate() - date.getDay() + 1))
  )
  const [
    endDate,
    setEndDate,
  ] = useState(addDays(date, 6))
  const [
    isOpen,
    setIsOpen,
  ] = useState(false)

  const dateArr = []

  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const studInfo = useSelector(state => state.prof.studentInfo)

  const [
    group,
    setGroup,
  ] = useState(studInfo[0].group)
  const [
    spec,
    setSpec,
  ] = useState(studInfo[0].namespec)

  const studentTimeTableForToday = useStudentTimetable({
    dateStart: format(new Date(singleDate), 'yyyy-MM-dd'),
    dateEnd: format(new Date(singleDate), 'yyyy-MM-dd'),
    group: group,
  })
  const test2 = useStudentTimetable({
    dateStart: format(new Date(startDate), 'yyyy-MM-dd'),
    dateEnd: format(new Date(endDate), 'yyyy-MM-dd'),
    group: group,
  })

  const groupsQ = useQuery(GETUSERGROUPS_QUERY)

  /* const weeksSchedule = useMemo(() => {
   if (test2.error) {
     return <div>....error...</div>
   } else if(test2.loading) {
     return <div>....loading...</div>
   } else if(test2.data) {}
  }, [test2.data, test2.error, test2.loading])
   */
  /* useEffect(() => {
    if (!groups) {
      groupsQ.data && setGroups(groupsQ.data?.getUserGroups)
      setGroup(groupsQ?.data?.getUserGroups[0])
    }

    if (!chooseDay) {
      test2.refetch()
    } else {
      test.refetch()
    }
    groupsQ.data && setGroups(groupsQ.data?.getUserGroups)
    !group && setGroup(groups[0])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupsQ.data, group, groups]) */

  useEffect(() => {
    if (!group) {
      return
    } else if (!chooseDay) {
      !startDate &&
        setStartDate(
          new Date(date.setDate(date.getDate() - date.getDay() + 1))
        )
      !endDate && setEndDate(addDays(date, 6))

      setArr2(test2?.data?.studentTimetable)
    } else {
      setArr(studentTimeTableForToday?.data?.studentTimetable)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    studentTimeTableForToday.data,
    test2.data,
    groupsQ.data,
  ])

  function handleSingle () {
    setChooseDay(true)
    setRangeDate(null)
  }

  const switchDate = (move: any) => {
    if (chooseDay) {
      const down = addDays(singleDate, -1)
      const up = addDays(singleDate, 1)

      !move && setSignleDate(down)
      move && setSignleDate(up)
    } else {
      if (!move) {
        setStartDate(addDays(startDate, -7))
        setEndDate(addDays(endDate, -7))
      } else {
        setStartDate(addDays(startDate, 7))
        setEndDate(addDays(endDate, 7))
      }
    }
  }

  if (startDate && endDate) {
    let currentDate = startDate
    while (currentDate <= endDate) {
      dateArr.push(currentDate)
      currentDate = addDays(currentDate, 1)
    }
  }

  useEffect(() => {
    if (rangeDate) {
      setStartDate(rangeDate[0])
      setEndDate(rangeDate[rangeDate.length - 1])
    }
  }, [
    rangeDate,
  ])

  function updateGroup (i: any) {
    setGroup(i.group)
    setSpec(i.namespec)
    setIsOpen(!isOpen)
  }

  return (
    <div className="Schedule" onClick={ () => setCalendar(false) }>
      <HistoryLinks
        links={ [
          {
            value: 'Главная',
            path: '/',
          },
        ] }
        loc="Расписание"
      />

      <h1 className="d-h1">Расписание</h1>

      <div className="Row d-row d-space Schedule__filter-wrapper">
        <div
          className="chooseFieldY d-cursor"
          onClick={ () => setIsOpen(!isOpen) }
        >
          { isOpen && (
            <div className="ScheChoose">
              { studInfo &&
                studInfo.map((i: any) => (
                  <span
                    className="OptionChoose"
                    key={ i.group }
                    onClick={ () => updateGroup(i) }
                  >
                    { i.namespec }
                  </span>
                )) }
            </div>
          ) }
          <span className="zachem">{ spec ? spec : 'Выберите группу' }</span>
          { !isOpen ? (
            <IconChevronDown size={ 20 } />
          ) : (
            <IconChevronUp size={ 20 } />
          ) }
        </div>

        <div className="Schedule__controls-wrapper">
          <div className="Row d-row">
            <span
              onClick={ () => switchDate(false) }
              className="p-10 a-scale d-cursor"
            >
              <div style={ { height: 40, } } className="d-row d-center text-style">
                <div
                  style={ {
                    fontWeight: 500,
                    paddingRight: 10,
                    fontSize: 28,
                    color: 'grey',
                    margin: 0,
                  } }
                >
                  { '<' }
                </div>

                { chooseDay ? 'Предыдущий день' : 'Предыдущая неделя' }
              </div>
            </span>
            <div className="Cal" onClick={ e => e.stopPropagation() }>
              <button
                className="Click a-scale d-cursor"
                onClick={ () => setCalendar(!calendar) }
              >
                { rangeDate
                  ? `${ format(new Date(rangeDate[0]), 'dd MMMM yyyy', {
                    locale: ru,
                  }).slice(0, 3) +
                  format(new Date(rangeDate[0]), 'dd MMMM yyyy', {
                    locale: ru,
                  })[3].toUpperCase() +
                  format(new Date(rangeDate[0]), 'dd MMMM yyyy', {
                    locale: ru,
                  }).slice(4)
                  } - ${ format(new Date(rangeDate[1]), 'dd MMMM yyyy', {
                    locale: ru,
                  }).slice(0, 3) +
                  format(new Date(rangeDate[1]), 'dd MMMM yyyy', {
                    locale: ru,
                  })[3].toUpperCase() +
                  format(new Date(rangeDate[1]), 'dd MMMM yyyy', {
                    locale: ru,
                  }).slice(4)
                  }`
                  : format(singleDate, 'dd MMMM yyyy', { locale: ru, }).slice(
                    0,
                    3
                  ) +
                  format(singleDate, 'dd MMMM yyyy', {
                    locale: ru,
                  })[3].toUpperCase() +
                  format(singleDate, 'dd MMMM yyyy', { locale: ru, }).slice(4) }
                <img className="p-10" src={ CalendarSvg } alt={ '' } />
              </button>
              { calendar && (
                <Calendar
                  locale={ 'Ru' }
                  formatMonthYear={ (locale: any, date: any) => format(date, 'dd MMMM yyyy', { locale: ru, }).slice(0, 3) +
                    format(date, 'dd MMMM yyyy', {
                      locale: ru,
                    })[3].toUpperCase() +
                    format(date, 'dd MMMM yyyy', { locale: ru, }).slice(4)
                  }
                  formatShortWeekday={ (locale: any, date: any) => changeWeekDays(date)
                  }
                  className={
                    calendar ? 'ScheduleCalendar ' : 'ScheduleCalendar off'
                  }
                  value={ chooseDay ? singleDate : rangeDate }
                  onChange={ chooseDay ? setSignleDate : setRangeDate }
                  selectRange={ !chooseDay }
                />
              ) }
            </div>
            <span
              onClick={ () => switchDate(true) }
              className="p-10 a-scale d-cursor"
            >
              <div
                style={ {
                  height: 40,
                  alignItems: 'center',
                } }
                className="d-row d-center text-style"
              >
                { chooseDay ? 'Следующий день' : 'Следующая неделя' }

                <div
                  style={ {
                    fontWeight: 500,
                    paddingLeft: 10,
                    fontSize: 28,
                    color: 'grey',
                    margin: 0,
                  } }
                >
                  { '>' }
                </div>
              </div>
            </span>
          </div>

          <div className="d-row">
            <span
              onClick={ () => handleSingle() }
              className={ `text-style-choise p-10 Choosed d-cursor ${ chooseDay && 'ActiveC'
                }` }
            >
              По дням
            </span>

            <span
              onClick={ () => setChooseDay(false) }
              className={ `text-style-choise p-10 Choosed d-cursor ${ !chooseDay && 'ActiveC'
                }` }
            >
              По неделям
            </span>
          </div>
        </div>
      </div>

      <br />
      { chooseDay ? (
        <div className="ShortSchedule">
          <TableSchedule
            status={ false }
            props={
              studentTimeTableForToday.loading
                ? null
                : studentTimeTableForToday?.data?.studentTimetable
            }
            date={ {
              day: format(singleDate, 'd'),
              month: format(new Date(singleDate), 'MMMM', { locale: ru, }),
              weekday: format(singleDate, 'EEEE'),
            } }
          />
        </div>
      ) : (
        <div className="ShortSchedule d-scrolloff ">
          { dateArr.map(date => (
            <div className="Table d-scrolloff" key={ date.toString() }>
              <h2 className={ clsx('DateS p-20', styles.dateDescription) }>
                <span>
                  { date &&
                    `${ weekdays?.find(
                      weekDay => weekDay?.en === format(date, 'EEEE')
                    )?.ru
                    }, ${ format(date, 'd') } ${ format(new Date(date), 'MMMM', {
                      locale: ru,
                    }) }` }
                </span>

                <span>
                  { test2?.data?.studentTimetable?.[0]?.chislitel !== null &&
                    test2?.data?.studentTimetable?.[0]?.chislitel !==
                    undefined &&
                    (test2?.data?.studentTimetable?.[0]?.chislitel
                      ? 'Неделя: Числитель'
                      : 'Неделя: Знаменатель') }
                </span>
              </h2>

              <table>
                <tr className="info">
                  <td className="date Bold" style={ { fontWeight: '700', } }>
                    Пара
                  </td>
                  <td className="time Bold" style={ { fontWeight: '700', } }>
                    Время
                  </td>
                  <td className="lesson Bold" style={ { fontWeight: '700', } }>
                    Предмет
                  </td>
                  <td className="coach Bold" style={ { fontWeight: '700', } }>
                    Преподаватель
                  </td>
                  <td className="type Bold" style={ { fontWeight: '700', } }>
                    Тип
                  </td>
                  <td className="audince Bold" style={ { fontWeight: '700', } }>
                    Аудитория
                  </td>
                </tr>

                { test2?.data ? (
                  test2.data.studentTimetable.map((i: any) => {
                    // eslint-disable-next-line eqeqeq
                    return (
                      String(i.day) === format(date, 'd') && (
                        <tr key={ i.id } className="row">
                          <td className="date d-cursor">{ i?.couple }</td>
                          <td className="time d-cursor">
                            { i.start ? i.start : 'Не указано' }-
                            { i.end ? i.end : 'Не указано' }
                          </td>
                          <td className="lesson d-cursor">{ i?.discipline }</td>
                          <td className="coach d-cursor">{ i?.teacher }</td>
                          <td className="type d-cursor">{ i?.type }</td>
                          <td className="audince d-cursor">{ i?.room }</td>
                        </tr>
                      )
                    )
                  })
                ) : (
                  <div>....loading....</div>
                ) }
              </table>
            </div>
          )) }
        </div>
      ) }

      <br />
    </div>
  )
}

export default Schedule
