/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  IconArrowRampRight,
  IconBook,
  IconBoxMultiple,
  IconBrandCodepen,
  IconBuildingSkyscraper,
  IconBulb,
  IconCircleDotted,
  IconDeviceLaptop,
  IconFlag,
  IconNotes,
  IconPlayerPlay,
  IconReportAnalytics,
  IconSchool,
  IconSquareCheck,
  IconTrophy,
  IconWallet,
  IconWorld,
} from '@tabler/icons'
import { format, } from 'date-fns'
import { useState, } from 'react'
import { useUserInfo, } from '../../gql/hooks/useME'
import ButtonSimple from '../common/ButtonSimple/ButtonSimple'
import CalendarSimple from '../common/CalendarSimple/CalendarSimple'
import CollapseContainer2, { AccordionInnder, } from '../common/CollapseContainer2/CollapseContainer2'
import FilterDropDown from '../common/FilterDropDown/FilterDropDown'
import Popup from '../common/Popup/Popup'
import HistoryLinks from '../common/HistoryLinks/HistoryLinks'
import PopupContentReject from './popupContent/PopupContentReject/PopupContentReject'
import TableContainer from './TableContainer/TableContainer'

import {
  appealTableSource as headerFields_21_1,
} from './constants/tablesFormData/table_21_1'
import {
  appealTableSource as headerFields_21_2,
} from './constants/tablesFormData/table_21_2'
// import {
//   headerAppealFields as headerFields_22_1,
// } from './TableContainer/tablesData/table_22_1';
// import {
//   headerAppealFields as headerFields_22_2,
// } from './TableContainer/tablesData/table_22_2';
import {
  appealTableSource as headerFields_22_3,
} from './constants/tablesFormData/table_22_3'
// import {
//   headerAppealFields as headerFields_22_4,
// } from './TableContainer/tablesData/table_22_4';
import {
  appealTableSource as headerFields_22_5,
} from './constants/tablesFormData/table_22_5'
import {
  appealTableSource as headerFields_22_6,
} from './constants/tablesFormData/table_22_6'
import {
  appealTableSource as headerFields_22_7,
} from './constants/tablesFormData/table_22_7'
import {
  appealTableSource as headerFields_22_8,
} from './constants/tablesFormData/table_22_8'
import {
  appealTableSource as headerFields_22_9,
} from './constants/tablesFormData/table_22_9'
// import {
//   headerAppealFields as headerFields_22_10,
// } from './TableContainer/tablesData/table_22_10';
import {
  appealTableSource as headerFields_22_11,
} from './constants/tablesFormData/table_22_11'
import {
  appealTableSource as headerFields_22_12,
} from './constants/tablesFormData/table_22_12'
import {
  appealTableSource as headerFields_22_13,
} from './constants/tablesFormData/table_22_13'
import {
  appealTableSource as headerFields_22_14,
} from './constants/tablesFormData/table_22_14'
import {
  appealTableSource as headerFields_22_15,
} from './constants/tablesFormData/table_22_15'
import {
  appealTableSource as headerFields_22_16,
} from './constants/tablesFormData/table_22_16'
import {
  appealTableSource as headerFields_22_17,
} from './constants/tablesFormData/table_22_17'
import {
  appealTableSource as headerFields_22_18,
} from './constants/tablesFormData/table_22_18'

import AMS_POP_UP_APPEAL_CONTENT from './pages/AdministratorTemplate/PopUpAppealContent/AmsPopUpAppealContent'

import useUrlSearch from '../../hooks/useUrlSearch'
import useGetEcListData from '../../gql/hooks/effectiveContractHooks/useGetEcListData'
import useEcSubscription from '../../gql/hooks/effectiveContractHooks/useEcSubscription2'
import { dataWithThresholdValues, } from './amsUtils/amsUtils'
import useGetEcAmsThresholdValues from '../../gql/hooks/effectiveContractHooks/useGetEcAmsThresholdValues'
import { EcTableName, } from '@/legacy/components/EffectiveContract/constants'


const ModerateAppeals = () => {
  const { dataTypeUpdated, setDataTypeUpdated, } = useEcSubscription()

  const [
    achievementType,
    setAchievementType,
  ] = useState('all')
  const [
    achievementStatus,
    setAchievementStatus,
  ] = useState('')
  const [
    achievementDate,
    setAchievementDate,
  ] = useState(null)

  const [
    isPopupOpen,
    setIsPopUpOpen,
  ] = useState(false)
  const [
    popUpType,
    setPopUpType,
  ] = useState(null)
  const [
    popUpData,
    setPopUpData,
  ] = useState<any>(null)
  const [
    dataType,
    setDataType,
  ] = useState(null)

  const moderateId = useUrlSearch('id')

  const fullName = useUserInfo(moderateId)?.data?.userInfo?.full_name


  const table_21_1 = useGetEcListData(moderateId, 'appeal_21_1', dataTypeUpdated, setDataTypeUpdated)
  const table_21_2 = useGetEcListData(moderateId, 'appeal_21_2', dataTypeUpdated, setDataTypeUpdated)

  const table_22_1 = useGetEcListData(moderateId, 'appeal_22_1', dataTypeUpdated, setDataTypeUpdated)
  const table_22_2 = useGetEcListData(moderateId, 'appeal_22_2', dataTypeUpdated, setDataTypeUpdated)

  const table_22_3 = useGetEcListData(moderateId, 'appeal_22_3', dataTypeUpdated, setDataTypeUpdated)
  const table_22_4 = useGetEcListData(moderateId, 'appeal_22_4', dataTypeUpdated, setDataTypeUpdated)
  const table_22_5 = useGetEcListData(moderateId, 'appeal_22_5', dataTypeUpdated, setDataTypeUpdated)
  const table_22_6 = useGetEcListData(moderateId, 'appeal_22_6', dataTypeUpdated, setDataTypeUpdated)
  const table_22_7 = useGetEcListData(moderateId, 'appeal_22_7', dataTypeUpdated, setDataTypeUpdated)
  const table_22_8 = useGetEcListData(moderateId, 'appeal_22_8', dataTypeUpdated, setDataTypeUpdated)

  const table_22_9 = useGetEcListData(moderateId, 'appeal_22_9', dataTypeUpdated, setDataTypeUpdated)
  const table_22_10 = useGetEcListData(moderateId, 'appeal_22_10', dataTypeUpdated, setDataTypeUpdated)
  const table_22_11 = useGetEcListData(moderateId, 'appeal_22_11', dataTypeUpdated, setDataTypeUpdated)
  const table_22_12 = useGetEcListData(moderateId, 'appeal_22_12', dataTypeUpdated, setDataTypeUpdated)

  const table_22_13 = useGetEcListData(moderateId, 'appeal_22_13', dataTypeUpdated, setDataTypeUpdated)
  const table_22_14 = useGetEcListData(moderateId, 'appeal_22_14', dataTypeUpdated, setDataTypeUpdated)
  const table_22_15 = useGetEcListData(moderateId, 'appeal_22_15', dataTypeUpdated, setDataTypeUpdated)
  const table_22_16 = useGetEcListData(moderateId, 'appeal_22_16', dataTypeUpdated, setDataTypeUpdated)
  const table_22_17 = useGetEcListData(moderateId, 'appeal_22_17', dataTypeUpdated, setDataTypeUpdated)
  const table_22_18 = useGetEcListData(moderateId, 'appeal_22_18', dataTypeUpdated, setDataTypeUpdated)

  const thresholdValues = useGetEcAmsThresholdValues()


  const popUpSelector = {
    displayRejectReason: <PopupContentReject title={ popUpData?.achievement } reason={ popUpData?.reason } onClickHandler={ () => setIsPopUpOpen(false) } />,
    displayAppealRejectReason: <PopupContentReject type='appeal' reason={ popUpData?.reason } onClickHandler={ () => setIsPopUpOpen(false) } />,

    appeal: <AMS_POP_UP_APPEAL_CONTENT dataType={ dataType } data={ popUpData } onClosePopUp={ () => setIsPopUpOpen(false) } />,
  }


  return (
    <div className="effective-contract">
      <HistoryLinks
        links={ [
          {
            value: 'Главная',
            path: '/',
          },
          // { value: "Эффективный контракт", path: EFFECTIVE_CONTRACT_MODERATE_ACHIEVEMENT_ROUTE },
        ] }
        loc="Модерация апелляций"
      />

      <h2 className="effective-contract__title">
        Модерация апелляций: { fullName }
      </h2>

      <CollapseContainer2 styles={ { marginBottom: '2rem', } }>
        <AccordionInnder
          headerText={ (
            <span className="effective-contract__subtitle">
              Фильтрация апелляций
            </span>
          ) }
        >
          <Filters
            setAchievementType={ setAchievementType }
            setAchievementStatus={ setAchievementStatus }
            setAchievementDate={ setAchievementDate }
          />
        </AccordionInnder>
      </CollapseContainer2>


      { (
        table_21_1?.length > 0
        || table_21_2?.length > 0
      )
        && (
          achievementType === 'table_21_1'
          || achievementType === 'table_21_2'
          || achievementType === 'all'
        )
        && (
          <h3 className="effective-contract-add-chievement__subtitle">
            Базовые показатели
          </h3>
        ) }


      { table_21_1?.length > 0 && (achievementType === 'table_21_1' || achievementType === 'all') ? (
        <AccordionInnder
          Icon={ IconBook }
          headerText="Обеспечение информационно-образовательной среды Университета учебными и учебно-методическими материалами"
        >
          <TableContainer
            dataType={ EcTableName.TABLE_21_1 }
            headerFields={ headerFields_21_1 }
            ecTableData={ dataWithThresholdValues({
              data: table_21_1.map((table: any) => {
                return {
                  ...table.data,
                  appeal_id: table.rec_id,
                }
              }),
              thresholdValues: thresholdValues?.table_21_1,
            }) }
            isAppealMode
          // popUpControllHandler={ () => {
          //   // @ts-expect-error TS(2345): Argument of type '"appeal"' is not assignable to p... Remove this comment to see the full error message
          //   setPopUpType('appeal')
          //   // @ts-expect-error TS(2345): Argument of type '"appeal_21_1"' is not assignable... Remove this comment to see the full error message
          //   setDataType('appeal_21_1')
          //   setIsPopUpOpen(!isPopupOpen)
          // } }
          // getRowData={ (answer: any) => setPopUpData(answer) }
          // extra={ {
          //   returnDataForDisplayRejectReason: (row: any) => {
          //     // @ts-expect-error TS(2345): Argument of type '"displayAppealRejectReason"' is ... Remove this comment to see the full error message
          //     setPopUpType('displayAppealRejectReason')
          //     setIsPopUpOpen(true)
          //     setPopUpData({
          //       reason: row.appealConclusion,
          //     })
          //   },
          // } }
          />
        </AccordionInnder>
      ) : false }


      { table_21_2?.length > 0 && (achievementType === 'table_21_2' || achievementType === 'all') ? (
        <AccordionInnder
          Icon={ IconBulb }
          headerText="Количество направлений подготовки, на которые объявлен набор на бюджетной основе, но не закрытые КЦП"
        >
          <TableContainer
            dataType={ EcTableName.TABLE_21_2 }
            headerFields={ headerFields_21_2 }
            ecTableData={ dataWithThresholdValues({
              data: table_21_2.map((table: any) => {
                return {
                  ...table.data,
                  appeal_id: table.rec_id,
                }
              }),
              thresholdValues: thresholdValues?.table_21_2,
            }) }
            isAppealMode
          // popUpControllHandler={ () => {
          //   // @ts-expect-error TS(2345): Argument of type '"appeal"' is not assignable to p... Remove this comment to see the full error message
          //   setPopUpType('appeal')
          //   // @ts-expect-error TS(2345): Argument of type '"appeal_21_2"' is not assignable... Remove this comment to see the full error message
          //   setDataType('appeal_21_2')
          //   setIsPopUpOpen(!isPopupOpen)
          // } }
          // getRowData={ (answer: any) => setPopUpData(answer) }
          // extra={ {
          //   returnDataForDisplayRejectReason: (row: any) => {
          //     // @ts-expect-error TS(2345): Argument of type '"displayAppealRejectReason"' is ... Remove this comment to see the full error message
          //     setPopUpType('displayAppealRejectReason')
          //     setIsPopUpOpen(true)
          //     setPopUpData({
          //       reason: row.appealConclusion,
          //     })
          //   },
          // } }
          />
        </AccordionInnder>
      ) : false }


      { (
        table_22_1?.length > 0
        || table_22_2?.length > 0
      )
        && (
          achievementType === 'table_22_1'
          || achievementType === 'table_22_2'
          || achievementType === 'all'
        )
        && (
          <h3 className="effective-contract-add-chievement__subtitle">
            Профориентационная работа
          </h3>
        ) }


      { table_22_1?.length > 0 && (achievementType === 'table_22_1' || achievementType === 'all') ? (
        <AccordionInnder
          Icon={ IconArrowRampRight }
          headerText="Количество направлений подготовки, на которые объявлен набор"
        >
          <TableContainer
            dataType={ EcTableName.TABLE_22_1 }
            // headerFields={ headerFields_22_1 }
            ecTableData={ dataWithThresholdValues({
              data: table_22_1.map((table: any) => {
                return {
                  ...table.data,
                  appeal_id: table.rec_id,
                }
              }),
              thresholdValues: thresholdValues?.table_22_1,
            }) }
            isAppealMode
          // popUpControllHandler={ () => {
          //   // @ts-expect-error TS(2345): Argument of type '"appeal"' is not assignable to p... Remove this comment to see the full error message
          //   setPopUpType('appeal')
          //   // @ts-expect-error TS(2345): Argument of type '"appeal_22_1"' is not assignable... Remove this comment to see the full error message
          //   setDataType('appeal_22_1')
          //   setIsPopUpOpen(!isPopupOpen)
          // } }
          // getRowData={ (answer: any) => setPopUpData(answer) }
          // extra={ {
          //   returnDataForDisplayRejectReason: (row: any) => {
          //     // @ts-expect-error TS(2345): Argument of type '"displayAppealRejectReason"' is ... Remove this comment to see the full error message
          //     setPopUpType('displayAppealRejectReason')
          //     setIsPopUpOpen(true)
          //     setPopUpData({
          //       reason: row.appealConclusion,
          //     })
          //   },
          // } }
          />
        </AccordionInnder>
      ) : false }


      { table_22_2?.length > 0 && (achievementType === 'table_22_2' || achievementType === 'all') ? (
        <AccordionInnder
          Icon={ IconCircleDotted }
          headerText="Средний балл ЕГЭ студентов, принятых по результатам ЕГЭ на обучение по очной форме по программам бакалавриата и специалитета"
        >
          <TableContainer
            dataType={ EcTableName.TABLE_22_2 }
            // headerFields={ headerFields_22_2 }
            ecTableData={ dataWithThresholdValues({
              data: table_22_2.map((table: any) => {
                return {
                  ...table.data,
                  appeal_id: table.rec_id,
                }
              }),
              thresholdValues: thresholdValues?.table_22_2,
            }) }
            isAppealMode
          // popUpControllHandler={ () => {
          //   // @ts-expect-error TS(2345): Argument of type '"appeal"' is not assignable to p... Remove this comment to see the full error message
          //   setPopUpType('appeal')
          //   // @ts-expect-error TS(2345): Argument of type '"appeal_22_2"' is not assignable... Remove this comment to see the full error message
          //   setDataType('appeal_22_2')
          //   setIsPopUpOpen(!isPopupOpen)
          // } }
          // getRowData={ (answer: any) => setPopUpData(answer) }
          // extra={ {
          //   returnDataForDisplayRejectReason: (row: any) => {
          //     // @ts-expect-error TS(2345): Argument of type '"displayAppealRejectReason"' is ... Remove this comment to see the full error message
          //     setPopUpType('displayAppealRejectReason')
          //     setIsPopUpOpen(true)
          //     setPopUpData({
          //       reason: row.appealConclusion,
          //     })
          //   },
          // } }
          />
        </AccordionInnder>
      ) : false }


      { (
        table_22_3?.length > 0
        || table_22_4?.length > 0
        || table_22_5?.length > 0
        || table_22_6?.length > 0
        || table_22_7?.length > 0
        || table_22_8?.length > 0
      )
        && (
          achievementType === 'table_22_3'
          || achievementType === 'table_22_4'
          || achievementType === 'table_22_5'
          || achievementType === 'table_22_6'
          || achievementType === 'table_22_7'
          || achievementType === 'table_22_8'
          || achievementType === 'all'
        )
        && (
          <h3 className="effective-contract-add-chievement__subtitle">
            Образовательная деятельность
          </h3>
        ) }


      { table_22_3?.length > 0 && (achievementType === 'table_22_3' || achievementType === 'all') ? (
        <AccordionInnder
          Icon={ IconSchool }
          headerText="Удельный вес численности обучающихся по программам магистратуры в общей численности приведенного контингента  обучающихся по основным образовательным программам высшего образования"
        >
          <TableContainer
            dataType={ EcTableName.TABLE_22_3 }
            headerFields={ headerFields_22_3 }
            ecTableData={ dataWithThresholdValues({
              data: table_22_3.map((table: any) => {
                return {
                  ...table.data,
                  appeal_id: table.rec_id,
                }
              }),
              thresholdValues: thresholdValues?.table_22_3,
            }) }
            isAppealMode
          // popUpControllHandler={ () => {
          //   // @ts-expect-error TS(2345): Argument of type '"appeal"' is not assignable to p... Remove this comment to see the full error message
          //   setPopUpType('appeal')
          //   // @ts-expect-error TS(2345): Argument of type '"appeal_22_3"' is not assignable... Remove this comment to see the full error message
          //   setDataType('appeal_22_3')
          //   setIsPopUpOpen(!isPopupOpen)
          // } }
          // getRowData={ (answer: any) => setPopUpData(answer) }
          // extra={ {
          //   returnDataForDisplayRejectReason: (row: any) => {
          //     // @ts-expect-error TS(2345): Argument of type '"displayAppealRejectReason"' is ... Remove this comment to see the full error message
          //     setPopUpType('displayAppealRejectReason')
          //     setIsPopUpOpen(true)
          //     setPopUpData({
          //       reason: row.appealConclusion,
          //     })
          //   },
          // } }
          />
        </AccordionInnder>
      ) : false }


      { table_22_4?.length > 0 && (achievementType === 'table_22_4' || achievementType === 'all') ? (
        <AccordionInnder
          Icon={ IconBoxMultiple }
          headerText="Комплектность групп очной формы обучения"
        >
          <TableContainer
            dataType={ EcTableName.TABLE_22_4 }
            // headerFields={ headerFields_22_4 }
            ecTableData={ dataWithThresholdValues({
              data: table_22_4.map((table: any) => {
                return {
                  ...table.data,
                  appeal_id: table.rec_id,
                }
              }),
              thresholdValues: thresholdValues?.table_22_4,
            }) }
            isAppealMode
          // popUpControllHandler={ () => {
          //   // @ts-expect-error TS(2345): Argument of type '"appeal"' is not assignable to p... Remove this comment to see the full error message
          //   setPopUpType('appeal')
          //   // @ts-expect-error TS(2345): Argument of type '"appeal_22_4"' is not assignable... Remove this comment to see the full error message
          //   setDataType('appeal_22_4')
          //   setIsPopUpOpen(!isPopupOpen)
          // } }
          // getRowData={ (answer: any) => setPopUpData(answer) }
          // extra={ {
          //   returnDataForDisplayRejectReason: (row: any) => {
          //     // @ts-expect-error TS(2345): Argument of type '"displayAppealRejectReason"' is ... Remove this comment to see the full error message
          //     setPopUpType('displayAppealRejectReason')
          //     setIsPopUpOpen(true)
          //     setPopUpData({
          //       reason: row.appealConclusion,
          //     })
          //   },
          // } }
          />
        </AccordionInnder>
      ) : false }


      { table_22_5?.length > 0 && (achievementType === 'table_22_5' || achievementType === 'all') ? (
        <AccordionInnder
          Icon={ IconWallet }
          headerText="Количество студентов стипендиатов. Количество студентов-стипендиатов, победители/призеры олимпиад и конкурсов и абитуриенты-победители всероссийских олимпиад"
        >
          <TableContainer
            dataType={ EcTableName.TABLE_22_5 }
            headerFields={ headerFields_22_5 }
            ecTableData={ dataWithThresholdValues({
              data: table_22_5.map((table: any) => {
                return {
                  ...table.data,
                  appeal_id: table.rec_id,
                }
              }),
              thresholdValues: thresholdValues?.table_22_5,
            }) }
            isAppealMode
          // popUpControllHandler={ () => {
          //   // @ts-expect-error TS(2345): Argument of type '"appeal"' is not assignable to p... Remove this comment to see the full error message
          //   setPopUpType('appeal')
          //   // @ts-expect-error TS(2345): Argument of type '"appeal_22_5"' is not assignable... Remove this comment to see the full error message
          //   setDataType('appeal_22_5')
          //   setIsPopUpOpen(!isPopupOpen)
          // } }
          // getRowData={ (answer: any) => setPopUpData(answer) }
          // extra={ {
          //   returnDataForDisplayRejectReason: (row: any) => {
          //     // @ts-expect-error TS(2345): Argument of type '"displayAppealRejectReason"' is ... Remove this comment to see the full error message
          //     setPopUpType('displayAppealRejectReason')
          //     setIsPopUpOpen(true)
          //     setPopUpData({
          //       reason: row.appealConclusion,
          //     })
          //   },
          // } }
          />
        </AccordionInnder>
      ) : false }


      { table_22_6?.length > 0 && (achievementType === 'table_22_6' || achievementType === 'all') ? (
        <AccordionInnder
          Icon={ IconDeviceLaptop }
          headerText="Доля онлайн-курсов, включенных в учебные планы по образовательным программам ВО, реализуемых в Университете"
        >
          <TableContainer
            dataType={ EcTableName.TABLE_22_6 }
            headerFields={ headerFields_22_6 }
            ecTableData={ dataWithThresholdValues({
              data: table_22_6.map((table: any) => {
                return {
                  ...table.data,
                  appeal_id: table.rec_id,
                }
              }),
              thresholdValues: thresholdValues?.table_22_6,
            }) }
            isAppealMode
          // popUpControllHandler={ () => {
          //   // @ts-expect-error TS(2345): Argument of type '"appeal"' is not assignable to p... Remove this comment to see the full error message
          //   setPopUpType('appeal')
          //   // @ts-expect-error TS(2345): Argument of type '"appeal_22_6"' is not assignable... Remove this comment to see the full error message
          //   setDataType('appeal_22_6')
          //   setIsPopUpOpen(!isPopupOpen)
          // } }
          // getRowData={ (answer: any) => setPopUpData(answer) }
          // extra={ {
          //   returnDataForDisplayRejectReason: (row: any) => {
          //     // @ts-expect-error TS(2345): Argument of type '"displayAppealRejectReason"' is ... Remove this comment to see the full error message
          //     setPopUpType('displayAppealRejectReason')
          //     setIsPopUpOpen(true)
          //     setPopUpData({
          //       reason: row.appealConclusion,
          //     })
          //   },
          // } }
          />
        </AccordionInnder>
      ) : false }


      { table_22_7?.length > 0 && (achievementType === 'table_22_7' || achievementType === 'all') ? (
        <AccordionInnder
          Icon={ IconReportAnalytics }
          headerText="Объем дополнительных образовательных услуг, оказанных центрами, созданными в институте, на факультете"
        >
          <TableContainer
            dataType={ EcTableName.TABLE_22_7 }
            headerFields={ headerFields_22_7 }
            ecTableData={ dataWithThresholdValues({
              data: table_22_7.map((table: any) => {
                return {
                  ...table.data,
                  appeal_id: table.rec_id,
                }
              }),
              thresholdValues: thresholdValues?.table_22_7,
            }) }
            isAppealMode
          // popUpControllHandler={ () => {
          //   // @ts-expect-error TS(2345): Argument of type '"appeal"' is not assignable to p... Remove this comment to see the full error message
          //   setPopUpType('appeal')
          //   // @ts-expect-error TS(2345): Argument of type '"appeal_22_7"' is not assignable... Remove this comment to see the full error message
          //   setDataType('appeal_22_7')
          //   setIsPopUpOpen(!isPopupOpen)
          // } }
          // getRowData={ (answer: any) => setPopUpData(answer) }
          // extra={ {
          //   returnDataForDisplayRejectReason: (row: any) => {
          //     // @ts-expect-error TS(2345): Argument of type '"displayAppealRejectReason"' is ... Remove this comment to see the full error message
          //     setPopUpType('displayAppealRejectReason')
          //     setIsPopUpOpen(true)
          //     setPopUpData({
          //       reason: row.appealConclusion,
          //     })
          //   },
          // } }
          />
        </AccordionInnder>
      ) : false }


      { table_22_8?.length > 0 && (achievementType === 'table_22_8' || achievementType === 'all') ? (
        <AccordionInnder
          Icon={ IconPlayerPlay }
          headerText="Количество выполненных обучающимися проектов и стартапов на возмездной основе"
        >
          <TableContainer
            dataType={ EcTableName.TABLE_22_8 }
            headerFields={ headerFields_22_8 }
            ecTableData={ dataWithThresholdValues({
              data: table_22_8.map((table: any) => {
                return {
                  ...table.data,
                  appeal_id: table.rec_id,
                }
              }),
              thresholdValues: thresholdValues?.table_22_8,
            }) }
            isAppealMode
          // popUpControllHandler={ () => {
          //   // @ts-expect-error TS(2345): Argument of type '"appeal"' is not assignable to p... Remove this comment to see the full error message
          //   setPopUpType('appeal')
          //   // @ts-expect-error TS(2345): Argument of type '"appeal_22_8"' is not assignable... Remove this comment to see the full error message
          //   setDataType('appeal_22_8')
          //   setIsPopUpOpen(!isPopupOpen)
          // } }
          // getRowData={ (answer: any) => setPopUpData(answer) }
          // extra={ {
          //   returnDataForDisplayRejectReason: (row: any) => {
          //     // @ts-expect-error TS(2345): Argument of type '"displayAppealRejectReason"' is ... Remove this comment to see the full error message
          //     setPopUpType('displayAppealRejectReason')
          //     setIsPopUpOpen(true)
          //     setPopUpData({
          //       reason: row.appealConclusion,
          //     })
          //   },
          // } }
          />
        </AccordionInnder>
      ) : false }


      { (
        table_22_9?.length > 0
        || table_22_10?.length > 0
        || table_22_11?.length > 0
        || table_22_12?.length > 0
      )
        && (
          achievementType === 'table_22_9'
          || achievementType === 'table_22_10'
          || achievementType === 'table_22_11'
          || achievementType === 'table_22_12'
          || achievementType === 'all'
        )
        && (
          <h3 className="effective-contract-add-chievement__subtitle">
            Научная деятельность
          </h3>
        ) }


      { table_22_9?.length > 0 && (achievementType === 'table_22_9' || achievementType === 'all') ? (
        <AccordionInnder
          Icon={ IconSquareCheck }
          headerText="Полученные РИДы, прошедшие утверждение на НТС или УМС Университета"
        >
          <TableContainer
            dataType={ EcTableName.TABLE_22_9 }
            headerFields={ headerFields_22_9 }
            ecTableData={ dataWithThresholdValues({
              data: table_22_9.map((table: any) => {
                return {
                  ...table.data,
                  appeal_id: table.rec_id,
                }
              }),
              thresholdValues: thresholdValues?.table_22_9,
            }) }
            isAppealMode
          // popUpControllHandler={ () => {
          //   // @ts-expect-error TS(2345): Argument of type '"appeal"' is not assignable to p... Remove this comment to see the full error message
          //   setPopUpType('appeal')
          //   // @ts-expect-error TS(2345): Argument of type '"appeal_22_9"' is not assignable... Remove this comment to see the full error message
          //   setDataType('appeal_22_9')
          //   setIsPopUpOpen(!isPopupOpen)
          // } }
          // getRowData={ (answer: any) => setPopUpData(answer) }
          // extra={ {
          //   returnDataForDisplayRejectReason: (row: any) => {
          //     // @ts-expect-error TS(2345): Argument of type '"displayAppealRejectReason"' is ... Remove this comment to see the full error message
          //     setPopUpType('displayAppealRejectReason')
          //     setIsPopUpOpen(true)
          //     setPopUpData({
          //       reason: row.appealConclusion,
          //     })
          //   },
          // } }
          />
        </AccordionInnder>
      ) : false }


      { table_22_10?.length > 0 && (achievementType === 'table_22_10' || achievementType === 'all') ? (
        <AccordionInnder
          Icon={ IconNotes }
          headerText="Статьи в изданиях, входящих в Scopus  и Web of Science, 1-4 квартили"
        >
          <TableContainer
            dataType={ EcTableName.TABLE_22_10 }
            // headerFields={ headerFields_22_10 }
            ecTableData={ dataWithThresholdValues({
              data: table_22_10.map((table: any) => {
                return {
                  ...table.data,
                  appeal_id: table.rec_id,
                }
              }),
              thresholdValues: thresholdValues?.table_22_10,
            }) }
            isAppealMode
          // popUpControllHandler={ () => {
          //   // @ts-expect-error TS(2345): Argument of type '"appeal"' is not assignable to p... Remove this comment to see the full error message
          //   setPopUpType('appeal')
          //   // @ts-expect-error TS(2345): Argument of type '"appeal_22_10"' is not assignabl... Remove this comment to see the full error message
          //   setDataType('appeal_22_10')
          //   setIsPopUpOpen(!isPopupOpen)
          // } }
          // getRowData={ (answer: any) => setPopUpData(answer) }
          // extra={ {
          //   returnDataForDisplayRejectReason: (row: any) => {
          //     // @ts-expect-error TS(2345): Argument of type '"displayAppealRejectReason"' is ... Remove this comment to see the full error message
          //     setPopUpType('displayAppealRejectReason')
          //     setIsPopUpOpen(true)
          //     setPopUpData({
          //       reason: row.appealConclusion,
          //     })
          //   },
          // } }
          />
        </AccordionInnder>
      ) : false }


      { table_22_11?.length > 0 && (achievementType === 'table_22_11' || achievementType === 'all') ? (
        <AccordionInnder
          Icon={ IconTrophy }
          headerText="Научные гранты  и проекты Университета, получившие финансовую поддержку"
        >
          <TableContainer
            dataType={ EcTableName.TABLE_22_11 }
            headerFields={ headerFields_22_11 }
            ecTableData={ dataWithThresholdValues({
              data: table_22_11.map((table: any) => {
                return {
                  ...table.data,
                  appeal_id: table.rec_id,
                }
              }),
              thresholdValues: thresholdValues?.table_22_11,
            }) }
            isAppealMode
          // popUpControllHandler={ () => {
          //   // @ts-expect-error TS(2345): Argument of type '"appeal"' is not assignable to p... Remove this comment to see the full error message
          //   setPopUpType('appeal')
          //   // @ts-expect-error TS(2345): Argument of type '"appeal_22_11"' is not assignabl... Remove this comment to see the full error message
          //   setDataType('appeal_22_11')
          //   setIsPopUpOpen(!isPopupOpen)
          // } }
          // getRowData={ (answer: any) => setPopUpData(answer) }
          // extra={ {
          //   returnDataForDisplayRejectReason: (row: any) => {
          //     // @ts-expect-error TS(2345): Argument of type '"displayAppealRejectReason"' is ... Remove this comment to see the full error message
          //     setPopUpType('displayAppealRejectReason')
          //     setIsPopUpOpen(true)
          //     setPopUpData({
          //       reason: row.appealConclusion,
          //     })
          //   },
          // } }
          />
        </AccordionInnder>
      ) : false }


      { table_22_12?.length > 0 && (achievementType === 'table_22_12' || achievementType === 'all') ? (
        <AccordionInnder
          Icon={ IconSchool }
          headerText="Число научных конференций с международным участием, проведенных Университетом, по которым изданы материалы, индексируемые в международных информационно-аналитических системах научного цитирования Web of Science и Scopus"
        >
          <TableContainer
            dataType={ EcTableName.TABLE_22_12 }
            headerFields={ headerFields_22_12 }
            ecTableData={ dataWithThresholdValues({
              data: table_22_12.map((table: any) => {
                return {
                  ...table.data,
                  appeal_id: table.rec_id,
                }
              }),
              thresholdValues: thresholdValues?.table_22_12,
            }) }
            isAppealMode
          // popUpControllHandler={ () => {
          //   // @ts-expect-error TS(2345): Argument of type '"appeal"' is not assignable to p... Remove this comment to see the full error message
          //   setPopUpType('appeal')
          //   // @ts-expect-error TS(2345): Argument of type '"appeal_22_12"' is not assignabl... Remove this comment to see the full error message
          //   setDataType('appeal_22_12')
          //   setIsPopUpOpen(!isPopupOpen)
          // } }
          // getRowData={ (answer: any) => setPopUpData(answer) }
          // extra={ {
          //   returnDataForDisplayRejectReason: (row: any) => {
          //     // @ts-expect-error TS(2345): Argument of type '"displayAppealRejectReason"' is ... Remove this comment to see the full error message
          //     setPopUpType('displayAppealRejectReason')
          //     setIsPopUpOpen(true)
          //     setPopUpData({
          //       reason: row.appealConclusion,
          //     })
          //   },
          // } }
          />
        </AccordionInnder>
      ) : false }


      { (
        table_22_13?.length > 0
        || table_22_14?.length > 0
        || table_22_15?.length > 0
        || table_22_16?.length > 0
        || table_22_17?.length > 0
        || table_22_18?.length > 0
      )
        && (
          achievementType === 'table_22_13'
          || achievementType === 'table_22_14'
          || achievementType === 'table_22_15'
          || achievementType === 'table_22_16'
          || achievementType === 'table_22_17'
          || achievementType === 'table_22_18'
          || achievementType === 'all'
        )
        && (
          <h3 className="effective-contract-add-chievement__subtitle">
            Иные виды деятельности
          </h3>
        ) }


      { table_22_13?.length > 0 && (achievementType === 'table_22_13' || achievementType === 'all') ? (
        <AccordionInnder
          Icon={ IconWorld }
          headerText="Число иностранных преподавателей, привлеченных к работе в штат"
        >
          <TableContainer
            dataType={ EcTableName.TABLE_22_13 }
            headerFields={ headerFields_22_13 }
            ecTableData={ dataWithThresholdValues({
              data: table_22_13.map((table: any) => {
                return {
                  ...table.data,
                  appeal_id: table.rec_id,
                }
              }),
              thresholdValues: thresholdValues?.table_22_13,
            }) }
            isAppealMode
          // popUpControllHandler={ () => {
          //   // @ts-expect-error TS(2345): Argument of type '"appeal"' is not assignable to p... Remove this comment to see the full error message
          //   setPopUpType('appeal')
          //   // @ts-expect-error TS(2345): Argument of type '"appeal_22_13"' is not assignabl... Remove this comment to see the full error message
          //   setDataType('appeal_22_13')
          //   setIsPopUpOpen(!isPopupOpen)
          // } }
          // getRowData={ (answer: any) => setPopUpData(answer) }
          // extra={ {
          //   returnDataForDisplayRejectReason: (row: any) => {
          //     // @ts-expect-error TS(2345): Argument of type '"displayAppealRejectReason"' is ... Remove this comment to see the full error message
          //     setPopUpType('displayAppealRejectReason')
          //     setIsPopUpOpen(true)
          //     setPopUpData({
          //       reason: row.appealConclusion,
          //     })
          //   },
          // } }
          />
        </AccordionInnder>
      ) : false }


      { table_22_14?.length > 0 && (achievementType === 'table_22_14' || achievementType === 'all') ? (
        <AccordionInnder
          Icon={ IconFlag }
          headerText="Число преподавателей Университета, приглашенных для преподавания в образовательные, научно-образовательные российские и иностранные организации"
        >
          <TableContainer
            dataType={ EcTableName.TABLE_22_14 }
            headerFields={ headerFields_22_14 }
            ecTableData={ dataWithThresholdValues({
              data: table_22_14.map((table: any) => {
                return {
                  ...table.data,
                  appeal_id: table.rec_id,
                }
              }),
              thresholdValues: thresholdValues?.table_22_14,
            }) }
            isAppealMode
          // popUpControllHandler={ () => {
          //   // @ts-expect-error TS(2345): Argument of type '"appeal"' is not assignable to p... Remove this comment to see the full error message
          //   setPopUpType('appeal')
          //   // @ts-expect-error TS(2345): Argument of type '"appeal_22_14"' is not assignabl... Remove this comment to see the full error message
          //   setDataType('appeal_22_14')
          //   setIsPopUpOpen(!isPopupOpen)
          // } }
          // getRowData={ (answer: any) => setPopUpData(answer) }
          // extra={ {
          //   returnDataForDisplayRejectReason: (row: any) => {
          //     // @ts-expect-error TS(2345): Argument of type '"displayAppealRejectReason"' is ... Remove this comment to see the full error message
          //     setPopUpType('displayAppealRejectReason')
          //     setIsPopUpOpen(true)
          //     setPopUpData({
          //       reason: row.appealConclusion,
          //     })
          //   },
          // } }
          />
        </AccordionInnder>
      ) : false }


      { table_22_15?.length > 0 && (achievementType === 'table_22_15' || achievementType === 'all') ? (
        <AccordionInnder
          Icon={ IconBrandCodepen }
          headerText="Реализация совместных международных образовательных программ"
        >
          <TableContainer
            dataType={ EcTableName.TABLE_22_15 }
            headerFields={ headerFields_22_15 }
            ecTableData={ dataWithThresholdValues({
              data: table_22_15.map((table: any) => {
                return {
                  ...table.data,
                  appeal_id: table.rec_id,
                }
              }),
              thresholdValues: thresholdValues?.table_22_15,
            }) }
            isAppealMode
          // popUpControllHandler={ () => {
          //   // @ts-expect-error TS(2345): Argument of type '"appeal"' is not assignable to p... Remove this comment to see the full error message
          //   setPopUpType('appeal')
          //   // @ts-expect-error TS(2345): Argument of type '"appeal_22_15"' is not assignabl... Remove this comment to see the full error message
          //   setDataType('appeal_22_15')
          //   setIsPopUpOpen(!isPopupOpen)
          // } }
          // getRowData={ (answer: any) => setPopUpData(answer) }
          // extra={ {
          //   returnDataForDisplayRejectReason: (row: any) => {
          //     // @ts-expect-error TS(2345): Argument of type '"displayAppealRejectReason"' is ... Remove this comment to see the full error message
          //     setPopUpType('displayAppealRejectReason')
          //     setIsPopUpOpen(true)
          //     setPopUpData({
          //       reason: row.appealConclusion,
          //     })
          //   },
          // } }
          />
        </AccordionInnder>
      ) : false }


      { table_22_16?.length > 0 && (achievementType === 'table_22_16' || achievementType === 'all') ? (
        <AccordionInnder
          Icon={ IconSchool }
          headerText="Отношение числа выпускников, трудоустроившихся в течение календарного года, следующего за годом выпуска к общему числу выпускников за календарный год за вычетом выпускников, продолживших обучение по очной форме"
        >
          <TableContainer
            dataType={ EcTableName.TABLE_22_16 }
            headerFields={ headerFields_22_16 }
            ecTableData={ dataWithThresholdValues({
              data: table_22_16.map((table: any) => {
                return {
                  ...table.data,
                  appeal_id: table.rec_id,
                }
              }),
              thresholdValues: thresholdValues?.table_22_16,
            }) }
            isAppealMode
          // popUpControllHandler={ () => {
          //   // @ts-expect-error TS(2345): Argument of type '"appeal"' is not assignable to p... Remove this comment to see the full error message
          //   setPopUpType('appeal')
          //   // @ts-expect-error TS(2345): Argument of type '"appeal_22_16"' is not assignabl... Remove this comment to see the full error message
          //   setDataType('appeal_22_16')
          //   setIsPopUpOpen(!isPopupOpen)
          // } }
          // getRowData={ (answer: any) => setPopUpData(answer) }
          // extra={ {
          //   returnDataForDisplayRejectReason: (row: any) => {
          //     // @ts-expect-error TS(2345): Argument of type '"displayAppealRejectReason"' is ... Remove this comment to see the full error message
          //     setPopUpType('displayAppealRejectReason')
          //     setIsPopUpOpen(true)
          //     setPopUpData({
          //       reason: row.appealConclusion,
          //     })
          //   },
          // } }
          />
        </AccordionInnder>
      ) : false }


      { table_22_17?.length > 0 && (achievementType === 'table_22_17' || achievementType === 'all') ? (
        <AccordionInnder
          Icon={ IconBuildingSkyscraper }
          headerText="Отношение числа выпускников, трудоустроившихся по полученной специальности в течение календарного года, следующего за годом выпуска  к общему числу выпускников за календарный год за вычетом выпускников, продолживших обучение по очной форме"
        >
          <TableContainer
            dataType={ EcTableName.TABLE_22_17 }
            headerFields={ headerFields_22_17 }
            ecTableData={ dataWithThresholdValues({
              data: table_22_17.map((table: any) => {
                return {
                  ...table.data,
                  appeal_id: table.rec_id,
                }
              }),
              thresholdValues: thresholdValues?.table_22_17,
            }) }
            isAppealMode
          // popUpControllHandler={ () => {
          //   // @ts-expect-error TS(2345): Argument of type '"appeal"' is not assignable to p... Remove this comment to see the full error message
          //   setPopUpType('appeal')
          //   // @ts-expect-error TS(2345): Argument of type '"appeal_22_17"' is not assignabl... Remove this comment to see the full error message
          //   setDataType('appeal_22_17')
          //   setIsPopUpOpen(!isPopupOpen)
          // } }
          // getRowData={ (answer: any) => setPopUpData(answer) }
          // extra={ {
          //   returnDataForDisplayRejectReason: (row: any) => {
          //     // @ts-expect-error TS(2345): Argument of type '"displayAppealRejectReason"' is ... Remove this comment to see the full error message
          //     setPopUpType('displayAppealRejectReason')
          //     setIsPopUpOpen(true)
          //     setPopUpData({
          //       reason: row.appealConclusion,
          //     })
          //   },
          // } }
          />
        </AccordionInnder>
      ) : false }


      { table_22_18?.length > 0 && (achievementType === 'table_22_18' || achievementType === 'all') ? (
        <AccordionInnder
          Icon={ IconCircleDotted }
          headerText="Средний балл рейтинга НПР"
        >
          <TableContainer
            dataType={ EcTableName.TABLE_22_18 }
            headerFields={ headerFields_22_18 }
            ecTableData={ dataWithThresholdValues({
              data: table_22_18.map((table: any) => {
                return {
                  ...table.data,
                  appeal_id: table.rec_id,
                }
              }),
              thresholdValues: thresholdValues?.table_22_18,
            }) }
            isAppealMode
          // popUpControllHandler={ () => {
          //   // @ts-expect-error TS(2345): Argument of type '"appeal"' is not assignable to p... Remove this comment to see the full error message
          //   setPopUpType('appeal')
          //   // @ts-expect-error TS(2345): Argument of type '"appeal_22_18"' is not assignabl... Remove this comment to see the full error message
          //   setDataType('appeal_22_18')
          //   setIsPopUpOpen(!isPopupOpen)
          // } }
          // getRowData={ (answer: any) => setPopUpData(answer) }
          // extra={ {
          //   returnDataForDisplayRejectReason: (row: any) => {
          //     // @ts-expect-error TS(2345): Argument of type '"displayAppealRejectReason"' is ... Remove this comment to see the full error message
          //     setPopUpType('displayAppealRejectReason')
          //     setIsPopUpOpen(true)
          //     setPopUpData({
          //       reason: row.appealConclusion,
          //     })
          //   },
          // } }
          />
        </AccordionInnder>
      ) : false }


      { isPopupOpen && (
        <Popup
          isOpen={ isPopupOpen }
          handlePopup={ () => setIsPopUpOpen(false) }
          withScroll
        >
          {
            // @ts-expect-error TS(2538): Type 'null' cannot be used as an index type.
            popUpSelector[popUpType]
          }
        </Popup>
      ) }
    </div>
  )
}


const Filters = ({
  setAchievementType,
  setAchievementStatus,
  setAchievementDate,
}: any) => {
  const achievementTypes = [
    {
      itemName: 'all',
      value: 'Все',
    },
    {
      itemName: 'table_21_1',
      value: 'Обеспечение информационно-образовательной среды Университета учебными и учебно-методическими материалами',

    },
    {
      itemName: 'table_21_2',
      value: 'Количество направлений подготовки, на которые объявлен набор на бюджетной основе, но не закрытые КЦП',
    },
    {
      itemName: 'table_22_1',
      value: 'Количество направлений подготовки, на которые объявлен набор',
    },
    {
      itemName: 'table_22_2',
      value: 'Средний балл ЕГЭ студентов, принятых по результатам ЕГЭ на обучение по очной форме по программам бакалавриата и специалитета',
    },
    {
      itemName: 'table_22_3',
      value: 'Удельный вес численности обучающихся по программам магистратуры в общей численности приведенного контингента  обучающихся по основным образовательным программам высшего образования',

    },
    {
      itemName: 'table_22_4',
      value: 'Комплектность групп очной формы обучения',
    },
    {
      itemName: 'table_22_5',
      value: 'Количество студентов стипендиатов. Количество студентов-стипендиатов, победители/призеры олимпиад и конкурсов и абитуриенты-победители всероссийских олимпиад',
    },
    {
      itemName: 'table_22_6',
      value: 'Доля онлайн-курсов, включенных в учебные планы по образовательным программам ВО, реализуемых в Университете',
    },
    {
      itemName: 'table_22_7',
      value: 'Объем дополнительных образовательных услуг, оказанных центрами, созданными в институте, на факультете',
    },
    {
      itemName: 'table_22_8',
      value: 'Количество выполненных обучающимися проектов и стартапов на возмездной основе',
    },
    {
      itemName: 'table_22_9',
      value: 'Полученные РИДы, прошедшие утверждение на НТС или УМС Университета',
    },
    {
      itemName: 'table_22_10',
      value: 'Статьи в изданиях, входящих в Scopus  и Web of Science, 1-4 квартили',
    },
    {
      itemName: 'table_22_11',
      value: 'Научные гранты  и проекты Университета, получившие финансовую поддержку',
    },
    {
      itemName: 'table_22_12',
      value: 'Число научных конференций с международным участием, проведенных Университетом, по которым изданы материалы, индексируемые в международных информационно-аналитических системах научного цитирования Web of Science и Scopus',
    },
    {
      itemName: 'table_22_13',
      value: 'Число иностранных преподавателей, привлеченных к работе в штат',
    },
    {
      itemName: 'table_22_14',
      value: 'Число преподавателей Университета, приглашенных для преподавания в образовательные, научно-образовательные российские и иностранные организации',
    },
    {
      itemName: 'table_22_15',
      value: 'Реализация совместных международных образовательных программ',
    },
    {
      itemName: 'table_22_16',
      value: 'Отношение числа выпускников, трудоустроившихся в течение календарного года, следующего за годом выпуска к общему числу выпускников за календарный год за вычетом выпускников, продолживших обучение по очной форме',
    },
    {
      itemName: 'table_22_17',
      value: 'Отношение числа выпускников, трудоустроившихся по полученной специальности в течение календарного года, следующего за годом выпуска  к общему числу выпускников за календарный год за вычетом выпускников, продолживших обучение по очной форме',
    },
    {
      itemName: 'table_22_18',
      value: 'Средний балл рейтинга НПР',
    },
  ]

  const achievementStatuses = [
    {
      itemName: '',
      value: 'Все',
    },
    {
      itemName: 'appealPending',
      value: 'Ожидает проверки',
    },
    {
      itemName: 'appealRejected',
      value: 'Отклонено',
    },
    {
      itemName: 'appealApproved',
      value: 'Подтверждено',
    },
  ]

  const [
    achievementTypeFilter,
    setAchievementTypeFilter,
  ] = useState('all')
  const [
    achievementStatusFilter,
    setAchievementStatusFilter,
  ] = useState('')
  const [
    achievementDateFilter,
    setAchievementDateFilter,
  ] = useState('')

  return (
    <div className="effective-contract__filters-wrapper">
      <FilterDropDown
        customWidth="36rem"
        maxWidthOfinput="33rem"
        defaultHint="Тип"
        items={ achievementTypes.map(type => type.value) }
        onClickHandler={ (answer: any) => {
          setAchievementTypeFilter(
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            achievementTypes.find(item => item.value === answer).itemName)
        } }
      />

      <FilterDropDown
        customWidth="21rem"
        defaultHint="Статус"
        items={ achievementStatuses.map(type => type.value) }
        onClickHandler={ (answer: any) => {
          setAchievementStatusFilter(
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            achievementStatuses.find(item => item.value === answer).itemName)
        } }
      />

      <CalendarSimple
        styles={ { width: '18rem', } }
        returnRange={ (range: any) => {
          setAchievementDateFilter({
            // @ts-expect-error TS(2345): Argument of type '{ from: string; to: string; }' i... Remove this comment to see the full error message
            from: format(new Date(range[0]), 'yyyy-MM-dd'),
            to: format(new Date(range[1]), 'yyyy-MM-dd'),
          })
        } }
      />

      <ButtonSimple
        value="Применить"
        variant="fill"
        onClickHandler={ () => {
          setAchievementType(achievementTypeFilter)
          setAchievementStatus(achievementStatusFilter)
          setAchievementDate(achievementDateFilter)
        } }
      />
    </div>
  )
}


export default ModerateAppeals
