import { useState, } from 'react'
import { NewPageWrapper, } from '@/legacy/components/common/GeneralElements/GeneralElements'
import HistoryLinks from '@/legacy/components/common/HistoryLinks/HistoryLinks'
import { findContact, } from '@/legacy/Utils/functions'
import { useSelector, } from 'react-redux'
import ConfirmPhoneNumber from '@/legacy/components/Mfc/ConfirmPhoneNumber'
import FilterDropDown from '@/legacy/components/common/FilterDropDown/FilterDropDown'
import FormGenerator from '@/legacy/components/Mfc/FormGenerator/FormGenerator'
import { duty, } from '@/legacy/components/Mfc/DutyNotes'

const GetDutyNote = () => {
  const personalInfo = useSelector((state: any) => state.auth.me)
  const [
    newMobile,
    setNewMobile,
  ] = useState<any>()
  const [
    applicationType,
    setApplicationType,
  ] = useState<any>()
  const [
    isVarified,
    setIsVarified,
  ] = useState(false)
  const [
    phoneOption,
    setPhoneOption,
  ] = useState('Из моего профиля')
  const [
    payload,
    setPayload,
  ] = useState<any>({})

  const tel =
    findContact(personalInfo.data.contacts, {
      kind_contact_information: 'ТелефонМобильныйФизическиеЛица',
    }) !== '--'
      ? findContact(personalInfo.data.contacts, {
        kind_contact_information: 'ТелефонМобильныйФизическиеЛица',
      })
      : findContact(personalInfo.data.contacts, {
        kind_contact_information: 'Мобильный телефон',
      })

  return (
    <NewPageWrapper className="GetMfcApplication">
      <HistoryLinks
        links={ [
          {
            value: 'Главная',
            path: '/',
          },
        ] }
        loc="Конструктор подачи служебной записки"
      />
      <h1 className="page-main-title">Конструктор подачи служебной записки</h1>
      <h2 className="section-title">Укажите номер мобильного телефона</h2>
      <ConfirmPhoneNumber
        setMobile={ setNewMobile }
        newMobile={ newMobile }
        setIsVarified={ setIsVarified }
        isVarified={ isVarified }
        setPhoneOption={ setPhoneOption }
        phoneOption={ phoneOption }
        tel={ tel }
      />
      { (newMobile?.length === 12 && isVarified) ||
        (phoneOption === 'Из моего профиля' && (
          <>
            <h2 className="section-title">Выберите тип служебной записки</h2>
            <FilterDropDown
              items={ duty }
              field="name"
              onClickHandler={ setApplicationType }
              defaultHint="Выберите тип"
              firstIsSelected
              customWidth="40%"
            />
            { applicationType && (
              <FormGenerator
                setPayload={ setPayload }
                payload={ payload }
                id={ applicationType.id }
              />
            ) }
          </>
        )) }
    </NewPageWrapper>
  )
}

export default GetDutyNote
