import React from 'react'
import HistoryLinks from '../common/HistoryLinks/HistoryLinks'
import StudentJournal from './StudentJournal/StudentJournal'
import WorkerJournal from './WorkerJournal/WorkerJournal'
import './StudyJournal.less'
import { useSelector, } from 'react-redux'

function StudyJournal () {
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const user = useSelector(state => state.auth.userType)

  return (
    <div className="journalPage">
      <HistoryLinks links={ [
        {
          value: 'Главная',
          path: '/',
        },
        {
          value: 'Учебный процесс',
          path: '/study-journal',
        },
      ] } loc="Электронный журнал" />
      { user === 'student' ? <StudentJournal /> : <WorkerJournal /> }
    </div>
  )
}

export default StudyJournal
