import { UserRoles, } from '@/legacy/constants'
import { useAppSelector, } from '@/legacy/redux/hooks'
import { UserRoleType, } from '@/legacy/types'


const isUserRole = ((role: null | UserRoleType): role is UserRoleType => role !== null)


const useGetRole = (): UserRoleType => {
  const defaultRole = useAppSelector(state => state.auth.userType)
  const choosenRole = useAppSelector(state => state.auth.choosenRole)


  if (isUserRole(choosenRole)) {
    return choosenRole
  } else if (isUserRole(defaultRole)) {
    return defaultRole
  } return UserRoles.UNKNOWN
}


export default useGetRole
