import React, { useState, useMemo, } from 'react'
import './Anounces.less'
import HistoryLinks from '../common/HistoryLinks/HistoryLinks'
import AdCard from './AdCard/AdCard'
import EventCard from './EventCard/EventCard'
import '../Main/Filters/Filter.less'
import TagsFilter from '../common/TagsFilter/TagsFilter'
import Calendar from 'react-calendar'
import { useGetNews, } from '../../gql/hooks/useNews'
import PaginationSimple from '../common/PaginationSimple/PaginationSimple'
import format from 'date-fns/format'
import ru from 'date-fns/locale/ru'
import NoContracts from '../Finances/PaymentPage/NoContracts/NoContracts'
import Preloader from '../common/PreLoader/Preloader'

function Anounces () {
  const [
    offs,
    setOffs,
  ] = useState(0)
  const [
    limit, /* , setLimit */
  ] = useState(20)
  const [
    selectedCategories,
    setSelectedCategories,
  ] = useState<any>([])
  const [
    date,
    setDate,
  ] = useState(null)

  const { data, loading, error, } = useGetNews(offs, limit, {
    endDate: date,
    startDate: date,
    tags: selectedCategories,
    eventType: 'Announcements',
  })


  const cards = useMemo(() => {
    if (loading) {
      return <Preloader />
    }
    if (error) {
      return <div>...error...</div>
    } else if (data) {
      if (data.newsFeed.items.length === 0) {
        return <NoContracts title="По выбранным фильтрам анонсов не найдено" />
      }
      return data.newsFeed.items.map((i: any, index: any) => {
        if (i.tags.includes('Мероприятия')) {
          return <li key={ index }><EventCard i={ i } /></li>
        }
        return <li key={ index }><AdCard i={ i } /></li>
      })
    }
  }, [
    data,
    loading,
    error,
  ])

  const handlSelected = (i: any) => {
    if (selectedCategories.includes(i)) {
      setSelectedCategories(selectedCategories.filter((item: any) => item !== i))
      setOffs(0)
    } else {
      setSelectedCategories([
        ...selectedCategories,
        i,
      ])
      setOffs(0)
    }
  }

  function handlePaginate (e: any, value: any) {
    setOffs(value * 20)
    const page = document.querySelector('.main-scroll-bar')?.firstChild
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    page.scrollTo(0, 0)
  }

  function getUpperCase (y: any) {
    const date: any = []
    const first = format(new Date(y), 'LLLL', { locale: ru, }).split('')
    // eslint-disable-next-line array-callback-return
    first.map((i, index) => {
      if (index === 0) {
        date.push(i.toUpperCase())
      } else {
        date.push(i)
      }
    })
    return date.join('')
  }

  function changeDate (e: any) {
    if (format(new Date(e), 'yyyy-MM-dd') === date) {
      setDate(null)
    } else {
      // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      setDate(format(new Date(e), 'yyyy-MM-dd'))
    }
  }

  return (
    <div className="InnerPage Anounces">
      <HistoryLinks links={ [
        {
          value: 'Главная',
          path: '/',
        },
      ] } loc="Объявления" />
      <div className="studentInner">
        <div className="anouncesAll">
          <div className="mainContent">
            <h2 className="profileTitle">Все объявления</h2>
            <ul className="anoncesCards">
              { cards }
            </ul>
          </div>
          <div className="filtersWrap">
            <TagsFilter items={ [
              'Объявления',
              'Мероприятия',
            ] } onClick={ handlSelected } />
            <h3 className="calendarTitle">Архив объявлений</h3>
            <Calendar
              locale={ 'Ru' }
              className={ 'react-calendarC' }
              formatMonthYear={ (locale: any, date: any) => getUpperCase(date) }
              formatShortWeekday={ (locale: any, date: any) => format(date, 'EEEEEE', { locale: ru, }).toUpperCase() }
              value={ date ? new Date(date) : null }
              onChange={ (e: any) => changeDate(e) }
              maxDate={ new Date(Date.now()) }
            />
          </div>
        </div>
        { data?.newsFeed?.metrics.count > 20 && <PaginationSimple count={ Math.round((data?.newsFeed?.metrics.count - 20) / 20) } onChange={ handlePaginate } page={ offs ? Math.round(offs / 20) : 1 } /> }
      </div>
    </div>
  )
}

export default Anounces
