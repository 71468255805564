import { EcTableName, EC_MODER_GROUPE, } from '@/legacy/components/EffectiveContract/constants'
import TableCell from '@/legacy/components/EffectiveContract/TableContainer/components/TableBody/components/TableCell/TableCell'
import { AchievementMixedParticipant, } from '@/legacy/components/EffectiveContract/types/achievementParticipant'
import findParticipants from '@/legacy/components/EffectiveContract/lib/findParticipants'
import useGetHandlerForUpdateEcData from '@/legacy/gql/hooks/effectiveContractHooks/useGetHandlerForUpdateEcData'
import { FileData, TableCellType, TableRowType, UUID, } from '@/legacy/types'
import { useEffect, useState, } from 'react'
import styles from './TableRow.module.less'
import { UserRoles, } from '@/legacy/constants'
import useGetRole from '@/legacy/redux/hooks/common/useGetRole'
import { EC_TEACHER_GROUPE, } from '@/legacy/components/EffectiveContract/constants/effectiveContractTeachers-constants'
import useGetUserInfo from '@/legacy/hooks/useGetUserInfo'


export type OnChangeRowValue = (cellKey: string, newValue: string | boolean | FileData[] | AchievementMixedParticipant[] | null) => void


const useCreateControlledRow = (rowData: TableRowType, dataType:string, userGuid:string) => {
  const [
    controlledRow,
    setControlledRow,
  ] = useState<TableRowType>(rowData)

  const { onUpdateHandler, } = useGetHandlerForUpdateEcData()


  const onChangeRowValue: OnChangeRowValue = (cellKey, newValue) => {
    controlledRow[cellKey] = newValue
    setControlledRow(JSON.parse(JSON.stringify(controlledRow)))
  }


  const onUpdateRowHandler = () => {
    let userIds = controlledRow.user_ids
    const newUserIds = findParticipants(controlledRow)
    controlledRow.dataType = dataType
    controlledRow.creator_id = userGuid
    if (newUserIds.length > 0) {
      userIds = newUserIds
    }
    onUpdateHandler({
      rec_id: controlledRow.rec_id,
      new_data: controlledRow,
      user_ids: userIds,
    })
  }


  return {
    controlledRow,
    setControlledRow,
    onChangeRowValue,
    onUpdateRowHandler,
  }
}


const defineRowEditAvailable = (row: TableRowType, currentUserGuid: UUID): boolean => {
  if (!/approved|rejected/gi.test(row.status)) {
    if (row.creator_id === currentUserGuid || currentUserGuid === '439ec26d-e951-11eb-bc20-005056aa8995') {
      return true
    }

    if (row.creator_id === null) {
      return true
    }
  }

  return false
}

const defineCanUserEditAchievement = (userRole: UserRoles, dataType: EcTableName): boolean => {
  // TODO: Добавить условие, что среди обычных пользователей, table_1_3 могут редактировать только "Заведующий кафедрой"

  if (EC_TEACHER_GROUPE[userRole]?.canEditAchievement.some(allowedDataType => allowedDataType === dataType)) {
    return true
  }

  if (EC_MODER_GROUPE[userRole]?.canEditAchievement.some(allowedDataType => allowedDataType === dataType)) {
    return true
  }

  return false
}


interface TableRowProps {
  headerFields: TableCellType[] | undefined
  row: TableRowType
  rowNumber: number
  dataType: EcTableName
  isAppealMode?: boolean
}


const TableRow = ({
  headerFields,
  row,
  rowNumber,
  dataType,
  isAppealMode,
}: TableRowProps): JSX.Element => {
  const { userGuid, } = useGetUserInfo()
  const {
    controlledRow,
    onChangeRowValue,
    setControlledRow,
    onUpdateRowHandler,
  } = useCreateControlledRow(row, dataType, userGuid)

  useEffect(() => {
    setControlledRow(row)
  }, [
    row,
    setControlledRow,
  ])

  const userRole = useGetRole()
  const isUserCanEditRow = defineCanUserEditAchievement(userRole, dataType)
  const isRowEditAvailable = defineRowEditAvailable(row, userGuid) && isUserCanEditRow


  return (
    <tr className={ styles.row } >
      { headerFields?.map((cell: TableCellType, cellIndex: number) => (
        <TableCell
          key={ cellIndex }
          row={ controlledRow }
          cell={ cell }
          rowNumber={ rowNumber }
          dataType={ dataType }
          isRowEditAvailable={ isRowEditAvailable }
          onChangeRowValue={ onChangeRowValue }
          onUpdateRowHandler={ onUpdateRowHandler }
          isAppealMode={ isAppealMode }
        />
      )) }
    </tr>
  )
}


export default TableRow
