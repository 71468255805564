/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, } from 'react'
import { useSelector, } from 'react-redux'
import { useGetFacultatives, } from '../../../../../gql/hooks/useMyEducation'
import Preloader from '../../../../common/PreLoader/Preloader'
import PaperContainerWrapper from '../../../../common/PaperContainer/PaperContainerWrapper'
import TrackStatistics from '../DetailPageComponents/TrackStatistics/TrackStatistics'
import StudentsDistribution from '../DetailPageComponents/StudentsDistribution/StudentsDistribution'
import FacultativeGrades from '../DetailPageComponents/FacultativeGrades/FacultativeGrades'
import ElectiveHeader from '../DetailPageComponents/HeaderBlock/ElectiveHeader'

const ElectiveDetailPage = ({
  id,
  role,
  type,
}: any) => {
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const refresh = useSelector(state => state.myEd.updateFacultatives)

  const { data, error, loading, refetch, } = useGetFacultatives({
    filter: {
      facultative_id: Number(id),
      isModerator: true,
      type: 'elective',
    },
    pagination: {
      'offset': 0,
      'limit': 1000,
    },
  })


  useEffect(() => {
    refetch()
  }, [
    refresh,
    refetch,
  ])


  const electiveInfo = useMemo(() => {
    if (error) {
      return <div>error</div>
    } else if (loading) {
      return <Preloader />
    } else if (data) {
      return <>
        <PaperContainerWrapper
          styles={ {
            display: 'flex',
            flexDirection: 'column',
          } }
        >
          <ElectiveHeader role={ role } type={ type }
            /* attachedFiles={attachedFiles} */ res={ data?.esFacultative?.items[0] } />
        </PaperContainerWrapper>

        { role === 'osrv-moder' ? (
          <>
            <TrackStatistics type={ type } payloadType="elective" />

            <StudentsDistribution type={ type } facInfo={ data } />
          </>
        ) : false }
      </>
    }
  }, [
    data,
    error,
    loading,
    role,
    type,
  ])


  return electiveInfo
}

export default ElectiveDetailPage
