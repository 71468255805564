/* eslint-disable no-console */
import { useEffect, useState, useMemo, } from 'react'
import './InitiatePopup.less'
import clsx from 'clsx'
import { useProjectActivityCreate, } from '../../../../gql/hooks/useProjects'
import { handleRole, } from '../../../../Utils/functions'
import './InitiatePopup.less'
import useGetRole from '@/legacy/redux/hooks/common/useGetRole'
import { useAppContext, } from '@/App/providers/AppProvider'
// import { useAppContext } from '../../../App/AppProvider';


function InitiatePopup ({
  isOpen,
  handlePopup,
}: any) {
  const [
    projectName,
    setProjectName,
  ] = useState('')
  const [
    problem,
    setProblem,
  ] = useState('')
  const [
    activity,
    setActivity,
  ] = useState('')
  const [
    disabled,
    setDisabled,
  ] = useState(false)


  const role = useGetRole()

  // const user = useSelector((state) => state.auth.me)

  const [
    paInitiate,
  ] = useProjectActivityCreate()

  const params = useMemo(() => {
    return {
      event: 'newInitiate',
      payload: {
        userType: handleRole(role),
        name: projectName,
        description: problem,
        activity,
      },
    }
  }, [
    activity,
    problem,
    projectName,
    role,
  ])

  useEffect(() => {
    setDisabled(Object.values(params?.payload).some((i: any) => i?.replaceAll(' ', '').length < 5))
  }, [
    params,
  ])

  const clearForm = () => {
    setProjectName('')
    setProblem('')
    setActivity('')
    handlePopup()
  }

  const { openToast, } = useAppContext()

  function onSubmit (e: any) {
    e.preventDefault()

    paInitiate({ variables: { params, }, })
      .then(res => {
        openToast?.({ message: 'Заявка отправленна', })
        return res
      })
      .then(() => {
        setProjectName('')
        setProblem('')
        setActivity('')
        handlePopup()
      })
      .catch(e => {
        console.log('onCreate error', e)
      })
  }


  return (
    <div className={ clsx('taskPopup', isOpen && 'taskPopAct') }>
      <form className="taskContainer" onClick={ e => e.stopPropagation() } style={ { position: 'relative', } }>
        <button className="close" onClick={ clearForm }>x</button>

        <h2>Инициировать проект</h2>

        <input type="text" placeholder='Название проекта' onChange={ e => setProjectName(e.target.value) } minLength={ 10 } value={ projectName } required />

        <textarea minLength={ 10 } placeholder='Описание проблематики или исследования или задачи, для реализации которой поможет проект' onChange={ e => setProblem(e.target.value) } value={ problem } required />

        <textarea minLength={ 10 } placeholder='Описание действий, разработки, которую предлагает провести преподаватель для решения поставленной задачи' onChange={ e => setActivity(e.target.value) } value={ activity } required />

        <button type='submit' onClick={ e => onSubmit(e) } className={ clsx(!disabled && 'statusButton buttonActive', disabled && 'disabled') } disabled={ disabled }>Отправить заявку</button>
      </form>
    </div>
  )
}

export default InitiatePopup
