/* eslint-disable no-console */
import '../Mfc.less'
import HistoryLinks from '../../common/HistoryLinks/HistoryLinks'
import { useSelector, useDispatch, } from 'react-redux'
import StudentMfc from '../StudentMfc/StudentReferences/StudentMfc'
import TabButtons from '../../common/TabButtons/TabButtons'
import { mfcButtons, } from '../MfcUtils'
import useRedirect from '@/legacy/hooks/useRedirect'
import useGetRole from '@/legacy/redux/hooks/common/useGetRole'
import ButtonSimple from '@/legacy/components/common/ButtonSimple/ButtonSimple'
import { setStatus, } from '@/legacy/redux/MfcSlices/MfcSlice'


function MyReferences () {
  const redirect = useRedirect()

  const dispatch = useDispatch()
  const activeStatus = useSelector((state: any) => state.mfc.activeStatus)


  function chooseStatus (key: any) {
    dispatch(setStatus(key))
  }


  function isActive (key: any) {
    return activeStatus.name === key.name
  }


  const role = useGetRole()

  console.log('mfcRole', role)

  return (
    <div className="mfc">
      <HistoryLinks links={ [
        {
          value: 'Главная',
          path: '/',
        },
      ] } loc="Мои справки" />
      <h2 className="mfcTitle">Мои справки</h2>
      <div className="battonsWrap">
        <TabButtons items={ mfcButtons } isActive={ isActive } clickHandler={ chooseStatus } />
        <ButtonSimple onClickHandler={ () => redirect('/get-reference') } variant="fill" value={ 'Заказать новую справку' } />
        {/*  <button onClick={ ( } className="buttonActive statusButton">Заказать новую справку</button> */ }
      </div>
      <StudentMfc />
    </div>
  )
}

export default MyReferences
