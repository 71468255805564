/* eslint-disable no-console */
import './RecordCard.less'
import { IconArrowBarToDown, } from '@tabler/icons'
import { useSelector, useDispatch, } from 'react-redux'
import { setCurrentBook, } from '../../../redux/RecordBookSlices/RecordBookSlice'
import clsx from 'clsx'
import { EndPoints, } from '../../../lib/endpoints'


function RecordCard ({
  data,
}: any) {
  const dispatch = useDispatch()
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const currentBook = useSelector(state => state.rec.currentBook)
  const { recordbook_number, department, nameprof, namespec, course, elevel, } = data

  function onRecorBook (i: any) {
    dispatch(setCurrentBook(i))
  }

  function downLoadFile () {
    fetch(`${ EndPoints.recordbook }/${ recordbook_number.replace('/', '_') }`, {
      credentials: 'include',
    })
      .then(res => res.blob())
      .then(blob => {
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = 'зачетная книжка'
        link.click()
      })
      .catch(e => console.log('err', e))
    // window.open(`https://${URL}/api/files/recordbooks/${recordbook_number.replace("/","_")}`, '_blank', 'toolbar=0,location=0,menubar=0')
  }


  const isActive = recordbook_number === currentBook?.recordbook_number

  return (
    <div className={ clsx('recordCard', isActive && 'cardActive') } onClick={ () => onRecorBook(data) }>
      <h2 className={ clsx('cardTitle', isActive && 'titleActive') }>{ `Зачетная книжка «${ recordbook_number }»` }</h2>
      <p className="department">{ department }</p>
      <p className="subSpec">{ namespec }</p>
      <p className="spec">{ nameprof }</p>
      <p className="level">{ `${ course } курс ` }&middot;{ ` ${ elevel }` }</p>
      <div className="downloadWrap" onClick={ downLoadFile }>
        <h3 className="download">Скачать</h3>
        <div className="wrapIcon"><IconArrowBarToDown color={ 'white' } /></div>
      </div>
    </div>
  )
}

export default RecordCard
