/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useEffect, useState, } from 'react'
import { IconCirclePlus, } from '@tabler/icons'
import './Avatars.less'
import { useSelector, useDispatch, } from 'react-redux'
import { selectImg, } from '@/legacy/redux/ProfileSlices/CropSlice/CropSlice'
import ImgCropDialog from '../ImgCrapDialog/ImgCropDialog'
import { useAvatarsChanged, } from '@/legacy/gql/hooks/useAvatars'
import { EndPoints, } from '@/legacy/lib/endpoints'
import { updateAvatar, } from '@/legacy/redux/Subscriptions/SubscriptionsSlice'
import CurrentAvatar from '@/legacy/components/Profile/Avatars/CurrentAvatar'


const Avatars: FC = () => {
  const dispatch = useDispatch()
  const [
    /* newFile */, setNewFile,
  ] = useState()
  // const [upload] = useUploadFormDataMutation();
  // const photos = useSelector((state) => state.crop.allPhotos);
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const currentImg = useSelector(state => state.crop.selectedImg)

  const useAvatarsEvent = () => {
    const dispatch = useDispatch()
    const { error, loading, data, } = useAvatarsChanged()

    useEffect(() => {
      if (loading || error) {
        return
      }
      if (data?.uploadsChanged) {
        dispatch(updateAvatar())
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      loading,
      error,
      data,
    ])
  }

  useAvatarsEvent()

  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const refresh = useSelector(state => state.subscriptions.avatars)


  // function PhotoGalery () {
  //   const { refetch, data, loading, error, } = useAvatarsList()
  //
  //
  //   useEffect(() => {
  //     refetch()
  //   }, [
  //     refresh,
  //     refetch,
  //   ])
  //
  //   if (data) {
  //     return data.uploads.map((i: any) => {
  //       return <GetPhotos key={ i.upload_id } id={ i.upload_id } />
  //     })
  //   } if (loading) {
  //     return <Preloader />
  //   }
  //   if (error) {
  //     return <div>произошла ошибка, попробуйте позже</div>
  //   }
  // }

  function onFileChange (e: any) {
    setNewFile(e.target.files[0])
    const formData = new FormData()
    formData.append('avatars', e.target.files[0])
    fetch(EndPoints.upload, {
      method: 'POST',
      body: formData,
      credentials: 'include',
    }).then(res => {
      // @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
      dispatch(selectImg())
      console.log('Upload RES', res)
    }).catch(e => {
      console.log('ERROR', e)
    })
  }

  function GetPhotos ({
    id,
  }: any) {
    return (
      <li className="edit" key={ id } onClick={ () => chooseImg(id) }>
        <img
          className="allPhotos"
          src={ `${ EndPoints.download }/${ id }` }
          alt="avatar"
        />
      </li>
    )
  }


  function chooseImg (img: any) {
    dispatch(selectImg(img))
  }

  /* function inputClick(e) {
    console.log(e.target.value);
  } */

  function OpenWindow () {
    return <ImgCropDialog id={ currentImg } url={ `${ EndPoints.download }/${ currentImg }` } />
  }

  return (
    <div className="profileAvatars">
      <CurrentAvatar />
      <ul className="allAvatarsWrap">
        {/* <PhotoGalery />*/}
        {/* {photos.map((i) => <li className="edit" key={i.id} onClick={() => chooseImg(i)}><img className="allPhotos" src={i.src} alt="avatar"/></li>)} */ }
        <li className="upload">
          <div className="addPhoto"><IconCirclePlus color="#7671DD" /></div>
          <input type="file" onChange={ onFileChange } /* onClick={inputClick} */></input>
        </li>
      </ul>
      { currentImg ? <OpenWindow />
        /* cropInit={currentImg.crop} zoomInit={currentImg.zoom} aspectInit={currentImg.aspect} */ : null }
    </div>
  )
}

export default Avatars
