/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { useState, useMemo, useEffect, } from 'react'
import { IconCirclePlus, IconFile, IconCircleX, } from '@tabler/icons'
import clsx from 'clsx'
import { useCreateProject, } from '../../../../../../gql/hooks/useProjects'
import { downloadFile, } from '../../../../../../hooks/useDownloadFile'
import { useAppContext, } from '@/App/providers/AppProvider'
import { useDeleteUpload, } from '../../../../../../gql/hooks/useAvatars'
import { EndPoints, } from '../../../../../../lib/endpoints'
import useGetRole from '@/legacy/redux/hooks/common/useGetRole'

function ResultsProject ({
  project,
  isSupervisorMode,
}: any) {
  const [
    report,
    setReport,
  ] = useState<string | null>(null)
  const [
    files,
    setFiles,
  ] = useState()

  // const [isSucces, setIsSucces] = useState(false)

  const role = useGetRole()


  const { addNotification, openToast, } = useAppContext()


  const newRes: any = []
  useEffect(() => {
    project.data.results.map((i: any) => newRes.push(i))
    newRes.push({
      title: report,
      file: null,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    report,
  ])

  const [
    paControl,
  ] = useCreateProject()

  function handleAddResult () {
    const params = {
      event: 'change',
      payload: {
        req_id: project.req_id,
        args: {
          status: 'ready',
          data: {
            ...project.data,
            results: newRes,
          },
        },
        action: 'update',
      },

    }
    paControl({ variables: { params, }, })
      .then(res => {
        console.log('Результат добавлен', res)
      })
      .catch(e => {
        console.log('onCreate error', e)
      })
  }


  const [
    deleteUpload,
  ] = useDeleteUpload()

  function deleteResult (res: any) {
    const newResults = project.data.results.filter((i: any) => i.title !== res.title)
    if (res.file) {
      deleteUpload({
        variables: {
          upload_ids: [
            res.file[0].upload_id,
          ],
        },
      })
        .then(() => console.log('Файл удален из базы'))
        .catch(e => console.log(e))
    }

    const params = {
      event: 'change',
      payload: {
        req_id: project.req_id,
        args: {
          status: 'ready',
          data: {
            ...project.data,
            results: newResults,
          },
        },
        action: 'update',
      },

    }
    paControl({ variables: { params, }, })
      .then(res => {
        console.log('Результат добавлен', res)
      })
      .catch(e => {
        console.log('onCreate error', e)
      })
  }

  function sendFile (file: any, title: any) {
    const formData = new FormData()
    formData.append(`project/${ project.req_id }/result`, file)

    fetch(EndPoints.upload, {
      method: 'POST',
      body: formData,
      credentials: 'include',
    }).then(res => {
      return res.json()
    })
      .then(file => {
        const newArr: any = []
        if (file.code === 500) {
          project.data.results.forEach((i: any) => {
            newArr.push(i)
          })
          openToast?.({ message: 'Файл уже прикреплен', })
        } else {
          project.data.results.forEach((i: any) => {
            if (i.title === title) {
              newArr.push({
                title: i.title,
                file,
              })
            } else {
              newArr.push(i)
            }
          })
        }

        return newArr
      })
      .then(last => {
        const params = {
          event: 'change',
          payload: {
            req_id: project.req_id,
            action: 'update',
            args: {
              status: 'ready',
              data: {
                ...project.data,
                results: last,
              },
            },
          },
        }

        paControl({ variables: { params, }, })
          .then(res => {
            console.log('Результат добавлен', res)
          })
          .catch(e => {
            console.log('onCreate error', e)
          })
      })
      .catch(e => console.log(e))
  }

  function handleArchivate () {
    const params = {
      event: 'change',
      payload: {
        req_id: project.req_id,
        args: { status: 'archive', },
        action: 'update',
      },
    }
    paControl({ variables: { params, }, })
      .then(res => {
        openToast?.({ message: 'Пpоект отправлен в архив!', })
      })
      .then(() => {
        addNotification?.('Проектная деятельность', [
          project.data.supervisor.id,
        ], { message: 'Отчёт по проекту принят.', }, {
          path: '/project',
          from: 'Проектная деятельность',
        })
      })
      .catch(e => {
        console.log('onCreate error', e)
      })
  }


  const docTypes = useMemo(() => {
    return (
      <ul className="resultsList">
        { project.data.results.map((i: any) => <li key={ i.title }><div className={ clsx(!i.file && 'resultFile', i.file && 'resultFileFilled') }>
          <div>
            <IconFile color={ i.file ? '#7671DD' : '#C5C5C5' } />
            <p>{ i.title }</p>
          </div>
          { !i.file ? <>
            <input style={ { display: 'none', } } type="file" id={ i.title } onChange={ e => sendFile(e?.target?.files?.[0], i.title) } />
            { isSupervisorMode && (
              <>
                <label htmlFor={ i.title } className="activity">Прикрепить файл</label>
                <IconCircleX className={ 'delete-icon' } color="#C5C5C5" onClick={ () => deleteResult(i) } />
              </>
            ) }
          </> : <div>
            <p className="activity" onClick={ () => downloadFile(i.file[0]) }>{ i.file[0] ? i.file[0].name : null }</p>
            { isSupervisorMode && (
              <IconCircleX className={ 'delete-icon' } color="#C5C5C5" onClick={ () => deleteResult(i) } />
            ) }
          </div> }
        </div></li>)
        }
      </ul>
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    project.data.results,
  ])


  return (
    <div className="projResults">
      <h2>Итоговые результаты работы над проектом</h2>

      <br />

      { isSupervisorMode && (
        <div className="addResult">
          <input type="text" placeholder='Введите текст' onChange={ e => setReport(e?.target?.value) } />

          <button
            className={ clsx('statusButton buttonActive', !report && 'disabled') }
            disabled={ !report }
            onClick={ handleAddResult/* () => setFiles([...files, {type: report}]) */ }
          >
            Добавить результат
            <IconCirclePlus color="white" />
          </button>
        </div>
      ) }

      { docTypes }

      { (project.status === 'completed' && role === 'pa_moder') && (
        <button
          className={ clsx('statusButton buttonActive') }
          style={ { marginTop: '30px', } }
          onClick={ handleArchivate }>
          Принять результаты проекта
        </button>
      ) }

      {/* <SuccessPopup isOpen={isSucces} text="Проект успешно отправлен в архив!" handlePopup={() => setIsSucces(false)}/> */ }
    </div>
  )
}

export default ResultsProject
