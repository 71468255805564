import './ModeratePortfolio.less'
import HistoryLinks from '../../common/HistoryLinks/HistoryLinks'
import { IconChartBar, } from '@tabler/icons'
import RatingFilters from '../PortfolioRating/RatingFilters/RatingFilters'
import RatingTable from '../PortfolioRating/RatingTable/RatingTable'
import { useSelector, } from 'react-redux'
import useRedirect from '@/legacy/hooks/useRedirect'
import ExportCourseworks from '@/features/portfolio/DownloadExportedCourseworks'
import ExportPractics from '@/features/portfolio/DownloadExportedPractics'

function ModeratePortfolio () {
  const redirect = useRedirect()

  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const sort = useSelector(state => state.port.tableSort.users)

  const columns = [
    {
      title: 'ФИО ',
      width: 500,
      dataIndex: 'name',
      key: 'name',
    },
    {
      width: 500,
      title: (
        <div
          className="thWrap"
          data-tip={ 'Количество неподтвержденных достижений' }
        >
          <h3 className="tableTitle">Количество неподтвержденных достижений</h3>
        </div>
      ),
      dataIndex: 'notavailable',
      key: 'notavailable',
      sorter: true,
      showSorterTooltip: false,
      defaultSortOrder:
        sort.field === 'notavailable' &&
        (sort.order === 'desc' ? 'descend' : 'ascend'),
    },
    {
      width: 500,
      title: (
        <div
          className="thWrap"
          data-tip={ 'Количество подтвержденных достижений' }
        >
          <h3 className="tableTitle">Количество подтвержденных достижений</h3>
        </div>
      ),
      dataIndex: 'available',
      key: 'available',
      sorter: true,
      showSorterTooltip: false,
      defaultSortOrder:
        sort.field === 'available' &&
        (sort.order === 'desc' ? 'descend' : 'ascend'),
    },
    {
      width: 500,
      title: (
        <div className="thWrap" data-tip={ 'Дата последней активности' }>
          <h3 className="tableTitle">Дата последней активности</h3>
        </div>
      ),
      dataIndex: 'last_activity',
      key: 'last_activity',
      sorter: true,
      showSorterTooltip: false,
      defaultSortOrder:
        sort.field === 'last_activity' &&
        (sort.order === 'desc' ? 'descend' : 'ascend'),
    },
  ]

  return (
    <div className="InnerPage allProjects moderatePortfolio">
      <HistoryLinks
        links={ [
          {
            value: 'Главная',
            path: '/',
          },
        ] }
        loc="Модерация портфолио"
      />

      <div className="studentInner scroll">
        <div
          style={ {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'baseline',
            margin: '20px 0',
          } }
        >
          <h2 className="profileTitle">Модерация портфолио</h2>

          <div
            className="showRate"
            onClick={ () => redirect('/portfolio-rating') }
          >
            Смотреть рейтинг обучающихся
            <IconChartBar color="#7671DD" size={ 25 } />
          </div>
          <ExportCourseworks/>
          <ExportPractics/>
        </div>

        <RatingFilters />

        <RatingTable moderContent={ columns } />
      </div>
    </div>
  )
}

export default ModeratePortfolio
