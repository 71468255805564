import React, { useMemo, useEffect, } from 'react'
import { useGetApplicationsMfc, } from '../../../../gql/hooks/mfc/useApplications'
import ApplicationTemplate from '../../ReferenceTemplate/ApplicationTemplate'
import { useSelector, useDispatch, } from 'react-redux'
import Preloader from '../../../common/PreLoader/Preloader'
import NoContracts from '../../../Finances/PaymentPage/NoContracts/NoContracts'
import { useReferenceChanged, } from '../../../../gql/hooks/mfc/useReferences'
import { updateReference, } from '../../../../redux/Subscriptions/SubscriptionsSlice'


const StudentApplications = () => {
  const activeStatus = useSelector((state: any) => state.mfc.activeStatus)
  const refresh = useSelector((state: any) => state.subscriptions.references)

  const useReferenceEvent = () => {
    const dispatch = useDispatch()
    const { error, loading, data, } = useReferenceChanged()
    useEffect(() => {
      if (loading || error) {
        return
      }
      if (!data?.referenceChanged) {
        return
      }
      dispatch(updateReference())
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      loading,
      error,
      data,
    ])
  }

  useReferenceEvent()

  const { data, error, loading, refetch, } = useGetApplicationsMfc({
    type: 'StudentStatements',
    status: activeStatus.serverStat,
  })

  useEffect(() => {
    refetch()
  }, [
    refetch,
    refresh,
  ])

  const applications = useMemo(() => {
    if (loading) {
      return <Preloader />
    }
    if (error) {
      return <div>Произошла ошибка, попробуйте поздней</div>
    } else if (data) {
      if (data.mfcStatements.items.length < 1) {
        return <NoContracts title="У вас еще нет активных заявок" />
      }
      return data.mfcStatements.items.map((i: any) => <ApplicationTemplate key={ i.doc_id } content={ i } />)
    }
  }, [
    data,
    error,
    loading,
  ])

  return <ul>
    { applications }
  </ul>
}


export default StudentApplications
