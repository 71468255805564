import {EndPoints,} from '@/legacy/lib/endpoints'
import React, {FC, useMemo,} from 'react'
import {useSelector,} from 'react-redux'

const CurrentAvatar: FC = () => {
  const refresh = useSelector(
    // @ts-expect-error TS(18046): 'state' is of type 'unknown'.
    state => state.subscriptions.updateDefaultAvatar
  )

  return useMemo(() => {
    return <img className="choosenAvatar" src={EndPoints.avatar} alt="avatar"/>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    refresh,
  ])
}

export default CurrentAvatar
