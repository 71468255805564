import { useMemo, } from 'react'
import './SideBar.less'
import { IconMenu2, } from '@tabler/icons'
import logo from '../../assets/images/header-logo.svg'
import clsx from 'clsx'
import MainItem from './MainItem/MainItem'
import SubItem from './SubItem/SubItem'
import { URL as currentURL, } from '@/legacy/Utils/constants'


function SideBar ({
  navItems,
  isSideBarOpen,
  handleWrapColor,
  itemsFilter,
  onMainItemClick,
  onSubItemClick,
  isSubOpen,
  isSubItemClicked,
  sideBarShrink,
}: any) {
  function renderSideBar (first: any, previos: any) {
    return first.map((i: any, index: any) => {
      if (i.items && (!i.environment || i.environment === currentURL)) {
        const roleItems = itemsFilter(i.items)
        return (
          <div key={ index } className={ clsx(i.depth && !isSubOpen(previos) && 'mainHidden') } >
            <MainItem depth={ i.depth } item={ i } handleWrapColor={ handleWrapColor } handleClick={ () => onMainItemClick(i.value) } isSideBarOpen={ isSideBarOpen } isSubItemClicked={ isSubItemClicked } isOpen={ isSubOpen } />
            <ul className="subMenuOpen">
              { renderSideBar(roleItems, i.value) }
            </ul>
          </div>
        )
      }
      return ((!i.environment || i.environment === currentURL) && <SubItem key={ i.value } item={ i } i={ previos } handleClick={ () => onSubItemClick(i.path, i.value) } isSideBarOpen={ isSideBarOpen } isOpen={ isSubOpen } isSubItemClicked={ isSubItemClicked } />)
    })
  }

  const navMenu = useMemo(() => (
    <aside id="sidebar" className={ clsx('sideBar', !isSideBarOpen && 'sideBarClosed') }>
      <div className="sideBarHeaderWrap">
        <div className="sideBarHeader">
          <button type="button" onClick={ sideBarShrink }>
            <IconMenu2 size={ 24 } color="#3934A3" />
          </button>
          <img src={ logo } alt="logo" className={ clsx('logo', !isSideBarOpen && 'logoHidden') } />
        </div>
      </div>
      <ul>
        { renderSideBar(navItems, 'Главная') }
      </ul>
    </aside>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [
    navItems,
  ])

  return (navMenu)
}

export default SideBar


/* import { React, useMemo } from 'react';
import './SideBar.less';
import { IconMenu2 } from '@tabler/icons';
import logo from '../../assets/images/header-logo.svg';
import clsx from 'clsx';
import MainItem from './MainItem/MainItem';
import SubItem from './SubItem/SubItem';


function SideBar({ navItems, isSideBarOpen, onMainItemClick, onSubItemClick, isSubOpen, isSubItemClicked, sideBarShrink }) {


  const navMenu = useMemo(() =>
    <aside id="sidebar" className={clsx("sideBar", !isSideBarOpen && "sideBarClosed")}>
      <div className="sideBarHeaderWrap">
        <div className="sideBarHeader">
          <button type="button" onClick={sideBarShrink}>
            <IconMenu2 size={24} color="#3934A3" />
          </button>
          <img src={logo} alt="logo" className={clsx("logo", !isSideBarOpen && "logoHidden")} />
        </div>
      </div>
      <ul>
        {navItems.map((i, index) => {
          if (i.items) {
            return (
              <div key={index}>
                <MainItem item={i} handleClick={() => onMainItemClick(i.value)} isSideBarOpen={isSideBarOpen} isSubItemClicked={isSubItemClicked} isOpen={isSubOpen} />
                <ul className="subMenuOpen">
                  {i.items.map((item, index) => {
                    if (item.items) {
                      return <div key={index} className={clsx(!isSubOpen(i.value) && "mainHidden")}>
                        <MainItem depth={item.depth} item={item} handleClick={() => onMainItemClick(item.value)} isSideBarOpen={isSideBarOpen} isSubItemClicked={isSubItemClicked} isOpen={isSubOpen} />
                        <ul className="subMenuOpen">
                          {item.items.map((tresh) => {
                            return <SubItem key={tresh.value} i={item} item={tresh} handleClick={() => onSubItemClick(tresh.path, tresh.value)} isSideBarOpen={isSideBarOpen} isOpen={isSubOpen} isSubItemClicked={isSubItemClicked} />
                          })}
                        </ul>
                      </div>
                    } else {
                      return (<SubItem key={item.value} i={i} item={item} handleClick={() => onSubItemClick(item.path, item.value)} isSideBarOpen={isSideBarOpen} isOpen={isSubOpen} isSubItemClicked={isSubItemClicked} />)
                    }
                  })}
                </ul>
              </div>
            )
          } else {
            return (<MainItem key={i.value} item={i} handleClick={() => onSubItemClick(i.path, i.value)} isSideBarOpen={isSideBarOpen} isOpen={isSubOpen} isSubItemClicked={isSubItemClicked} />)
          }
        })
        }
      </ul>
    </aside>
    , [navItems])

  return (navMenu);
}

export default SideBar; */
