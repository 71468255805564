import { EcTableName, } from '@/legacy/components/EffectiveContract/constants/effectiveContractTableName-constants'
import { UserRoles, } from '@/legacy/constants'


type EffectiveContractTeacherGroupe = {
  [key in UserRoles]?: {
    canSee: EcTableName[],
    canEditAchievement: EcTableName[],
    canAddNewRecords: EcTableName[],
  }
}


export const EC_TEACHER_GROUPE: EffectiveContractTeacherGroupe = {
  [UserRoles.WORKER]: {
    canSee: [
      EcTableName.TABLE_1_2,
      EcTableName.TABLE_1_3,
      EcTableName.TABLE_1_4,
      EcTableName.TABLE_2_1,
      EcTableName.TABLE_2_2,
      EcTableName.TABLE_2_3,
      EcTableName.TABLE_2_4,
      EcTableName.TABLE_2_5,
      EcTableName.TABLE_2_6,
      EcTableName.TABLE_2_7,
      EcTableName.TABLE_2_10,
      EcTableName.TABLE_3_1,
      EcTableName.TABLE_3_2,
      EcTableName.TABLE_3_3,
      EcTableName.TABLE_3_4,
      EcTableName.TABLE_3_5,
    ],
    canEditAchievement: [
      EcTableName.TABLE_1_2,
      EcTableName.TABLE_1_3,
      EcTableName.TABLE_1_4,
      EcTableName.TABLE_2_1,
      EcTableName.TABLE_2_2,
      EcTableName.TABLE_2_3,
      EcTableName.TABLE_2_4,
      EcTableName.TABLE_2_5,
      EcTableName.TABLE_2_6,
      EcTableName.TABLE_2_7,
      EcTableName.TABLE_2_10,
      EcTableName.TABLE_3_1,
      EcTableName.TABLE_3_2,
      EcTableName.TABLE_3_3,
      EcTableName.TABLE_3_4,
      EcTableName.TABLE_3_5,
    ],
    canAddNewRecords: [
      EcTableName.TABLE_1_2,
      EcTableName.TABLE_1_3,
      EcTableName.TABLE_1_4,
      EcTableName.TABLE_2_1,
      EcTableName.TABLE_2_2,
      EcTableName.TABLE_2_3,
      EcTableName.TABLE_2_4,
      EcTableName.TABLE_2_5,
      EcTableName.TABLE_2_6,
      EcTableName.TABLE_2_7,
      EcTableName.TABLE_2_10,
      EcTableName.TABLE_3_1,
      EcTableName.TABLE_3_2,
      EcTableName.TABLE_3_3,
      EcTableName.TABLE_3_4,
      EcTableName.TABLE_3_5,
    ],
  },
  [UserRoles.TEACHER]: {
    canSee: [
      EcTableName.TABLE_1_2,
      EcTableName.TABLE_1_3,
      EcTableName.TABLE_1_4,
      EcTableName.TABLE_2_1,
      EcTableName.TABLE_2_2,
      EcTableName.TABLE_2_3,
      EcTableName.TABLE_2_4,
      EcTableName.TABLE_2_5,
      EcTableName.TABLE_2_6,
      EcTableName.TABLE_2_7,
      EcTableName.TABLE_2_10,
      EcTableName.TABLE_3_1,
      EcTableName.TABLE_3_2,
      EcTableName.TABLE_3_3,
      EcTableName.TABLE_3_4,
      EcTableName.TABLE_3_5,
    ],
    canEditAchievement: [
      EcTableName.TABLE_1_2,
      EcTableName.TABLE_1_3,
      EcTableName.TABLE_1_4,
      EcTableName.TABLE_2_1,
      EcTableName.TABLE_2_2,
      EcTableName.TABLE_2_3,
      EcTableName.TABLE_2_4,
      EcTableName.TABLE_2_5,
      EcTableName.TABLE_2_6,
      EcTableName.TABLE_2_7,
      EcTableName.TABLE_2_10,
      EcTableName.TABLE_3_1,
      EcTableName.TABLE_3_2,
      EcTableName.TABLE_3_3,
      EcTableName.TABLE_3_4,
      EcTableName.TABLE_3_5,
    ],
    canAddNewRecords: [
      EcTableName.TABLE_1_2,
      EcTableName.TABLE_1_3,
      EcTableName.TABLE_1_4,
      EcTableName.TABLE_2_1,
      EcTableName.TABLE_2_2,
      EcTableName.TABLE_2_3,
      EcTableName.TABLE_2_4,
      EcTableName.TABLE_2_5,
      EcTableName.TABLE_2_6,
      EcTableName.TABLE_2_7,
      EcTableName.TABLE_2_10,
      EcTableName.TABLE_3_1,
      EcTableName.TABLE_3_2,
      EcTableName.TABLE_3_3,
      EcTableName.TABLE_3_4,
      EcTableName.TABLE_3_5,
    ],
  },
  [UserRoles.TEACHER_GPH]: {
    canSee: [
      EcTableName.TABLE_1_2,
      EcTableName.TABLE_1_3,
      EcTableName.TABLE_1_4,
      EcTableName.TABLE_2_1,
      EcTableName.TABLE_2_2,
      EcTableName.TABLE_2_3,
      EcTableName.TABLE_2_4,
      EcTableName.TABLE_2_5,
      EcTableName.TABLE_2_6,
      EcTableName.TABLE_2_7,
      EcTableName.TABLE_2_10,
      EcTableName.TABLE_3_1,
      EcTableName.TABLE_3_2,
      EcTableName.TABLE_3_3,
      EcTableName.TABLE_3_4,
      EcTableName.TABLE_3_5,
    ],
    canEditAchievement: [
      EcTableName.TABLE_1_2,
      EcTableName.TABLE_1_3,
      EcTableName.TABLE_1_4,
      EcTableName.TABLE_2_1,
      EcTableName.TABLE_2_2,
      EcTableName.TABLE_2_3,
      EcTableName.TABLE_2_4,
      EcTableName.TABLE_2_5,
      EcTableName.TABLE_2_6,
      EcTableName.TABLE_2_7,
      EcTableName.TABLE_2_10,
      EcTableName.TABLE_3_1,
      EcTableName.TABLE_3_2,
      EcTableName.TABLE_3_3,
      EcTableName.TABLE_3_4,
      EcTableName.TABLE_3_5,
    ],
    canAddNewRecords: [
      EcTableName.TABLE_1_2,
      EcTableName.TABLE_1_3,
      EcTableName.TABLE_1_4,
      EcTableName.TABLE_2_1,
      EcTableName.TABLE_2_2,
      EcTableName.TABLE_2_3,
      EcTableName.TABLE_2_4,
      EcTableName.TABLE_2_5,
      EcTableName.TABLE_2_6,
      EcTableName.TABLE_2_7,
      EcTableName.TABLE_2_10,
      EcTableName.TABLE_3_1,
      EcTableName.TABLE_3_2,
      EcTableName.TABLE_3_3,
      EcTableName.TABLE_3_4,
      EcTableName.TABLE_3_5,
    ],
  },
}
