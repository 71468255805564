import { legacy_effectiveContractModerGroupe, } from '@/legacy/components/EffectiveContract/constants/effectiveContractModersAndAdmins-constants'
import { useEffect, useState, } from 'react'
import { useSelector, } from 'react-redux'
import { useLocation, } from 'react-router-dom'
import { useCreateEcData, useUpdateEcData, } from '../../../../../gql/hooks/effectiveContractHooks/useEffectiveContract'
import { useAppContext, } from '@/App/providers/AppProvider'
import ButtonSimple from '../../../../common/ButtonSimple/ButtonSimple'
import InputSelectDeprecated from '../../../../common/InputSelectDeprecated/InputSelectDeprecated'
import InputTextDeprecated from '../../../../common/InputTextDeprecated/InputTextDeprecated'
import { defineUserGroupe, } from '../../../defineUserGroupe'
import { table_cat_2_10, } from '../../../popUpInputsCatalogs'

const Inputs_2_10 = ({
  onClickHandler,
  hint,
  defaultValues,
}: any) => {
  const { addNotification, } = useAppContext()


  const { search, } = useLocation()
  const moderateId = new URLSearchParams(search).get('id')
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const currentRole = useSelector(state => state.auth.choosenRole)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const currentUserType = useSelector(state => state.auth.userType)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const currentUserId = useSelector(state => state.auth.me.data.guid)

  const [
    user_ids,
  ] = useState(
    (
      defineUserGroupe(currentRole, legacy_effectiveContractModerGroupe)
      || defineUserGroupe(currentUserType, legacy_effectiveContractModerGroupe)
    )
      ? moderateId
      : currentUserId)


  const [
    onAddNewEcData,
  ] = useCreateEcData()
  const onAddNewEcDataHandler = (params: any) => {
    onAddNewEcData(params)
      .then(() => {
        addNotification?.(
          'Эффективный контракт',
          (
            (
              defineUserGroupe(currentRole, legacy_effectiveContractModerGroupe)
              || defineUserGroupe(currentUserType, legacy_effectiveContractModerGroupe)
            )
              ? moderateId
              : currentUserId
          ),
          { message: 'Ваше достижение добавлено!', },
          {
            path: '/effective-contract',
            from: 'Эффективный контракт',
          }
        )
      })
  }

  const [
    onUpdateRow,
  ] = useUpdateEcData()
  const onUpdateRowHandler = (id: any, newData: any) => onUpdateRow({
    variables: {
      rec_id: id,
      new_data: newData,
    },
  })


  const [
    magazineName,
    setMagazineName,
  ] = useState('')
  const [
    role,
    setRole,
  ] = useState('')
  const [
    link,
    setLink,
  ] = useState('')


  useEffect(() => {
    if (hint === 'edit') {
      setMagazineName(defaultValues.magazineName)
      setRole(defaultValues.role)
      setLink(defaultValues.link)
    }
  }, [
    hint,
    defaultValues,
  ])


  const fieldsToDefault = () => {
    setMagazineName('')
    setRole('')
    setLink('')
  }


  return (
    <>
      <InputTextDeprecated
        defaultHint="Название журнала"
        value={ magazineName }
        onChangeValue={ setMagazineName }
      />

      <InputSelectDeprecated
        defaultHint="Роль"
        items={ table_cat_2_10.role }
        defaultValue={ role }
        callBack={ setRole }
        withResetValue={ true }
      />

      <InputTextDeprecated
        defaultHint="Ссылка на сайт журнала"
        value={ link }
        onChangeValue={ setLink }
      />

      <br />

      <ButtonSimple
        isDisabled={ (
          magazineName.trim().length < 1
          || role.trim().length < 1
          || link.trim().length < 1
        ) }
        value={ hint === 'edit' ? 'Сохранить изменения' : 'Добавить достижение' }
        variant="full-filled"
        onClickHandler={ () => {
          if (hint === 'edit') {
            onUpdateRowHandler(defaultValues.rec_id, {
              magazineName: magazineName,
              role: role,
              link: link,
              status: 'pending',
              reason: '',
              scores: null,
            })

            fieldsToDefault()
            onClickHandler()
          } else {
            onAddNewEcDataHandler({
              variables: {
                user_ids: [
                  user_ids,
                ],
                dataType: 'table_2_10',
                data: {
                  magazineName: magazineName,
                  role: role,
                  link: link,
                  status: 'pending',
                  reason: '',
                  scores: null,
                  isAddedByModer: defineUserGroupe(currentRole, legacy_effectiveContractModerGroupe),
                },
              },
            })

            fieldsToDefault()
            onClickHandler()
          };
        } }
      />
    </>
  )
}


export default Inputs_2_10
