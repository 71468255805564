import CircularProgress from '@mui/material/CircularProgress'
import './Preloader.less'


interface PreloaderProps {
  size?: number
}

const Preloader = ({
  size = 100,
}: PreloaderProps) => {
  return <div className="pre-loader">
    <CircularProgress size={ size } />
  </div>
}


export default Preloader
