import React, { useMemo, } from 'react'
import FilterDropDown from '@/legacy/components/common/FilterDropDown/FilterDropDown'
import Preloader from '@/legacy/components/common/PreLoader/Preloader'
import { useGetFormData, } from '@/legacy/gql/hooks/mfc/useApplications'


const SelectFromQueryField = ({ type, label, onClickHandler, }: any) => {
  const pagination = {
    offset: 0,
    limit: 1000,
  }

  const { data, error, loading, } = useGetFormData({
    type,
    pagination,
  })

  console.log('type', type)

  const selectFields = useMemo(() => {
    if (loading) {
      return [
        { name: <Preloader />, },
      ]
    }
    if (error) {
      return [
        { name: 'произошла ошибка, попробуйте позже', },
      ]
    } else if (data) {
      return data.referenceGetFormFieldData.items
    }
  }, [
    data,
    error,
    loading,
  ])


  return <FilterDropDown items={ selectFields } defaultHint={ label } field="name"
    onClickHandler={ onClickHandler } firstIsSelected />
}

export default SelectFromQueryField
