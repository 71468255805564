import { EC_LIST_DATA, } from '@/legacy/gql/hooks/effectiveContractHooks/useEffectiveContract'
import { useQuery, } from '@apollo/client'
import { TableRowType, UUID, } from '@/legacy/types'
import { useEffect, useMemo, } from 'react'
import { EcAchivementStatus, EcTableName, } from '@/legacy/components/EffectiveContract/constants'


interface UseGetTableDataParams {
  dataType: EcTableName,
  user_id: UUID,
  status: EcAchivementStatus | null,
  date: null | any,
  dataTypeUpdated: EcTableName | null
}


const useGetTableData = ({
  dataType,
  user_id,
  status,
  date, /** Фильтр по датам */
  dataTypeUpdated,
}: UseGetTableDataParams) => {
  const { data, loading, refetch, } = useQuery(EC_LIST_DATA, {
    variables: {
      params: {
        dataType,
        user_id,
        status,
        date,
      },
    },
  })


  useEffect(() => {
    if (dataTypeUpdated === dataType) {
      refetch()
    }
  }, [
    dataType,
    dataTypeUpdated,
    refetch,
  ])


  const result: TableRowType[] = useMemo(() => {
    if (!loading && data) {
      if (dataType === 'table_1_1') {
        return data.ecListData.map((item: any) => ({
          ...item.data,
          rec_id: item.rec_id,
          isAppeal: item.isAppeal,
          reason: item.reason,
          textAppeal: item.textAppeal,
          status: item.status,
          user_id: item.user_id,
          appealLinkId: item.appealLinkId,
        }))
      }
      if (dataType === 'table_1_11') {
        return data.ecListData.map((item: any) => ({
          ...item.data,
          rec_id: item.rec_id,
          isAppeal: item.isAppeal,
          reason: item.reason,
          textAppeal: item.textAppeal,
          user_id: item.user_id,
          appealLinkId: item.appealLinkId,
          status: item.status,
        }))
      }
      if (dataType === 'table_1_4') {
        return data.ecListData.map((item: any) => ({
          ...item.data,
          rec_id: item.rec_id,
          isAppeal: item.isAppeal,
          reason: item.reason,
          textAppeal: item.textAppeal,
          status: item.status,
          user_id: item.user_id,
          appealLinkId: item.appealLinkId,
          creator_id: item.creator_id,
          dataBase: (((item.data.list_of_vac && item.data.citation_base_scopus) ? 'Scopus, ВАК' : '') || (item.data.citation_base_scopus ? 'Scopus' : ''))
              || ((item.data.citation_base_web_of_science && item.data.citation_base_rinz) ? 'WoS, РИНЦ' : '')
              || (((item.data.list_of_vac && item.data.citation_base_rinz) ? 'РИНЦ, ВАК' : '') || (item.data.citation_base_rinz ? 'РИНЦ' : ''))
              || (((item.data.list_of_vac && item.data.citation_base_web_of_science) ? 'WoS, ВАК' : '') || (item.data.citation_base_web_of_science ? 'WoS' : ''))
              || (item.data.list_of_vac ? 'ВАК' : ''),
        }))
      }
      if (dataType === 'table_2_7') {
        return data.ecListData.map((item: any) => ({
          ...item.data,
          rec_id: item.rec_id,
          scores: item.scores,
          isAppeal: item.isAppeal,
          reason: item.reason,
          textAppeal: item.textAppeal,
          status: item.status,
          user_id: item.user_id,
          appealLinkId: item.appealLinkId,
          creator_id: item.creator_id,
          user_ids: item.user_ids,
          source_type: item.data.source_type || 'Журнал',
          quartileNumber: item.data.quartileNumber || item.data.quartile?.journal_quartile,
          countAuthors: item.data.countAuthors || item.data.authors?.length,
        }))
      }
      if (dataType === 'table_2_8') {
        return data.ecListData.map((item: any) => ({
          ...item.data,
          rec_id: item.rec_id,
          isAppeal: item.isAppeal,
          reason: item.reason,
          textAppeal: item.textAppeal,
          status: item.status,
          user_id: item.user_id,
          appealLinkId: item.appealLinkId,
          creator_id: item.creator_id,
          user_ids: item.user_ids,
          role: item.data.authors.find((author: any) => author.guid === user_id)?.is_leader ? 'Руководитель' : 'Участник',
          scores: item.data.authors.find((author: any) => author.guid === user_id)?.score,
        }))
      }
      if (dataType === 'table_1_12') {
        return data.ecListData.map((item: any) => {
          return {
            ...item.data,
            rec_id: item.rec_id,
            scores: item.scores,
            isAppeal: item.isAppeal,
            reason: item.reason,
            textAppeal: item.textAppeal,
            status: item.status,
            user_id: item.user_id,
            appealLinkId: item.appealLinkId,
            creator_id: item.creator_id,
            user_ids: item.user_ids,
          }
        })
      }
      if (dataType === 'table_3_31') {
        return data.ecListData.map((item: any) => ({
          ...item.data,
          rec_id: item.rec_id,
          isAppeal: item.isAppeal,
          reason: item.reason,
          textAppeal: item.textAppeal,
          status: item.status,
          user_id: item.user_id,
          appealLinkId: item.appealLinkId,
          creator_id: item.creator_id,
          user_ids: item.user_ids,
          scores: 50,
        }))
      }

      return data.ecListData.map((item: any) => ({
        ...item.data,
        rec_id: item.rec_id,
        scores: item.scores,
        isAppeal: item.isAppeal,
        reason: item.reason,
        textAppeal: item.textAppeal,
        status: item.status,
        user_id: item.user_id,
        appealLinkId: item.appealLinkId,
        creator_id: item.creator_id,
        user_ids: item.user_ids,
      }))
    }

    return []
  }, [
    data,
    dataType,
    loading,
    user_id,
  ])


  return result
}


export default useGetTableData
